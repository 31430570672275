import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  GET_USER_MOVEMENT_IMAGES_ACTION,
  GET_USER_MOVEMENT_IMAGES_ZIP_ACTION,
} from './proctoringExamConst'
import { ActionTypes, ProctoringExamTypeData } from './proctoringExamType'

const initialState = {
  isLoadingProctoring: false,
  isLoadingZipProctoring: false,
  errorMessage: '',
  userMovementData: {},
  userMovementDataZip: {},
}

const proctoringReducer = persistReducer(
  {
    storage,
    key: 'proctoring-data',
    whitelist: [],
  },
  (state: ProctoringExamTypeData = initialState, action: ActionTypes) => {
    switch (action.type) {
      // ==================================================
      // Proctoring Image List
      // ==================================================
      case GET_USER_MOVEMENT_IMAGES_ACTION.USER_MOVEMENT_IMAGES_REQUEST:
        return {
          ...state,
          isLoadingProctoring: true,
        }

      case GET_USER_MOVEMENT_IMAGES_ACTION.USER_MOVEMENT_IMAGES_SUCCESS:
        return {
          ...state,
          isLoadingProctoring: false,
          userMovementData: action.payload,
        }

      case GET_USER_MOVEMENT_IMAGES_ACTION.USER_MOVEMENT_IMAGES_ERROR:
        return {
          ...state,
          isLoadingProctoring: false,
        }

      case GET_USER_MOVEMENT_IMAGES_ZIP_ACTION.USER_MOVEMENT_IMAGES_ZIP_REQUEST:
        return {
          ...state,
          isLoadingZipProctoring: true,
        }

      case GET_USER_MOVEMENT_IMAGES_ZIP_ACTION.USER_MOVEMENT_IMAGES_ZIP_SUCCESS:
        return {
          ...state,
          isLoadingZipProctoring: false,
          userMovementDataZip: action.payload,
        }

      case GET_USER_MOVEMENT_IMAGES_ZIP_ACTION.USER_MOVEMENT_IMAGES_ZIP_ERROR:
        return {
          ...state,
          isLoadingZipProctoring: false,
        }

      default:
        return state
    }
  }
)

export default proctoringReducer
