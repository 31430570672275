/* eslint-disable @typescript-eslint/no-explicit-any */
import { CancelTokenSource } from 'axios'
import { toast } from 'react-toastify'
import { Action, Store } from 'redux'
import { ThunkAction } from 'redux-thunk'

import {
  QUESTION_PAPER_UPLOAD_URL,
  QUESTION_PAPER_URL,
  SUITABLE_URL,
} from '../../service/apiEndPoints'
import { apiCall, METHOD } from '../../service/baseApiCall'
import { errorToast, setLocalStorage, successToast } from '../../util/utils'
import {
  QUESTION_PAPER_DELETE,
  QUESTION_PAPER_DETAILS,
  QUESTION_PAPER_LIST,
  QUESTION_PAPER_UPLOAD,
  SUITABLE_FOR_LIST,
} from './questionPaperConst'

export const questionPaperList =
  (
    page?: number,
    // allocationType?: string,
    // subject?: string,
    // search?: string,
    // ordering?: string,
    // question_count_min?: number,
    cancelToken?: CancelTokenSource,
    randomize_questions?: boolean
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: QUESTION_PAPER_LIST.QUESTION_PAPER_LIST_REQUEST,
    })
    apiCall(
      QUESTION_PAPER_URL,
      (res: any) => {
        dispatch({
          type: QUESTION_PAPER_LIST.QUESTION_PAPER_LIST_SUCCESS,
          payload: res?.data,
        })
      },
      (errData: any) => {
        dispatch({
          type: QUESTION_PAPER_LIST.QUESTION_PAPER_LIST_ERROR,
        })
      },
      {
        params: {
          page: page || undefined,
          // allocation_type: allocationType || undefined,
          // subject: subject || undefined,
          // search: search || undefined,
          // ordering: ordering || undefined,
          // question_count_min: question_count_min || undefined,
          randomize_questions: randomize_questions ?? undefined,
        },
        cancelToken: cancelToken?.token,
      },
      METHOD.GET,
      { showToast: false }
    )
  }

export const questionPaperDetail =
  (questionPaperID): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: QUESTION_PAPER_DETAILS.QUESTION_PAPER_DETAILS_REQUEST,
    })
    apiCall(
      QUESTION_PAPER_URL + `/${questionPaperID}`,
      (res: any) => {
        dispatch({
          type: QUESTION_PAPER_DETAILS.QUESTION_PAPER_DETAILS_SUCCESS,
          payload: res?.data,
        })
      },
      (errData: any) => {
        dispatch({
          type: QUESTION_PAPER_DETAILS.QUESTION_PAPER_DETAILS_ERROR,
        })
      },
      {},
      METHOD.GET,
      { showToast: false }
    )
  }

export const questionPaperDelete =
  (
    questionPaperID,
    _handleSuccess: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: QUESTION_PAPER_DELETE.QUESTION_PAPER_DELETE_REQUEST,
    })
    apiCall(
      QUESTION_PAPER_URL + `/${questionPaperID}`,
      (res: any) => {
        dispatch({
          type: QUESTION_PAPER_DELETE.QUESTION_PAPER_DELETE_SUCCESS,
          payload: res?.data,
        }),
          _handleSuccess()
      },
      (errData: any) => {
        dispatch({
          type: QUESTION_PAPER_DELETE.QUESTION_PAPER_DELETE_ERROR,
        })
      },
      {},
      METHOD.DELETE,
      { showToast: false }
    )
  }

export const suitableForList =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: SUITABLE_FOR_LIST.SUITABLE_FOR_LIST_REQUEST,
    })
    apiCall(
      SUITABLE_URL,
      (res: any) => {
        dispatch({
          type: SUITABLE_FOR_LIST.SUITABLE_FOR_LIST_SUCCESS,
          payload: res,
        })
      },
      (errData: any) => {
        dispatch({
          type: SUITABLE_FOR_LIST.SUITABLE_FOR_LIST_ERROR,
        })
      },
      {},
      METHOD.GET,
      { showToast: false }
    )
  }

export const questionPaperUpload =
  (
    payload: Record<string, string | number | File | Array<string>>,
    handleSuccess: (...args) => void,
    handleError?: (...args) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: QUESTION_PAPER_UPLOAD.QUESTION_PAPER_UPLOAD_REQUEST,
    })
    const formPayload = new FormData()
    formPayload.append(
      'question_paper_name',
      payload.question_paper_name?.toString()
    )
    formPayload.append('file', payload.file as string)
    formPayload.append('description', payload.description as string)
    formPayload.append('question_count', payload.question_count as string)
    formPayload.append('state', 'PUBLISHED')
    formPayload.append('suitable_for', JSON.stringify(payload.suitable_for))

    apiCall(
      QUESTION_PAPER_UPLOAD_URL,
      (res: any) => {
        dispatch({
          type: QUESTION_PAPER_UPLOAD.QUESTION_PAPER_UPLOAD_SUCCESS,
        })
        successToast('Question paper has been uploaded')
        handleSuccess(res)
      },
      (err: any) => {
        dispatch({
          type: QUESTION_PAPER_UPLOAD.QUESTION_PAPER_UPLOAD_ERROR,
        })
        if (!handleError) {
          errorToast(
            err?.message?.file?.[0] ||
              'Question paper can not be uploaded. Please check error file'
          )
          !err?.message?.file?.[0] &&
            !err?.message?.non_field_errors?.[0] &&
            handleSuccess(err)
        } else {
          handleError(err)
        }
        // showErrorToastMessage(
        //   err?.message?.file?.[0] || err?.message || 'Something went wrong'
        // )
      },
      formPayload,
      METHOD.POST,
      {
        showToast: false,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  }
