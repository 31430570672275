export default {
  formId: 'createNewExamForm',
  formField: {
    examName: {
      name: 'examName',
      label: 'Exam name*',
      requiredErrorMsg: 'Exam name is required',
    },
    description: {
      name: 'description',
      label: 'Description*',
      requiredErrorMsg: 'Description is required',
    },
    examinationType: {
      name: 'examinationType',
      label: 'ExaminationType*',
      requiredErrorMsg: 'Description is required',
    },
    examType: {
      name: 'examType',
      label: 'Exam Type*',
      requiredErrorMsg: 'Exam Type is required',
    },
    examDuration: {
      name: 'examDuration',
      label: 'Exam Duration*',
      requiredErrorMsg: 'Exam Duration is required',
    },
    examValidForm: {
      name: 'examValidForm',
      subname: 'examValidFormTime',
      label: 'Exam Valid Form*',
      requiredErrorMsg: 'Exam Valid Form is required',
      invalidErrorMsg: 'Must be greater than current date',
    },
    examValidTill: {
      name: 'examValidTill',
      subname: 'examValidTillTime',
      label: 'Exam Valid Till*',
      requiredErrorMsg: 'Exam Valid Till is required',
      invalidErrorMsg: '*required',
    },
    resultDeclarationOn: {
      name: 'resultDeclarationOn',
      label: 'Result Declaration On*',
      requiredErrorMsg: 'Result Declaration On is required',
      invalidErrorMsg: '*required',
    },
    allocationType: {
      name: 'allocationType',
      label: 'Type*',
      requiredErrorMsg: 'Allocation type is required',
    },
    email: {
      name: 'email',
      label: 'Email*',
      requiredErrorMsg: 'Email is required',
    },
    batchName: {
      name: 'batchName',
      label: 'Batch Name*',
      requiredErrorMsg: 'Batch name is required',
    },
    student: {
      name: 'Student',
      label: 'Student*',
      requiredErrorMsg: 'Student is required',
    },
    syllabus: {
      name: 'syllabus',
      label: 'syllabus*',
      requiredErrorMsg: 'Syllabus is required',
    },
    name: {
      name: 'name',
      label: 'name*',
      requiredErrorMsg: 'Questionnaire name is required',
    },
    evalution: {
      name: 'evalution',
      label: 'evalution*',
      requiredErrorMsg: 'Evaluation mode is required',
    },
    totalQ: {
      name: 'totalQ',
      label: 'totalQ*',
      requiredErrorMsg: 'Total questions is required',
    },
    score: {
      name: 'score',
      label: 'score*',
      requiredErrorMsg: 'Score is required',
    },
    passingMark: {
      name: 'passingMark',
      label: 'passingMark*',
      requiredErrorMsg: 'Passing marks is required',
    },
    questionVisibility: {
      name: 'questionVisibility',
      label: 'questionVisibility*',
      requiredErrorMsg: 'Question visibility is required.',
    },
    mode: {
      name: 'mode',
      label: 'mode*',
      requiredErrorMsg: 'Mode is required',
    },
    selectType: {
      name: 'selectType',
      label: 'selectType*',
      requiredErrorMsg: 'Type is required',
    },
    easy: {
      name: 'easy',
      label: 'easy*',
      requiredErrorMsg: 'This field is required',
    },
    medium: {
      name: 'medium',
      label: 'medium*',
      requiredErrorMsg: 'This field is required',
    },
    hard: {
      name: 'hard',
      label: 'hard*',
      requiredErrorMsg: 'This field is required',
    },
  },
}
