import * as excel from 'exceljs'
import { saveAs } from 'file-saver'
import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Form, Image, InputGroup, Modal, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'

import BookmarkIcon from '../../../../assets/images/bookmark-icon.svg'
import BookmarkedIcon from '../../../../assets/images/bookmarked.svg'
import CollapsePlusIcon from '../../../../assets/images/collapse-plus.svg'
import CommentRatingIcon from '../../../../assets/images/comment-rating.svg'
import DownloadBlueIcon from '../../../../assets/images/download-blue.svg'
import GrayReviewStarIcon from '../../../../assets/images/gray-review-star.svg'
import ReviewStarIcon from '../../../../assets/images/review-star.svg'
import SearchIcon from '../../../../assets/images/search-icon.svg'
import { Loader } from '../../../../components/Loader'
import Pagination from '../../../../components/Pagination'
import {
  addReviewAndCommentThunk,
  candidateBookmarkThunk,
  candidateDataDownloadExcel,
  examByIdList,
  getCandidateReportListThunk,
  getReviewAndCommentThunk,
} from '../../../../redux/exam/examActions'
import { RESET_CANDIDATE_DOWNLOAD_REPORT } from '../../../../redux/exam/examConst'
import { Store } from '../../../../redux/rootType'
import { dispatch } from '../../../../redux/store'
import { convertMsToHM } from '../../../../util/convertTime'
import { getPercentage, successToast } from '../../../../util/utils'

/**
 * ==================================================
 * Global code
 * ==================================================
 */
const tableHeading = [
  { id: 1, heading: 'Bookmark' },
  { id: 2, heading: 'Candidate Name' },
  { id: 3, heading: 'Score' },
  { id: 4, heading: 'Skill Level' },
  { id: 5, heading: 'Comment & Rating' },
  { id: 6, heading: 'Action' },
]

const reviewData = [
  { id: 1, isSelected: false },
  { id: 2, isSelected: false },
  { id: 3, isSelected: false },
  { id: 4, isSelected: false },
  { id: 5, isSelected: false },
]

const itemsPerPage = 10

const CandidatesTab = ({ eachExamExaminees, examName }) => {
  /**
   * ==================================================
   * const variable
   * ==================================================
   */
  const params = useParams()
  const examId = params['Id']
  const location = useLocation()
  const navKey = location?.state?.tabKey || ''

  const candidateReportList = useSelector(
    (state: Store) => state?.examReducer?.candidateReportList
  ) as any
  const isLoadingCandidateReportList = useSelector(
    (state: Store) => state?.examReducer?.isLoadingCandidateReportList
  )
  const candidateDataListDownloadExcel = useSelector(
    (state: Store) => state?.examReducer?.candidateListDownloadExcel
  )
  const isGetCandidateDownloadExcelLoading = useSelector(
    (state: Store) => state?.examReducer?.isGetCandidateDownloadExcelLoading
  )
  const examDataById = useSelector(
    (state: Store) => state?.examReducer?.examDataById
  )

  /**
   * ==================================================
   * Component state
   * ==================================================
   */
  const [allQuestions, setAllQuestions] = useState<any>([])
  const [paginatedData, setPaginatedData] = useState(
    allQuestions?.length ? (allQuestions || [])?.slice(0, itemsPerPage) : []
  )
  const [page, setPage] = useState(1)
  const [show2, setShow2] = useState(false)
  const [reviewIcons, setReviewIcons] = useState<any>()
  const [comment, setComment] = useState('')
  const [individualData, setIndividualData] = useState<any>()
  const [ordering, setOrdering] = React.useState({ name: '', rank: '' })

  /**
   * ==================================================
   * functionality
   * ==================================================
   */
  const handleClose2 = () => {
    setShow2(false)
    setComment('')
  }
  const handleShow2 = (item, ratingData) => {
    const selectId = ratingData?.rating
      ? ratingData?.rating
      : item?.review_history[0]
    const mapData = reviewData.map((item, index, array) => {
      const isSelected =
        selectId || array.filter((item) => item.isSelected).pop()?.id
      return isSelected
        ? item.id <= isSelected
          ? { ...item, isSelected: true }
          : item
        : item
    })
    setReviewIcons(mapData)
    setIndividualData(item)
    setShow2(true)
  }

  const handleReviewAction = (index) => {
    if (individualData?.review_history?.length < 1) {
      const newData = reviewIcons?.map((item) => {
        const isSelected = item.id <= index + 1 ? true : false
        return { ...item, isSelected }
      })
      setReviewIcons(newData)
    }
  }

  const handlePercentage = (percentageData) => {
    if (percentageData <= 25) {
      return <span className="text-danger">({percentageData}%)</span>
    } else if (percentageData > 25 && percentageData <= 50) {
      return <span className="text-warning">({percentageData}%)</span>
    } else if (percentageData > 50 && percentageData <= 80) {
      return <span style={{ color: '#B0BD1D' }}>({percentageData}%)</span>
    } else if (percentageData > 80 && percentageData <= 100) {
      return <span className="text-success">({percentageData}%)</span>
    } else {
      return <span>({'-'})</span>
    }
  }

  const progressStatus = (badge) => {
    switch (badge) {
      case 'STARTED':
        return <span className="badge-info py-1 px-2 rounded">In Progress</span>

      default:
        return <span className="gray-badge py-1 px-2">Not Attempted</span>
    }
  }

  const handleReviewAndRating = () => {
    if (individualData?.review_history?.length <= 0) {
      const ratingData = reviewIcons?.filter((item) => item?.isSelected)?.length
      const payload = {
        rating: ratingData,
        comment: comment,
        examinee_exam: individualData?.id,
      }
      if (ratingData || comment) {
        dispatch(
          addReviewAndCommentThunk(payload, () => {
            dispatch(getCandidateReportListThunk({ id: examId }))
          })
        )
        setComment('')
        handleClose2()
      }
    } else {
      setComment('')
      handleClose2()
    }
  }

  const bookmarkCandidateFun = (id: string | number, bookmark: boolean) => {
    dispatch(
      candidateBookmarkThunk(id, !bookmark, examId, () => {
        dispatch(getCandidateReportListThunk({ id: examId }))
        if (bookmark) {
          successToast('Bookmarked removed successfully')
        } else {
          successToast('Bookmarked added successfully')
        }
      })
    )
  }

  const handleSearchDebounce = _.debounce((val: string) => {
    const payload = { id: examId, search: val.trim() }
    dispatch(getCandidateReportListThunk(payload))
  }, 500)

  const getExamId = () => examId

  const getOrdering = () => ordering || null

  const getIsGetStudentDownloadExcelLoading = () => {
    if (isGetCandidateDownloadExcelLoading) return true
  }

  const getValidString = (str: string | undefined) => str ?? ''
  const getDefaultString = (str?: string) => str ?? '-'
  const getScreenViolation = (count?: number) => (count === 4 ? 'Yes' : 'No')
  const getTestScore = (item) => {
    return item?.examinee_status ??
      item?.student_performance?.marks_obtained ??
      item?.student_performance?.total_marks ??
      item?.student_performance?.percentage
      ? item?.student_performance?.marks_obtained +
          '/' +
          item?.student_performance?.total_marks +
          ' (' +
          Number(item?.student_performance?.percentage) +
          '%)'
      : '-'
  }
  const getCandidateStatus = (item) => {
    return item?.student_performance?.submission_type === 'P'
      ? 'Rejected'
      : item?.student_performance?.marks_obtained <
          examDataById?.passing_marks && item?.examinee_status !== 'STARTED'
      ? 'Not Shortlisted'
      : item?.student_performance?.marks_obtained >= examDataById?.passing_marks
      ? 'Shortlisted'
      : '-'
  }
  const getAnswerCount = (count?: number) => count ?? 0
  const getLastQualification = (
    qualifications: {
      id: number
      qualification: {
        id: number
        name: string
        type: string
      }
      college: string
      status: string
      grade_status: string
      grade_value: string
      started_year: number
      completed_year: number
    }[]
  ) => {
    if (!Array.isArray(qualifications)) return []
    for (const qualification of qualifications) {
      if (
        qualification?.qualification?.type === 'M' ||
        qualification?.qualification?.type === 'B' ||
        qualification?.qualification?.type === 'H' ||
        qualification?.qualification?.type === 'S'
      ) {
        return {
          lastQualification: getDefaultString(
            qualification?.qualification?.name
          ),
          lastQualificationPercentage: getDefaultString(
            qualification?.grade_value
          ),
          lastQualificationPassingYear: getDefaultString(
            qualification?.completed_year?.toString()
          ),
          lastQualificationCollege: getDefaultString(qualification.college),
        }
      }
    }
    return {
      lastQualification: '-',
      lastQualificationPassingYear: '-',
      lastQualificationPercentage: '-',
      lastQualificationCollege: '-',
    }
  }
  const getSubjectWiseScore = (item, getAllTopics, data) => {
    return item?.subject_wise_score?.length
      ? item?.subject_wise_score
          ?.map((score) => ({
            [`${score?.subject?.name?.toLowerCase()}-marks`]:
              item?.examinee_status ||
              +score?.marks_obtained ||
              score?.total_marks
                ? score?.marks_obtained
                : '-',
            [`${score?.subject?.name?.toLowerCase()}-total-marks`]:
              item?.examinee_status ? score?.total_marks || '-' : '-',
            [`${score?.subject?.name?.toLowerCase()}-percentage`]:
              item?.examinee_status ||
              +score?.marks_obtained ||
              score?.total_marks
                ? Number(
                    getPercentage(
                      score?.marks_obtained,
                      score?.total_marks || 1
                    ).toFixed(2)
                  ) + '%'
                : '-',
            ...getAllTopics(
              item?.topic_wise_score,
              score?.subject,
              data
            ).reduce((a, v) => ({ ...v, ...a }), {}),
          }))
          .reduce((a, v) => ({ ...v, ...a }), {})
      : (data || candidateDataListDownloadExcel)
          ?.find((studentData) => studentData?.subject_wise_score?.length)
          ?.subject_wise_score?.map((score) => ({
            [`${score?.subject?.name?.toLowerCase()}-total-marks`]: '-',
            [`${score?.subject?.name?.toLowerCase()}-marks`]: '-',
            [`${score?.subject?.name?.toLowerCase()}-percentage`]: '-',
            ...getAllTopics([], score?.subject, data).reduce(
              (a, v) => ({ ...v, ...a }),
              {}
            ),
          }))
          .reduce((a, v) => ({ ...v, ...a }), {})
  }
  const headers = [
    {
      key: 'number',
      header: 'Sr. No.',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'name',
      header: 'Name',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'email',
      header: 'Email Id',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'mobile',
      header: 'Phone No',
      style: {
        alignment: { horizontal: 'left' },
      },
    },

    {
      key: 'attemptDate',
      header: 'Attempt Date',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'examStatus',
      header: 'Exam Status',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'testScore',
      header: 'Test Score',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'passingMarks',
      header: 'Passing Marks',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'passingPercentage',
      header: 'Passing Percentage',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'rank',
      header: 'Rank',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'candidateStatus',
      header: 'Candidate Status',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'lastQualification',
      header: 'Last Qualification',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'lastQualificationPercentage',
      header: 'Last Qualification Percentage',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'lastQualificationPassingYear',
      header: 'Last Qualification Passing Year',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'lastQualificationCollege',
      header: 'College',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'timeTaken',
      header: 'Time Taken',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'totalQuestions',
      header: 'Total Questions',
      style: {
        alignment: { horizontal: 'left' },
      },
    },

    {
      key: 'correctAnswers',
      header: 'Correct Answers',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'incorrectAnswers',
      header: 'Incorrect Answers',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'partiallyCorrectAnswers',
      header: 'Partially Correct Answers',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'unanswered',
      header: 'Unanswered',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
    {
      key: 'screenViolation',
      header: 'Screen Violation',
      style: {
        alignment: { horizontal: 'left' },
      },
    },
  ]
  const getTopicData = (data?: any) =>
    data || candidateDataListDownloadExcel || []

  const getAllTopics = (topicScore?: any, subject?: any, data?: any) => {
    const allTopics = getTopicData(data)
      ?.map((studentData) => studentData?.topic_wise_score)
      .flat(Infinity)
    const uniqueTopics = _.uniqBy(allTopics, (data: any) => data?.topic?.id)
    const presentTopics = allTopics?.map((topic) => {
      if (topic?.topic?.subject === subject?.id) {
        const topicIndex = topicScore?.findIndex(
          (topicScore) =>
            // topicScore?.id === topic?.id &&
            topicScore?.topic?.id === topic?.topic?.id &&
            topicScore?.topic?.subject === topic?.topic?.subject
        )
        if (topicIndex > -1) {
          return {
            [`${getValidString(subject?.name?.toLowerCase())}/${getValidString(
              topicScore[topicIndex]?.topic?.name?.toLowerCase()
            )}-marks`]: topicScore[topicIndex]?.marks_obtained,
            [`${getValidString(subject?.name?.toLowerCase())}/${getValidString(
              topicScore[topicIndex]?.topic?.name?.toLowerCase()
            )}-percentage`]:
              +topicScore[topicIndex]?.marks_obtained ||
              topicScore[topicIndex]?.total_marks
                ? Number(
                    getPercentage(
                      +topicScore[topicIndex]?.marks_obtained,
                      topicScore[topicIndex]?.total_marks || 1
                    ).toFixed(2)
                  ) + '%'
                : '-',
            [`${subject?.name?.toLowerCase() || ''}/${getValidString(
              topicScore[topicIndex]?.topic?.name?.toLowerCase()
            )}-total-marks`]: topicScore[topicIndex]?.total_marks || '-',
          }
        }
        return {
          [`${getValidString(subject?.name?.toLowerCase())}/${getValidString(
            topic?.topic?.name?.toLowerCase()
          )}-marks`]: '-',
          [`${getValidString(subject?.name?.toLowerCase())}/${getValidString(
            topic?.topic?.name?.toLowerCase()
          )}-percentage`]: '-',
          [`${getValidString(subject?.name?.toLowerCase())}/${getValidString(
            topic?.topic?.name?.toLowerCase()
          )}-total-marks`]: '-',
        }
      }
    })
    return topicScore ? presentTopics?.filter((topic) => topic) : uniqueTopics
  }
  const getAdditionalHeaders = (studentDataListDownloadExcel) => {
    return (
      (Array.isArray(studentDataListDownloadExcel) &&
        studentDataListDownloadExcel.find(
          (studentData) => studentData?.subject_wise_score?.length
        ) &&
        studentDataListDownloadExcel
          ?.find((studentData) => studentData?.subject_wise_score?.length)
          ?.subject_wise_score?.map((performance) => [
            // {
            //   key: `${getValidString(
            //     performance?.subject?.name?.toLowerCase()
            //   )}-total-marks`,
            //   header: `${getValidString(
            //     performance?.subject?.name
            //   )}-total-marks`,
            //   style: {
            //     alignment: { horizontal: 'left' },
            //   },
            // },
            // {
            //   key: `${getValidString(
            //     performance?.subject?.name?.toLowerCase()
            //   )}-marks`,
            //   header: `${getValidString(performance?.subject?.name)}-marks`,
            //   style: {
            //     alignment: { horizontal: 'left' },
            //   },
            // },
            // {
            //   key: `${getValidString(
            //     performance?.subject?.name?.toLowerCase()
            //   )}-percentage`,
            //   header: `${getValidString(
            //     performance?.subject?.name
            //   )}-percentage`,
            //   style: {
            //     alignment: { horizontal: 'left' },
            //   },
            // },
            ...(
              getAllTopics(
                undefined,
                undefined,
                studentDataListDownloadExcel
              ) || []
            ).flatMap((topic) =>
              (topic?.topic?.subject === performance.subject?.id
                ? [topic]
                : []
              ).map((topic) => [
                {
                  key: `${getValidString(
                    performance.subject?.name
                  ).toLowerCase()}/${getValidString(
                    topic?.topic?.name
                  ).toLowerCase()}-total-marks`,
                  header: `${getValidString(topic?.topic?.name)}-total-marks`,
                  style: {
                    alignment: {
                      horizontal: 'left',
                    },
                  },
                },
                {
                  key: `${getValidString(
                    performance.subject?.name
                  ).toLowerCase()}/${getValidString(
                    topic?.topic?.name
                  ).toLowerCase()}-marks`,
                  header: `${getValidString(
                    topic?.topic?.name
                  )}-obtained-marks`,
                  style: {
                    alignment: {
                      horizontal: 'left',
                    },
                  },
                },
                {
                  key: `${getValidString(
                    performance.subject?.name
                  ).toLowerCase()}/${getValidString(
                    topic?.topic?.name
                  ).toLowerCase()}-percentage`,
                  header: `${getValidString(topic?.topic?.name)}-percentage`,
                  style: {
                    alignment: {
                      horizontal: 'left',
                    },
                  },
                },
              ])
            ),
          ])
          .flat(Infinity)) ||
      []
    )
  }

  /**
   * xlsx file generate functionality
   * @param data
   * @param fileName
   */
  function generateXLSX(
    data: any,
    fileName: any,
    header: { key: string; header: string; style?: any }[],
    getAllTopics: any
  ) {
    const getExamineeStatus = (examStatus: string | null) => {
      if (examStatus === 'STARTED') {
        return 'Inprogress'
      } else if (examStatus === 'COMPLETED') {
        return 'Submitted'
      } else {
        return 'Unattempted'
      }
    }

    const workbook: excel.Workbook = new excel.Workbook()
    const sheet: excel.Worksheet = workbook.addWorksheet(fileName)
    sheet.getRow(1).font = { bold: true }
    sheet.columns = header
    let index = 1
    for (const item of data || []) {
      sheet.addRow({
        number: index,
        name: `${item?.student?.first_name} ${getValidString(
          item?.student?.last_name
        )}`,
        email: getDefaultString(item?.student?.email),
        mobile: getDefaultString(item?.student?.mobile_number),
        attemptDate: moment(
          item?.student_performance?.start_time?.toString()
        ).format('MM/DD/YYYY'),
        examStatus: getExamineeStatus(item?.examinee_status),
        screenViolation: getScreenViolation(
          item?.student_performance?.screen_violation_count
        ),
        testScore: getTestScore(item),
        passingMarks: getDefaultString(examDataById?.passing_marks),
        passingPercentage: getDefaultString(examDataById?.passing_percentage),
        rank:
          item?.examinee_status === 'COMPLETED'
            ? getDefaultString(item?.student_performance?.rank)
            : '-',
        candidateStatus: getCandidateStatus(item),
        college:
          item?.student?.educations?.length > 0
            ? item?.student?.educations?.map((clg) => clg?.college)
            : '-',
        qualification:
          item?.student?.educations?.length > 0
            ? item?.student?.educations?.map((clg) => clg?.qualification?.name)
            : '-',
        timeTaken: convertMsToHM(
          moment(item?.student_performance?.end_time).diff(
            item?.student_performance?.start_time,
            'second'
          )
        ),

        totalQuestions: getDefaultString(item?.question_count),
        correctAnswers: getAnswerCount(
          item?.student_performance?.correct_answers
        ),
        incorrectAnswers: getAnswerCount(
          item?.student_performance?.incorrect_answers
        ),
        partiallyCorrectAnswers: getAnswerCount(
          item?.student_performance?.partially_correct_answers
        ),
        unanswered: getAnswerCount(
          item?.student_performance?.unattempted_questions
        ),
        ...getLastQualification(item?.student?.educations),
        ...getSubjectWiseScore(item, getAllTopics, data),
      })
      index += 1
    }
    sheet.columns?.forEach((column: any) => {
      const lengths: any = column.values?.map((v: any) =>
        column?.key === 'number'
          ? v.toString().length + 1
          : v.toString().length + 20
      )
      const maxLength = Math.max(
        ...lengths.filter((v: number) => typeof v === 'number')
      )
      column.width = maxLength
    })
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      saveAs(blob, `${fileName}.xlsx`)
    })
  }

  const handleDownload = () => {
    getIsGetStudentDownloadExcelLoading()
    if (!candidateDataListDownloadExcel?.length) {
      dispatch(
        candidateDataDownloadExcel(
          false,
          getExamId(),
          null,
          getOrdering(),
          null,
          true,
          (candidateDataListDownloadExcel) => {
            generateXLSX(
              candidateDataListDownloadExcel,
              `${examDataById?.name?.replace(
                // eslint-disable-next-line no-useless-escape
                /[*?:\\/[\]]/g,

                '_'
              )}_Candidates`,
              [
                ...headers,
                ...(getAdditionalHeaders(candidateDataListDownloadExcel) || []),
              ],
              getAllTopics
            )
          }
        )
      )
    } else {
      generateXLSX(
        candidateDataListDownloadExcel,
        `${examDataById?.name?.replace(
          // eslint-disable-next-line no-useless-escape
          /[*?:\\/[\]]/g,
          '_'
        )}_Candidates`,
        [
          ...headers,
          ...((Array.isArray(candidateDataListDownloadExcel) &&
            candidateDataListDownloadExcel?.find(
              (studentData) => studentData?.subject_wise_score?.length
            ) &&
            candidateDataListDownloadExcel
              ?.find((studentData) => studentData?.subject_wise_score?.length)
              ?.subject_wise_score?.map((performance) => [
                // {
                //   key: `${getValidString(
                //     performance?.subject?.name?.toLowerCase()
                //   )}-total-marks`,
                //   header: `${getValidString(
                //     performance?.subject?.name
                //   )}-total-marks`,
                //   style: {
                //     alignment: { horizontal: 'left' },
                //   },
                // },
                // {
                //   key: `${getValidString(
                //     performance?.subject?.name?.toLowerCase()
                //   )}-marks`,
                //   header: `${getValidString(performance?.subject?.name)}-marks`,
                //   style: {
                //     alignment: { horizontal: 'left' },
                //   },
                // },
                // {
                //   key: `${getValidString(
                //     performance?.subject?.name?.toLowerCase()
                //   )}-percentage`,
                //   header: `${getValidString(
                //     performance?.subject?.name
                //   )}-percentage`,
                //   style: {
                //     alignment: { horizontal: 'left' },
                //   },
                // },
                ...(getAllTopics()?.flatMap((topic) => {
                  if (topic?.topic?.subject === performance.subject?.id) {
                    const subjectName = getValidString(
                      performance.subject?.name?.toLowerCase()
                    )
                    const topicName = getValidString(
                      topic?.topic?.name?.toLowerCase()
                    )

                    return [
                      {
                        key: `${subjectName}/${topicName}-total-marks`,
                        header: `${topicName}-total-marks`,
                        style: {
                          alignment: { horizontal: 'left' },
                        },
                      },
                      {
                        key: `${subjectName}/${topicName}-marks`,
                        header: `${topicName}-obtained-marks`,
                        style: {
                          alignment: { horizontal: 'left' },
                        },
                      },
                      {
                        key: `${subjectName}/${topicName}-percentage`,
                        header: `${topicName}-percentage`,
                        style: {
                          alignment: { horizontal: 'left' },
                        },
                      },
                    ]
                  } else {
                    return []
                  }
                }) || []),
              ])
              .flat(Infinity)) ||
            []),
        ],
        getAllTopics
      )
    }
  }

  /**
   * ==================================================
   * use effect handling
   * ==================================================
   */
  // useEffect(() => {
  //   dispatch(getCandidateReportListThunk(examId))
  // }, [])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_CANDIDATE_DOWNLOAD_REPORT })
    }
  }, [])

  useEffect(() => {
    setAllQuestions(candidateReportList || [])
  }, [candidateReportList?.length, isLoadingCandidateReportList])

  useEffect(() => {
    const newOffset = ((page - 1) * itemsPerPage) % (allQuestions?.length || 1)
    const newLimit = page * itemsPerPage
    setPaginatedData(
      allQuestions?.length > 0 ? allQuestions?.slice(newOffset, newLimit) : []
    )
    setAllQuestions((prev) => prev)
  }, [page, allQuestions, isLoadingCandidateReportList])

  useEffect(() => {
    if (examId) {
      dispatch(examByIdList(false, examId))
    }
  }, [examId])

  return (
    <>
      {' '}
      {/* <InputGroup className="search-box mb-4 pb-1">
        <InputGroup.Text id="basic-addon1">
          <Image src={SearchIcon} />
        </InputGroup.Text>
        <Form.Control
          placeholder="Search"
          aria-label="Search"
          onChange={(e) => handleSearchDebounce(e?.target?.value)}
          aria-describedby="basic-addon1"
        />
      </InputGroup>
      <div className="d-flex justify-content-end">
        {candidateDataListDownloadExcel && (
          <span className="ms-3 light-primary-box p-2 pointer">
            <Image src={DownloadBlueIcon} onClick={handleDownload} />
          </span>
        )}
      </div> */}
      <div className="d-flex justify-content-between">
        <InputGroup className="search-box mb-4 pb-1">
          <InputGroup.Text id="basic-addon1">
            <Image src={SearchIcon} />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search"
            aria-label="Search"
            onChange={(e) => handleSearchDebounce(e?.target?.value)}
            aria-describedby="basic-addon1"
          />
        </InputGroup>
        <div
          className="d-flex justify-content-end"
          style={{ height: 'fit-content' }}
        >
          {candidateDataListDownloadExcel && (
            <span className="ms-3 light-primary-box p-2 pointer">
              <Image src={DownloadBlueIcon} onClick={handleDownload} />
            </span>
          )}
        </div>
      </div>
      <Table
        bordered
        hover
        responsive
        className="overflow-scroll candidate-tab-table"
      >
        <thead>
          <tr className="border-0">
            {/* <th>Bookmark</th> */}
            {tableHeading.map((header) => {
              return <th key={header.id}>{header.heading}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {isLoadingCandidateReportList ? (
            <Loader />
          ) : paginatedData?.length > 0 ? (
            (paginatedData || []).map((item, index) => (
              <tr>
                <td>
                  <Image
                    src={item?.is_bookmark ? BookmarkedIcon : BookmarkIcon}
                    className="pointer"
                    onClick={() =>
                      bookmarkCandidateFun(item?.student?.id, item?.is_bookmark)
                    }
                  />
                </td>
                <td>
                  {item?.student?.first_name} {item?.student?.last_name}
                </td>
                <td>
                  {item?.student_performance?.marks_obtained
                    ? `${item?.student_performance?.marks_obtained}/${item?.student_performance?.total_marks}`
                    : '-'}{' '}
                  {item?.student_performance?.marks_obtained
                    ? handlePercentage(item?.student_performance?.percentage)
                    : ''}
                </td>
                <td>{item?.student_performance?.skill_level || '-'}</td>
                <td className="text-center">
                  <Image
                    className="pointer"
                    src={
                      item?.review_history?.length
                        ? CommentRatingIcon
                        : CollapsePlusIcon
                    }
                    onClick={() => {
                      if (item?.review_history?.length > 0) {
                        dispatch(
                          getReviewAndCommentThunk(
                            item?.review_history[
                              item?.review_history?.length - 1
                            ],
                            (res) => {
                              handleShow2(item, res?.data?.review)
                              setComment(res?.data?.review?.comment)
                            }
                          )
                        )
                      } else {
                        handleShow2(item, '')
                      }
                    }}
                  />
                </td>
                <td>
                  {item?.examinee_status === 'COMPLETED' ? (
                    <Link
                      to={`/exam-report/${item?.student?.id}`}
                      state={{
                        examName: examName,
                        examId: examId,
                        navKey: navKey,
                      }}
                      className="text-primary fw-bold"
                    >
                      View Report
                    </Link>
                  ) : (
                    progressStatus(item?.examinee_status)
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8} className="text-center">
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </Table>{' '}
      {candidateReportList?.length > itemsPerPage ? (
        <Pagination
          page={page}
          setPage={setPage}
          tableCount={candidateReportList?.length}
          itemsPerPage={itemsPerPage}
        />
      ) : null}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Comment & Rating</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body py-4">
          <div className="d-flex align-items-center assign-client-space">
            <div className="assign-client-modal pe-4">
              <h6 className="mb-1">{`${individualData?.student?.first_name} ${individualData?.student?.last_name}`}</h6>
              <span className="sub-title-clr">
                {individualData?.student?.email || '-'}
              </span>
            </div>
            <div className="assign-client-modal px-4">
              <small className="fw-bold custom-text-clr">Role</small>
              <p className="mb-0 mt-1">
                {individualData?.student?.role?.label}
              </p>
            </div>
            <div className="ms-4">
              <small className="fw-bold custom-text-clr">Experience</small>
              <p className="mb-0 mt-1">
                {individualData?.student?.total_year_of_experience
                  ? `${individualData?.student?.total_year_of_experience} ${
                      individualData?.student?.total_year_of_experience > 1
                        ? 'years'
                        : 'year'
                    }`
                  : '-'}
              </p>
            </div>
          </div>
          <p className="fw-bold mb-2">Rate</p>
          <div className="mb-3">
            {reviewIcons?.map((reviewIcon, index) => {
              return (
                <Image
                  onClick={() => handleReviewAction(index)}
                  className="me-2"
                  src={
                    reviewIcons[index]?.isSelected
                      ? ReviewStarIcon
                      : GrayReviewStarIcon
                  }
                />
              )
            })}
            {/* <Image className="me-2" src={ReviewStarIcon} />
            <Image className="me-2" src={ReviewStarIcon} />
            <Image className="me-2" src={ReviewStarIcon} />
            <Image className="me-2" src={ReviewStarIcon} />
            <Image className="me-2" src={GrayReviewStarIcon} /> */}
          </div>
          <Form.Group
            className="form-group position-relative"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Comment</Form.Label>
            <Form.Control
              rows={5}
              as="textarea"
              name="comment"
              className="form-control"
              placeholder="Potential candidate."
              value={comment}
              disabled={
                individualData?.review_history?.length > 0 ? true : false
              }
              style={{ resize: 'none' }}
              maxLength={250}
              cols={8}
              onChange={(event) => setComment(event.target.value)}
            />
            <span className="position-absolute bottom-0 end-0 me-2 mb-2 light-gray-text">
              {comment?.length}/250
            </span>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button
            className="btn  btn-primary px-4"
            type="button"
            onClick={handleReviewAndRating}
          >
            {individualData?.review_history?.length > 0 ? 'Close' : 'Save'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default CandidatesTab
