/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  QUESTION_PAPER_DELETE,
  QUESTION_PAPER_DETAILS,
  QUESTION_PAPER_LIST,
  QUESTION_PAPER_UPLOAD,
  REMOVE_CART_ITEMS,
  SET_CART_ITEMS,
  SUITABLE_FOR_LIST,
} from './questionPaperConst'
import { ActionTypes, QuestionPaperData } from './questionPaperType'

const initialState = {
  isLoading: false,
  errorMessage: '',
  questionPaperList: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  suitableForArray: [],
  questionPaperDetails: {},
  itemsInCart: [],
}

const questionPaperReducer = persistReducer(
  {
    storage,
    key: 'questionPaper',
    whitelist: ['questionPaperList', 'questionPaperDetails'],
  },
  (state: QuestionPaperData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case QUESTION_PAPER_LIST.QUESTION_PAPER_LIST_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case QUESTION_PAPER_LIST.QUESTION_PAPER_LIST_SUCCESS:
        const papers: Array<any> = action.payload
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          questionPaperList: papers,
        }

      case QUESTION_PAPER_LIST.QUESTION_PAPER_LIST_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }
      case QUESTION_PAPER_DETAILS.QUESTION_PAPER_DETAILS_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case QUESTION_PAPER_DETAILS.QUESTION_PAPER_DETAILS_SUCCESS:
        const questionPaper: object = action.payload
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          questionPaperDetails: questionPaper,
        }

      case QUESTION_PAPER_DETAILS.QUESTION_PAPER_DETAILS_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }
      case QUESTION_PAPER_DELETE.QUESTION_PAPER_DELETE_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case QUESTION_PAPER_DELETE.QUESTION_PAPER_DELETE_SUCCESS:
        return {
          ...state,
          isLoading: false,
        }
      case QUESTION_PAPER_DELETE.QUESTION_PAPER_DELETE_ERROR:
        return {
          ...state,
          isLoading: false,
        }
      case QUESTION_PAPER_UPLOAD.QUESTION_PAPER_UPLOAD_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case QUESTION_PAPER_UPLOAD.QUESTION_PAPER_UPLOAD_SUCCESS:
        return {
          ...state,
          isLoading: false,
        }
      case QUESTION_PAPER_UPLOAD.QUESTION_PAPER_UPLOAD_ERROR:
        return {
          ...state,
          isLoading: false,
        }
      case SUITABLE_FOR_LIST.SUITABLE_FOR_LIST_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case SUITABLE_FOR_LIST.SUITABLE_FOR_LIST_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          suitableForArray: action.payload,
        }

      case SUITABLE_FOR_LIST.SUITABLE_FOR_LIST_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case SET_CART_ITEMS.SET_CART_ITEMS:
        const items = []
        const cartFromLS = JSON.parse(localStorage.getItem('cartItems')!) || []
        if (state.itemsInCart.length > 0) {
          localStorage.setItem('cartItems', JSON.stringify(state.itemsInCart))
        }
        if (
          !(
            state.itemsInCart.length == 0 &&
            Object.keys(action.payload).length == 0 &&
            cartFromLS.length == 0
          )
        ) {
          const items = state.itemsInCart
            ? [...new Set([...state.itemsInCart, action.payload])]
            : [...new Set([...cartFromLS, action.payload])]
        }

        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          itemsInCart: items,
        }

      case REMOVE_CART_ITEMS.REMOVE_CART_ITEMS:
        let currentCartItems = state.itemsInCart
        currentCartItems = currentCartItems.filter(
          (el) => (el as any).id != (action.payload as any).id
        )
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          itemsInCart: currentCartItems,
        }
      default:
        return state
    }
  }
)

export default questionPaperReducer
