/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify'
import { Action, Store } from 'redux'
import { ThunkAction } from 'redux-thunk'

import {
  ADD_REVIEW_AND_COMMENT_URL,
  ASSIGN_VIEW_REPORT_PERMISSION_URL,
  CANDIDATE_BOOKMARK_URL,
  CANDIDATE_LIST_URL,
  DELETE_EXAM_URL,
  EXAM_TYPE_URL,
  EXAM_URL,
  EXAM_USERS_LIST_URL,
  GENERATE_QUESTIONS_BY_TOPIC_LIST_URL,
  GET_CANDIDATE_REPORT_LIST_URL,
  GET_CANDIDATE_REPORT_URL,
  GET_CANDIDATE_SUBMISSION_REPORT_LIST_URL,
  GET_DETAILS_OF_EXAM_URL,
  GET_JD_CANDIDATE_LIST_URL,
  GET_JD_EXAM_LIST_URL,
  GET_QUESTIONS_OF_EXAM_DETAILS_URL,
  GET_REVIEW_AND_COMMENT_URL,
  REASONING_TYPE_URL,
  SUITABLE_FOR_URL,
} from '../../service/apiEndPoints'
import { apiCall, METHOD } from '../../service/baseApiCall'
import { errorToast, setLocalStorage, successToast } from '../../util/utils'
import {
  ADD_REVIEW_AND_COMMENT,
  ASSIGN_EXAM,
  ASSIGN_VIEW_EXAM_REPORT_PERMISSION,
  CANDIDATE_BOOKMARK,
  CANDIDATE_DATA_DOWNLOAD_EXCEL,
  EXAM_CREATE,
  EXAM_DELETE,
  EXAM_DETAILS,
  EXAM_LIST,
  EXAM_TYPE_LIST,
  EXAM_UPDATE,
  EXAM_USERS_LIST,
  GENERATE_QUESTIONS_BY_TOPIC_LIST,
  GET_CANDIDATE_REPORT,
  GET_CANDIDATE_REPORT_LIST,
  GET_CANDIDATE_SUBMISSION_REPORT_LIST,
  GET_DETAILS_OF_EXAM,
  GET_JD_CANDIDATE_LIST,
  GET_JD_EXAM_LIST,
  GET_QUESTIONS_OF_EXAM_DETAILS,
  GET_REVIEW_AND_COMMENT,
  REASONING_TYPE_LIST,
  SUITABLE_FOR_LIST,
} from './examConst'

export const examList =
  (
    search?: string | null,
    page?: number,
    status?: string,
    examination_type?: any,
    duration_max?: any,
    ordering?: any,
    batchId?: number,
    isReadyExam?: boolean
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    const params: any = new URLSearchParams()
    if (search) params.append('search', search)
    if (page) params.append('page', page)
    if (status) params.append('status', status)
    if (status && !isReadyExam) params.append('status', 'ASSIGNED')
    if (examination_type) params.append('examination_type', examination_type)
    if (duration_max || duration_max == 0)
      params.append('duration_max', duration_max)
    if (ordering) params.append('ordering', ordering)
    if (batchId) params.append('batch', batchId)
    dispatch({
      type: EXAM_LIST.EXAM_LIST_REQUEST,
    })
    apiCall(
      EXAM_URL,
      (res: any) => {
        dispatch({
          type: EXAM_LIST.EXAM_LIST_SUCCESS,
          payload: res?.data,
        })
      },
      (errData: any) => {
        dispatch({
          type: EXAM_LIST.EXAM_LIST_ERROR,
        })
      },
      {},
      METHOD.GET,
      { showToast: false }
    )
  }

export const examCreate =
  (
    payload,
    _handleRedirect: (args) => void,
    _handleFailure: () => void,
    _handleSuccess?: (args) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EXAM_CREATE.EXAM_CREATE_REQUEST,
    })
    apiCall(
      EXAM_URL,
      (res: any) => {
        dispatch({
          type: EXAM_CREATE.EXAM_CREATE_SUCCESS,
          payload: res?.data,
        })
        _handleRedirect && _handleRedirect(res)
        _handleSuccess && _handleSuccess(res)
      },
      (err: any) => {
        dispatch({
          type: EXAM_CREATE.EXAM_CREATE_ERROR,
        }),
          _handleFailure()
        errorToast(err?.data?.message?.due_date?.[0])
      },
      payload,
      METHOD.POST,
      { showToast: true }
    )
  }

export const examDetail =
  (examID): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EXAM_DETAILS.EXAM_DETAILS_REQUEST,
    })
    apiCall(
      EXAM_URL + `/${examID}`,
      (res: any) => {
        dispatch({
          type: EXAM_DETAILS.EXAM_DETAILS_SUCCESS,
          payload: res?.data,
        })
      },
      (errData: any) => {
        dispatch({
          type: EXAM_DETAILS.EXAM_DETAILS_ERROR,
        })
      },
      {},
      METHOD.GET,
      { showToast: false }
    )
  }

export const examDelete =
  (
    examID,
    _handleSuccess: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EXAM_DELETE.EXAM_DELETE_REQUEST,
    })
    apiCall(
      DELETE_EXAM_URL + `${examID}`,
      (res: any) => {
        dispatch({
          type: EXAM_DELETE.EXAM_DELETE_SUCCESS,
          payload: res?.data,
        }),
          _handleSuccess()
      },
      (errData: any) => {
        dispatch({
          type: EXAM_DELETE.EXAM_DELETE_ERROR,
        })
      },
      {},
      METHOD.DELETE,
      { showToast: true }
    )
  }

export const examUpdate =
  (
    examId,
    payload,
    _handleRedirect?: (args) => void,
    _handleFailure?: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EXAM_UPDATE.EXAM_UPDATE_REQUEST,
    })
    apiCall(
      `${EXAM_URL}/${examId}`,
      (res: any) => {
        dispatch({
          type: EXAM_UPDATE.EXAM_UPDATE_SUCCESS,
          payload: res?.data,
        })
        _handleRedirect && _handleRedirect(res)
      },
      (err: any) => {
        dispatch({
          type: EXAM_UPDATE.EXAM_UPDATE_ERROR,
        }),
          _handleFailure && _handleFailure()
        errorToast(err?.data?.message?.due_date?.[0])
      },
      payload,
      METHOD.PUT,
      { showToast: true }
    )
  }

export const fetchExamineesForExam =
  (examID, page?: number): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EXAM_USERS_LIST.EXAM_USERS_LIST_REQUEST,
    })
    const params = new URLSearchParams()
    page && params.append('page', page?.toString())
    apiCall(
      EXAM_USERS_LIST_URL.replace('<exam_id>', examID),
      (res: any) => {
        dispatch({
          type: EXAM_USERS_LIST.EXAM_USERS_LIST_SUCCESS,
          payload: res?.data,
        })
      },
      (errData: any) => {
        dispatch({
          type: EXAM_USERS_LIST.EXAM_USERS_LIST_ERROR,
        })
      },
      {
        params,
      },
      METHOD.GET,
      { showToast: false }
    )
  }

export const examTypeList =
  (
    params?: URLSearchParams
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EXAM_TYPE_LIST.EXAM_TYPE_LIST_REQUEST,
    })
    apiCall(
      EXAM_TYPE_URL,
      (res: any) => {
        dispatch({
          type: EXAM_TYPE_LIST.EXAM_TYPE_LIST_SUCCESS,
          payload: res?.data,
        })
      },
      (err: any) => {
        dispatch({
          type: EXAM_TYPE_LIST.EXAM_TYPE_LIST_ERROR,
          payload: err.message,
        })
      },
      {},
      METHOD.GET,
      { showToast: false }
    )
  }

export const reasoningTypeList =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: REASONING_TYPE_LIST.REASONING_TYPE_LIST_REQUEST,
    })
    apiCall(
      REASONING_TYPE_URL,
      (res: any) => {
        dispatch({
          type: REASONING_TYPE_LIST.REASONING_TYPE_LIST_SUCCESS,
          payload: res?.data,
        })
      },
      (err: any) => {
        dispatch({
          type: REASONING_TYPE_LIST.REASONING_TYPE_LIST_ERROR,
          payload: err.message,
        })
      },
      {},
      METHOD.GET,
      { showToast: false }
    )
  }

// ************** Exam Scheduling and Assignment ******************

export const assignExamThunk =
  (
    examId: number,
    payload: Record<string, any>,
    onSuccess: () => void,
    onError?: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ASSIGN_EXAM.ASSIGN_EXAM_REQUEST,
    })
    apiCall(
      `${EXAM_URL}/${examId}/assign_exam`,
      (res: any) => {
        dispatch({
          type: ASSIGN_EXAM.ASSIGN_EXAM_SUCCESS,
        })
        successToast('Exam assigned successfully')
        onSuccess()
      },
      (err) => {
        dispatch({
          type: ASSIGN_EXAM.ASSIGN_EXAM_ERROR,
        })
        err?.message?.exam?.[0] && errorToast(err?.message?.exam?.[0])
        onError && onError()
      },
      payload,
      METHOD.POST,
      { showToast: false }
    )
  }

export const suitableForListThunk =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: SUITABLE_FOR_LIST.SUITABLE_FOR_LIST_REQUEST,
    })
    apiCall(
      SUITABLE_FOR_URL,
      (res: any) => {
        dispatch({
          type: SUITABLE_FOR_LIST.SUITABLE_FOR_LIST_SUCCESS,
          payload: res,
        })
      },
      (err: any) => {
        dispatch({
          type: SUITABLE_FOR_LIST.SUITABLE_FOR_LIST_ERROR,
          payload: err.message,
        })
      },
      {},
      METHOD.GET,
      { showToast: false }
    )
  }
export const getJDExamListThunk =
  (
    page?: number,
    status?: string,
    search?: string,
    show_only_my_exams?: boolean
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    const params: any = new URLSearchParams()
    if (page) params.append('page', page)
    if (status) params.append('status', status)

    if (status === 'ASSIGNED') params.append('status', 'ACTIVE')
    if (status === 'COMPLETED')
      params.append('status', 'EVALUATING_RESULT') &&
        params.append('status', 'RESULT_GENERATED')

    if (search) params.append('search', search)
    // Ordering list
    if (status === 'READY') {
      params.append('ordering', '-created_at')
    } else {
      params.append('ordering', '-valid_to')
    }
    if (show_only_my_exams)
      params.append('show_only_my_exams', show_only_my_exams)

    dispatch({
      type: GET_JD_EXAM_LIST.GET_JD_EXAM_LIST_REQUEST,
    })
    apiCall(
      GET_JD_EXAM_LIST_URL,
      (res) => {
        dispatch({
          type: GET_JD_EXAM_LIST.GET_JD_EXAM_LIST_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_JD_EXAM_LIST.GET_JD_EXAM_LIST_ERROR,
        })
      },
      {
        params: params,
      },
      METHOD.GET,
      { showToast: false }
    )
  }

export const generateQuestionsByTopicListThunk =
  (
    payload,
    _handleSuccess: () => void,
    _handleFailure?: (...args) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GENERATE_QUESTIONS_BY_TOPIC_LIST.GENERATE_QUESTIONS_BY_TOPIC_LIST_REQUEST,
    })
    apiCall(
      GENERATE_QUESTIONS_BY_TOPIC_LIST_URL,
      (res) => {
        dispatch({
          type: GENERATE_QUESTIONS_BY_TOPIC_LIST.GENERATE_QUESTIONS_BY_TOPIC_LIST_SUCCESS,
          payload: res?.data,
        }),
          _handleSuccess()
      },
      (err) => {
        dispatch({
          type: GENERATE_QUESTIONS_BY_TOPIC_LIST.GENERATE_QUESTIONS_BY_TOPIC_LIST_ERROR,
          payload: [],
        })
        _handleFailure && _handleFailure(err)
      },
      payload,
      METHOD.POST,
      { showToast: false }
    )
  }

export const getExamDetailsThunk =
  (id): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_DETAILS_OF_EXAM.GET_DETAILS_OF_EXAM_REQUEST,
    })
    apiCall(
      GET_DETAILS_OF_EXAM_URL(id),
      (res) => {
        dispatch({
          type: GET_DETAILS_OF_EXAM.GET_DETAILS_OF_EXAM_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_DETAILS_OF_EXAM.GET_DETAILS_OF_EXAM_ERROR,
        })
      },
      {},
      METHOD.GET,
      { showToast: false }
    )
  }

export const getQuestionListOfDetailsExamThunk =
  (payload): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_QUESTIONS_OF_EXAM_DETAILS.GET_QUESTIONS_OF_EXAM_DETAILS_REQUEST,
    })
    apiCall(
      GET_QUESTIONS_OF_EXAM_DETAILS_URL(payload?.id),
      (res) => {
        dispatch({
          type: GET_QUESTIONS_OF_EXAM_DETAILS.GET_QUESTIONS_OF_EXAM_DETAILS_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_QUESTIONS_OF_EXAM_DETAILS.GET_QUESTIONS_OF_EXAM_DETAILS_ERROR,
        })
      },
      { params: { search: payload?.search || undefined } },
      METHOD.GET,
      { showToast: false }
    )
  }

export const getCandidateReportListThunk =
  (payload): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CANDIDATE_REPORT_LIST.GET_CANDIDATE_REPORT_LIST_REQUEST,
    })
    apiCall(
      GET_CANDIDATE_REPORT_LIST_URL(payload?.id),
      (res) => {
        dispatch({
          type: GET_CANDIDATE_REPORT_LIST.GET_CANDIDATE_REPORT_LIST_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_CANDIDATE_REPORT_LIST.GET_CANDIDATE_REPORT_LIST_ERROR,
        })
      },
      { params: { search: payload?.search || undefined } },
      METHOD.GET,
      { showToast: false }
    )
  }

export const getCandidateReportThunk =
  (
    exam?: number,
    user?: number
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    const params: any = new URLSearchParams()
    params.append('user', user)

    dispatch({
      type: GET_CANDIDATE_REPORT.GET_CANDIDATE_REPORT_REQUEST,
    })
    apiCall(
      GET_CANDIDATE_REPORT_URL(exam),
      (res) => {
        dispatch({
          type: GET_CANDIDATE_REPORT.GET_CANDIDATE_REPORT_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_CANDIDATE_REPORT.GET_CANDIDATE_REPORT_ERROR,
        })
      },
      {
        params: params,
      },
      METHOD.GET,
      { showToast: false }
    )
  }

export const getJdCandidateListThunk =
  (
    jds: Array<number>,
    user_role: string,
    page?: number
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    // Reset state with initial value without API call
    dispatch({
      type: GET_JD_CANDIDATE_LIST.RESET_JD_CANDIDATE_LIST,
    })

    const params: any = new URLSearchParams()
    params.append('jds', JSON.stringify(jds))
    params.append('user_role', user_role)
    dispatch({
      type: GET_JD_CANDIDATE_LIST.GET_JD_CANDIDATE_LIST_REQUEST,
    })
    apiCall(
      GET_JD_CANDIDATE_LIST_URL,
      (res) => {
        dispatch({
          type: GET_JD_CANDIDATE_LIST.GET_JD_CANDIDATE_LIST_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_JD_CANDIDATE_LIST.GET_JD_CANDIDATE_LIST_ERROR,
        })
      },
      {
        params: params,
      },
      METHOD.GET,
      { showToast: false }
    )
  }

export const getCandidateSubmissionReportListThunk =
  (
    exam: number,
    student: string,
    page?: number
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    const params: any = new URLSearchParams()
    params.append('exam', exam)
    params.append('student', student)
    dispatch({
      type: GET_CANDIDATE_SUBMISSION_REPORT_LIST.GET_CANDIDATE_SUBMISSION_REPORT_LIST_REQUEST,
    })
    apiCall(
      GET_CANDIDATE_SUBMISSION_REPORT_LIST_URL,
      (res) => {
        dispatch({
          type: GET_CANDIDATE_SUBMISSION_REPORT_LIST.GET_CANDIDATE_SUBMISSION_REPORT_LIST_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_CANDIDATE_SUBMISSION_REPORT_LIST.GET_CANDIDATE_SUBMISSION_REPORT_LIST_ERROR,
        })
      },
      {
        params: params,
      },
      METHOD.GET,
      { showToast: false }
    )
  }

export const addReviewAndCommentThunk =
  (
    payload,
    _handleSuccess?: (args) => void,
    _handleFailure?: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ADD_REVIEW_AND_COMMENT.ADD_REVIEW_AND_COMMENT_REQUEST,
    })
    apiCall(
      ADD_REVIEW_AND_COMMENT_URL,
      (res: any) => {
        dispatch({
          type: ADD_REVIEW_AND_COMMENT.ADD_REVIEW_AND_COMMENT_SUCCESS,
          payload: res?.data,
        })
        _handleSuccess && _handleSuccess(res)
      },
      (err: any) => {
        dispatch({
          type: ADD_REVIEW_AND_COMMENT.ADD_REVIEW_AND_COMMENT_ERROR,
        }),
          _handleFailure && _handleFailure()
        errorToast(err?.data?.message)
      },
      payload,
      METHOD.POST,
      { showToast: true }
    )
  }

export const getReviewAndCommentThunk =
  (
    id,
    _handleSuccess?: (args) => void,
    _handleFailure?: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_REVIEW_AND_COMMENT.GET_REVIEW_AND_COMMENT_REQUEST,
    })
    apiCall(
      GET_REVIEW_AND_COMMENT_URL(id),
      (res: any) => {
        dispatch({
          type: GET_REVIEW_AND_COMMENT.GET_REVIEW_AND_COMMENT_SUCCESS,
          payload: res?.data,
        })
        _handleSuccess && _handleSuccess(res)
      },
      (err: any) => {
        dispatch({
          type: GET_REVIEW_AND_COMMENT.GET_REVIEW_AND_COMMENT_ERROR,
        }),
          _handleFailure && _handleFailure()
        errorToast(err?.data?.message)
      },
      {},
      METHOD.GET,
      { showToast: false }
    )
  }

export const candidateBookmarkThunk =
  (
    canId: string | number | undefined,
    is_bookmark: boolean,
    exam: string | number | undefined,
    updatedDataFun
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CANDIDATE_BOOKMARK.CANDIDATE_BOOKMARK_REQUEST,
    })
    apiCall(
      CANDIDATE_BOOKMARK_URL,
      () => {
        dispatch({
          type: CANDIDATE_BOOKMARK.CANDIDATE_BOOKMARK_SUCCESS,
        })
        updatedDataFun()
      },
      () => {
        dispatch({
          type: CANDIDATE_BOOKMARK.CANDIDATE_BOOKMARK_ERROR,
        })
      },
      {
        user: canId,
        is_bookmark: is_bookmark,
        exam: exam,
      },
      METHOD.POST,
      { showToast: false }
    )
  }

// ************** STUDENT LIST FROM EXAM DOWNLOAD EXCEL ******************

export const candidateDataDownloadExcel =
  (
    showToast: boolean,
    id: string | undefined,
    // page: number,
    search: string | null,
    ordering: Record<string, string> | null,
    attempt_status: string | null,
    sorted: any,
    handleSuccess?: (...args) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    const params = new URLSearchParams()
    // page && params.append('page', page?.toString())
    params.append('all_sorted_records', sorted)
    ordering &&
      ordering.student_firstname &&
      params.append('ordering', ordering.student_firstname)
    ordering && ordering.rank && params.append('ordering', ordering.rank)
    params.append('subject_performace', 'true')
    dispatch({
      type: CANDIDATE_DATA_DOWNLOAD_EXCEL.CANDIDATE_DATA_DOWNLOAD_EXCEL_REQUEST,
    })
    apiCall(
      CANDIDATE_LIST_URL + `${id}/report`,
      (res: any) => {
        dispatch({
          type: CANDIDATE_DATA_DOWNLOAD_EXCEL.CANDIDATE_DATA_DOWNLOAD_EXCEL_SUCCESS,
          payload: res.data,
        })
        handleSuccess && handleSuccess(res.data)
      },
      (err: any) => {
        dispatch({
          type: CANDIDATE_DATA_DOWNLOAD_EXCEL.CANDIDATE_DATA_DOWNLOAD_EXCEL_ERROR,
          payload: err.message,
        })
      },
      {
        params,
      },
      METHOD.GET,
      { showToast: showToast }
    )
  }

//**************** GET EXAM BY ID  ***********************
export const examByIdList =
  (
    showToast = false,
    id: string | undefined
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch(examByIdInit(showToast, id))
  }

export const examByIdInit =
  (
    showToast: boolean,
    id: string | undefined
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EXAM_LIST.EXAM_LIST_BY_ID_REQUEST,
    })
    apiCall(
      `/api/exam-v2/${id}`,
      // EXAM_LIST_URL + `/${id}`,

      (res: any) => dispatch(examListByIdSuccess(res?.data)),
      (err: any) => {
        dispatch(examListByIdError(err))
      },
      {},
      METHOD.GET,
      { showToast: showToast }
    )
  }

export const examListByIdSuccess =
  (res: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EXAM_LIST.EXAM_LIST_BY_ID_SUCCESS,
      payload: res,
    })
  }

export const examListByIdError =
  (err: any): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: EXAM_LIST.EXAM_LIST_BY_ID_ERROR,
      payload: err.message,
    })
  }

// ************** Assign View Report Permission to Employee ******************

export const assignViewReportPermission =
  (
    examId: number,
    candidate_list: {
      user_exam_submission: number
      access_report: boolean
    }[],
    handleSuccess?: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ASSIGN_VIEW_EXAM_REPORT_PERMISSION.GET_ASSIGN_VIEW_EXAM_REPORT_PERMISSION_REQUEST,
    })
    apiCall(
      ASSIGN_VIEW_REPORT_PERMISSION_URL.replace('examId', examId.toString()),
      (res: any) => {
        dispatch({
          type: ASSIGN_VIEW_EXAM_REPORT_PERMISSION.GET_ASSIGN_VIEW_EXAM_REPORT_PERMISSION_SUCCESS,
          payload: res.data,
        })
        handleSuccess && handleSuccess()
      },
      () => {
        dispatch({
          type: ASSIGN_VIEW_EXAM_REPORT_PERMISSION.GET_ASSIGN_VIEW_EXAM_REPORT_PERMISSION_ERROR,
        })
      },
      {
        user_exam_submission_list: candidate_list,
      },
      METHOD.POST,
      { showToast: true }
    )
  }
