import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  GET_RESUME_FILE,
  SEND_DUPLICATE_RESUME_DATA,
  SEND_UPDATED_RESUME_DATA,
  UPLOAD_RESUME,
  UPLOADED_RESUME_LIST,
} from './resumeLibraryConst'
import { ActionTypes, ResumeLibraryData } from './resumeLibraryType'

const initialState = {
  isLoadingResumeList: false,
  resumeList: [],
  isLoadingResumeFile: false,
  resumeFile: {},
  updatedResumeData: [],
  duplicateResumeData: [],
  isLoadingUploadResume: false,
  uploadedResumeList: [],
}

const resumeLibraryReducer = persistReducer(
  { storage, key: 'resume-library', whitelist: [] },
  (state: ResumeLibraryData = initialState, action: ActionTypes) => {
    switch (action.type) {
      //Resume file
      case GET_RESUME_FILE.GET_RESUME_FILE_INITIALIZATION:
        return {
          ...state,
          isLoadingResumeFile: true,
        }

      case GET_RESUME_FILE.GET_RESUME_FILE_SUCCESS:
        return {
          ...state,
          isLoadingResumeFile: false,
          resumeFile: action.payload,
        }

      case GET_RESUME_FILE.GET_RESUME_FILE_ERROR:
        return {
          ...state,
          isLoadingResumeFile: false,
        }

      case UPLOAD_RESUME.UPLOAD_RESUME_REQUEST:
        return {
          ...state,
          isLoadingUploadResume: true,
        }

      case UPLOAD_RESUME.UPLOAD_RESUME_SUCCESS:
        return {
          ...state,
          isLoadingUploadResume: false,
          // resumeFile: action.payload,
        }

      case UPLOAD_RESUME.UPLOAD_RESUME_ERROR:
        return {
          ...state,
          isLoadingUploadResume: false,
        }

      case SEND_UPDATED_RESUME_DATA.SEND_UPDATED_RESUME_DATA_INITIALIZATION:
        return {
          ...state,
          updatedResumeData: action.payload,
          isLoading: false,
        }

      case SEND_DUPLICATE_RESUME_DATA.SEND_DUPLICATE_RESUME_DATA_INITIALIZATION:
        return {
          ...state,
          duplicateResumeData: action.payload,
          isLoading: false,
        }

      case UPLOADED_RESUME_LIST.UPLOADED_RESUME_LIST_INITIALIZATION:
        return {
          ...state,
          uploadedResumeList: [],
        }
      case UPLOADED_RESUME_LIST.SEND_UPLOADED_RESUME_LIST:
        return {
          ...state,
          uploadedResumeList: action.payload,
        }

      default:
        return state
    }
  }
)

export default resumeLibraryReducer
