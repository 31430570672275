import { Collapse, IconButton, TableCell, TableRow } from '@mui/material'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import React from 'react'
import { Col, Container, Form, Image, Row } from 'react-bootstrap'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'
import { useSelector } from 'react-redux'

import Resume from '../../assets/images/description.svg'
import EditableLabel from '../../components/EditableLabel'
import {
  getResumes,
  setDuplicateResumeData,
  setUpdateResumeData,
} from '../../redux/resume-library/resumeLibraryActions'
import { Store } from '../../redux/rootType'
import { dispatch } from '../../redux/store'
import { errorToast } from '../../util/utils'

const InvalidDataCustomRow = (props) => {
  const formikContext = useFormikContext()
  const userDetails = useSelector(
    (state: Store) => state.authReducer.userDetails
  ) as any

  const updatedResumeData = useSelector(
    (state: Store) => state?.resumeLibraryReducer?.updatedResumeData
  )

  const duplicateResumeData = useSelector(
    (state: Store) => state?.resumeLibraryReducer?.duplicateResumeData
  )

  const { row } = props
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const getProgressBarBgColor = (percentage: number) => {
    if (percentage >= 1 && percentage <= 40) {
      return 'poor'
    } else if (percentage >= 41 && percentage <= 69) {
      return 'average'
    } else if (percentage >= 70) {
      return 'good'
    }
  }

  const handleSelectCandidate = (key: number, checked: boolean) => {
    const extractedResumeData = _.cloneDeep(props?.invalidResumesData)

    const selectedCandidate = _.cloneDeep(duplicateResumeData)
    if (props?.setAllResumes || props?.allResumes) {
      const extractedResumeData = _.cloneDeep(props?.allResumes)
      const index = extractedResumeData?.findIndex(
        (resume) => resume?.id === key
      )
      if (!extractedResumeData[index]?.extracted_data?.name) {
        errorToast('Please enter name.')
        return
      }
      if (extractedResumeData[index]?.extracted_data?.email === '--') {
        errorToast('Please enter email.')
        return
      }
      if (extractedResumeData[index]?.extracted_data?.phone_number === '--') {
        errorToast('Please enter phone number.')
        return
      }
      if (index > -1) {
        extractedResumeData[index] = {
          ...(extractedResumeData?.[index] || {}),
          isChecked: checked,
        }
        props?.setAllResumes(extractedResumeData)
      }
    }
    const index = extractedResumeData?.findIndex((resume) => resume?.id === key)
    if (!extractedResumeData[index]?.extracted_data?.name) {
      errorToast('Please enter name.')
      return
    }
    if (extractedResumeData[index]?.extracted_data?.email === '--') {
      errorToast('Please enter email.')
      return
    }
    if (extractedResumeData[index]?.extracted_data?.phone_number === '--') {
      errorToast('Please enter phone number.')
      return
    }
    const selectedCandidateIndex = (
      (formikContext?.values as any)?.selectedCandidate ||
      props?.selectedCandidate ||
      []
    )?.findIndex((resume) => resume?.id === key)

    if (index > -1) {
      extractedResumeData[index] = {
        ...(extractedResumeData?.[index] || {}),
        isChecked: checked,
      }
      props?.setInvalidResumesData(extractedResumeData)
    }
    if (
      selectedCandidateIndex > -1 &&
      selectedCandidate?.[selectedCandidateIndex]
    ) {
      selectedCandidate[selectedCandidateIndex] = {
        ...(selectedCandidate?.[selectedCandidateIndex] || {}),
        isChecked: checked,
      }
    }
    props?.setFieldValue('selectedCandidate', extractedResumeData)

    updatedResumeData.forEach((item1) => {
      const item2 = extractedResumeData.find((item) => item.id === item1.id)
      if (item2) {
        Object.assign(item1, item2)
      }
    })
    dispatch(setUpdateResumeData(updatedResumeData))
    props?.setFilteredResumeData(updatedResumeData)

    dispatch(setDuplicateResumeData(selectedCandidate))

    props?.setSelectAll(
      props?.invalidResumesData?.length ===
        extractedResumeData?.filter((resume) => resume?.isChecked)?.length
    )
  }

  return !row?.message ? (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <div className="d-flex align-items-center">
            <Form.Check
              className="me-2 select-resume"
              type={'checkbox'}
              id={`${row?.id}`}
              checked={row?.isChecked || false}
              onChange={(e) => {
                handleSelectCandidate(row?.id, e?.target?.checked)
              }}
            />
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
            </IconButton>
          </div>
        </TableCell>
        <TableCell>
          <EditableLabel
            value={row?.extracted_data?.name || '-'}
            rowId={row?.id}
            setFilteredResumeData={props?.setInvalidResumesData}
            setFieldValue={props?.setFieldValue}
            changeKey="name"
            regex={/^(?!\s+$)[a-zA-Z\s]{2,40}$/}
            errorMessage="Name must be greater than or equal to 2 characters and less than or equal to 40 characters, special characters and numbers are not allowed"
            allResumes={props?.allResumes}
            setAllResumes={props?.setAllResumes}
            filteredResumesData={props?.invalidResumesData}
          />
        </TableCell>
        {/* <TableCell>
          {row?.extracted_data?.skills?.length ? (
            row?.extracted_data?.skills?.length > 4 ? (
              <>
                {row?.extracted_data?.skills?.slice(0, 4)?.join(', ')},
                <span
                  className="text-primary pointer"
                  onClick={() => {
                    props?.setSkills([])
                    props?.setShowSkillSet(true)
                    props?.setSkills(row?.extracted_data?.skills?.slice(4))
                  }}
                >
                  +{row?.extracted_data?.skills?.length - 4}
                </span>
              </>
            ) : (
              row?.extracted_data?.skills?.join(', ')
            )
          ) : (
            '-'
          )}
        </TableCell> */}
        <TableCell>
          <div>
            <span>{`${
              row?.extracted_data?.skill_similarity_score ?? 0
            }%`}</span>
            <div
              className={`skills-match-bar  ${getProgressBarBgColor(
                row?.extracted_data?.skill_similarity_score || 0
              )}`}
            >
              <div
                className={`progress-bar`}
                role="progressbar"
                style={{
                  width: `${row?.extracted_data?.skill_similarity_score}%`,
                }}
              ></div>
            </div>
          </div>
        </TableCell>
        <TableCell>
          {/* <Dropdown align="end">
            <Dropdown.Toggle as={Link} id="dropdown-basic" to="#">
              <i className="fas fa-ellipsis-v fa-fw"></i>
            </Dropdown.Toggle>
            <AppendedMyComponent>
              <Dropdown.Menu></Dropdown.Menu>
            </AppendedMyComponent>
          </Dropdown> */}
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Container>
                <Row>
                  {/* <Col>
                    <p className="fw-normal font-size-14 text-black-400 mb-2">
                      Job Title
                    </p>
                    <p className="lato-text">{props?.jobTitle?.label || '-'}</p>
                  </Col> */}
                  <Col>
                    <p className="fw-normal font-size-14 text-black-400 mb-2">
                      Experience
                    </p>
                    <p className="lato-text">
                      {row?.extracted_data?.experience > -1
                        ? `${row?.extracted_data?.experience} years`
                        : '-'}
                    </p>
                  </Col>
                  <Col>
                    <p className="fw-normal font-size-14 text-black-400 mb-2">
                      Email
                    </p>
                    <p className="lato-text mb-1">
                      <EditableLabel
                        value={row?.extracted_data?.email || '-'}
                        rowId={row?.id}
                        setFilteredResumeData={props?.setInvalidResumesData}
                        setFieldValue={props?.setFieldValue}
                        changeKey="email"
                        regex={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
                        errorMessage="Please enter valid email"
                        allResumes={props?.allResumes}
                        setAllResumes={props?.setAllResumes}
                        filteredResumesData={props?.invalidResumesData}
                      />
                      {/* {row?.extracted_data?.email || '-'} */}
                    </p>
                    {/* <p className="lato-text">
                      <span className="fw-normal">Number: </span>
                      <EditableLabel
                        value={row?.extracted_data?.phone_number || '-'}
                        rowId={row?.id}
                        setFilteredResumeData={props?.setFilteredResumeData}
                        setFieldValue={props?.setFieldValue}
                        changeKey="phone_number"
                        type="number"
                        regex={/^\d{10}$/}
                        errorMessage="Phone number must be of 10 digits"
                      />
                    </p> */}
                  </Col>
                  <Col>
                    <p className="fw-normal font-size-14 text-black-400 mb-2">
                      Number
                    </p>
                    <p className="lato-text mb-1">
                      <EditableLabel
                        value={row?.extracted_data?.phone_number || '-'}
                        rowId={row?.id}
                        setFilteredResumeData={props?.setInvalidResumesData}
                        setFieldValue={props?.setFieldValue}
                        changeKey="phone_number"
                        type="string"
                        regex={/^\d{10}$/}
                        errorMessage="Phone number must be of 10 digits"
                        allResumes={props?.allResumes}
                        setAllResumes={props?.setAllResumes}
                        filteredResumesData={props?.invalidResumesData}
                      />
                    </p>
                  </Col>
                  <Col>
                    <p className="fw-normal font-size-14 text-black-400 mb-2">
                      Resume
                    </p>
                    {isLoading ? (
                      <i className="fas fa-spinner fa-spin"></i>
                    ) : (
                      <Image
                        src={Resume}
                        alt="resume"
                        className="pointer"
                        onClick={() => {
                          setIsLoading(true)
                          // dispatch(
                          //   getResumes(
                          //     {
                          //       user_id: userDetails?.data?.id,
                          //       prefix: row?.file_url,
                          //     },
                          //     row?.file_name || 'resume.pdf',
                          //     undefined,
                          //     () => {
                          //       setIsLoading(false)
                          //     }
                          //   )
                          // )
                          dispatch(
                            getResumes(
                              {
                                user_id: userDetails?.data?.id,
                                prefix: row?.extracted_data?.uuid,
                              },
                              row?.file_name || 'resume.pdf',
                              undefined,
                              () => {
                                setIsLoading(false)
                              }
                            )
                          )
                        }}
                      />
                      // <a href={row.file_url} target="_blank" download>
                      //   {' '}
                      //   <Image src={Resume} alt="resume" />{' '}
                      // </a>
                    )}
                  </Col>
                </Row>
              </Container>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  ) : (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell colSpan={5} className="text-center">
          No data found from {row?.file_name} file
        </TableCell>
      </TableRow>
    </>
  )
}

export default InvalidDataCustomRow
