import { useState } from 'react'
import { Col, Form, Image, Row } from 'react-bootstrap'

import call from '../../../assets/images/help_call.svg'
import UploaderIcon from '../../../assets/images/help_drag.svg'
import msg from '../../../assets/images/help_msg.svg'

const HelpCenterResourceContact = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [show2, setShow2] = useState(false)
  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  const [show3, setShow3] = useState(false)
  const handleClose3 = () => setShow3(false)
  const handleShow3 = () => setShow3(true)

  const [show4, setShow4] = useState(false)
  const handleClose4 = () => setShow4(false)
  const handleShow4 = () => setShow4(true)

  const [show5, setShow5] = useState(false)
  const handleClose5 = () => setShow5(false)
  const handleShow5 = () => setShow5(true)

  return (
    <>
      <Row className="p-4">
        <Col md={9}>
          <div className="mb-4 pb-1">
            <h1 className="font-size-22 text-body fw-semibold mb-1">
              Contact Us
            </h1>
            <p className="sub-title-clr font-size-16 mb-0">
              Please enter the details of your request. A member of our support
              staff will respond to you through mail as soon as possible.
            </p>
          </div>
          <Form>
            <Form.Group
              className="form-group mb-4 pb-3"
              controlId="exampleForm.ControlInput2"
            >
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                name="username"
                // value={"“Skill Assessment” question paper is having issue"}
                placeholder="Enter Subject Name"
                className="form-control"
              />
            </Form.Group>
            <Form.Group
              className="form-group mb-4 pb-3 position-relative"
              controlId="exampleForm.ControlInput2"
            >
              <Form.Label>Issue Description</Form.Label>
              <Form.Control
                as="textarea"
                // value={"Skills Assessment for Software Developers Hiring qualified software developers - Question paper has been having issue uploading"}
                placeholder="Enter Issue Description"
                style={{ height: '120px' }}
              />
              <span className="position-absolute bottom-0 end-0 me-4 mb-4 light-gray-text">
                50/250
              </span>
            </Form.Group>
            <Form.Group
              className="form-group position-relative"
              controlId="exampleForm.ControlInput2"
            >
              <Form.Label>Attachment (optional)</Form.Label>
              <div
                className="photo-uploader"
                style={{
                  minHeight: 174,
                }}
              >
                <Image src={UploaderIcon} />
                <p className="mt-3 text-center mb-2">
                  Drag & drop file here Or
                  <span className="text-primary"> Browse file</span> on you
                  computer
                </p>
                <p className="mb-0 light-gray-text">
                  Upload JPG, PNG, Docs smaller than 5MB
                </p>
              </div>
            </Form.Group>
          </Form>
          <hr className="custom-hr" />
          <div>
            <button className="btn btn-light py-2 font-size-16" type="button">
              Cancel
            </button>
            <button
              className="btn btn-primary py-2 px-4 ms-4 me-5"
              type="button"
            >
              Submit
            </button>
          </div>
        </Col>
      </Row>

      <div className="help-info p-4">
        <p className="mb-0 font-size-16" style={{ color: '#404040' }}>
          Contact Information:
        </p>
        <p className="mb-0 text-black-300 font-size-16 fw-semibold">
          <Image className="me-2" src={call} />
          (91) 789 887 760
        </p>
        <p className="mb-0 text-black-300 font-size-16 fw-semibold">
          <Image className="me-2" src={msg} />
          help@prep.study
        </p>
      </div>
    </>
  )
}

export default HelpCenterResourceContact
