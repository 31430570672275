import { FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import {
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
  Table,
} from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'

import ClockPickerIcon from '../../../assets/images/clock-picker-icon.svg'
import CloseBlackIcon from '../../../assets/images/close-black-icon.svg'
import DatePickerIcon from '../../../assets/images/date-picker.svg'
import EditBlueIcon from '../../../assets/images/edit-blue-icon.svg'
import FilterIcon from '../../../assets/images/filter-icon.svg'
import NameBadgeIcon from '../../../assets/images/name-badge.svg'
import RightBlackArrowIcon from '../../../assets/images/right-black-arrow.svg'
import SearchIcon from '../../../assets/images/search-icon.svg'
import UpoloadResumeIcon from '../../../assets/images/upoload-resume.svg'
import UserImagesIcon from '../../../assets/images/User-images.svg'
import { Loader } from '../../../components/Loader'
import Pagination from '../../../components/Pagination'
import {
  assignExamThunk,
  examDetail,
  getExamDetailsThunk,
  getJdCandidateListThunk,
} from '../../../redux/exam/examActions'
import { GET_JD_CANDIDATE_LIST } from '../../../redux/exam/examConst'
import { getJobDescriptionListThunk } from '../../../redux/job-description/jobDescriptionActions'
import { Store } from '../../../redux/rootType'
import { dispatch } from '../../../redux/store'
import { errorToast } from '../../../util/utils'
import { AssignExamSchema } from '../../../validations/examSchema/examSchema'
import CustomTimePicker from '../CustomTimePicker'
import DatePickerField from './../DatePickerField'

/**
 * ==================================================
 * Global code
 * ==================================================
 */
const tableHeading = [
  { id: 1, heading: 'Candidate Name' },
  { id: 2, heading: 'Role' },
  { id: 3, heading: 'Experience' },
  { id: 4, heading: 'Added by' },
  { id: 5, heading: 'Added on' },
  { id: 6, heading: 'Resume' },
]

const itemsPerPage = 5
const status = 'A'

const AssignExam = () => {
  /**
   * ==================================================
   * const variable
   * ==================================================
   */
  const navigate = useNavigate()
  const { state, pathname } = useLocation()
  const formRef = useRef<any>()
  // const cancelTokenSource = useRef<CancelTokenSource>()

  const { examId } = useParams<{ examId: string }>()

  /**
   * ==================================================
   * Data from redux store
   * ==================================================
   */

  const isLoadingJdCandidateList = useSelector(
    (state: Store) => state?.examReducer?.isLoadingJdCandidateList
  )
  const jdCandidateList = useSelector(
    (state: Store) => state?.examReducer?.jdCandidateList
  )?.map((val) => {
    return { ...val, isChecked: false }
  })

  const examDetailsData = useSelector(
    (state: Store) => state?.examReducer?.examDetailsData
  ) as any

  const jobDescriptionList = useSelector(
    (state: Store) => state?.jobDescriptionReducer?.jobDescriptionList
  )

  const jobDescriptionListOptions = jobDescriptionList[0]?.jd.map((data) => {
    return {
      label: data?.job_title,
      value: data.id,
    }
  })

  // const examQuestionPaper = useSelector(
  //   (state: Store) => state?.QuestionDataReducer?.questionPaperDetailsForExam
  // )

  /**
   * ==================================================
   * Component state
   * ==================================================
   */

  const [page, setPage] = useState(1)
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const [candidateListUpdate, setCandidateListUpdate] = useState(
    jdCandidateList || []
  )
  const [paginatedData, setPaginatedData] = useState(
    (candidateListUpdate || [])?.slice(0, itemsPerPage)
  )
  const [searchData, setSearchData] = useState<any>([])
  const [searchTerm, setSearchTerm] = useState('')

  /**
   * ==================================================
   * functionality
   * ==================================================
   */
  const formikAssignExam = useFormik({
    enableReinitialize: true,
    initialValues: {
      examValidFromDate: '',
      examValidFromTime: '',
      examValidTillDate: '',
      examValidTillTime: '',
      resultDeclarationDate: '',
      resultDeclarationTime: '',
      examAccessReport: true,
      examDuration: examDetailsData?.duration || 60,
      batchType: state?.batchType || '',
      batchName: state?.batch?.toString() || '',
      selectedMembers: [],
      batchExpiryDate: '',
      isSetExamExpiryDate: false,
      selectedJD: [],
    },
    validateOnMount: true,
    validationSchema: AssignExamSchema,
    onSubmit: (values) => {
      // on submit
      const payloadData = {
        valid_from: `${moment(values.examValidFromDate).format(
          'YYYY-MM-DD'
        )} ${moment(values.examValidFromTime).format('HH:mm')}`,
        valid_to: `${moment(values.examValidTillDate).format(
          'YYYY-MM-DD'
        )} ${moment(values.examValidTillTime).format('HH:mm')}`,
        declare_results_at: `${moment(values.resultDeclarationDate).format(
          'YYYY-MM-DD'
        )} ${moment(values.resultDeclarationTime).format('HH:mm')}`,
        access_report: values.examAccessReport,
        // reschedule: state?.reschedule || undefined,
      }
      payloadData['jd_users'] =
        values?.selectedMembers?.length > 0
          ? values?.selectedMembers?.map(
              (member: { user: { id: number } }) => member?.user?.id
            )
          : undefined
      payloadData['jds'] =
        values?.selectedJD?.length > 0
          ? values?.selectedJD?.map((jd: { value: number }) => jd?.value)
          : undefined

      dispatch(
        assignExamThunk(
          +examId!,
          payloadData,
          () => {
            navigate('/exams')
          },
          () => {
            errorToast('Assign exam failed.')
          }
        )
      )
    },
  })

  const updateListOfCandidateAll = (check) => {
    const updatedListOfItems = candidateListUpdate?.map((val) => ({
      ...val,
      isChecked: !check,
    }))
    const newUpdatedListOfItems = updatedListOfItems.filter(
      (item) => item.isChecked
    )
    formikAssignExam.setFieldValue('selectedMembers', newUpdatedListOfItems)
    setCandidateListUpdate(updatedListOfItems)
    setIsCheckedAll(!check)
  }

  const updateListOfCandidate = (itemId, isChecked) => {
    const updatedListOfItems = [...candidateListUpdate]
    const question_index = candidateListUpdate?.findIndex(
      (item) => item?.id === itemId
    )

    updatedListOfItems[question_index].isChecked = isChecked
    setCandidateListUpdate(updatedListOfItems)
    const newUpdatedListOfItems = updatedListOfItems.filter(
      (item) => item.isChecked
    )
    formikAssignExam.setFieldValue('selectedMembers', newUpdatedListOfItems)

    const allCheck = updatedListOfItems.filter(
      (val) => val.isChecked === false
    )?.length
    allCheck > 0 ? setIsCheckedAll(false) : setIsCheckedAll(true)
  }

  const handleSearchButton = () => {
    const searchTermExist = (search) =>
      search.searchTerm.toLowerCase() === searchTerm.toLowerCase()
    const isExit = searchData.some(searchTermExist)
    if (!isExit && searchTerm) {
      setSearchData((prevData: any) => [
        ...prevData,
        { searchTerm, id: prevData.length + 1 },
      ])
      setSearchTerm('')
    }
  }

  const handleRemoveSearchTerm = (id) => {
    const removedSearchTerm = searchData?.filter((item) => {
      return item.id !== id
    })

    setSearchData(removedSearchTerm)
  }

  /**
   * ==================================================
   * use effect handling
   * ==================================================
   */
  useEffect(() => {
    dispatch(getExamDetailsThunk(examId))
  }, [examId])

  useEffect(() => {
    const jdId = formikAssignExam.values.selectedJD.map(
      (item: { value: number }) => item.value
    )
    const user_role = 'external-candidate'

    if (jdId.length) {
      dispatch(getJdCandidateListThunk(jdId, user_role))
    } else {
      // Reset state with initial value without API call
      dispatch({
        type: GET_JD_CANDIDATE_LIST.RESET_JD_CANDIDATE_LIST,
      })
      formikAssignExam.setFieldValue('selectedMembers', [])
    }
  }, [formikAssignExam.values.selectedJD])

  useEffect(() => {
    dispatch(
      getJobDescriptionListThunk(
        status,
        // search,
        undefined,
        undefined
      )
    )
  }, [])

  useEffect(() => {
    setCandidateListUpdate(jdCandidateList)
  }, [JSON.stringify(jdCandidateList)])

  useEffect(() => {
    const newOffset =
      ((page - 1) * itemsPerPage) % (jdCandidateList.length || 1)
    const newLimit = page * itemsPerPage

    setPaginatedData(candidateListUpdate?.slice(newOffset, newLimit))
    setCandidateListUpdate((prev) => prev)
  }, [page, candidateListUpdate])

  return (
    <>
      <Container className="p-5 shadow-card-box">
        <div className="mb-3 pb-1">
          <Link to="/exams">
            <span className="text-muted">Exam</span>
          </Link>
          <Image src={RightBlackArrowIcon} className="mx-2" />
          <Link to="/exams">
            <span className="text-muted">Created</span>
          </Link>
          <Image src={RightBlackArrowIcon} className="mx-2" />
          <Link
            to={`/exam-details/${state?.examId}`}
            state={{ tabKey: state?.navKey }}
          >
            <span className="text-muted">
              {examDetailsData?.name || state?.examName || '-'}
            </span>
          </Link>
          <Image src={RightBlackArrowIcon} className="mx-2" />
          <span className="text-black">Assign Exam</span>
        </div>
        <FormikProvider value={formikAssignExam}>
          <Form onSubmit={formikAssignExam.handleSubmit}>
            <div className="card">
              <div className="card-body p-4 my-2 mx-3">
                <div className="d-flex mb-5 align-items-center">
                  <Link
                    to={`/exam-details/${state?.examId}`}
                    state={{ tabKey: state?.navKey }}
                    className="h4 d-block"
                  >
                    <IoMdArrowBack className="me-2" />
                    Assign Exam
                  </Link>
                  {/* <Image src={EditBlueIcon} className="ms-auto" />
                  <span className="text-primary ms-1">Edit Exam Details</span> */}
                </div>
                <Row lg={2}>
                  <Col>
                    {/* <Form.Group className="form-group" controlId="FirstName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="batchName"
                        placeholder="Give a batch name for this exam"
                        className="form-control"
                        value={formikAssignExam.values?.batchName}
                        onChange={formikAssignExam.handleChange}
                        onBlur={formikAssignExam.handleBlur}
                      />
                      {formikAssignExam.errors.batchName &&
                        formikAssignExam.touched.batchName && (
                          <small className="text-danger">
                            {formikAssignExam.errors.batchName as string}
                          </small>
                        )}
                    </Form.Group> */}
                    <h5 className="mb-2">Schedule</h5>
                    <div className="d-flex">
                      <Col lg={8}>
                        <Form.Group
                          className="form-group password-group"
                          controlId="exampleForm.ControlInput2"
                        >
                          <Form.Label className="d-flex custom-margin-bottom-date-time align-items-center">
                            Exam Start Date
                          </Form.Label>
                          {/* <Form.Control
                          type="text"
                          name="start_date"
                          placeholder={'Start Date'}
                          className="form-control"
                        /> */}
                          <div className="d-flex custom-margin-right-date-time justify-content-between">
                            <DatePickerField
                              id="examValidFromDate"
                              name="examValidFromDate"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group
                          className="form-group password-group ms-auto"
                          controlId="exampleForm.ControlInput2"
                        >
                          <Form.Label className="d-flex align-items-center">
                            Start Time
                          </Form.Label>
                          <CustomTimePicker
                            id="examValidFromTime"
                            name="examValidFromTime"
                            date={
                              formikAssignExam?.values?.examValidFromDate ||
                              moment()
                            }
                          />
                          {/* </Form.Group> */}
                          {/* <Form.Control
                          type="text"
                          name="examValidFromTime"
                          placeholder={'Start Time'}
                          className="form-control"
                          value={formikAssignExam.values?.examValidFromTime}
                          onChange={formikAssignExam.handleChange}
                          onBlur={formikAssignExam.handleBlur}
                        /> */}
                        </Form.Group>
                      </Col>
                    </div>
                    {/* Below functionality is commented because Expiry date is required as Corporate */}

                    {/* <Form.Check
                      className="mb-3"
                      type={'checkbox'}
                      checked={formikAssignExam?.values?.isSetExamExpiryDate}
                      onChange={() =>
                        formikAssignExam.setFieldValue(
                          'isSetExamExpiryDate',
                          !formikAssignExam?.values?.isSetExamExpiryDate
                        )
                      }
                      id={`1`}
                      label={`Set exam expiry date (Candidates can take up exam anytime until the valid date)`}
                    /> */}
                    <div className="d-flex">
                      <Col lg={8}>
                        <Form.Group
                          className="form-group password-group"
                          controlId="exampleForm.ControlInput2"
                        >
                          <Form.Label className="d-flex custom-margin-bottom-date-time align-items-center">
                            Exam End Date
                          </Form.Label>
                          <div className="d-flex custom-margin-right-date-time justify-content-between">
                            <DatePickerField
                              id="examValidTillDate"
                              name="examValidTillDate"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group
                          className="form-group password-group ms-auto"
                          controlId="exampleForm.ControlInput2"
                        >
                          <Form.Label className="d-flex align-items-center">
                            End Time
                          </Form.Label>
                          <CustomTimePicker
                            id="examValidTillTime"
                            name="examValidTillTime"
                            date={
                              formikAssignExam?.values?.examValidTillDate ||
                              moment()
                            }
                          />
                          {/* <Form.Control
                          type="text"
                          name="examValidTillTime"
                          placeholder={'Password'}
                          className="form-control"
                          value={formikAssignExam.values?.examValidTillTime}
                          onChange={formikAssignExam.handleChange}
                          onBlur={formikAssignExam.handleBlur}
                        /> */}
                          {/* <Image className="warning-icon" src={ClockPickerIcon} /> */}
                        </Form.Group>
                      </Col>
                    </div>
                    <Form.Label>Job Description</Form.Label>
                    <Select
                      name="selectedJD"
                      hideSelectedOptions={false}
                      options={jobDescriptionListOptions}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select Job Description"
                      isMulti
                      closeMenuOnSelect={true}
                      onChange={(values) => {
                        formikAssignExam.setFieldValue('selectedJD', values)
                      }}
                      value={formikAssignExam.values?.selectedJD}
                    />
                    {formikAssignExam.errors.selectedJD &&
                      formikAssignExam.touched.selectedJD && (
                        <small className="text-danger">
                          {formikAssignExam.errors.selectedJD as string}
                        </small>
                      )}
                  </Col>
                </Row>
                <h5 className="mb-3 mt-5 pb-1">
                  Select Candidates to Assign Exam
                </h5>
                <div className="d-flex align-items-center mb-4">
                  <InputGroup className="search-box">
                    <InputGroup.Text id="basic-addon1">
                      <Image src={SearchIcon} />
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon1"
                      value={searchTerm}
                      onChange={(event) => setSearchTerm(event.target.value)}
                    />
                  </InputGroup>
                  <div className="filter-btn ms-3" onClick={handleSearchButton}>
                    <Image src={FilterIcon} className="me-2" />
                    Filter
                  </div>
                  <span className="ms-auto badge badge-warning text-dark py-2 px-3">
                    Candidates Selected:
                    <span className="text-new-primary fw-bold">
                      {' '}
                      {formikAssignExam.values.selectedMembers.length}
                    </span>
                  </span>
                </div>
                <div className="d-flex align-items-center flex-wrap mb-2 pb-1">
                  {searchData?.map((item) => {
                    return (
                      <div className="selected-expertise" key={item.id}>
                        <small className="me-1">{item.searchTerm}</small>
                        <Image
                          src={CloseBlackIcon}
                          className="pointer"
                          onClick={() => handleRemoveSearchTerm(item.id)}
                        />
                      </div>
                    )
                  })}
                </div>
                {formikAssignExam.errors.selectedMembers &&
                  formikAssignExam.touched.selectedMembers && (
                    <small className="text-danger">
                      {formikAssignExam.errors.selectedMembers as string}
                    </small>
                  )}
                <Table bordered hover responsive className="overflow-scroll">
                  <thead>
                    <tr className="border-0">
                      <th>
                        <Form.Check
                          type={'checkbox'}
                          id={'CandidateAll'}
                          checked={isCheckedAll}
                          onChange={() =>
                            updateListOfCandidateAll(isCheckedAll)
                          }
                        />
                      </th>
                      {tableHeading.map((head) => {
                        return <th key={head.id}>{head.heading}</th>
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {isLoadingJdCandidateList ? (
                      <tr>
                        <td colSpan={8}>
                          <Loader height={'500px'} />
                        </td>
                      </tr>
                    ) : paginatedData?.length > 0 ? (
                      (paginatedData || [])?.map((candidate) => {
                        return (
                          <tr>
                            <td>
                              <Form.Check
                                key={candidate?.id}
                                type={'checkbox'}
                                id={'Candidate' + candidate?.id}
                                checked={candidate?.isChecked}
                                onChange={() =>
                                  updateListOfCandidate(
                                    candidate?.id,
                                    !candidate?.isChecked
                                  )
                                }
                              />
                            </td>
                            <td>
                              <Image
                                src={
                                  `${process.env.REACT_APP_IMAGE_URL}${candidate?.user?.photo}` ||
                                  NameBadgeIcon
                                }
                                className="me-1"
                              />
                              {candidate?.user?.first_name}{' '}
                              {candidate?.user?.last_name}
                            </td>
                            <td>{candidate?.user?.role?.name}</td>
                            <td>
                              {candidate?.user?.total_experience >= 0
                                ? candidate?.user?.total_experience
                                : '-'}{' '}
                              years
                            </td>
                            <td>
                              <div className="d-flex">
                                <Image src={UserImagesIcon} />
                                <div className="ms-2">
                                  <p className="mb-0 text-dark">
                                    {candidate.created_by.first_name}{' '}
                                    {candidate.created_by.last_name}
                                  </p>
                                  <small className="dark-gray">
                                    {candidate?.created_by?.role?.name}{' '}
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td>
                              {moment(candidate?.created_at).format(
                                'DD MMM, YYYY'
                              )}
                            </td>
                            <td>
                              <Image src={UpoloadResumeIcon} />
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={8} className="text-center">
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {jdCandidateList?.length > itemsPerPage ? (
                  <Pagination
                    page={page}
                    setPage={setPage}
                    tableCount={jdCandidateList?.length}
                    itemsPerPage={itemsPerPage}
                  />
                ) : null}
              </div>
              <div className="card-footer p-4 mx-3 text-end">
                <button
                  className="btn btn-light py-2 px-4"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary ms-3 py-2 px-4"
                  type="submit"
                >
                  Assign Exam
                </button>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </Container>
    </>
  )
}

export default AssignExam
