import { Action, Store } from 'redux'
import { ThunkAction } from 'redux-thunk'

import {
  GET_RESUME_LINK_URL,
  UPLOAD_RESUME_URL,
} from '../../service/apiEndPoints'
import { apiCall, METHOD } from '../../service/baseApiCall'
import { errorToast, successToast } from '../../util/utils'
import {
  GET_RESUME_FILE,
  GET_RESUME_LINK,
  SEND_DUPLICATE_RESUME_DATA,
  SEND_UPDATED_RESUME_DATA,
  UPLOAD_RESUME,
  UPLOADED_RESUME_LIST,
} from './resumeLibraryConst'

// ************** Get Resume Link ******************

export const getResumes = (
  payload,
  fileName,
  handleSuccess?: (...args) => void,
  callback?: () => void
): ThunkAction<void, Store, unknown, Action<string>> => {
  return (dispatch) => {
    dispatch({
      type: GET_RESUME_LINK?.GET_RESUME_LINK_INITIALIZATION,
    })
    apiCall(
      `${process.env.REACT_APP_RESUME_READER_URL}${GET_RESUME_LINK_URL}`,
      (res) => {
        dispatch({
          type: GET_RESUME_LINK.GET_RESUME_LINK_SUCCESS,
        })
        if (res?.url) {
          const link = document.createElement('a')
          link.href = res?.url
          link.setAttribute('download', fileName)
          link.setAttribute('target', '_blank')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          handleSuccess && handleSuccess(res)
        } else {
          errorToast('Resume not found')
        }
        callback && callback()
      },
      (err) => {
        dispatch({
          type: GET_RESUME_LINK.GET_RESUME_LINK_FAILED,
        })
        errorToast('Something went wrong')
        callback && callback()
      },
      { params: { ...payload } },
      METHOD.GET,
      {}
    )
  }
}

export const uploadResumeThunk =
  (
    payload,
    _handleSuccess?: () => void,
    _handleFailure?: (args) => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPLOAD_RESUME.UPLOAD_RESUME_REQUEST,
    })
    apiCall(
      UPLOAD_RESUME_URL,
      (res) => {
        dispatch({
          type: UPLOAD_RESUME.UPLOAD_RESUME_SUCCESS,
        }),
          _handleSuccess && _handleSuccess()
      },
      (err) => {
        dispatch({
          type: UPLOAD_RESUME.UPLOAD_RESUME_ERROR,
        })
        _handleFailure && _handleFailure(err)
      },
      payload,
      METHOD.POST,
      { showToast: true }
    )
  }

export const setUpdateResumeData =
  (updateResumeData): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SEND_UPDATED_RESUME_DATA.SEND_UPDATED_RESUME_DATA_INITIALIZATION,
      payload: updateResumeData,
    })
  }

export const setDuplicateResumeData =
  (duplicateResumeData): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SEND_DUPLICATE_RESUME_DATA.SEND_DUPLICATE_RESUME_DATA_INITIALIZATION,
      payload: duplicateResumeData,
    })
  }

export const setInitialUploadedResumeData =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: UPLOADED_RESUME_LIST.UPLOADED_RESUME_LIST_INITIALIZATION,
      payload: [],
    })
  }

export const setUploadedResumeData =
  (resumeData): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: UPLOADED_RESUME_LIST.SEND_UPLOADED_RESUME_LIST,
      payload: resumeData,
    })
  }
