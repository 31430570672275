/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  ACCOUNT_VERIFICATION,
  FORGOT_PASSWORD,
  GET_PROFILE_DETAILS,
  LOGIN,
  LOGOUT,
  PROFILE_UPDATE,
  REGISTER,
  RESEND_EMAIL_OTP,
  RESET_PASSWORD,
  VARIFY_EMAIL,
  VARIFY_EMAIL_OTP,
} from './authConst'
import { ActionTypes, UserData } from './authType'

const initialState = {
  isLoading: false,
  isLogin: false,
  isLogout: true,
  errorMessage: '',
  accessToken: '',
  refreshToken: '',
  userDetails: {},
  user: {},
  varifyEmailOTP: '',
  varifyEmail: {},
  isLoadingOTPVarification: false,
}

const authReducer = persistReducer(
  {
    storage,
    key: 'user',
    whitelist: [
      'accessToken',
      'refreshToken',
      'userDetails',
      'isLogin',
      'isLogout',
      'user',
    ],
  },
  (state: UserData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case REGISTER.REGISTER_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case REGISTER.REGISTER_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case REGISTER.REGISTER_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case ACCOUNT_VERIFICATION.ACCOUNT_VERIFICATION_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoadingOTPVarification: true,
        }

      case ACCOUNT_VERIFICATION.ACCOUNT_VERIFICATION_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          isLoadingOTPVarification: false,
        }

      case ACCOUNT_VERIFICATION.ACCOUNT_VERIFICATION_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoadingOTPVarification: false,
        }

      case LOGIN.LOGIN_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case LOGIN.LOGIN_SUCCESS:
        const user: Record<string, any> = action.payload

        return {
          ...state,
          isLoading: false,
          isLogin: true,
          isLogout: false,
          accessToken: user.access,
          refreshToken: user.refresh,
          userDetails: user.user,
          user: user,
        }

      case LOGIN.LOGIN_ERROR:
        return {
          ...state,
          // errorMessage: action.payload,
          isLoading: false,
        }

      case RESET_PASSWORD.RESET_PASSWORD_REQUEST:
        return {
          ...state,
          isLoading: true,
        }

      case RESET_PASSWORD.RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          isLoading: false,
        }

      case RESET_PASSWORD.RESET_PASSWORD_ERROR:
        return {
          ...state,
          // errorMessage: action.payload,
          isLoading: false,
        }

      case FORGOT_PASSWORD.FORGOT_PASSWORD_REQUEST:
        return {
          ...state,
          isLoading: true,
        }

      case FORGOT_PASSWORD.FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          isLoading: false,
        }

      case FORGOT_PASSWORD.FORGOT_PASSWORD_ERROR:
        return {
          ...state,
          // errorMessage: action.payload,
          isLoading: false,
        }

      case LOGOUT.LOGOUT_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isLogin: false,
          isLogout: true,
          accessToken: '',
          refreshToken: '',
          errorMessage: '',
          userDetails: {},
        }
      case PROFILE_UPDATE.PROFILE_UPDATE_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case PROFILE_UPDATE.PROFILE_UPDATE_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case PROFILE_UPDATE.PROFILE_UPDATE_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case GET_PROFILE_DETAILS.GET_PROFILE_DETAILS_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case GET_PROFILE_DETAILS.GET_PROFILE_DETAILS_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          userDetails: action.payload,
        }

      case GET_PROFILE_DETAILS.GET_PROFILE_DETAILS_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      /**
       * ==================================================
       * Varify email
       * ==================================================
       */
      case VARIFY_EMAIL.VARIFY_EMAIL_REQUEST:
        return {
          ...state,
          isLoading: true,
        }

      case VARIFY_EMAIL.VARIFY_EMAIL_SUCCESS:
        return {
          ...state,
          isLoading: false,
          varifyEmail: action.payload,
        }

      case VARIFY_EMAIL.VARIFY_EMAIL_ERROR:
        return {
          ...state,
          isLoading: false,
        }

      /**
       * ==================================================
       * Varify OTP
       * ==================================================
       */
      case VARIFY_EMAIL_OTP.VARIFY_EMAIL_OTP_REQUEST:
        return {
          ...state,
        }

      case VARIFY_EMAIL_OTP.VARIFY_EMAIL_OTP_SUCCESS:
        return {
          ...state,
          varifyEmailOTP: action.payload,
        }

      case VARIFY_EMAIL_OTP.VARIFY_EMAIL_OTP_ERROR:
        return {
          ...state,
        }

      /**
       * ==================================================
       * Resend email otp
       * ==================================================
       */
      case RESEND_EMAIL_OTP.RESEND_EMAIL_OTP_REQUEST:
        return {
          ...state,
          isLoading: true,
        }

      case RESEND_EMAIL_OTP.RESEND_EMAIL_OTP_SUCCESS:
        return {
          ...state,
          isLoading: false,
        }

      case RESEND_EMAIL_OTP.RESEND_EMAIL_OTP_ERROR:
        return {
          ...state,
          isLoading: false,
        }

      default:
        return state
    }
  }
)

export default authReducer
