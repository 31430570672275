/* eslint-disable @typescript-eslint/no-explicit-any */
import { applyMiddleware, compose, createStore } from 'redux'
// import {configureStore} from "@red"
import { persistStore } from 'redux-persist'
import thunk from 'redux-thunk'

import { apiMiddleWare } from '../service/axiosConfig'
import rootReducer from './rootReducer'
// import { apiMiddleWare } from '../service/axiosConfig'
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

// Configure store with reducers and create
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
export type RootState = ReturnType<typeof store.getState>

const store: any = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunk), applyMiddleware(apiMiddleWare))
)

export const persistor = persistStore(store)
export const { dispatch } = store

// Infer the `RootState` and `AppDispatch` types from the store itself
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export default store
