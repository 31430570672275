import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  ALL_CANDIDATE_LIST,
  CANDIDATE_RESUME_LIST,
} from './candidateManagementConst'
import { ActionTypes, CandidateManagementData } from './candidateManagementType'

const initialState = {
  isLoadingCandidateList: false,
  candidateList: [],
  isLoadingAllCandidateList: false,
  allCandidateList: [],
}

const candidateManagementReducer = persistReducer(
  { storage, key: 'candidate-management', whitelist: [] },
  (state: CandidateManagementData = initialState, action: ActionTypes) => {
    switch (action.type) {
      //Resume file
      case CANDIDATE_RESUME_LIST.CANDIDATE_RESUME_LIST_REQUEST:
        return {
          ...state,
          isLoadingCandidateList: true,
        }

      case CANDIDATE_RESUME_LIST.CANDIDATE_RESUME_LIST_SUCCESS:
        return {
          ...state,
          isLoadingCandidateList: false,
          candidateList: action.payload,
        }

      case CANDIDATE_RESUME_LIST.CANDIDATE_RESUME_LIST_ERROR:
        return {
          ...state,
          isLoadingCandidateList: false,
        }

      case ALL_CANDIDATE_LIST.ALL_CANDIDATE_LIST_REQUEST:
        return {
          ...state,
          isLoadingAllCandidateList: true,
        }

      case ALL_CANDIDATE_LIST.ALL_CANDIDATE_LIST_SUCCESS:
        return {
          ...state,
          isLoadingAllCandidateList: false,
          allCandidateList: action.payload,
        }

      case ALL_CANDIDATE_LIST.ALL_CANDIDATE_LIST_ERROR:
        return {
          ...state,
          isLoadingAllCandidateList: false,
        }

      default:
        return state
    }
  }
)

export default candidateManagementReducer
