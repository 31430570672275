import { useState } from 'react'
import { Card, Col, Form, Image, InputGroup, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import SearchIcon from '../../../assets/images/search_icon.svg'

const HelpCenterResource = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [show2, setShow2] = useState(false)
  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  const [show3, setShow3] = useState(false)
  const handleClose3 = () => setShow3(false)
  const handleShow3 = () => setShow3(true)

  const [show4, setShow4] = useState(false)
  const handleClose4 = () => setShow4(false)
  const handleShow4 = () => setShow4(true)

  const [show5, setShow5] = useState(false)
  const handleClose5 = () => setShow5(false)
  const handleShow5 = () => setShow5(true)

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <InputGroup
          className="search-box"
          style={{
            maxWidth: 200,
          }}
        ></InputGroup>
      </div>
      <Row className="mt-4 shadow-card-box mb-4">
        <Col md={6}>
          <Card className="card-equal-height ">
            <Card.Body>
              <h6 className="mb-3 text-black-300 font-size-18">Get Started</h6>
              <ul className="mb-0">
                <li className="mb-3 text-black-300 font-size-16">
                  How to build strong profile?
                </li>
                <li className="mb-3 text-black-300 font-size-16">
                  How to create question paper and get more buys?
                </li>
                <li className="mb-3 text-black-300 font-size-16">
                  How to create question paper?
                </li>
                <li className="mb-3 text-black-300 font-size-16">
                  What is question set?
                </li>
                <li className="mb-0 text-black-300 font-size-16">
                  What is upload question set?
                </li>
              </ul>
            </Card.Body>
            <Card.Footer>
              <Link to="/HelpCenterFaqDetailReadmore" className="fw-semibold">
                View All (12)
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="card-equal-height ">
            <Card.Body>
              <h6 className="mb-3 text-black-300 font-size-18">
                Features Overview
              </h6>
              <ul className="mb-0">
                <li className="mb-3 text-black-300 font-size-16">
                  Learn about analytics{' '}
                </li>
                <li className="mb-3 text-black-300 font-size-16">
                  Make use of analytics
                </li>
                <li className="mb-3 text-black-300 font-size-16">
                  Payment history
                </li>
                <li className="mb-3 text-black-300 font-size-16">
                  Question type and features
                </li>
                <li className="mb-0 text-black-300 font-size-16">
                  How to track orders?
                </li>
              </ul>
            </Card.Body>
            <Card.Footer>
              <Link to="/HelpCenterFaqDetailReadmore" className="fw-semibold">
                View All (12)
              </Link>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4 shadow-card-box">
        <Col md={6}>
          <Card className="card-equal-height ">
            <Card.Body>
              <h6 className="mb-3 text-black-300 font-size-18">
                Question paper management
              </h6>
              <ul className="mb-0">
                <li className="mb-3 text-black-300 font-size-16">
                  What is question set?
                </li>
                <li className="mb-3 text-black-300 font-size-16">
                  How upload question set?
                </li>
                <li className="mb-3 text-black-300 font-size-16">
                  How to set question paper prize?
                </li>
                <li className="mb-3 text-black-300 font-size-16">
                  How to choose cover image for question paper?
                </li>
                <li className="mb-0 text-black-300 font-size-16">
                  Preview question paper
                </li>
              </ul>
            </Card.Body>
            <Card.Footer>
              <Link to="/HelpCenterFaqDetailReadmore" className="fw-semibold">
                View All (12)
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="card-equal-height ">
            <Card.Body>
              <h6 className="mb-3 text-black-300 font-size-18">
                Billing and payment
              </h6>
              <ul className="mb-0">
                <li className="mb-3 text-black-300 font-size-16">
                  Monthly payment transaction
                </li>
                <li className="mb-3 text-black-300 font-size-16">
                  Revenue report
                </li>
                <li className="mb-3 text-black-300 font-size-16">
                  How do i earn revenue from prep.study?
                </li>
                <li className="mb-3 text-black-300 font-size-16">
                  Payment overview
                </li>
                <li className="mb-0 text-black-300 font-size-16">
                  Transaction invoice
                </li>
              </ul>
            </Card.Body>
            <Card.Footer>
              <Link to="/HelpCenterFaqDetailReadmore" className="fw-semibold">
                View All (12)
              </Link>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default HelpCenterResource
