const QUESTION_PAPER_LIST = {
  QUESTION_PAPER_LIST_REQUEST: 'QUESTION_PAPER_LIST_REQUEST',
  QUESTION_PAPER_LIST_SUCCESS: 'QUESTION_PAPER_LIST_SUCCESS',
  QUESTION_PAPER_LIST_ERROR: 'QUESTION_PAPER_LIST_ERROR',
}

const QUESTION_PAPER_DETAILS = {
  QUESTION_PAPER_DETAILS_REQUEST: 'QUESTION_PAPER_DETAILS_REQUEST',
  QUESTION_PAPER_DETAILS_SUCCESS: 'QUESTION_PAPER_DETAILS_SUCCESS',
  QUESTION_PAPER_DETAILS_ERROR: 'QUESTION_PAPER_DETAILS_ERROR',
}

const QUESTION_PAPER_DELETE = {
  QUESTION_PAPER_DELETE_REQUEST: 'QUESTION_PAPER_DELETE_REQUEST',
  QUESTION_PAPER_DELETE_SUCCESS: 'QUESTION_PAPER_DELETE_SUCCESS',
  QUESTION_PAPER_DELETE_ERROR: 'QUESTION_PAPER_DELETE_ERROR',
}

const QUESTION_PAPER_UPLOAD = {
  QUESTION_PAPER_UPLOAD_REQUEST: 'QUESTION_PAPER_UPLOAD_REQUEST',
  QUESTION_PAPER_UPLOAD_SUCCESS: 'QUESTION_PAPER_UPLOAD_SUCCESS',
  QUESTION_PAPER_UPLOAD_ERROR: 'QUESTION_PAPER_UPLOAD_ERROR',
}

const SUITABLE_FOR_LIST = {
  SUITABLE_FOR_LIST_REQUEST: 'SUITABLE_FOR_LIST_REQUEST',
  SUITABLE_FOR_LIST_SUCCESS: 'SUITABLE_FOR_LIST_SUCCESS',
  SUITABLE_FOR_LIST_ERROR: 'SUITABLE_FOR_LIST_ERROR',
}

const SET_CART_ITEMS = {
  SET_CART_ITEMS: 'SET_CART_ITEMS',
}

const REMOVE_CART_ITEMS = {
  REMOVE_CART_ITEMS: 'REMOVE_CART_ITEMS',
}

export {
  QUESTION_PAPER_DELETE,
  QUESTION_PAPER_DETAILS,
  QUESTION_PAPER_LIST,
  QUESTION_PAPER_UPLOAD,
  REMOVE_CART_ITEMS,
  SET_CART_ITEMS,
  SUITABLE_FOR_LIST,
}
