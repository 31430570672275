import React, { useState } from 'react'
import {
  Accordion,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { Link } from 'react-router-dom'

import PlusIcon from '../../assets/images/btn-plus.svg'
import CalenderIcon from '../../assets/images/calender-icon.svg'
import ChennaiIcon from '../../assets/images/chennai-icon.svg'
import ConsultationIcon from '../../assets/images/consultation-icon.svg'
import DeleteIcon20 from '../../assets/images/delete-icon20.svg'
import EditIcon20 from '../../assets/images/edit-icon20.svg'
import FacebookIcon from '../../assets/images/facebook-icon.svg'
import GitHubIcon from '../../assets/images/git-hub-icon.svg'
import UploaderIcon from '../../assets/images/help_drag.svg'
import LinkedinIcon from '../../assets/images/linkedin-icon.svg'
import RightBlackArrowIcon from '../../assets/images/right-black-arrow.svg'
import SkillsTag from '../../assets/images/skills-tag.svg'
import TwitterIcon from '../../assets/images/twitter-icon.svg'
import UploaderIconSm from '../../assets/images/uploader-icon-sm.svg'
import WebsiteIcon from '../../assets/images/website-icon.svg'
import ZipIcon20 from '../../assets/images/zip-icon-20.svg'

const AddCandidate = () => {
  const [show1, setShow1] = useState(false)

  const handleClose1 = () => setShow1(false)
  const handleShow1 = () => setShow1(true)

  const [show2, setShow2] = useState(false)

  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  const [show3, setShow3] = useState(false)

  const handleClose3 = () => setShow3(false)
  const handleShow3 = () => setShow3(true)

  return (
    <>
      <Container className="p-5 shadow-card-box">
        <div className="mb-3 pb-1">
          <span className="text-muted">Candidate Management</span>
          <Image src={RightBlackArrowIcon} className="mx-2" />
          <span className="text-dark">Add Candidate</span>
        </div>
        <div className="card">
          <div className="card-body p-0">
            <div className="p-4 m-3 mb-0 pb-0">
              <Link to="/candidate-management" className="h4 d-block">
                <IoMdArrowBack className="me-2" />
                Add Candidate
              </Link>
            </div>
            {/* <Form> */}
            <div className="p-4 m-3">
              <Row className="0question-card g-4">
                <Col lg={6}>
                  <Form.Group
                    className="form-group mb-0 position-relative"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label>Upload Resume to be parsed</Form.Label>
                    <div
                      className="photo-uploader mb-0"
                      style={{
                        minHeight: 174,
                      }}
                    >
                      <Image src={UploaderIcon} />
                      <p className="mt-3 text-center mb-2">
                        Drag & drop file here Or
                        <span className="text-primary"> Browse file</span> on
                        you computer
                      </p>
                      <p className="mb-0 light-gray-text">
                        Upload JPG, PNG, Docs smaller than 5MB
                      </p>
                    </div>
                  </Form.Group>

                  <div className="card border-light-gray shadow-none mt-4 bg-opacity-10 bg-success mb-0">
                    <div className="card-body d-flex fw-semibold">
                      <Image src={ZipIcon20} className="me-2" /> Animeverse
                      <span className="ms-auto font-size-14 text-gray-300">
                        24 MB
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="candidate-accordion">
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Personal Information</Accordion.Header>
                  <Accordion.Body className="p-4 m-3">
                    <Row className="question-card g-4">
                      <Col lg={10}>
                        <Row lg={2} className="question-card g-4">
                          <Col md={6}>
                            <Form.Group
                              className="form-group"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="first_name"
                                placeholder="Enter first name"
                                className="form-control"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="form-group"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                name="last_name"
                                placeholder="Enter last name"
                                className="form-control"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="form-group"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                name="email"
                                placeholder="Enter email"
                                className="form-control"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="form-group"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Contact Number </Form.Label>
                              <Form.Control
                                type="text"
                                name="contact_number"
                                placeholder="Contact Number"
                                className="form-control"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="form-group"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>City </Form.Label>
                              <Form.Select aria-label="Default select example">
                                <option>Select an option</option>
                                <option value="1">Option One</option>
                                <option value="2">Option Two</option>
                                <option value="3">Option Three</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="form-group"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>State </Form.Label>
                              <Form.Select aria-label="Default select example">
                                <option>Select an option</option>
                                <option value="1">Option One</option>
                                <option value="2">Option Two</option>
                                <option value="3">Option Three</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="form-group"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Gender </Form.Label>
                              <Form.Select aria-label="Default select example">
                                <option>Select an option</option>
                                <option value="1">Option One</option>
                                <option value="2">Option Two</option>
                                <option value="3">Option Three</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="form-group"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Date of Birth </Form.Label>
                              <InputGroup className="mb-3">
                                <Form.Control
                                  placeholder="Recipient's username"
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                  className="border-end-0"
                                />
                                <InputGroup.Text
                                  id="basic-addon2"
                                  className="bg-transparent"
                                >
                                  <Image src={CalenderIcon} />
                                </InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Work Experience</Accordion.Header>
                  <Accordion.Body className="p-4 m-3">
                    <div className="d-flex align-items-center">
                      <p className="fw-semibold">
                        Developer <span className="text-gray-300 mx-2">|</span>{' '}
                        2 yr 3 mths{' '}
                      </p>
                      <div className="ms-auto d-flex">
                        <a className="icon-34-btn me-2">
                          <Image src={EditIcon20} />
                        </a>
                        <a className="icon-34-btn">
                          <Image src={DeleteIcon20} />
                        </a>
                      </div>
                    </div>
                    <div>
                      <ul className="ps-3">
                        <li className="mb-1">
                          Design mobile applications, web platforms & dashboard
                        </li>
                        <li className="mb-1">Conduct user research</li>
                        <li className="mb-1">Build wireframes and prototype</li>
                        <li className="mb-1">
                          Created Information architecture and User workflow
                        </li>
                        <li className="mb-1">
                          Conduct AB testing to gather user feedback
                        </li>
                      </ul>
                    </div>
                    <div>
                      <p className="mb-2">
                        <Image src={ConsultationIcon} className="me-1" /> Canada
                        Paradigm Consultation
                      </p>
                      <p className="mb-2">
                        <Image src={ChennaiIcon} className="me-1" /> Chennai
                      </p>
                    </div>
                    <hr />
                    <div className="d-flex align-items-center">
                      <p className="fw-semibold">
                        Sr. Developer{' '}
                        <span className="text-gray-300 mx-2">|</span> 3 yr 3{' '}
                      </p>
                      <div className="ms-auto d-flex">
                        <a className="icon-34-btn me-2">
                          <Image src={EditIcon20} />
                        </a>
                        <a className="icon-34-btn">
                          <Image src={DeleteIcon20} />
                        </a>
                      </div>
                    </div>
                    <div>
                      <ul className="ps-3">
                        <li className="mb-1">
                          Passionate about clean code, focused on delivering the
                          product of the highest quality.
                        </li>
                        <li className="mb-1">
                          Experienced with building distributed, high
                          performing, concurrent and robust Java software.
                        </li>
                        <li className="mb-1">
                          High level of responsibility and commitment to improve
                          and troubleshoot applications.
                        </li>
                        <li className="mb-1">
                          Eager to learn new technologies.
                        </li>
                      </ul>
                    </div>
                    <div>
                      <p className="mb-2">
                        <Image src={ConsultationIcon} className="me-1" /> ABC
                        Company Solutions
                      </p>
                      <p className="mb-2">
                        <Image src={ChennaiIcon} className="me-1" /> Bangalore
                      </p>
                    </div>
                    <hr className="my-4" />
                    <button
                      onClick={handleShow1}
                      className="btn btn-outline-dark pe-4"
                    >
                      <Image src={PlusIcon} className="me-1" /> Add Work
                      Experience
                    </button>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Education</Accordion.Header>
                  <Accordion.Body className="p-4 m-3">
                    <div className="d-flex align-items-center">
                      <p className="fw-semibold">
                        Master in Computer Application{' '}
                        <span className="text-gray-300 mx-2">|</span> A++
                      </p>
                      <small className="text-light">Information Systems</small>
                      <div className="ms-auto d-flex">
                        <a className="icon-34-btn me-2">
                          <Image src={EditIcon20} />
                        </a>
                        <a className="icon-34-btn">
                          <Image src={DeleteIcon20} />
                        </a>
                      </div>
                    </div>
                    <div>
                      <p className="mb-2">
                        <Image src={ConsultationIcon} className="me-1" />{' '}
                        University of Mumbai
                      </p>
                      <p className="mb-2">
                        <Image src={ChennaiIcon} className="me-1" /> Chennai
                      </p>
                    </div>
                    <hr className="my-4" />
                    <button
                      onClick={handleShow2}
                      className="btn btn-outline-dark pe-4"
                      type="button"
                    >
                      <Image src={PlusIcon} className="me-1" /> Education
                    </button>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Skills</Accordion.Header>
                  <Accordion.Body className="p-4 m-3 py-5">
                    <div className="skills-tag-name mb-3">
                      <p className="tag-name">
                        <a href="#">
                          Project Management{' '}
                          <i className="far fa-times-circle fa-fw"></i>
                        </a>
                      </p>
                      <p className="tag-name">
                        <a href="#">
                          Ownership{' '}
                          <i className="far fa-times-circle fa-fw"></i>
                        </a>
                      </p>
                      <p className="tag-name">
                        <a href="#">
                          Task management{' '}
                          <i className="far fa-times-circle fa-fw"></i>
                        </a>
                      </p>
                      <p className="tag-name">
                        <a href="#">
                          Spring Data JPA{' '}
                          <i className="far fa-times-circle fa-fw"></i>
                        </a>
                      </p>
                      <p className="tag-name">
                        <a href="#">
                          Java <i className="far fa-times-circle fa-fw"></i>
                        </a>
                      </p>
                      <p className="tag-name">
                        <a href="#">
                          GitHub <i className="far fa-times-circle fa-fw"></i>
                        </a>
                      </p>
                      <p className="tag-name">
                        <a href="#">
                          PostgreSQL{' '}
                          <i className="far fa-times-circle fa-fw"></i>
                        </a>
                      </p>
                      <p className="tag-name">
                        <a href="#">
                          Quarkus <i className="far fa-times-circle fa-fw"></i>
                        </a>
                      </p>
                      <p className="tag-name">
                        <a href="#">
                          Go Language{' '}
                          <i className="far fa-times-circle fa-fw"></i>
                        </a>
                      </p>
                      <p className="tag-name">
                        <a href="#">
                          PostgreSQL{' '}
                          <i className="far fa-times-circle fa-fw"></i>
                        </a>
                      </p>
                      <p className="tag-name">
                        <a href="#">
                          Hibernate{' '}
                          <i className="far fa-times-circle fa-fw"></i>
                        </a>
                      </p>
                      <p className="tag-name">
                        <a href="#">
                          Gradle <i className="far fa-times-circle fa-fw"></i>
                        </a>
                      </p>
                    </div>
                    <Image src={SkillsTag} className="me-1" />{' '}
                    <span className="font-size-18 fw-semibold">
                      Enter Skills
                    </span>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Social Links</Accordion.Header>
                  <Accordion.Body className="p-4 m-3">
                    <Row className="question-card g-4">
                      <Col lg={10}>
                        <Row lg={2} className="question-card gx-4">
                          <Col md={6}>
                            <Form.Group
                              className="form-group"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                <Image src={WebsiteIcon} className="me-1" />{' '}
                                Website
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="url"
                                placeholder="URL"
                                className="form-control"
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="form-group"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                <Image src={TwitterIcon} className="me-1" />{' '}
                                Twitter
                              </Form.Label>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon2">
                                  www.twitter.com
                                </InputGroup.Text>
                                <Form.Control placeholder="URl" />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="form-group"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                <Image src={FacebookIcon} className="me-1" />{' '}
                                Facebook profile
                              </Form.Label>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon2">
                                  www.facebook.com
                                </InputGroup.Text>
                                <Form.Control placeholder="URl" />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="form-group"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                <Image src={GitHubIcon} className="me-1" /> Git
                                Hub{' '}
                              </Form.Label>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon2">
                                  www.instagram.com
                                </InputGroup.Text>
                                <Form.Control placeholder="URl" />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group
                              className="form-group"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>
                                <Image src={LinkedinIcon} className="me-1" />{' '}
                                Linkedin profile
                              </Form.Label>
                              <InputGroup className="mb-3">
                                <InputGroup.Text id="basic-addon2">
                                  www.linkedin.com
                                </InputGroup.Text>
                                <Form.Control placeholder="URl" />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>Custom field</Accordion.Header>
                  <Accordion.Body className="p-4 m-3">
                    <div className="bg-light p-4 mb-4">
                      <Row className="question-card gx-4">
                        <Col>
                          <Form.Group
                            className="form-group mb-0"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Field Type</Form.Label>
                            <Form.Select aria-label="Default select example">
                              <option>Select an option</option>
                              <option value="1">Option One</option>
                              <option value="2">Option Two</option>
                              <option value="3">Option Three</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group
                            className="form-group mb-0"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Field Name</Form.Label>
                            <Form.Select aria-label="Default select example">
                              <option>Select an option</option>
                              <option value="1">Option One</option>
                              <option value="2">Option Two</option>
                              <option value="3">Option Three</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col md={1}>
                          <Form.Group
                            className="form-group mb-0"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label className="d-block">&nbsp;</Form.Label>
                            <button className="btn btn-primary" type="button">
                              Add
                            </button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <div className="">
                      <small className="mb-2 d-block">Field 1</small>
                      <p className="d-flex align-items-center fw-semibold">
                        <Form.Check
                          className="me-2"
                          type={'checkbox'}
                          id={`1`}
                        />{' '}
                        Willing to Relocate
                      </p>
                    </div>
                    <hr />
                    <div className="">
                      <small className="mb-2 d-block">Field 2</small>
                      <p className="fw-semibold">Source File</p>
                      <button
                        className="btn btn-outline-dark pe-4"
                        onClick={handleShow3}
                      >
                        <Image className="me-2" src={UploaderIconSm} /> File
                        Upload
                      </button>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="text-end p-4 mx-3">
              <button className="btn btn-primary px-4 ms-4" type="button">
                Save
              </button>
              <button className="btn  btn-outline-dark px-4 ms-4" type="button">
                Save & Add new
              </button>
            </div>
            {/* </Form> */}
          </div>
        </div>
      </Container>

      <Modal show={show1} onHide={handleClose1} className="sideModal">
        <Modal.Header closeButton>
          <Modal.Title>Work Experience</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body p-4">
          <Form.Group className="form-group">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Title"
              value={'Sr. Consultant'}
              className="form-control"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Company</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Company"
              value={'CSG'}
              className="form-control"
            />
          </Form.Group>
          <Row lg={2}>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>Industry</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Industry"
                    className="border-end-0"
                  />
                  <InputGroup.Text className="bg-transparent">
                    <Image src={ChennaiIcon} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>Location</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Location"
                    className="border-end-0"
                  />
                  <InputGroup.Text className="bg-transparent">
                    <Image src={ChennaiIcon} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="form-group m-0 pb-3">
            <Form.Check
              type={'checkbox'}
              id={`5`}
              label={`I currently work here`}
            />
          </Form.Group>
          <Row lg={2}>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>Start Date</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Start Date"
                    className="border-end-0"
                  />
                  <InputGroup.Text className="bg-transparent">
                    <Image src={CalenderIcon} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>End Date</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="End Date"
                    className="border-end-0"
                  />
                  <InputGroup.Text className="bg-transparent">
                    <Image src={CalenderIcon} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="form-group">
            <Form.Label>Description</Form.Label>
            <textarea
              className="form-control"
              rows={3}
              placeholder="Description"
              style={{ resize: 'none' }}
              cols={8}
            ></textarea>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleClose2}
            className="btn  btn-outline-dark px-4 ms-auto"
            type="button"
          >
            Cancel
          </button>
          <button className="btn  btn-primary px-4" type="button">
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} className="sideModal">
        <Modal.Header closeButton>
          <Modal.Title>Education</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body p-4">
          <Form.Group className="form-group">
            <Form.Label>School/College Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter School/College Name"
              value={'University of Mumbai'}
              className="form-control"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Qualification</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Qualification"
              value={'Master in Computer Application'}
              className="form-control"
            />
          </Form.Group>
          <Row lg={2}>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>Specialisation</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Specialisation"
                  value={'Information Systems'}
                  className="form-control"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>Grade</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Grade"
                  value={'A++'}
                  className="form-control"
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="form-group">
            <Form.Label>Location</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Location"
                value={'Chennai'}
                className="border-end-0"
              />
              <InputGroup.Text className="bg-transparent">
                <Image src={ChennaiIcon} />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Row lg={2}>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>Start Date</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Start Date"
                    className="border-end-0"
                  />
                  <InputGroup.Text className="bg-transparent">
                    <Image src={CalenderIcon} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>End Date</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="End Date"
                    className="border-end-0"
                  />
                  <InputGroup.Text className="bg-transparent">
                    <Image src={CalenderIcon} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="form-group">
            <Form.Label>Description</Form.Label>
            <textarea
              className="form-control"
              rows={3}
              placeholder="Description"
              style={{ resize: 'none' }}
              cols={8}
            ></textarea>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleClose2}
            className="btn  btn-outline-dark px-4 ms-auto"
            type="button"
          >
            Cancel
          </button>
          <button className="btn  btn-primary px-4" type="button">
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3} size="sm" centered>
        <Modal.Body className="modal-body p-4">
          <div className="text-center">
            <p className="fw-semibold mb-2">
              You sure want to remove candidate’s education “Master in Computer
              Application” ?
            </p>
            <p className="text-danger font-size-14">
              This action cannot be reversed
            </p>
            <div className="mt-4 pt-2">
              <button
                onClick={handleClose3}
                className="btn btn-outline-dark px-4 me-3"
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary px-4" type="button">
                Remove
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddCandidate
