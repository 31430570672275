import React from 'react'
import { Container, Form, Image, Table } from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { Link } from 'react-router-dom'

import DeleteIcon20 from '../../../assets/images/delete-icon-20.svg'
import EditeIcon20 from '../../../assets/images/edite-icon-20.svg'
import RightBlackArrowIcon from '../../../assets/images/right-black-arrow.svg'

const Preview = () => {
  return (
    <>
      <Container className="p-5 shadow-card-box">
        <div className="mb-3 pb-1">
          <span className="text-muted">Candidate Management</span>
          <Image src={RightBlackArrowIcon} className="mx-2" />
          <span className="text-muted">Upload Candidates</span>
          <Image src={RightBlackArrowIcon} className="mx-2" />
          <span className="text-dark">Preview</span>
        </div>
        <div className="card">
          <div className="card-body p-4">
            <div className="mb-4 d-flex align-items-center">
              <div>
                <Link to="/candidate-management" className="h4 d-block">
                  <IoMdArrowBack className="me-2" />
                  Preview Upload Candidates
                </Link>
              </div>
              <div className="ms-auto">
                <button className="btn btn-primary px-4" type="button">
                  Upload candidates
                </button>
              </div>
            </div>
            <div className="card card border-light-gray shadow-none mb-0">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <Table bordered hover className="overflow-scroll">
                    <thead>
                      <tr className="border-0">
                        <th style={{ width: '64px' }}></th>
                        <th>Candidate Name</th>
                        <th>Contact</th>
                        <th>Skill Set</th>
                        <th>Experience</th>
                        <th className="text-end">&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-0">
                        <td>
                          {' '}
                          <Form.Check type={'checkbox'} id={`1`} />
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span
                              className="raunr-alphabet-1 me-2"
                              style={{ backgroundColor: '#e05151' }}
                            >
                              R
                            </span>
                            Raymond Dane
                          </div>
                        </td>
                        <td>raymond09@mail.com</td>
                        <td>React.js, EcmaScript, JSON, Immutable, +2</td>
                        <td>3 years</td>
                        <td className="text-end">
                          <Link to="/edit-candidate">
                            <Image src={EditeIcon20} className="me-2" />
                          </Link>
                          <Link to="javascript:;">
                            <Image src={DeleteIcon20} className="me-2" />
                          </Link>
                        </td>
                      </tr>
                      <tr className="border-0">
                        <td>
                          {' '}
                          <Form.Check type={'checkbox'} id={`2`} />
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span
                              className="raunr-alphabet-1 me-2"
                              style={{ backgroundColor: '#9b51e0' }}
                            >
                              S
                            </span>
                            Savannah Nguyen
                          </div>
                        </td>
                        <td>raymond09@mail.com</td>
                        <td>React.js, EcmaScript, JSON, Immutable, +2</td>
                        <td>3 years</td>
                        <td className="text-end">
                          <Link to="/candidate-management">
                            <Image src={EditeIcon20} className="me-2" />
                          </Link>
                          <Link to="/candidate-management">
                            <Image src={DeleteIcon20} className="me-2" />
                          </Link>
                        </td>
                      </tr>
                      <tr className="border-0">
                        <td>
                          {' '}
                          <Form.Check type={'checkbox'} id={`3`} />
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span
                              className="raunr-alphabet-1 me-2"
                              style={{ backgroundColor: '#219653' }}
                            >
                              a
                            </span>
                            Albert Flores
                          </div>
                        </td>
                        <td>raymond09@mail.com</td>
                        <td>React.js, EcmaScript, JSON, Immutable, +2</td>
                        <td>3 years</td>
                        <td className="text-end">
                          <Link to="/candidate-management">
                            <Image src={EditeIcon20} className="me-2" />
                          </Link>
                          <Link to="/candidate-management">
                            <Image src={DeleteIcon20} className="me-2" />
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Preview
