import { Action, Store } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { TOPIC_LIST_URL } from '../../service/apiEndPoints'
import { apiCall, METHOD } from '../../service/baseApiCall'
import { errorToast, successToast } from '../../util/utils'
import { GET_TOPIC_LIST } from './commonConst'

export const getTopicListThunk =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: GET_TOPIC_LIST.GET_TOPIC_LIST_REQUEST,
    })
    apiCall(
      TOPIC_LIST_URL,
      (res) => {
        dispatch({
          type: GET_TOPIC_LIST.GET_TOPIC_LIST_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_TOPIC_LIST.GET_TOPIC_LIST_ERROR,
        })
      },
      {},
      METHOD.GET,
      { showToast: false }
    )
  }
