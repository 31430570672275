import React, { useEffect, useState } from 'react'
import {
  Col,
  Collapse,
  Container,
  Form,
  Image,
  Row,
  Table,
} from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import ArrowDownIcon from '../../../../assets/images/arrow_down_icon.svg'
import BlackQuestionPaperIcon from '../../../../assets/images/black-question-paper.svg'
import LeftCircleArrow from '../../../../assets/images/left-circle-arrow.svg'
import QuestionPaperImage from '../../../../assets/images/question-paper.png'
import RefreshIcon from '../../../../assets/images/refresh-icon.svg'
import RightCircleArrow from '../../../../assets/images/right-circle-arrow.svg'
import { Loader } from '../../../../components/Loader'
import Pagination from '../../../../components/Pagination'
import { generateQuestionsByTopicListThunk } from '../../../../redux/exam/examActions'
import { Store } from '../../../../redux/rootType'
import { dispatch } from '../../../../redux/store'
import { dangerouslySetHTML, errorToast } from '../../../../util/utils'

/**
 * ==================================================
 * Global code
 * ==================================================
 */
const findDifficultyLevel = (level) => {
  switch (level) {
    case 'E':
      return 'Easy'
    case 'M':
      return 'Medium'
    case 'H':
      return 'Hard'
  }
}
const itemsPerPage = 10

const CreateExamQuestionsLibrary = (props) => {
  /**
   * ==================================================
   * const variable
   * ==================================================
   */
  const topicIds = props?.formikCreateExam?.values?.topics?.map((data) => {
    return data?.value
  })
  const question_count =
    Number(props?.formikCreateExam?.values?.question_count) || 10

  /**
   * ==================================================
   * Data from redux store
   * ==================================================
   */

  const questionListByTopics = useSelector(
    (state: Store) => state?.examReducer?.questionListByTopics
  )

  const topicLists = questionListByTopics?.map((item) => {
    const questionCount = item?.questions?.length
    return { ...item.topic, questionCount }
  })

  const flattenedQuestionListByTopicsData = questionListByTopics.flatMap(
    (item) =>
      item.questions.map((question, index) => ({
        ...question,
        questionId: index + 1,
        topic: item.topic,
      }))
  )

  const questionSetData = questionListByTopics.map((item) => {
    return {
      subject: item.topic.subject,
      question_list: item.questions.map((question) => ({
        question: question.id,
        marks: question.score ? question.score : 1,
        topic: item.topic.id,
      })),
    }
  })

  const isLoadingQuestionListByTopics = useSelector(
    (state: Store) => state?.examReducer?.isLoadingQuestionListByTopics
  )

  /**
   * ==================================================
   * Component state
   * ==================================================
   */

  const navigate = useNavigate()
  const [allQuestions, setAllQuestions] = useState<any>([])
  const [paginatedData, setPaginatedData] = useState(
    (allQuestions || [])?.slice(0, itemsPerPage)
  )
  const [open, setOpen] = useState(false)
  const [questionId, setQuestionId] = useState(0)
  const [page, setPage] = useState(1)
  const [showImageModal, setShowImageModal] = useState(false)
  const [clickeImagePath, setClickedImagePath] = useState('')
  const [isRegenerated, setIsRegenerated] = useState(false)

  /**
   * ==================================================
   * functionality
   * ==================================================
   */

  const handleQuestionOptions = (option) => {
    switch (option) {
      case 0:
        return 'A'
      case 1:
        return 'B'
      case 2:
        return 'C'
      case 3:
        return 'D'
      case 4:
        return 'E'
      case 5:
        return 'F'
    }
  }

  const handleRegenerateQuestions = () => {
    dispatch(
      generateQuestionsByTopicListThunk(
        { question_count: question_count, topics: topicIds },
        () => {
          props.formikCreateExam.setFieldValue('isAutoGenerateQuestions', true)
          setIsRegenerated(!isRegenerated)
        },
        (err) => {
          errorToast(err?.message?.distribution)
          props.formikCreateExam.setFieldValue('isAutoGenerateQuestions', false)
        }
      )
    )
  }

  const _handleImageClick = (item: { image: string }) => {
    setClickedImagePath(item?.image)
    setShowImageModal(true)
  }

  /**
   * ==================================================
   * use effect handling
   * ==================================================
   */
  // useEffect(() => {
  //   dispatch(
  //     generateQuestionsByTopicListThunk(
  //       { question_count: question_count, topics: topicIds },
  //       () => {
  //         props.formikCreateExam.setFieldValue('isAutoGenerateQuestions', true)
  //       },
  //       (err) => {
  //         errorToast(err?.message?.distribution)
  //         props.formikCreateExam.setFieldValue('isAutoGenerateQuestions', false)
  //       }
  //     )
  //   )
  // }, [])

  useEffect(() => {
    props.formikCreateExam.setFieldValue('question_set_data', questionSetData)
  }, [questionListByTopics, isRegenerated])

  useEffect(() => {
    setAllQuestions(flattenedQuestionListByTopicsData || [])
  }, [questionListByTopics, isRegenerated])

  useEffect(() => {
    const newOffset = ((page - 1) * itemsPerPage) % (allQuestions?.length || 1)
    const newLimit = page * itemsPerPage
    setPaginatedData(allQuestions?.slice(newOffset, newLimit))
    setAllQuestions((prev) => prev)
  }, [page, allQuestions, isRegenerated])

  return (
    <>
      <div className="fixed-top">
        <div className="progress-header">
          <Link to="/exams" className="h5 text-white mb-0">
            <IoMdArrowBack className="me-2" />
            Create New Exam
          </Link>
          <p className="text-white h6 fw-normal ms-auto mb-0">Step 1/3</p>
        </div>
        <div className="progress-line">
          <div className="progress" style={{ width: '66%' }}></div>
        </div>
      </div>

      <div className="card border-0">
        <div className="card-body p-0">
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg={12}>
                <div className="d-flex align-items-center mb-5">
                  <Image src={BlackQuestionPaperIcon} />
                  <h5 className="ms-2 mb-0">Questions Library</h5>
                </div>
                <div className="d-flex mb-4 pb-2">
                  {topicLists?.slice(0, 3)?.map((topic) => {
                    return (
                      <>
                        <h6 className="mb-0 fw-normal">
                          {topic?.name} - {topic?.questionCount}
                        </h6>
                        <span className="new-gray mx-3">|</span>
                      </>
                    )
                  })}
                  {topicLists?.slice(3)?.length > 0 && (
                    <h6
                      className="text-primary mb-0 fw-normal pointer"
                      style={{ marginRight: '2rem' }}
                    >
                      {topicLists?.slice(3)?.length} More Topic
                    </h6>
                  )}
                  <Image src={RefreshIcon} />
                  <span
                    className="text-primary ms-1 fw-bold pointer"
                    onClick={handleRegenerateQuestions}
                  >
                    Regenerate Questions
                  </span>
                  <h6 className="mb-0 ms-auto fw-bold">
                    Total questions:{' '}
                    <span className="text-new-primary">
                      {allQuestions?.length}
                    </span>
                  </h6>
                </div>
                <div className="table-responsive">
                  <>
                    <Table bordered responsive>
                      <thead>
                        <tr className="table-accordian-light">
                          <th style={{ width: '50px' }}>#</th>
                          <th style={{ width: '40px' }}>No.</th>
                          <th>Question</th>
                          <th style={{ width: '100px' }}>Topic</th>
                          <th style={{ width: '100px' }}>Complexity</th>
                          <th style={{ width: '100px' }}>Score</th>
                          <th style={{ width: '100px' }}>Time</th>
                        </tr>
                      </thead>
                      <tbody className="accordian-table-body">
                        {isLoadingQuestionListByTopics ? (
                          <tr>
                            <td colSpan={8}>
                              <Loader height={'500px'} />
                            </td>
                          </tr>
                        ) : paginatedData?.length > 0 ? (
                          (paginatedData || [])?.map((item, index) => {
                            return (
                              <>
                                <tr
                                  className="border-0 collapse-row pointer"
                                  onClick={() => {
                                    setQuestionId(open ? index + 1 : 0)
                                    setOpen(!open)
                                  }}
                                  aria-controls="example-collapse-text"
                                  aria-expanded={
                                    questionId == index + 1 ? true : false
                                  }
                                >
                                  <td
                                    className="text-center"
                                    style={{ width: '60px' }}
                                  >
                                    <Image
                                      className="rotate-img"
                                      src={ArrowDownIcon}
                                    />
                                  </td>
                                  <td style={{ width: '40px' }}>
                                    {item?.questionId}
                                  </td>
                                  <td
                                    className="sub-title-clr"
                                    style={{ fontSize: '16px' }}
                                  >
                                    {dangerouslySetHTML(item?.text)}
                                  </td>
                                  <td style={{ width: '100px' }}>
                                    {/* {item?.topic?.join(',')} */}
                                    {item?.topic?.name}
                                  </td>
                                  <td style={{ width: '100px' }}>
                                    {findDifficultyLevel(
                                      item?.difficulty_level
                                    )}
                                  </td>
                                  <td style={{ width: '100px' }}>
                                    {item?.score}
                                  </td>
                                  <td style={{ width: '100px' }}>
                                    {item?.time_to_answer} min
                                  </td>
                                </tr>
                                <tr className="border-0">
                                  <td colSpan={6} className="p-0 text-dark">
                                    <Collapse
                                      in={
                                        questionId == index + 1 ? true : false
                                      }
                                    >
                                      <div
                                        id="example-collapse-text"
                                        className="px-5 py-4"
                                      >
                                        <p className="fw-semibold mb-2">
                                          Question
                                        </p>
                                        <p className="font-size-16 sub-title-clr mb-3">
                                          {/* What will be the output of the
                                          following program? */}
                                          {dangerouslySetHTML(item?.text)}
                                        </p>
                                        {/* comp?.logo
                                ? `${process.env.REACT_APP_IMAGE_URL}${comp?.logo}`
                                : InactiveIcon */}
                                        {item?.body_images?.length
                                          ? item?.body_images?.map((image) => {
                                              return (
                                                <Image
                                                  src={
                                                    image?.body_images
                                                      ? `${process.env.REACT_APP_IMAGE_URL}${image}`
                                                      : QuestionPaperImage
                                                  }
                                                />
                                              )
                                            })
                                          : ''}
                                        {item?.options?.length > 0 && (
                                          <div className="mt-4 pt-3">
                                            <p className="fw-semibold">
                                              Options (Correct answer is marked
                                              in green)
                                            </p>
                                            {item?.options?.map(
                                              (questionOptions, index) => {
                                                return (
                                                  <div className="mb-3 d-flex">
                                                    <span
                                                      className={
                                                        questionOptions?.is_correct
                                                          ? 'Active-answer'
                                                          : 'Inactive-answer'
                                                      }
                                                    >
                                                      {handleQuestionOptions(
                                                        index
                                                      )}
                                                    </span>
                                                    <span className="sub-title-clr font-size-16">
                                                      {dangerouslySetHTML(
                                                        questionOptions?.text
                                                      )}
                                                    </span>
                                                    {questionOptions?.image ? (
                                                      <img
                                                        src={`${process.env.REACT_APP_IMAGE_URL}${questionOptions?.image}?w=10&h=10`}
                                                        srcSet={`${process.env.REACT_APP_IMAGE_URL}${questionOptions?.image}?w=10&h=1&dpr=2 2x`}
                                                        alt={questionOptions?.id?.toString()}
                                                        loading="lazy"
                                                        className="border rounded mt-2 pe-zoom question-preview"
                                                        onClick={() =>
                                                          _handleImageClick(
                                                            questionOptions
                                                          )
                                                        }
                                                      />
                                                    ) : null}
                                                  </div>
                                                )
                                              }
                                            )}
                                            {/* <div className="mb-3">
                                            <span className="Inactive-answer">
                                              B
                                            </span>
                                            <span className="sub-title-clr font-size-16">
                                              8 times *** and 7 times +++++
                                            </span>
                                          </div> */}
                                            {/* <div className="mb-3">
                                            <span className="Active-answer">
                                              B
                                            </span>
                                            <span className="sub-title-clr font-size-16">
                                              8 times *** and 7 times +++++
                                            </span>
                                          </div> */}
                                          </div>
                                        )}
                                        {item?.explanation && (
                                          <div className="mt-4 pt-3">
                                            <p className="fw-semibold mb-3">
                                              Explanation{' '}
                                            </p>
                                            <p className="sub-title-clr font-size-16">
                                              {dangerouslySetHTML(
                                                item?.explanation
                                              )}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    </Collapse>
                                  </td>
                                </tr>
                              </>
                            )
                          })
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                    {allQuestions?.length > itemsPerPage ? (
                      <Pagination
                        tableCount={allQuestions?.length}
                        page={page}
                        setPage={setPage}
                        backgroundColor="bg-white"
                        itemsPerPage={itemsPerPage}
                      />
                    ) : null}
                    {/* <div className="d-flex align-items-center mt-3">
                        <p className="sub-title-clr font-size-16 me-auto">
                          1-10 of 30
                        </p>
                        <Form.Select
                          className="w-130 shadow-none border-0 p-0 form-select"
                          aria-label="Default select example"
                        >
                          <option>10 results/page</option>
                          <option value="1">Last 3 Months</option>
                          <option value="2">Last 6 Months</option>
                          <option value="3">Last 9 Months</option>
                        </Form.Select>
                        <p className="ms-4 font-size-16 mb-0 sub-title-clr">
                          <span className="pointer">
                            <Image src={LeftCircleArrow} />
                          </span>
                          <span className="mx-2">1 / 3</span>
                          <span className="pointer">
                            <Image src={RightCircleArrow} />
                          </span>
                        </p>
                      </div> */}
                  </>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <div className="card-footer px-5 text-end">
          <button
            className="btn btn-primary"
            onClick={() => navigate('/create-exam-setting')}
          >
            Next Step
          </button>
        </div> */}
      </div>
    </>
  )
}

export default CreateExamQuestionsLibrary
