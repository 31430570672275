import { Image } from 'react-bootstrap'

import BlueEarthIcon from '../../../../assets/images/blue-earth.svg'
import BlueGmailIcon from '../../../../assets/images/blue-gmail.svg'
import FacebookSocialIcon from '../../../../assets/images/facebook-social.svg'
import LinkedInIcon from '../../../../assets/images/linked-in.png'
import UserImagesIcon from '../../../../assets/images/User-images.svg'

const ClientDetail = () => {
  return (
    <>
      <h6 className="fw-bold mb-2">About Company</h6>
      <p className="mb-4 pb-2">
        Since its founding in 2013, ABC Software Solution is a software company
        focused on people that aims to improve the world. All of the people we
        deal with are at the core of our value system since they are our
        greatest source of inspiration and support.
      </p>
      <h6 className="fw-bold mb-2">Address</h6>
      <p className="mb-4 pb-2">
        26/C, Hosur Road, Electronics City, Bangalore, Karnataka, India - 560100
      </p>
      <h6 className="fw-bold mb-2">Contact Information</h6>
      <div className="mb-2">
        <Image src={BlueEarthIcon} />
        <span className="ms-2">ABC Software Solution</span>
      </div>
      <div className="mb-4 pb-2">
        <Image src={BlueGmailIcon} />
        <span className="ms-2">sam.s@ABCSoftwareSolution.in</span>
      </div>
      <h6 className="fw-bold mb-2">Social Links:</h6>
      <div className="social-link-box">
        <div className="social-box">
          <Image src={FacebookSocialIcon} />
          <span className="ms-2 fw-bold">ABC Software Solution</span>
        </div>
        <div className="social-box">
          <Image src={LinkedInIcon} />
          <span className="ms-2 fw-bold">ABC Software Solution</span>
        </div>
      </div>
      <hr className="custom-hr" />
      <div className="d-flex mb-3">
        <Image src={UserImagesIcon} width={'35px'} />
        <div className="ms-2">
          <p className="mb-0 text-dark">Wade Warren</p>
          <small className="dark-gray">Team Name </small>
        </div>
      </div>
      <div className="d-flex mb-3">
        <Image src={UserImagesIcon} width={'35px'} />
        <div className="ms-2">
          <p className="mb-0 text-dark">Gorge Filly</p>
          <small className="dark-gray">Team Name </small>
        </div>
      </div>
    </>
  )
}

export default ClientDetail
