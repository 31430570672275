import 'react-toastify/dist/ReactToastify.css'

import { Suspense, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  BrowserRouter as Router,
  Navigate as Redirect,
  Route,
  Routes as Switch,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Header from '../components/Header'
import { Loader } from '../components/Loader'
import Sidebar from '../components/Sidebar'
import { Store } from '../redux/rootType'
import messages_en from '../translations/en.json'
import messages_hi from '../translations/hi.json'
import { privateRoutes, publicRoutes } from './routeConstants'

export default function Routes() {
  const isLogout = useSelector((state: Store) => state.authReducer.isLogout)
  const isLogin = useSelector((state: Store) => state.authReducer.isLogin)
  const accessToken = useSelector(
    (state: Store) => state.authReducer.accessToken
  )

  const language = 'en' //useSelector((state: Store) => state.userDataReducer.local);
  const messages = {
    en: messages_en,
    hi: messages_hi,
  }
  const [message, setMessage] = useState(messages['en'])

  useEffect(() => {
    if (language === 'en') {
      setMessage(messages['en'])
    }
    // else {
    //   setMessage(messages['zh'])
    // }
  }, [language])

  return (
    <div>
      <IntlProvider messages={message} locale={language}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          closeButton={false}
          pauseOnHover
          limit={1}
        />
        <Router basename={process.env.PUBLIC_URL}>
          <Suspense fallback={<Loader height="100vh" />}>
            <Switch>
              {/* {!isLogin && isLogout && !accessToken ? ( */}
              <>
                {publicRoutes.map(
                  ({ path, element: Element, navBar }, index) => (
                    <Route
                      key={index}
                      path={path}
                      element={
                        <div id="app">
                          <div className="content-wrapper">
                            {navBar && <Header />}
                            <Element />
                          </div>
                        </div>
                      }
                    />
                  )
                )}
                {/* <Route
                    path="/"
                    element={<Redirect to="/auth/login" replace />}
                  />
                  <Route path="*" element={<Redirect to="/" replace />} /> */}
              </>
              {/* ) : (
                <Route
                  path="/auth/login"
                  element={<Redirect to="/home" replace />}
                />
              )} */}

              {/* {isLogin && !isLogout && accessToken && ( */}
              <>
                {privateRoutes.map(
                  ({ path, element: Element, navBar, sideBar }, index) => (
                    <Route
                      key={index}
                      path={path}
                      element={
                        <div id="app">
                          {navBar && (
                            <div
                              className="position-fixed top-0 w-100"
                              style={{ zIndex: 999 }}
                            >
                              <Header />
                            </div>
                          )}
                          {sideBar && (
                            <div className="siderbar-menu">
                              <Sidebar />
                            </div>
                          )}
                          <div
                            style={{
                              marginLeft: sideBar ? '250px' : '',
                              marginTop: navBar ? '66px' : '',
                              overflowX: 'hidden',
                              background: '#FAFAFA',
                              height: 'calc(100vh - 70px)',
                            }}
                          >
                            <Element />
                          </div>
                        </div>
                      }
                    />
                  )
                )}
                <Route path="/" element={<Redirect to="/home" replace />} />
                <Route
                  path="*"
                  element={<Redirect to="/page-not-found" replace />}
                />
              </>
              {/* )} */}
            </Switch>
          </Suspense>
        </Router>
      </IntlProvider>
    </div>
  )
}
