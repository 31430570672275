import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { Link } from 'react-router-dom'

import PlusIcon from '../../../assets/images/btn-plus.svg'
import UploaderIcon from '../../../assets/images/help_drag.svg'
import RightBlackArrowIcon from '../../../assets/images/right-black-arrow.svg'
import XlsIcon58 from '../../../assets/images/xls-icon-58.svg'

const UploadCandidates = () => {
  return (
    <>
      <Container className="p-5 shadow-card-box">
        <div className="mb-3 pb-1">
          <span className="text-muted">Candidate Management</span>
          <Image src={RightBlackArrowIcon} className="mx-2" />
          <span className="text-dark">Darlene Robertson</span>
        </div>
        <div className="card">
          <div className="card-body p-5">
            <div className="mb-4 d-flex align-items-center">
              <div>
                <Link to="/candidate-management" className="h4 d-block">
                  <IoMdArrowBack className="me-2" />
                  Add Candidate
                </Link>
                <p className="mb-0 ms-4">
                  Use these templates <a href="javascript:;">CSV</a> or{' '}
                  <a href="javascript:;">XLSX</a>. File size upto 10MB
                </p>
              </div>
              <div className="ms-auto">
                <button
                  className="btn btn-outline-dark pe-4 me-3"
                  type="button"
                >
                  <Image src={PlusIcon} className="me-1" /> Add Files
                </button>
                <Link to="/candidates-preview" className="btn btn-primary px-4">
                  Next
                </Link>
              </div>
            </div>
            <div>
              <div
                className="photo-uploader mb-0 bg-light"
                style={{
                  minHeight: 437,
                }}
              >
                <Image src={UploaderIcon} />
                <p className="mt-3 text-center mb-2">
                  Drag & drop file here Or
                  <span className="text-primary"> Browse file</span> on you
                  computer
                </p>
                <p className="mb-0 light-gray-text">
                  Upload JPG, PNG, Docs smaller than 5MB
                </p>
              </div>
            </div>

            <div className="mt-4">
              <div
                className="photo-uploader mb-0 bg-light justify-content-start align-items-start p-4"
                style={{
                  minHeight: 437,
                }}
              >
                <Row className="g-4 w-100">
                  <Col lg={2}>
                    <div className="card border-light-gray shadow-none mb-0">
                      <div className="card-body d-flex flex-column align-items-center text-center px-2 py-4">
                        <Image
                          src={XlsIcon58}
                          className="mb-3"
                          width={'58px'}
                        />
                        <span className="font-size-12">
                          Candidate lis... (10 MB)
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default UploadCandidates
