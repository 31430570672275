import moment from 'moment'
import * as Yup from 'yup'

export const RESULT_VALIDATION_BUFFER = 4

const ExamSchema = Yup.object().shape({
  exam_name: Yup.string()
    .required('The Exam Name field is required.')
    .max(150, 'Exam Name text may not be longer than. 150 characters.')
    .matches(/(?![\s.]+$)/, 'Enter a valid Exam Name'),
  description: Yup.string().max(
    1024,
    'Description text may not be longer than. 1024 characters.'
  ),
  exam_type: Yup.string().required('This field is required'),
  Instructions: Yup.string().max(
    200,
    'Instructions text may not be longer than. 200 characters.'
  ),
  exam_duration: Yup.number()
    .min(1, 'Please provide exam duration between 1 to 360')
    .max(360, 'Please provide exam duration between 1 to 360')
    .typeError('Please enter valid Exam Duration')
    .required('Exam Duration is required field'),
})

// const AssignExamSchema = Yup.object().shape({
//   batchType: Yup.string().required('Please select batch type'),
//   batchName: Yup.string().required('Batch is required'),
//   selectedMembers: Yup.array().min(1, 'Please select members'),
//   examDuration: Yup.number()
//     .required(`Exam duration is required`)
//     .min(1, 'Duration should be greater than 0')
//     .max(360, 'Duration should not be greater than 360')
//     .typeError('Please enter valid number'),
//   examValidFromDate: Yup.date().required('Valid from date is required.'),

//   examValidFromTime: Yup.date()
//     .required('Valid from time is required')
//     .test(
//       'examValidFromTime',
//       'Time must be greater than current time',
//       function (val, testContext) {
//         const { examValidFromTime, examValidFromDate } = this.parent
//         if (!examValidFromTime) return true
//         const selectedDate = moment(examValidFromDate)?.format('YYYY-MM-DD')
//         const selectedTime = moment(examValidFromTime).format('HH:mm')

//         if (
//           moment(
//             moment(`${selectedDate} ${selectedTime}`).format('YYYY-MM-DD HH:mm')
//           ).isSameOrBefore(moment(moment().format('YYYY-MM-DD HH:mm')))
//         ) {
//           return testContext.createError({
//             ...testContext,
//             message: 'Time must be greater than current time',
//           })
//         }
//         return true
//       }
//     ),
//   examValidTillDate: Yup.date()
//     .required('Valid till date is required')
//     .test('examValidTillDate', 'Error', function (val, testContext) {
//       const {
//         examValidFromDate,
//         examValidTillTime,
//         examValidTillDate,
//         batchExpiryDate,
//       } = this.parent
//       if (!examValidTillTime) return true
//       if (
//         moment(moment(examValidTillDate).format('YYYY-MM-DD')).isBefore(
//           moment(examValidFromDate).format('YYYY-MM-DD')
//         )
//       ) {
//         return testContext.createError({
//           ...testContext,
//           message: 'Date must be greater than from date',
//         })
//       }
//       if (
//         batchExpiryDate &&
//         moment(moment(examValidTillDate).format('YYYY-MM-DD')).isAfter(
//           moment(batchExpiryDate).format('YYYY-MM-DD')
//         )
//       ) {
//         return testContext.createError({
//           ...testContext,
//           message: `Valid till date must be less than batch expiry date ${batchExpiryDate}`,
//         })
//       }

//       return true
//     }),
//   examValidTillTime: Yup.date()
//     .required('Valid till time is required')
//     .test('examValidTillTime', '', function (val, testContext) {
//       const {
//         examValidFromTime,
//         examValidFromDate,
//         examValidTillDate,
//         examValidTillTime,
//       } = this.parent
//       const selectedDate = moment(examValidFromDate)?.format('YYYY-MM-DD')
//       const selectedTime = moment(examValidFromTime).format('HH:mm')
//       const selectedToDate = moment(examValidTillDate)?.format('YYYY-MM-DD')
//       const selectedToTime = moment(examValidTillTime).format('HH:mm')
//       if (!examValidTillTime) return true
//       if (
//         moment(
//           moment(`${selectedToDate} ${selectedToTime}`).format(
//             'YYYY-MM-DD HH:mm'
//           )
//         ).isSameOrBefore(
//           moment(`${selectedDate} ${selectedTime}`).format('YYYY-MM-DD HH:mm')
//         )
//       ) {
//         return testContext.createError({
//           ...testContext,
//           message: 'Time must be greater than from time',
//         })
//       }
//       return true
//     }),
//   resultDeclarationDate: Yup.date()
//     .required('Declaration date is required')
//     .test('resultDeclarationDate', 'Error', function (val, testContext) {
//       const { resultDeclarationTime, examValidTillDate } = this.parent
//       if (
//         moment(moment(val).format('YYYY-MM-DD')).isBefore(
//           moment(examValidTillDate).format('YYYY-MM-DD')
//         )
//       ) {
//         return testContext.createError({
//           ...testContext,
//           message: `Result declaration date must be greater than exam till date`,
//         })
//       }
//       if (!resultDeclarationTime) return true

//       return true
//     }),
//   resultDeclarationTime: Yup.date()
//     .required('Declaration time is required')
//     .test('resultDeclarationTime', '', function (val, testContext) {
//       const {
//         examValidTillTime,
//         examValidTillDate,
//         resultDeclarationDate,
//         resultDeclarationTime,
//         examDuration,
//       } = this.parent
//       const selectedDate = moment(examValidTillDate)?.format('YYYY-MM-DD')
//       const selectedTime = moment(examValidTillTime).format('HH:mm')
//       const declareDate = moment(resultDeclarationDate)?.format('YYYY-MM-DD')
//       const declareTime = moment(resultDeclarationTime).format('HH:mm')
//       if (
//         moment(
//           moment(`${declareDate} ${declareTime}`).format('YYYY-MM-DD HH:mm')
//         ).isSameOrBefore(
//           moment(`${selectedDate} ${selectedTime}`)
//             .add({ minute: +examDuration + RESULT_VALIDATION_BUFFER || 0 })
//             .format('YYYY-MM-DD HH:mm')
//         )
//       ) {
//         return testContext.createError({
//           ...testContext,
//           message:
//             'Result declarations time must be greater than exam valid till time + exam duration + 5 minutes as buffer time.',
//         })
//       }
//       return true
//     }),
// })

const AssignExamSchema = Yup.object().shape({
  // batchName: Yup.string().required('Batch is required'),
  examValidFromDate: Yup.date().required('Valid from date is required.'),
  selectedMembers: Yup.array().min(1, 'Please select members'),
  examValidFromTime: Yup.date()
    .required('Valid from time is required')
    .test(
      'examValidFromTime',
      'Time must be greater than current time',
      function (val, testContext) {
        const { examValidFromTime, examValidFromDate } = this.parent
        if (!examValidFromTime) return true
        const selectedDate = moment(examValidFromDate)?.format('YYYY-MM-DD')
        const selectedTime = moment(examValidFromTime).format('HH:mm')

        if (
          moment(
            moment(`${selectedDate} ${selectedTime}`).format('YYYY-MM-DD HH:mm')
          ).isSameOrBefore(moment(moment().format('YYYY-MM-DD HH:mm')))
        ) {
          return testContext.createError({
            ...testContext,
            message: 'Time must be greater than current time',
          })
        }
        return true
      }
    ),
  examValidTillDate: Yup.date()
    .required('Valid till date is required')
    .test('examValidTillDate', 'Error', function (val, testContext) {
      const {
        examValidFromDate,
        examValidTillTime,
        examValidTillDate,
        batchExpiryDate,
      } = this.parent
      if (!examValidTillTime) return true
      if (
        moment(moment(examValidTillDate).format('YYYY-MM-DD')).isBefore(
          moment(examValidFromDate).format('YYYY-MM-DD')
        )
      ) {
        return testContext.createError({
          ...testContext,
          message: 'Date must be greater than from date',
        })
      }
      if (
        batchExpiryDate &&
        moment(moment(examValidTillDate).format('YYYY-MM-DD')).isAfter(
          moment(batchExpiryDate).format('YYYY-MM-DD')
        )
      ) {
        return testContext.createError({
          ...testContext,
          message: `Valid till date must be less than batch expiry date ${batchExpiryDate}`,
        })
      }

      return true
    }),
  examValidTillTime: Yup.date()
    .required('Valid till time is required')
    .test('examValidTillTime', '', function (val, testContext) {
      const {
        examValidFromTime,
        examValidFromDate,
        examValidTillDate,
        examValidTillTime,
      } = this.parent
      const selectedDate = moment(examValidFromDate)?.format('YYYY-MM-DD')
      const selectedTime = moment(examValidFromTime).format('HH:mm')
      const selectedToDate = moment(examValidTillDate)?.format('YYYY-MM-DD')
      const selectedToTime = moment(examValidTillTime).format('HH:mm')
      if (!examValidTillTime) return true
      if (
        moment(
          moment(`${selectedToDate} ${selectedToTime}`).format(
            'YYYY-MM-DD HH:mm'
          )
        ).isSameOrBefore(
          moment(`${selectedDate} ${selectedTime}`).format('YYYY-MM-DD HH:mm')
        )
      ) {
        return testContext.createError({
          ...testContext,
          message: 'Time must be greater than from time',
        })
      }
      return true
    }),
  selectedJD: Yup.array().min(1, 'Please select job description'),
})

export { AssignExamSchema, ExamSchema }
