import { Box, LinearProgress, Typography } from '@mui/material'
import { Formik } from 'formik'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import PlusIcon from '../../../assets/images/btn-plus.svg'
import DocIcon58 from '../../../assets/images/doc-icon-58.svg'
import UploaderIcon from '../../../assets/images/help_drag.svg'
import RightBlackArrowIcon from '../../../assets/images/right-black-arrow.svg'
import {
  setInitialUploadedResumeData,
  setUploadedResumeData,
} from '../../../redux/resume-library/resumeLibraryActions'
import { Store } from '../../../redux/rootType'
import { dispatch } from '../../../redux/store'
import { formatBytes, generateUUID } from '../../../util/utils'

interface InitialValues {
  batchName: string
  description: string
  activationDate: string
  expiryDate: string
  jobRole: string
  institute: string
  resumes: any
  subjects: any
  selectedCandidate: { value: string; label: string; email: string }[]
  email: string[]
  addMembersType: string
  skillSet: string[]
  selectUploadedResumes: boolean
  file: string | File
  examViewAnswers: boolean
  resume_data: any
}

interface ExtractedResumeData {
  file_name: string
  message: string
  file_size: string
  file_url: string
  extracted_data: {
    name: string
    email: string
    experience: number
    phone_number: string
    uuid: string
    skills: string[]
    skill_similarity_score: number
    address: { city: string; country: string; state: string }
  }
  isChecked?: boolean
}

const UploadCandidates = () => {
  const formRef = useRef<any>()
  const navigate = useNavigate()

  const userDetails = useSelector(
    (state: Store) => state?.authReducer?.userDetails
  )
  const uploadedResumeList = useSelector(
    (state: Store) => state?.resumeLibraryReducer?.uploadedResumeList
  )

  const [formValues] = useState<InitialValues>({
    batchName: formRef?.current?.values
      ? formRef?.current?.values?.batchName
      : '',
    description: '',
    activationDate: '',
    expiryDate: '',
    jobRole: '',
    resumes: uploadedResumeList || ([] as any),
    subjects: [],
    selectedCandidate: [],
    email: [],
    addMembersType: '',
    skillSet: [],
    selectUploadedResumes: false,
    file: '',
    institute: '',
    examViewAnswers: false,
    resume_data: [],
  })
  const [socketConnectionLoading, setIsLoading] = useState(false)
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const [selectUploadedResumes, setSelectUploadedResumes] = useState(false)
  const [socketData, setSocketData] = useState<any>([])
  const [filteredResumeData, setFilteredResumeData] = useState<
    (ExtractedResumeData & { id: number; error: any })[]
  >([])
  const [resumeDataLength, setResumeDataLength] = useState(0)
  const [isLoadingResumes, setIsLoadingResumes] = useState(false)

  const handleSubmit = (values: InitialValues) => {
    // Empty onSubmit function
  }

  const InputTag = ({ values, setFieldValue }) => {
    return (
      <input
        type="file"
        multiple
        accept="application/pdf"
        title=""
        className="blue-input pointer"
        onChange={(e) => {
          const files: {
            file: File
            error: string
          }[] = values?.resumes || []
          for (
            let index = 0;
            index < (e?.target?.files?.length || 0);
            index++
          ) {
            let error = ''
            if (
              e?.target?.files &&
              e?.target?.files[index].size > 2 * 1024 * 1024
            ) {
              error = 'File with maximum size of 2MB is not allowed'
            }
            e?.target?.files &&
              e?.target?.files[index] &&
              files.push({
                file: e?.target?.files[index],
                error,
              })
          }
          dispatch(setUploadedResumeData(files || values?.resumes?.files || []))

          setFieldValue('resumes', files || values?.resumes?.files || [])
        }}
      />
    )
  }

  useEffect(() => {
    setFilteredResumeData(
      socketData?.map((resumeData, index) => ({
        ...resumeData,
        id: index,
      }))
    )
  }, [socketData])

  useEffect(() => {
    setFilteredResumeData(
      socketData?.map((resumeData, index) => ({
        ...resumeData,
        id: index,
      }))
    )
  }, [socketData])

  if (socketData?.length && resumeDataLength === socketData?.length) {
    navigate('/candidates-preview-resumes', {
      state: { resumeData: socketData },
    })
  }

  return (
    <>
      <Container className="p-5 shadow-card-box">
        <div className="mb-3 pb-1">
          <span className="text-muted">Candidate Management</span>
          <Image src={RightBlackArrowIcon} className="mx-2" />
          <span className="text-dark">Upload Candidates</span>
        </div>
        <Formik
          initialValues={formValues}
          onSubmit={handleSubmit}
          enableReinitialize
          innerRef={formRef}
          // values={formValues}
        >
          {({
            setFieldValue,
            values,
            errors,
            touched,
            validateForm,
            setFieldTouched,
          }) => {
            return (
              <>
                <div className="card">
                  <div className="card-body p-5">
                    <div className="mb-4 d-flex align-items-center">
                      <div>
                        <Link
                          to="/candidate-management"
                          onClick={() =>
                            dispatch(setInitialUploadedResumeData())
                          }
                          className="h4 d-block"
                        >
                          <IoMdArrowBack className="me-2" />
                          Upload Resumes
                        </Link>
                        <p className="mb-0 ms-4">
                          You can upload files in formats JPG, PNG, and Docs,
                          with a maximum limit of 50 files per upload.
                        </p>
                      </div>
                      <div className="ms-auto">
                        <button
                          className="btn btn-outline-dark pe-4 me-3 fileinput-container"
                          type="button"
                          disabled={isLoadingResumes}
                        >
                          <InputTag
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                          <Image src={PlusIcon} className="me-1" /> Add Files
                        </button>
                        <button
                          className="btn btn-primary px-4"
                          type="submit"
                          disabled={
                            values?.resumes?.filter((resume) => resume?.error)
                              ?.length ||
                            values?.resumes?.length > 150 ||
                            socketConnectionLoading ||
                            isLoadingResumes ||
                            values?.resumes?.length < 1
                          }
                          onClick={() => {
                            setIsLoadingResumes(true)
                            if (values?.resumes?.length <= 0) {
                              return
                            }
                            if (
                              values?.resumes?.filter((resume) => resume?.error)
                                ?.length ||
                              values?.resumes?.length > 150
                            ) {
                              return
                            }
                            validateForm(values).then(() => {
                              const keysLength = Object.keys(errors)?.filter(
                                (key) => key !== 'selectedCandidate'
                              ).length
                              if (keysLength) {
                                for (const key in errors) {
                                  if (key === 'selectedCandidate') continue
                                  setFieldTouched(key, true)
                                }
                              } else {
                                const socket = new WebSocket(
                                  `wss://proctoring.prep.study:8002/ws/${generateUUID()}`
                                )
                                setIsLoading(true)
                                setSocket(socket)
                                socket.onopen = function (event) {
                                  setFieldValue('socketData', [])
                                  for (const file of values?.resumes || []) {
                                    const metadata = {
                                      filename: file?.file?.name,
                                      skill_set: values?.skillSet,
                                      user_info: {
                                        user_name: userDetails?.username,
                                        user_id: `${userDetails?.id}`,
                                      },
                                    }

                                    // Convert metadata to JSON string
                                    const metadataJson =
                                      JSON.stringify(metadata)

                                    // Send file data and metadata
                                    const blob = new Blob([file?.file], {
                                      type: file?.type,
                                    })
                                    socket.send(blob)
                                    socket.send(metadataJson)
                                    setResumeDataLength(values?.resumes?.length)
                                  }
                                }

                                socket.onmessage = function (event) {
                                  setIsLoading(false)
                                  setSocketData((prev: any) => {
                                    const prevData = _.cloneDeep(prev)
                                    prevData?.push(JSON.parse(event?.data))
                                    setFieldValue(
                                      'selectedCandidate',
                                      prevData?.map((resumeData, index) => ({
                                        ...resumeData,
                                        id: index,
                                      }))
                                    )
                                    return prevData
                                  })

                                  setSelectUploadedResumes(true)
                                  setFieldValue('selectUploadedResumes', true)
                                }
                              }
                            })
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    {isLoadingResumes &&
                      values?.resumes?.length !== socketData?.length && (
                        <Row
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress
                              variant="buffer"
                              value={Math.round(
                                (socketData?.length / values?.resumes?.length) *
                                  100
                              )}
                              classes={{
                                root: 'custom-progress',
                              }}
                              valueBuffer={
                                Math.round(
                                  (socketData?.length /
                                    values?.resumes?.length) *
                                    100
                                ) > 100
                                  ? 100
                                  : Math.round(
                                      (socketData?.length /
                                        values?.resumes?.length) *
                                        100
                                    ) + 10
                              }
                            />
                          </Box>
                          <Box sx={{ minWidth: 35 }}>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                            >{`${Math.round(
                              (socketData?.length / values?.resumes?.length) *
                                100
                            )}%`}</Typography>
                          </Box>
                        </Row>
                      )}
                    <div>
                      {values?.resumes?.length ? null : (
                        <div
                          className="photo-uploader mb-0 bg-light fileinput-container"
                          style={{
                            minHeight: 437,
                            display: 'flex',
                          }}
                        >
                          <Image src={UploaderIcon} />
                          <p className="mt-3 text-center mb-2">
                            Drag & drop file here Or
                            <div className="text-primary d-inline">
                              Browse file
                              <InputTag
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            </div>{' '}
                            <span className="text-primary"> Browse file</span>{' '}
                            on you computer
                          </p>
                          <p className="mb-0 light-gray-text">
                            Upload JPG, PNG, Docs smaller than 5MB
                          </p>
                        </div>
                      )}
                      {errors?.resumes && touched?.resumes && (
                        <p className="formik-error">
                          {Array.isArray(errors?.resumes)
                            ? ''
                            : (errors as any)?.resumes}
                        </p>
                      )}
                      {values?.resumes?.length ? (
                        <div className="mt-4">
                          <div
                            className="photo-uploader mb-0 bg-light justify-content-start align-items-start p-4"
                            style={{
                              minHeight: 437,
                            }}
                          >
                            <Row className="g-4 w-100">
                              {values?.resumes
                                // ?.slice(page * 10, (page + 1) * 10)
                                ?.map(
                                  (resume, index) =>
                                    resume && (
                                      <Col lg={2}>
                                        <div className="card border-light-gray shadow-none mb-0">
                                          <div className="card-body d-flex flex-column align-items-center text-center px-2 py-4">
                                            <Image
                                              src={DocIcon58}
                                              className="mb-3"
                                              width={'58px'}
                                            />
                                            <span className="font-size-12">
                                              {resume?.file?.name || '-'} (
                                              {resume?.file?.size
                                                ? formatBytes(
                                                    resume?.file?.size
                                                  )
                                                : '-'}
                                              )
                                            </span>
                                          </div>
                                        </div>
                                      </Col>
                                    )
                                )}
                            </Row>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            )
          }}
        </Formik>
      </Container>
    </>
  )
}

export default UploadCandidates
