import { useState } from 'react'
import { Col, Form, Image, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Select from 'react-select'

import BlackQuestionPaperIcon from '../../../assets/images/black-question-paper.svg'
import CloseBlackIcon from '../../../assets/images/close-black-icon.svg'
import ExamDetailIcon from '../../../assets/images/exam-details-icon.svg'
import { Store } from '../../../redux/rootType'

const selectQuestionPaperType = [
  {
    value: 1,
    label: 'Select Question paper from library',
  },
  {
    value: 2,
    label: 'Auto generate question paper',
  },
  {
    value: 3,
    label: 'Buy Question paper from store',
  },
]

const CreateExamDetails = (props) => {
  /**
   * ==================================================
   * Data from redux store
   * ==================================================
   */

  const suitableForList = useSelector(
    (state: Store) => state?.examReducer?.suitableForList
  )

  const suitableForListOptions = suitableForList.map((data) => {
    return {
      label: data?.name,
      value: data.id,
    }
  })

  const topicList = useSelector(
    (state: Store) => state?.commonReducer?.topicList
  )

  const topicListOptions = topicList.map((data) => {
    return {
      label: data?.name,
      value: data.id,
    }
  })

  // default set false
  const [showQuestionCount, setShowQuestionCount] = useState(
    props.formikCreateExam.values.specifyQuestionCountCheckbox
  )

  return (
    <Row className="justify-content-center">
      <Col lg={8}>
        <div className="d-flex align-items-center mb-4 pb-3">
          <Image src={ExamDetailIcon} />
          <h5 className="ms-2 mb-0">Exam Details</h5>
        </div>
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Exam Name</Form.Label>
          <Form.Control
            type="text"
            name="exam_name"
            className="form-control"
            value={props.formikCreateExam.values?.exam_name}
            onChange={props.formikCreateExam.handleChange}
            onBlur={props.formikCreateExam.handleBlur}
          />
          {props.formikCreateExam.errors.exam_name &&
            props.formikCreateExam.touched.exam_name && (
              <small className="text-danger">
                {props.formikCreateExam.errors.exam_name as string}
              </small>
            )}
        </Form.Group>
        <Form.Group
          className="form-group"
          controlId="exampleForm.ControlInput1"
        >
          <Form.Label>Description</Form.Label>
          <textarea
            name="description"
            className="form-control"
            rows={5}
            style={{ resize: 'none' }}
            cols={8}
            value={props.formikCreateExam.values?.description}
            onChange={props.formikCreateExam.handleChange}
            onBlur={props.formikCreateExam.handleBlur}
          ></textarea>
          {props.formikCreateExam.errors.description &&
            props.formikCreateExam.touched.description && (
              <small className="text-danger">
                {props.formikCreateExam.errors.description as string}
              </small>
            )}
        </Form.Group>
        <Form.Label>Suitable for</Form.Label>
        <Select
          name="exam_type"
          hideSelectedOptions={false}
          options={suitableForListOptions}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder="Select Question Paper Type"
          isMulti
          closeMenuOnSelect={true}
          value={props.formikCreateExam.values?.exam_type}
          onChange={(values) =>
            props.formikCreateExam.setFieldValue('exam_type', values)
          }
        />
        {/* <div className="d-flex align-items-center flex-wrap">
          <div className="selected-expertise mb-0">
            <small className="me-1">Back-End Developer</small>
            <Image src={CloseBlackIcon} />
          </div>
          <div className="selected-expertise mb-0">
            <small className="me-1">UI Developers</small>
            <Image src={CloseBlackIcon} />
          </div>
        </div> */}
        <hr className="my-5" />
        <div className="d-flex align-items-center mb-4 pb-3">
          <Image src={BlackQuestionPaperIcon} />
          <h5 className="ms-2 mb-0">Question Paper</h5>
        </div>
        <Form.Label>Question Paper Selection Type</Form.Label>
        <Select
          name="question_paper_selection_type"
          hideSelectedOptions={false}
          options={selectQuestionPaperType}
          className="react-select-container"
          classNamePrefix="react-select"
          placeholder="Select Question Paper Type"
          // isMulti
          closeMenuOnSelect={true}
          onChange={(value) => {
            props.formikCreateExam.setFieldValue(
              'question_paper_selection_type',
              value?.value
            )
            props.formikCreateExam.setFieldValue('topics', [])
            if (value?.value === 2) {
              setShowQuestionCount(true)
              props.formikCreateExam.setFieldValue(
                'specifyQuestionCountCheckbox',
                true
              )
            }
          }}
          value={
            selectQuestionPaperType?.find(
              (type) =>
                type.value ===
                props.formikCreateExam.values.question_paper_selection_type
            ) || null
          }
        />
        {props.formikCreateExam.values.question_paper_selection_type === 2 && (
          <>
            <Form.Label>Topics</Form.Label>
            <Select
              name="topics"
              hideSelectedOptions={false}
              options={topicListOptions}
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder="Select Topics"
              isMulti
              closeMenuOnSelect={true}
              onChange={(values) =>
                props.formikCreateExam.setFieldValue('topics', values)
              }
              value={props.formikCreateExam.values?.topics}
            />
          </>
        )}
        <div className="d-flex mt-4 pt-3">
          <Form.Check
            type={'checkbox'}
            name="specifyQuestionCountCheckbox"
            checked={
              props.formikCreateExam.values.specifyQuestionCountCheckbox ||
              showQuestionCount
            }
            disabled={
              props.formikCreateExam.values.question_paper_selection_type === 2
            }
            onClick={() => {
              setShowQuestionCount(!showQuestionCount)
              props.formikCreateExam.setFieldValue(
                'specifyQuestionCountCheckbox',
                !showQuestionCount
              )
            }}
          />
          <div className="ms-2 ps-1">
            <h6 className="mb-1">Specify Question Count</h6>
            <p className="dark-gray mb-3">
              Only specified number of question will be selected from the
              question paper
            </p>
            {(showQuestionCount ||
              props.formikCreateExam.values.specifyQuestionCountCheckbox) && (
              <Form.Group
                className="form-group w-50"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="text"
                  name="question_count"
                  className="form-control"
                  value={props.formikCreateExam.values?.question_count}
                  onChange={props.formikCreateExam.handleChange}
                  onBlur={props.formikCreateExam.handleBlur}
                />
              </Form.Group>
            )}
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default CreateExamDetails
