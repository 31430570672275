import { Formik } from 'formik'
import _ from 'lodash'
import React, { forwardRef, useState } from 'react'
import { Table } from 'react-bootstrap'

import Pagination from '../../components/Pagination'
import InvalidDataCustomRow from './InvalidDataCustomRow'

interface InitialValues {
  resumes: any
  selectedCandidate: { value: string; label: string; email: string }[]
  selectUploadedResumes: boolean
  resume_data: any
}

interface InvalidResumeDataListProps extends InitialValues {
  socketData: any
  invalidResumesData: any
  setInvalidResumesData: (data: any) => void
  ref: any
  setFilteredResumeData?: any
}

const RESUMES_PER_PAGE = 50
const InvalidResumeDataList = forwardRef<
  HTMLDivElement,
  InvalidResumeDataListProps
>(
  (
    {
      socketData,
      invalidResumesData,
      setInvalidResumesData,
      setFilteredResumeData,
    },
    ref: any
  ) => {
    const [formValuesInvalidData] = useState<InitialValues>({
      resumes: [],
      selectedCandidate: [],
      selectUploadedResumes: false,
      resume_data: [],
    })
    const [page, setPage] = useState(1)
    const [sortSkillSet, setSortSkillSet] = useState<'asc' | 'desc'>('asc')
    const [showSkillsSet, setShowSkillsSet] = useState(false)
    const [skills, setSkills] = useState<string[]>([])
    const [selectAll, setSelectAll] = useState(false)

    const handleInvalidDataSubmit = (values) => {
      // Empty On submit
    }

    return (
      <Formik
        initialValues={formValuesInvalidData}
        onSubmit={handleInvalidDataSubmit}
        enableReinitialize
        innerRef={ref}
      >
        {({ setFieldValue }) => {
          return (
            <>
              <Table bordered hover className="overflow-scroll">
                <thead>
                  <tr className="border-0">
                    <th style={{ width: '64px' }}></th>
                    <th>File Name</th>
                    <th>Progress</th>
                    <th className="text-end">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {invalidResumesData?.length ? (
                    invalidResumesData
                      ?.slice(
                        ((page - 1) * RESUMES_PER_PAGE) %
                          (invalidResumesData?.length || 1),
                        page * RESUMES_PER_PAGE
                      )
                      ?.map((val) => {
                        return (
                          <InvalidDataCustomRow
                            key={val?.id}
                            row={val}
                            setFieldValue={setFieldValue}
                            setInvalidResumesData={setInvalidResumesData}
                            invalidResumesData={invalidResumesData}
                            setShowSkillSet={setShowSkillsSet}
                            setSkills={setSkills}
                            setSelectAll={setSelectAll}
                            setFilteredResumeData={setFilteredResumeData}
                          />
                        )
                      })
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {invalidResumesData?.length > RESUMES_PER_PAGE && (
                <Pagination
                  page={page}
                  setPage={setPage}
                  itemsPerPage={RESUMES_PER_PAGE}
                  backgroundColor="bg-white"
                  tableCount={invalidResumesData?.length}
                />
              )}
            </>
          )
        }}
      </Formik>
    )
  }
)

export default InvalidResumeDataList
