import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import {
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  InputGroup,
  Row,
} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import BookmarkIcon from '../../../assets/images/bookmark-icon.svg'
import BookmarkedIcon from '../../../assets/images/bookmarked.svg'
import CloseBlackIcon from '../../../assets/images/close-black-icon.svg'
import CompanyDetailImg from '../../../assets/images/company-detail-img.png'
import CompanyDetailImg2 from '../../../assets/images/company-detail-img-2.png'
import DownBlackImg from '../../../assets/images/down-black-icon.svg'
import InactiveIcon from '../../../assets/images/inactive-img.svg'
import LocationonblueIcon from '../../../assets/images/location_on_blue.svg'
import SearchIcon from '../../../assets/images/search-icon.svg'
import TajYellowImg from '../../../assets/images/taj-yellow.svg'
import { Loader } from '../../../components/Loader'
import {
  companyBookmarkThunk,
  getCompaniesListThunk,
} from '../../../redux/companies/companiesActions'
import { Store } from '../../../redux/rootType'
import { dispatch } from '../../../redux/store'
import CompanyFilter from '../company_list/CompanyFilter'
/**
 * ==================================================
 * Global code
 * ==================================================
 */
interface TCompaniesList {
  id: number | string
  business_category: string
  is_bookmark: boolean
  logo: string | null
  name: string
  job_description: {
    active_opening: number
    job_roles: Array<string>
  }
  primary_address: { city: { name: string } }
}
const isClient = true
const ClientsList = () => {
  /**
   * ==================================================
   * const variable
   * ==================================================
   */

  /**
   * ==================================================
   * Data from redux store
   * ==================================================
   */
  const companiesList = useSelector(
    (state: Store) => state?.companiesReducer?.companiesList
  )

  // const companiesListCount = useSelector(
  //   (state: Store) => state?.companiesReducer?.companiesList?.count
  // )

  const isLoadingCompaniesList = useSelector(
    (state: Store) => state?.companiesReducer?.isLoadingCompaniesList
  )
  const businessCategoryList = (
    useSelector(
      (state: Store) => state?.companiesReducer?.businessCategoryList
    ) || []
  )?.map((val) => ({ ...val, isChecked: false }))

  const cityList = (
    useSelector((state: Store) => state?.companiesReducer?.cityList) || []
  )?.map((val) => ({ ...val, isChecked: false }))

  const isLoadingBusinessCategoryList = useSelector(
    (state: Store) => state?.companiesReducer?.isLoadingBusinessCategoryList
  )
  const isLoadingCityList = useSelector(
    (state: Store) => state?.companiesReducer?.isLoadingCityList
  )
  /**
   * ==================================================
   * Component state
   * ==================================================
   */
  // const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [industryType, setIndustryType] = useState(businessCategoryList)
  const [location, setLocation] = useState(cityList)
  /**
   * ==================================================
   * const based on component state and redux state
   * ==================================================
   */

  /**
   * ==================================================
   * functionality
   * ==================================================
   */
  const locationPayload = _.join(
    location?.filter((loc) => loc?.isChecked)?.map((loc) => loc?.id)
  )
  const industryTypeListPayload = _.join(
    industryType?.filter((loc) => loc?.isChecked)?.map((loc) => loc?.id)
  )

  const handleSearchDebounce = _.debounce((val: string) => {
    setSearch(val)
    dispatch(
      getCompaniesListThunk(
        val,
        locationPayload,
        industryTypeListPayload,
        undefined,
        isClient
      )
    )
  }, 500)

  const bookmarkCompanyFun = (id: string | number, bookmark: boolean) => {
    dispatch(
      companyBookmarkThunk(id, !bookmark, () =>
        dispatch(
          getCompaniesListThunk(
            search,
            locationPayload,
            industryTypeListPayload,
            undefined,
            isClient
          )
        )
      )
    )
  }
  /**
   * ==================================================
   * use effect handling
   * ==================================================
   */
  useEffect(() => {
    dispatch(
      getCompaniesListThunk(
        search,
        locationPayload,
        industryTypeListPayload,
        undefined,
        isClient
      )
    )
  }, [])

  return (
    <Container fluid className="p-5">
      <div className="d-flex align-items-center mb-4">
        <h4 className="mb-0 me-2 pe-1">Clients</h4>
        <span className="badge-plan">
          <Image src={TajYellowImg} className="me-1" />
          Upgrade plan to see details
        </span>
        {/* <Dropdown className="ms-auto filter-list">
          <Dropdown.Toggle id="dropdown-basic">
            Default <Image src={DownBlackImg} className="ms-1" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to="/add-employee">
              Add Individually
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/upload-employee">
              Upload in Bulk
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
        <InputGroup className="ms-auto search-box ms-3">
          <InputGroup.Text id="basic-addon1">
            <Image src={SearchIcon} />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search job role or company"
            aria-label="Search"
            aria-describedby="basic-addon1"
            onChange={(e) => {
              handleSearchDebounce(e?.target?.value)
            }}
          />
        </InputGroup>
      </div>
      <Row className="shadow-card-box company-list-card">
        <Col lg={9}>
          {/* <div className="company-list">
            <span className="selected-company">
              Software
              <Image src={CloseBlackIcon} className="ms-1" />
            </span>
            <span className="selected-company">
              Design
              <Image src={CloseBlackIcon} className="ms-1" />
            </span>
            <span className="selected-company">
              UX Designer
              <Image src={CloseBlackIcon} className="ms-1" />
            </span>
            <span className="selected-company">
              Backend Developer
              <Image src={CloseBlackIcon} className="ms-1" />
            </span>
            <span className="selected-company">
              Chennai
              <Image src={CloseBlackIcon} className="ms-1" />
            </span>
            <span className="selected-company">
              Active jobs: 10-20
              <Image src={CloseBlackIcon} className="ms-1" />
            </span>
            <span className="text-primary fw-bold">View more</span>
          </div> */}
          <div
            className="dynamic-height pb-4"
            style={{ height: 'calc(100vh - 205px)', overflowY: 'scroll' }}
          >
            {isLoadingCompaniesList ? (
              <Loader height={'500px'} />
            ) : (
              <>
                {companiesList?.length > 0 ? (
                  companiesList?.map((comp: TCompaniesList) => (
                    <div className="card" key={comp?.id}>
                      <div className="card-body p-4">
                        <div className="d-flex mb-3 pb-1 align-items-start">
                          <Avatar
                            size="50"
                            src={
                              comp?.logo
                                ? `${process.env.REACT_APP_IMAGE_URL}${comp?.logo}`
                                : InactiveIcon
                            }
                            round="5px"
                          />
                          <div className="ms-3">
                            <Link
                              to={`/company-details/${comp?.id}`}
                              state={{
                                compId: comp?.id,
                                tabName: 'Clients',
                                route: '/client-list',
                              }}
                              className="h6 fw-normal"
                            >
                              {comp?.name || '-'}
                            </Link>
                            <span className="badge-blue">
                              {comp?.business_category || ''}
                            </span>
                            <div className="d-flex mt-1 align-items-center">
                              <Image src={LocationonblueIcon} />
                              <span className="ms-1">
                                {comp?.primary_address?.city?.name || ''}
                              </span>
                            </div>
                          </div>
                          <Image
                            src={
                              comp?.is_bookmark ? BookmarkedIcon : BookmarkIcon
                            }
                            className="ms-auto pointer"
                            onClick={() =>
                              bookmarkCompanyFun(comp?.id, comp?.is_bookmark)
                            }
                          />
                        </div>
                        <p className="mb-2 dark-gray">
                          Active openings:{' '}
                          <span className="text-dark">
                            {comp?.job_description?.active_opening || 0}
                          </span>
                        </p>
                        <p className="mb-0 dark-gray">
                          Job roles:{' '}
                          <span className="text-dark">
                            {comp?.job_description?.job_roles?.length > 0
                              ? comp?.job_description?.job_roles?.map(
                                  (jd) => `${jd}, `
                                )
                              : 0}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className="card"
                    style={{
                      minHeight: '350px',
                    }}
                  >
                    <div className="card-body p-4 text-center">
                      <div>No Data Found</div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
        <Col lg={3}>
          <CompanyFilter
            isClient={isClient}
            search={search}
            industryType={industryType}
            setIndustryType={setIndustryType}
            location={location}
            setLocation={setLocation}
            locationPayload={locationPayload}
            industryTypeListPayload={industryTypeListPayload}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default ClientsList
