import React, { useState } from 'react'
import {
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Nav,
  Row,
  Tab,
  Table,
} from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { Link } from 'react-router-dom'

import DeleteAction from '../../../assets/images/delete-action.svg'
import Editicon from '../../../assets/images/edit-action.svg'
import EmailIcon from '../../../assets/images/email-icon.svg'
import AccountDetails from '../../../assets/images/Help_Contact.svg'
import ChangePassword from '../../../assets/images/Help_faq.svg'
import Bankdetails from '../../../assets/images/Help_res.svg'
import IntegrationIcon from '../../../assets/images/integration-icon.svg'
import MultiplePerson from '../../../assets/images/multiple-person.svg'
import NotificationSetting from '../../../assets/images/notification-setting.svg'
import SearchIcon from '../../../assets/images/search-icon.svg'
import HelpCenterFaq from './HelpCenterFaq'
import HelpCenterResource from './HelpCenterResource'
import HelpCenterResourceContact from './HelpCenterResourceContact'

const HelpCenter = () => {
  const [show2, setShow2] = useState(false)

  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  return (
    <Container className="px-4 py-5 shadow-card-box">
      <div className="d-flex align-items-center mb-4 pb-2">
        <Link to="/" className="h4 mb-0">
          <IoMdArrowBack className="me-2" /> Help Center
        </Link>
        <InputGroup className="ms-auto search-box">
          <InputGroup.Text id="basic-addon1">
            <Image src={SearchIcon} />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search"
            aria-label="Search"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      </div>
      <div className="verical-tab mb-5">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col lg={4}>
              <div className="card">
                <div className="card-body">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <Image className="me-2" src={AccountDetails} />
                        FAQ
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="me-2" eventKey="second">
                        <Image className="me-2" src={Bankdetails} />
                        Resource
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="mb-0" eventKey="third">
                        <Image className="me-2" src={ChangePassword} />
                        Contact Us
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="card">
                <div className="card-body p-4">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      {/* <h5 className="mb-4">Notification Settings</h5>
                        <div className="table-responsive">
                          <Table bordered hover className="overflow-scroll">
                            <thead>
                              <tr className="border-0">
                                <th>Category </th>
                                <th>Activity Type</th>
                                <th>In-App</th>
                                <th>Mail</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>General Updates</td>
                                <td className="Gray26">
                                  Daily Summary <br />
                                  <br /> Weekly Digest
                                </td>
                                <td>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexSwitchCheckDefault"
                                    ></label>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexSwitchCheckDefault1"
                                    ></label>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Recruitment Activities</td>
                                <td className="Gray26">
                                  Job Posting Updates
                                  <br />
                                  <br />
                                  Applications Status Updates
                                  <br />
                                  <br />
                                  Shortlisted Candidates
                                </td>
                                <td>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault2"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexSwitchCheckDefault2"
                                    ></label>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault3"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexSwitchCheckDefault3"
                                    ></label>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div> */}
                      <HelpCenterFaq />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      {/* <h5 className="mb-4">My Template</h5>
                        <div className="table-responsive mb-5">
                          <Table bordered hover className="overflow-scroll">
                            <thead>
                              <tr className="border-0">
                                <th>Name</th>
                                <th>Subject</th>
                                <th>Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <span className="text-primary">
                                    Assessment Invite{' '}
                                    <Image
                                      src={MultiplePerson}
                                      className="ms-1"
                                    />{' '}
                                  </span>
                                </td>
                                <td>Welcome to Java Assessment </td>
                                <td>Candidate Email </td>
                                <td>
                                  <Image src={Editicon} />{' '}
                                  <Image className="ms-2" src={DeleteAction} />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <h5 className="mb-4">Organisation’s Template</h5>
                        <div className="table-responsive">
                          <Table bordered hover className="overflow-scroll">
                            <thead>
                              <tr className="border-0">
                                <th>Name</th>
                                <th>Subject</th>
                                <th>Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <span className="text-primary">
                                    Assessment Invite{' '}
                                  </span>
                                </td>
                                <td>Welcome to Java Assessment </td>
                                <td>Candidate Email </td>
                                <td>
                                  <Image src={Editicon} />{' '}
                                  <Image className="ms-2" src={DeleteAction} />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div> */}
                      <HelpCenterResource />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <HelpCenterResourceContact />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </Container>
  )
}

export default HelpCenter
