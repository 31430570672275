import React from 'react'
import {
  Container,
  Form,
  Image,
  InputGroup,
  Nav,
  Tab,
  Table,
} from 'react-bootstrap'

import CompanyImg from '../../../assets/images/company-img.png'
import DownBlackIcon from '../../../assets/images/download-black.svg'
import CompanyDetailIcon from '../../../assets/images/question-paper.png'
import SearchIcon from '../../../assets/images/search-icon.svg'
import TotalEarning from '../../../assets/images/total-earning.svg'
import TotalExpenditur from '../../../assets/images/total-expenditur.svg'

const TransactionHistory = () => {
  return (
    <Container className="p-5">
      <h4 className="mb-3">Transaction History</h4>
      <Tab.Container defaultActiveKey="first">
        <div className="card-header p-0 bg-transparent">
          <Nav variant="pills" className="tab-light-border align-items-end">
            <Nav.Item>
              <Nav.Link eventKey="first">Earnings</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Expenditure</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="card-body px-0 py-4 my-3">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <div className="d-flex align-items-center mb-4">
                <InputGroup className="search-box">
                  <InputGroup.Text id="basic-addon1">
                    <Image src={SearchIcon} />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                <div className="border-card d-flex ms-auto p-3 align-items-center">
                  <Image src={TotalEarning} />
                  <small className="custom-text-clr fw-bold ms-1">
                    Total Earnings
                  </small>
                  <h5 className="ms-2 mb-0">₹ 1,10,000</h5>
                </div>
              </div>
              <div className="table-responsive">
                <Table bordered hover className="overflow-scroll">
                  <thead>
                    <tr className="border-0">
                      {/* <th></th> */}
                      <th>Invoice ID</th>
                      <th>Clients</th>
                      <th className="text-center">Candidates Placed</th>
                      <th>Amount</th>
                      <th>Payment date</th>
                      <th className="text-center">Invoice</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-0">
                      {/* <td>
                        {' '}
                        <Form.Check type={'checkbox'} id={`1`} />
                      </td> */}
                      <td>12345</td>
                      <td>
                        <span className="d-flex">
                          <Image
                            width={20}
                            height={20}
                            className="rounded-2 me-2"
                            src={CompanyImg}
                          />
                          ABC Software Solution
                        </span>
                      </td>
                      <td className="text-center">20</td>
                      <td>₹ 20,000</td>
                      <td>1 Sep, 2024</td>
                      <td className="text-center">
                        <Image src={DownBlackIcon} />
                      </td>
                    </tr>
                    <tr className="border-0">
                      {/* <td>
                        {' '}
                        <Form.Check type={'checkbox'} id={`2`} />
                      </td> */}
                      <td>12345</td>
                      <td>
                        <span className="d-flex">
                          <Image
                            width={20}
                            height={20}
                            className="rounded-2 me-2"
                            src={CompanyImg}
                          />
                          CodeSphere Technologies
                        </span>
                      </td>
                      <td className="text-center">20</td>
                      <td>₹ 20,000</td>
                      <td>1 Sep, 2024</td>
                      <td className="text-center">
                        <Image src={DownBlackIcon} />
                      </td>
                    </tr>
                    <tr className="border-0">
                      {/* <td>
                        {' '}
                        <Form.Check type={'checkbox'} id={`3`} />
                      </td> */}
                      <td>12345</td>
                      <td>
                        <span className="d-flex">
                          <Image
                            width={20}
                            height={20}
                            className="rounded-2 me-2"
                            src={CompanyImg}
                          />
                          InnoSoft Innovations
                        </span>
                      </td>
                      <td className="text-center">20</td>
                      <td>₹ 20,000</td>
                      <td>1 Sep, 2024</td>
                      <td className="text-center">
                        <Image src={DownBlackIcon} />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <div className="d-flex align-items-center mb-4">
                <InputGroup className="search-box">
                  <InputGroup.Text id="basic-addon1">
                    <Image src={SearchIcon} />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
                <div className="border-card d-flex ms-auto p-3 align-items-center">
                  <Image src={TotalExpenditur} />
                  <small className="custom-text-clr fw-bold ms-1">
                    Total Expenditure
                  </small>
                  <h5 className="ms-2 mb-0">₹ 1,262</h5>
                </div>
              </div>
              <div className="table-responsive">
                <Table bordered hover className="overflow-scroll">
                  <thead>
                    <tr className="border-0">
                      {/* <th></th> */}
                      <th>Invoice ID</th>
                      <th>Question Paper</th>
                      <th>Amount</th>
                      <th>Purchased on</th>
                      <th className="text-center">Invoice</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-0">
                      {/* <td>
                        {' '}
                        <Form.Check type={'checkbox'} id={`4`} />
                      </td> */}
                      <td>12345</td>
                      <td>
                        <Image src={CompanyDetailIcon} className="me-2" />
                        Java skill assessment Dec 2022
                      </td>
                      <td>₹ 20,000</td>
                      <td>1 Sep, 2024</td>
                      <td className="text-center">
                        <Image src={DownBlackIcon} />
                      </td>
                    </tr>
                    <tr className="border-0">
                      {/* <td>
                        {' '}
                        <Form.Check type={'checkbox'} id={`5`} />
                      </td> */}
                      <td>12345</td>
                      <td>
                        <Image src={CompanyDetailIcon} className="me-2" />
                        Java skill assessment Dec 2022
                      </td>
                      <td>₹ 20,000</td>
                      <td>1 Sep, 2024</td>
                      <td className="text-center">
                        <Image src={DownBlackIcon} />
                      </td>
                    </tr>
                    <tr className="border-0">
                      {/* <td>
                        {' '}
                        <Form.Check type={'checkbox'} id={`6`} />
                      </td> */}
                      <td>12345</td>
                      <td>
                        <Image src={CompanyDetailIcon} className="me-2" />
                        Java skill assessment Dec 2022
                      </td>
                      <td>₹ 20,000</td>
                      <td>1 Sep, 2024</td>
                      <td className="text-center">
                        <Image src={DownBlackIcon} />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </Container>
  )
}

export default TransactionHistory
