import React, { useState } from 'react'
import {
  Accordion,
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  InputGroup,
  Modal,
  Nav,
  Row,
  Tab,
  Table,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'

import BookmarkIcon from '../../assets/images/bookmark-icon.svg'
import PlusIcon from '../../assets/images/btn-plus.svg'
import CalenderIcon from '../../assets/images/calender-icon.svg'
import ChennaiIcon from '../../assets/images/chennai-icon.svg'
import ConsultationIcon from '../../assets/images/consultation-icon.svg'
import DeleteIcon20 from '../../assets/images/delete-icon20.svg'
import EditIcon20 from '../../assets/images/edit-icon20.svg'
import FacebookIcon from '../../assets/images/facebook-raund-icon.png'
import GitHubIcon from '../../assets/images/git-hub-raund-icon.png'
import LinkedinIcon from '../../assets/images/linkedin-raund-icon.png'
import RightBlackArrowIcon from '../../assets/images/right-black-arrow.svg'
import SkillsTag from '../../assets/images/skills-tag.svg'
import TwitterIcon from '../../assets/images/twitter-raund-icon.png'
import UserImagesIcon from '../../assets/images/User-images.svg'
import VerticalDotsIcon from '../../assets/images/vertical-dots.svg'
import WebsiteIcon from '../../assets/images/website-raund-icon.png'
import ZipIcon20 from '../../assets/images/zip-icon-20.svg'

const ViewCandidate = () => {
  const [show1, setShow1] = useState(false)

  const handleClose1 = () => setShow1(false)
  const handleShow1 = () => setShow1(true)

  const [show2, setShow2] = useState(false)

  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  const [show3, setShow3] = useState(false)

  const handleClose3 = () => setShow3(false)
  const handleShow3 = () => setShow3(true)

  return (
    <>
      <Container className="p-5 shadow-card-box">
        <div className="mb-3 pb-1">
          <span className="text-muted">Candidate Management</span>
          <Image src={RightBlackArrowIcon} className="mx-2" />
          <span className="text-dark">Darlene Robertson</span>
        </div>
        <div className="card">
          <div className="card-body p-0">
            <div className="p-4 m-3">
              <div className="d-flex">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <span
                      className="raunr-alphabet-40"
                      style={{ backgroundColor: '#e05151' }}
                    >
                      R
                    </span>
                  </div>
                  <div>
                    <p className="fw-semibold mb-0">
                      Raymond Dane <Image className="ms-2" src={BookmarkIcon} />
                    </p>
                    <p className="font-size-14 text-gray-300 mb-0">
                      raymond09@gmail.com
                    </p>
                  </div>
                </div>
                <div className="ms-auto">
                  <Dropdown className="ms-3">
                    <Dropdown.Toggle as={Link} id="dropdown-basic" to="#">
                      <Nav.Link href="#">
                        <Image src={VerticalDotsIcon} />
                      </Nav.Link>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>Edit</Dropdown.Item>
                      <Dropdown.Item>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <Tab.Container defaultActiveKey="first">
              <div className="card-header py-0 px-5 bg-transparent">
                <Nav
                  variant="pills"
                  className="tab-light-border align-items-end"
                >
                  <Nav.Item>
                    <Nav.Link eventKey="first">Details</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Submissions</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
              <div className="card-body px-0 py-4 my-1">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div className="candidate-accordion">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            Personal Information
                          </Accordion.Header>
                          <Accordion.Body className="p-4 m-3">
                            <Row className="question-card g-4">
                              <Col lg={6}>
                                <Row lg={2} className="question-card gx-4 gy-3">
                                  <Col md={6}>
                                    <Form.Group className="form-group mb-0">
                                      <p className="font-size-12 text-gray-300 mb-2">
                                        First Name
                                      </p>
                                      <Form.Label>Dave</Form.Label>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group className="form-group mb-0">
                                      <p className="font-size-12 text-gray-300 mb-2">
                                        Last Name
                                      </p>
                                      <Form.Label>Garfield</Form.Label>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group className="form-group mb-0">
                                      <p className="font-size-12 text-gray-300 mb-2">
                                        Email
                                      </p>
                                      <Form.Label>dave@gmail.com</Form.Label>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group className="form-group mb-0">
                                      <p className="font-size-12 text-gray-300 mb-2">
                                        Contact Number{' '}
                                      </p>
                                      <Form.Label>+91 9999999999</Form.Label>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group className="form-group mb-0">
                                      <p className="font-size-12 text-gray-300 mb-2">
                                        City
                                      </p>
                                      <Form.Label>Bangalore</Form.Label>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group className="form-group mb-0">
                                      <p className="font-size-12 text-gray-300 mb-2">
                                        State
                                      </p>
                                      <Form.Label>Karnataka</Form.Label>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group className="form-group mb-0">
                                      <p className="font-size-12 text-gray-300 mb-2">
                                        Gender
                                      </p>
                                      <Form.Label>Male</Form.Label>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Group className="form-group mb-0">
                                      <p className="font-size-12 text-gray-300 mb-2">
                                        Date of Birth
                                      </p>
                                      <Form.Label>05/09/1996</Form.Label>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>Work Experience</Accordion.Header>
                          <Accordion.Body className="p-4 m-3">
                            <div className="d-flex align-items-center">
                              <p className="fw-semibold">
                                Developer{' '}
                                <span className="text-gray-300 mx-2">|</span> 2
                                yr 3 mths{' '}
                              </p>
                              <div className="ms-auto d-flex">
                                <a className="icon-34-btn me-2">
                                  <Image src={EditIcon20} />
                                </a>
                                <a className="icon-34-btn">
                                  <Image src={DeleteIcon20} />
                                </a>
                              </div>
                            </div>
                            <div>
                              <ul className="ps-3">
                                <li className="mb-1">
                                  Design mobile applications, web platforms &
                                  dashboard
                                </li>
                                <li className="mb-1">Conduct user research</li>
                                <li className="mb-1">
                                  Build wireframes and prototype
                                </li>
                                <li className="mb-1">
                                  Created Information architecture and User
                                  workflow
                                </li>
                                <li className="mb-1">
                                  Conduct AB testing to gather user feedback
                                </li>
                              </ul>
                            </div>
                            <div>
                              <p className="mb-2">
                                <Image
                                  src={ConsultationIcon}
                                  className="me-1"
                                />{' '}
                                Canada Paradigm Consultation
                              </p>
                              <p className="mb-2">
                                <Image src={ChennaiIcon} className="me-1" />{' '}
                                Chennai
                              </p>
                            </div>
                            <hr />
                            <div className="d-flex align-items-center">
                              <p className="fw-semibold">
                                Sr. Developer{' '}
                                <span className="text-gray-300 mx-2">|</span> 3
                                yr 3{' '}
                              </p>
                              <div className="ms-auto d-flex">
                                <a className="icon-34-btn me-2">
                                  <Image src={EditIcon20} />
                                </a>
                                <a className="icon-34-btn">
                                  <Image src={DeleteIcon20} />
                                </a>
                              </div>
                            </div>
                            <div>
                              <ul className="ps-3">
                                <li className="mb-1">
                                  Passionate about clean code, focused on
                                  delivering the product of the highest quality.
                                </li>
                                <li className="mb-1">
                                  Experienced with building distributed, high
                                  performing, concurrent and robust Java
                                  software.
                                </li>
                                <li className="mb-1">
                                  High level of responsibility and commitment to
                                  improve and troubleshoot applications.
                                </li>
                                <li className="mb-1">
                                  Eager to learn new technologies.
                                </li>
                              </ul>
                            </div>
                            <div>
                              <p className="mb-2">
                                <Image
                                  src={ConsultationIcon}
                                  className="me-1"
                                />{' '}
                                ABC Company Solutions
                              </p>
                              <p className="mb-2">
                                <Image src={ChennaiIcon} className="me-1" />{' '}
                                Bangalore
                              </p>
                            </div>
                            <hr className="ny-4" />
                            <button
                              onClick={handleShow1}
                              className="btn btn-outline-dark pe-4"
                            >
                              <Image src={PlusIcon} className="me-1" /> Add Work
                              Experience
                            </button>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>Education</Accordion.Header>
                          <Accordion.Body className="p-4 m-3">
                            <div className="d-flex align-items-center">
                              <p className="fw-semibold">
                                Master in Computer Application{' '}
                                <span className="text-gray-300 mx-2">|</span>{' '}
                                A++
                              </p>
                              <small className="text-light">
                                Information Systems
                              </small>
                              <div className="ms-auto d-flex">
                                <a className="icon-34-btn me-2">
                                  <Image src={EditIcon20} />
                                </a>
                                <a className="icon-34-btn">
                                  <Image src={DeleteIcon20} />
                                </a>
                              </div>
                            </div>
                            <div>
                              <p className="mb-2">
                                <Image
                                  src={ConsultationIcon}
                                  className="me-1"
                                />{' '}
                                University of Mumbai
                              </p>
                              <p className="mb-2">
                                <Image src={ChennaiIcon} className="me-1" />{' '}
                                Chennai
                              </p>
                            </div>
                            <hr className="ny-4" />
                            <button
                              onClick={handleShow2}
                              className="btn btn-outline-dark pe-4"
                              type="button"
                            >
                              <Image src={PlusIcon} className="me-1" />{' '}
                              Education
                            </button>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>Skills</Accordion.Header>
                          <Accordion.Body className="p-4 m-3 py-5">
                            <div className="skills-tag-name mb-3">
                              <p className="tag-name">
                                <a href="#">
                                  Project Management{' '}
                                  <i className="far fa-times-circle fa-fw"></i>
                                </a>
                              </p>
                              <p className="tag-name">
                                <a href="#">
                                  Ownership{' '}
                                  <i className="far fa-times-circle fa-fw"></i>
                                </a>
                              </p>
                              <p className="tag-name">
                                <a href="#">
                                  Task management{' '}
                                  <i className="far fa-times-circle fa-fw"></i>
                                </a>
                              </p>
                              <p className="tag-name">
                                <a href="#">
                                  Spring Data JPA{' '}
                                  <i className="far fa-times-circle fa-fw"></i>
                                </a>
                              </p>
                              <p className="tag-name">
                                <a href="#">
                                  Java{' '}
                                  <i className="far fa-times-circle fa-fw"></i>
                                </a>
                              </p>
                              <p className="tag-name">
                                <a href="#">
                                  GitHub{' '}
                                  <i className="far fa-times-circle fa-fw"></i>
                                </a>
                              </p>
                              <p className="tag-name">
                                <a href="#">
                                  PostgreSQL{' '}
                                  <i className="far fa-times-circle fa-fw"></i>
                                </a>
                              </p>
                              <p className="tag-name">
                                <a href="#">
                                  Quarkus{' '}
                                  <i className="far fa-times-circle fa-fw"></i>
                                </a>
                              </p>
                              <p className="tag-name">
                                <a href="#">
                                  Go Language{' '}
                                  <i className="far fa-times-circle fa-fw"></i>
                                </a>
                              </p>
                              <p className="tag-name">
                                <a href="#">
                                  PostgreSQL{' '}
                                  <i className="far fa-times-circle fa-fw"></i>
                                </a>
                              </p>
                              <p className="tag-name">
                                <a href="#">
                                  Hibernate{' '}
                                  <i className="far fa-times-circle fa-fw"></i>
                                </a>
                              </p>
                              <p className="tag-name">
                                <a href="#">
                                  Gradle{' '}
                                  <i className="far fa-times-circle fa-fw"></i>
                                </a>
                              </p>
                            </div>
                            <Image src={SkillsTag} className="me-1" />{' '}
                            <span className="font-size-18 fw-semibold">
                              Enter Skills
                            </span>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header>Social Links</Accordion.Header>
                          <Accordion.Body className="p-4 m-3">
                            <div>
                              <Link to="javascript:">
                                <Image src={WebsiteIcon} />
                              </Link>
                              <Link to="javascript:">
                                <Image src={TwitterIcon} />
                              </Link>
                              <Link to="javascript:">
                                <Image src={FacebookIcon} />
                              </Link>
                              <Link to="javascript:">
                                <Image src={GitHubIcon} />
                              </Link>
                              <Link to="javascript:">
                                <Image src={LinkedinIcon} />
                              </Link>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                          <Accordion.Header>Custom field</Accordion.Header>
                          <Accordion.Body className="p-4 m-3">
                            <div>
                              <small className="mb-2 d-block">Field 1</small>
                              <p className="d-flex align-items-center fw-semibold">
                                <Form.Check
                                  className="me-2"
                                  type={'checkbox'}
                                  id={`1`}
                                />{' '}
                                Willing to Relocate
                              </p>
                            </div>
                            <hr />
                            <div className="w-25">
                              <small className="mb-2 d-block">Field 2</small>
                              <p className="fw-semibold">Source File</p>
                              <div className="card border-light-gray shadow-none mb-0">
                                <div className="card-body d-flex fw-semibold">
                                  <Image src={ZipIcon20} className="me-2" />{' '}
                                  Animeverse
                                  <span className="ms-auto font-size-14 text-gray-300">
                                    24 MB
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <div className="table-responsive">
                      <Table bordered hover className="overflow-scroll">
                        <thead>
                          <tr className="border-0">
                            <th style={{ width: '64px' }}></th>
                            <th>Assigned to</th>
                            <th>Job</th>
                            <th>Assigned by</th>
                            <th>Assigned on</th>
                            <th>Match</th>
                            <th>Progress status</th>
                            <th className="text-center">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="border-0">
                            <td>
                              {' '}
                              <Form.Check type={'checkbox'} id={`1`} />
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span
                                  className="raunr-alphabet-1 me-2"
                                  style={{ backgroundColor: '#e05151' }}
                                >
                                  R
                                </span>
                                Raymond Dane
                              </div>
                            </td>
                            <td>
                              <p className="mb-0 d-block">React Developer</p>
                              <small className="text-gray-300 font-size-12">
                                2-3 years
                              </small>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <Image
                                    width={24}
                                    height={24}
                                    className="rounded-2 me-2"
                                    src={UserImagesIcon}
                                  />
                                </div>
                                <div>
                                  <p className="mb-0 d-block">Steve Harvey</p>
                                  <small className="text-gray-300 font-size-12">
                                    Recruiter Admin{' '}
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td>12 Dec, 2023</td>
                            <td>65%</td>
                            <td>
                              <span className="badge text-bg-secondary">
                                Submitted
                              </span>
                            </td>
                            <td className="text-center">
                              <Dropdown className="ms-3">
                                <Dropdown.Toggle
                                  as={Link}
                                  id="dropdown-basic"
                                  to="#"
                                >
                                  <Nav.Link href="#">
                                    <Image src={VerticalDotsIcon} />
                                  </Nav.Link>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item>Edit</Dropdown.Item>
                                  <Dropdown.Item>Delete</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                          <tr className="border-0">
                            <td>
                              {' '}
                              <Form.Check type={'checkbox'} id={`2`} />
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span
                                  className="raunr-alphabet-1 me-2"
                                  style={{ backgroundColor: '#9b51e0' }}
                                >
                                  S
                                </span>
                                Savannah Nguyen
                              </div>
                            </td>
                            <td>
                              <p className="mb-0 d-block">React Developer</p>
                              <small className="text-gray-300 font-size-12">
                                2-3 years
                              </small>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <Image
                                    width={24}
                                    height={24}
                                    className="rounded-2 me-2"
                                    src={UserImagesIcon}
                                  />
                                </div>
                                <div>
                                  <p className="mb-0 d-block">Steve Harvey</p>
                                  <small className="text-gray-300 font-size-12">
                                    Recruiter Admin{' '}
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td>12 Dec, 2023</td>
                            <td>65%</td>
                            <td>
                              <span className="badge text-bg-secondary">
                                Submitted
                              </span>
                            </td>
                            <td className="text-center">
                              <Dropdown className="ms-3">
                                <Dropdown.Toggle
                                  as={Link}
                                  id="dropdown-basic"
                                  to="#"
                                >
                                  <Nav.Link href="#">
                                    <Image src={VerticalDotsIcon} />
                                  </Nav.Link>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item>Edit</Dropdown.Item>
                                  <Dropdown.Item>Delete</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                          <tr className="border-0">
                            <td>
                              {' '}
                              <Form.Check type={'checkbox'} id={`3`} />
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span
                                  className="raunr-alphabet-1 me-2"
                                  style={{ backgroundColor: '#219653' }}
                                >
                                  a
                                </span>
                                Albert Flores
                              </div>
                            </td>
                            <td>
                              <p className="mb-0 d-block">React Developer</p>
                              <small className="text-gray-300 font-size-12">
                                2-3 years
                              </small>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <div>
                                  <Image
                                    width={24}
                                    height={24}
                                    className="rounded-2 me-2"
                                    src={UserImagesIcon}
                                  />
                                </div>
                                <div>
                                  <p className="mb-0 d-block">Steve Harvey</p>
                                  <small className="text-gray-300 font-size-12">
                                    Recruiter Admin{' '}
                                  </small>
                                </div>
                              </div>
                            </td>
                            <td>12 Dec, 2023</td>
                            <td>65%</td>
                            <td>
                              <span className="badge text-bg-secondary">
                                Submitted
                              </span>
                            </td>
                            <td className="text-center">
                              <Dropdown className="ms-3">
                                <Dropdown.Toggle
                                  as={Link}
                                  id="dropdown-basic"
                                  to="#"
                                >
                                  <Nav.Link href="#">
                                    <Image src={VerticalDotsIcon} />
                                  </Nav.Link>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item>Edit</Dropdown.Item>
                                  <Dropdown.Item>Delete</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
            {/* <Form> */}

            {/* <div className="text-end p-4 mx-3">
              <button className="btn btn-primary px-4 ms-4" type="button">
                Save
              </button>
              <button className="btn  btn-outline-dark px-4 ms-4" type="button">
                Save & Add new
              </button>
            </div> */}
            {/* </Form> */}
          </div>
        </div>
      </Container>

      <Modal show={show1} onHide={handleClose1} className="sideModal">
        <Modal.Header closeButton>
          <Modal.Title>Work Experience</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body p-4">
          <Form.Group className="form-group">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Title"
              value={'Sr. Consultant'}
              className="form-control"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Company</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Company"
              value={'CSG'}
              className="form-control"
            />
          </Form.Group>
          <Row lg={2}>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>Industry</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Industry"
                    className="border-end-0"
                  />
                  <InputGroup.Text className="bg-transparent">
                    <Image src={ChennaiIcon} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>Location</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Location"
                    className="border-end-0"
                  />
                  <InputGroup.Text className="bg-transparent">
                    <Image src={ChennaiIcon} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="form-group m-0 pb-3">
            <Form.Check
              type={'checkbox'}
              id={`5`}
              label={`I currently work here`}
            />
          </Form.Group>
          <Row lg={2}>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>Start Date</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Start Date"
                    className="border-end-0"
                  />
                  <InputGroup.Text className="bg-transparent">
                    <Image src={CalenderIcon} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>End Date</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="End Date"
                    className="border-end-0"
                  />
                  <InputGroup.Text className="bg-transparent">
                    <Image src={CalenderIcon} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="form-group">
            <Form.Label>Description</Form.Label>
            <textarea
              className="form-control"
              rows={3}
              placeholder="Description"
              style={{ resize: 'none' }}
              cols={8}
            ></textarea>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleClose2}
            className="btn  btn-outline-dark px-4 ms-auto"
            type="button"
          >
            Cancel
          </button>
          <button className="btn  btn-primary px-4" type="button">
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} className="sideModal">
        <Modal.Header closeButton>
          <Modal.Title>Education</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body p-4">
          <Form.Group className="form-group">
            <Form.Label>School/College Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter School/College Name"
              value={'University of Mumbai'}
              className="form-control"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Qualification</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Qualification"
              value={'Master in Computer Application'}
              className="form-control"
            />
          </Form.Group>
          <Row lg={2}>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>Specialisation</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Specialisation"
                  value={'Information Systems'}
                  className="form-control"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>Grade</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Grade"
                  value={'A++'}
                  className="form-control"
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="form-group">
            <Form.Label>Location</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Location"
                value={'Chennai'}
                className="border-end-0"
              />
              <InputGroup.Text className="bg-transparent">
                <Image src={ChennaiIcon} />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Row lg={2}>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>Start Date</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Start Date"
                    className="border-end-0"
                  />
                  <InputGroup.Text className="bg-transparent">
                    <Image src={CalenderIcon} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="form-group">
                <Form.Label>End Date</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="End Date"
                    className="border-end-0"
                  />
                  <InputGroup.Text className="bg-transparent">
                    <Image src={CalenderIcon} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="form-group">
            <Form.Label>Description</Form.Label>
            <textarea
              className="form-control"
              rows={3}
              placeholder="Description"
              style={{ resize: 'none' }}
              cols={8}
            ></textarea>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleClose2}
            className="btn  btn-outline-dark px-4 ms-auto"
            type="button"
          >
            Cancel
          </button>
          <button className="btn  btn-primary px-4" type="button">
            Save
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show3} onHide={handleClose3} size="sm" centered>
        <Modal.Body className="modal-body p-4">
          <div className="text-center">
            <p className="fw-semibold mb-2">
              You sure want to remove candidate’s education “Master in Computer
              Application” ?
            </p>
            <p className="text-danger font-size-14">
              This action cannot be reversed
            </p>
            <div className="mt-4 pt-2">
              <button
                onClick={handleClose3}
                className="btn btn-outline-dark px-4 me-3"
                type="button"
              >
                Cancel
              </button>
              <button className="btn btn-primary px-4" type="button">
                Remove
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ViewCandidate
