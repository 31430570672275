import _ from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import { Dropdown, Form, Image, InputGroup, Nav, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import SearchIcon from '../../assets/images/search-icon.svg'
import UserImagesIcon from '../../assets/images/User-images.svg'
import VerticalDotsIcon from '../../assets/images/vertical-dots.svg'
import { AppendedMyComponent } from '../../components/AppendedMyComponent'
import { Loader } from '../../components/Loader'
import Pagination from '../../components/Pagination'
import { getCandidateResumeListThunk } from '../../redux/candidate-management/candidateManagementActions'
import { Store } from '../../redux/rootType'
import { dispatch } from '../../redux/store'

const tableHeading = [
  {
    id: 1,
    header: 'Candidate Name',
  },
  { id: 2, header: 'Email' },
  { id: 3, header: 'Experience' },
  { id: 4, header: 'Added by' },
  { id: 5, header: 'Added on' },
]

const itemsPerPage = 10
const ResumesTable = () => {
  /**
   * ==================================================
   * Data from redux store
   * ==================================================
   */
  const isLoadingCandidateList = useSelector(
    (state: Store) => state?.candidateManagementReducer?.isLoadingCandidateList
  )
  const candidateList = useSelector(
    (state: Store) => state?.candidateManagementReducer?.candidateList
  )?.map((val) => ({
    ...val,
    isChecked: false,
  }))

  /**
   * ==================================================
   * Component state
   * ==================================================
   */

  const [page, setPage] = useState(1)
  const [candidateListData, setCandidateListData] = useState<any>([])
  const [paginatedData, setPaginatedData] = useState(
    candidateListData?.length
      ? (candidateListData || [])?.slice(0, itemsPerPage)
      : []
  )
  const [isCheckedAll, setIsCheckedAll] = useState(false)

  /**
   * ==================================================
   * functionality
   * ==================================================
   */

  const updateListOfCandidateAll = (check) => {
    const updatedListOfItems = paginatedData?.map((val) => ({
      ...val,
      isChecked: !check,
    }))
    setPaginatedData(updatedListOfItems)
    setIsCheckedAll(!check)
  }

  const updateListOfCandidate = (itemId, isChecked) => {
    const updatedListOfItems = [...paginatedData]
    const question_index = paginatedData?.findIndex(
      (item) => item?.id === itemId
    )
    updatedListOfItems[question_index].isChecked = isChecked
    setPaginatedData(updatedListOfItems)

    const allCheck = updatedListOfItems.filter(
      (val) => val.isChecked === false
    )?.length
    allCheck > 0 ? setIsCheckedAll(false) : setIsCheckedAll(true)
  }

  const handleSearchDebounce = _.debounce((val: string) => {
    const payload = { search: val }
    dispatch(getCandidateResumeListThunk(payload))
  }, 500)

  /**
   * ==================================================
   * use effect handling
   * ==================================================
   */

  useEffect(() => {
    const payload = {}
    dispatch(getCandidateResumeListThunk(payload))
  }, [])

  useEffect(() => {
    setCandidateListData(candidateList || [])
  }, [candidateList?.length])

  useEffect(() => {
    const newOffset =
      ((page - 1) * itemsPerPage) % (candidateListData?.length || 1)
    const newLimit = page * itemsPerPage
    setPaginatedData(
      candidateListData?.length > 0
        ? candidateListData?.slice(newOffset, newLimit)
        : []
    )
    setCandidateListData((prev) => prev)
  }, [page, candidateListData?.length])
  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <InputGroup className="search-box">
          <InputGroup.Text id="basic-addon1">
            <Image src={SearchIcon} />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search"
            aria-label="Search"
            onChange={(e) => handleSearchDebounce(e?.target?.value)}
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      </div>
      <div className="table-responsive">
        <Table bordered hover className="overflow-scroll">
          <thead>
            <tr className="border-0">
              <th style={{ width: '64px' }}>
                <Form.Check
                  className="form-checkbox m-0 select-resume"
                  type={'checkbox'}
                  id={'CandidateAll'}
                  checked={isCheckedAll}
                  onChange={() => updateListOfCandidateAll(isCheckedAll)}
                />
              </th>
              {tableHeading?.map((heading) => {
                return <th key={heading.id}>{heading.header}</th>
              })}
              <th className="text-center">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {isLoadingCandidateList ? (
              <tr>
                <td colSpan={8}>
                  <Loader height={'500px'} />
                </td>
              </tr>
            ) : paginatedData?.length > 0 ? (
              (paginatedData || []).map((candidate) => (
                <tr className="border-0">
                  <td>
                    {' '}
                    <Form.Check
                      key={candidate?.id}
                      type={'checkbox'}
                      id={'Candidate' + candidate?.id}
                      checked={candidate?.isChecked}
                      onChange={() =>
                        updateListOfCandidate(
                          candidate?.id,
                          !candidate?.isChecked
                        )
                      }
                    />
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <span
                        className="raunr-alphabet-1 me-2"
                        style={{ backgroundColor: `#e05151` }}
                      >
                        {candidate?.name?.charAt(0)}
                      </span>
                      {candidate?.name || '-'}
                    </div>
                  </td>
                  {/* <td>Senior UI designer</td> */}
                  <td>{candidate?.email || '-'}</td>
                  <td>
                    {candidate?.total_experience
                      ? `${candidate?.total_experience} ${
                          candidate?.total_experience > 1 ? 'years' : 'year'
                        }`
                      : '-'}
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div>
                        {candidate?.created_by?.photo ? (
                          <Avatar
                            size="25"
                            src={`${process.env.REACT_APP_IMAGE_URL}${candidate?.created_by?.photo}`}
                            round={true}
                            className="me-1 mt-1"
                          />
                        ) : (
                          <Avatar
                            facebookId="100008343750912"
                            size="25"
                            round={true}
                            className="me-1 mt-1"
                          />
                        )}
                        {/* <Image
                          width={24}
                          height={24}
                          className="rounded-2 me-2"
                          src={
                            candidate?.created_by?.photo
                              ? `${process.env.REACT_APP_IMAGE_URL}${candidate?.created_by?.photo}`
                              : UserImagesIcon
                          }
                        /> */}
                      </div>
                      <div>
                        <p className="mb-0 d-block">
                          {candidate?.created_by?.first_name +
                            ' ' +
                            candidate?.created_by?.last_name || '-'}
                        </p>
                        <small className="text-muted">
                          {candidate?.created_by?.role?.label || '-'}
                        </small>
                      </div>
                    </div>
                  </td>
                  <td>
                    {moment(candidate?.created_at).format('DD MMM, YYYY')}
                  </td>
                  <td className="text-center">
                    <Dropdown className="ms-3">
                      <Dropdown.Toggle as={Link} id="dropdown-basic" to="#">
                        <Nav.Link href="#">
                          <Image src={VerticalDotsIcon} />
                        </Nav.Link>
                      </Dropdown.Toggle>
                      <AppendedMyComponent>
                        <Dropdown.Menu>
                          <Dropdown.Item>Edit</Dropdown.Item>
                          <Dropdown.Item>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                      </AppendedMyComponent>
                    </Dropdown>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {candidateList?.length > itemsPerPage && (
          <Pagination
            page={page}
            setPage={setPage}
            itemsPerPage={itemsPerPage}
            backgroundColor="bg-white"
            tableCount={candidateList?.length}
          />
        )}
      </div>
    </div>
  )
}

export default ResumesTable
