import { FormikProvider, useFormik } from 'formik'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { Container, Form, Image, Table } from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import RightBlackArrowIcon from '../../../assets/images/right-black-arrow.svg'
import Pagination from '../../../components/Pagination'
import ConfirmationModal from '../../../modals/ConfirmationModal'
import {
  setDuplicateResumeData,
  setInitialUploadedResumeData,
  setUpdateResumeData,
  uploadResumeThunk,
} from '../../../redux/resume-library/resumeLibraryActions'
import { dispatch } from '../../../redux/store'
import CustomRow from '../CustomRow'
import InvalidResumeDataList from '../InvalidResumeDataList'

interface InitialValues {
  resumes: any
  selectedCandidate: any
  email: string[]
  skillSet: string[]
  selectUploadedResumes: boolean
  file: string | File
  examViewAnswers: boolean
  resume_data: any
}

interface ExtractedResumeData {
  file_name: string
  message: string
  file_size: string
  file_url: string
  extracted_data: {
    name: string
    email: string
    experience: number
    phone_number: string
    uuid: string
    skills: string[]
    skill_similarity_score: number
    address: { city: string; country: string; state: string }
  }
  isChecked?: boolean
}

const RESUMES_PER_PAGE = 50
const Preview = () => {
  const formRef = useRef<any>()
  const location = useLocation()
  const resumeData = location?.state?.resumeData
  const navigate = useNavigate()

  const [socketData, setSocketData] = useState<any>([])
  const [filteredResumeData, setFilteredResumeData] = useState<
    (ExtractedResumeData & { id: number; error: any })[]
  >([])
  const [page, setPage] = useState(1)
  const [invalidResumesData, setInvalidResumesData] = useState<any>()
  const [skills, setSkills] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [showSkillsSet, setShowSkillsSet] = useState(false)
  const [resumesData, setResumesData] = useState<any>()
  const [errorLabels, setErrorLabels] = useState<any>()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [checkAllValue, setCheckAllValue] = useState(false)
  const [formValues] = useState<InitialValues>({
    resumes: [] as any,
    selectedCandidate: [],
    email: [],
    skillSet: [],
    selectUploadedResumes: true,
    file: '',
    examViewAnswers: false,
    resume_data: [],
  })
  filteredResumeData?.map((item, index) => {
    return { ...item, id: index }
  })

  const getResumes = (values) => {
    return values?.selectUploadedResumes
      ? {
          resume_data: (values.selectedCandidate || [])?.map(
            (candidate: any) => ({
              name: candidate?.extracted_data?.name,
              email:
                candidate?.extracted_data?.email === 'Not found.'
                  ? undefined
                  : candidate?.extracted_data?.email,
              skill_ratio: candidate?.extracted_data?.skill_similarity_score,
              ...(candidate?.isChecked && {
                is_invited: candidate?.isChecked,
              }),
              skills: candidate?.extracted_data?.skills,
              mobile_number: candidate?.extracted_data?.phone_number,
              total_experience: candidate?.extracted_data?.experience,
              uuid: candidate?.extracted_data?.uuid,
              city: candidate?.extracted_data?.address?.city,
              country: candidate?.extracted_data?.address?.country,
              state: candidate?.extracted_data?.address?.state,
            })
          ),
        }
      : {}
  }

  const handleError = (values, error) => {
    // const labels = handleResumeErrorData(error?.data?.data?.resume_data)
    const labels = handleResumeErrorData(error?.data?.message?.resume_data)
    setErrorLabels(labels)
  }

  const handleResumeErrorData = (errorOnResumeData) => {
    !errorOnResumeData && dispatch(setUpdateResumeData(filteredResumeData))
    if (!errorOnResumeData) return []

    setResumesData(filteredResumeData)

    setSelectAll(false)

    const transformInvalidData = (invalidData) => {
      const result = {}

      Object.keys(invalidData).forEach((index) => {
        const errors = invalidData[index]

        Object.keys(errors).forEach((errorType) => {
          if (!result[errorType]) {
            result[errorType] = []
          }
          result[errorType].push(index)
        })
      })

      return result
    }

    const transformedData = errorOnResumeData?.invalid_data
      ? transformInvalidData(errorOnResumeData?.invalid_data)
      : {}

    const errorData = {
      ...errorOnResumeData?.duplicate_emails,
      ...errorOnResumeData?.duplicate_mobile_numbers,
      ...errorOnResumeData?.existing_emails,
      ...errorOnResumeData?.existing_mobile_numbers,
    }
    // const invalidData = Object.keys(errorOnResumeData?.invalid_data || {})

    const existEmail = Object.keys(errorOnResumeData?.existing_emails || {})
    const existNumber = Object.keys(
      errorOnResumeData?.existing_mobile_numbers || {}
    )

    const errorKeys = Object.keys(errorData)
    // const errorKeysIndexValue = errorKeys.map(
    //   (item, index) => errorData[errorKeys[index]]
    // )

    const updated_data_index: any = []
    const duplicateResume: any = []

    errorKeys.forEach((key) => {
      errorData[key].forEach((err_index) => {
        filteredResumeData.map((item, index2) => {
          let vardata = item

          parseInt(err_index) - 1 == index2
            ? ((vardata = { ...item, isChecked: false }),
              !updated_data_index?.includes(index2) &&
                (updated_data_index.push(index2),
                duplicateResume.push(vardata)))
            : item
        })
      })
    })
    const updateFilteredData = filteredResumeData.map((element, index) => {
      return !updated_data_index?.includes(index)
        ? element
        : { ...element, isChecked: false }
    })

    // const invalidResumes = filteredResumeData
    //   .filter((item, index) => {
    //     return errorData[errorKeys[index]]
    //   })
    //   ?.map((item) => ({ ...item, isChecked: false }))

    dispatch(setUpdateResumeData(updateFilteredData))
    dispatch(setDuplicateResumeData(duplicateResume))

    setFilteredResumeData(updateFilteredData)
    setFieldValue('selectedCandidate', updateFilteredData)

    setInvalidResumesData(duplicateResume)

    const errorDataWithInvalidData = { ...errorData, ...transformedData }
    const errorKeysWithInvalidData = Object.keys(errorDataWithInvalidData)

    return errorKeysWithInvalidData.map((item, index) => (
      <label className="text-danger mt-2 col-12">{`${index + 1}. Found ${
        existEmail.includes(item) || existNumber.includes(item)
          ? 'existing'
          : item === 'email'
          ? ''
          : 'duplicated'
      } resume with ${
        item === 'email' ? `invalid ${item}` : item
      } on resume number ${
        errorDataWithInvalidData[errorKeysWithInvalidData[index]]
      }`}</label>
    ))
  }

  const formikUploadResume = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    // validationSchema: OrganisationSocialProfileSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      const resume = getResumes(values)
      const payload = {
        resume_data: JSON.stringify(resume?.resume_data),
      }

      if (resume?.resume_data?.some((resume) => resume?.is_invited)) {
        dispatch(
          uploadResumeThunk(
            payload,
            () => {
              navigate('/candidate-management')
              dispatch(setInitialUploadedResumeData())
            },
            (error) => {
              handleError(values, error)
            }
          )
        )
      }
    },
  })

  const { setFieldValue } = formikUploadResume

  useEffect(() => {
    const data = resumeData?.map((item, index) => ({ ...item, id: index }))
    setFilteredResumeData(data)
    setFieldValue('selectedCandidate', data)
  }, [resumeData])

  return (
    <>
      <Container className="p-5 shadow-card-box">
        <div className="mb-3 pb-1">
          <span className="text-muted">Candidate Management</span>
          <Image src={RightBlackArrowIcon} className="mx-2" />
          <span className="text-muted">Upload Candidates</span>
          <Image src={RightBlackArrowIcon} className="mx-2" />
          <span className="text-dark">Preview</span>
        </div>
        <div className="card">
          <FormikProvider value={formikUploadResume}>
            <Form onSubmit={formikUploadResume.handleSubmit}>
              <div className="card-body p-4">
                <div className="mb-4 d-flex align-items-center">
                  <div>
                    <Link
                      to="/upload-candidates-resumes"
                      state={{ filteredResumeData: filteredResumeData }}
                      className="h4 d-block"
                    >
                      <IoMdArrowBack className="me-2" />
                      Preview Upload Candidates
                    </Link>
                  </div>
                  <div className="ms-auto">
                    <button className="btn btn-primary px-4" type="submit">
                      Upload candidates
                    </button>
                  </div>
                </div>
                <div className="card card border-light-gray shadow-none mb-0">
                  <div className="card-body p-0">
                    <div className="table-responsive">
                      <Table bordered hover className="overflow-scroll">
                        <thead>
                          <tr className="border-0">
                            <th style={{ width: '64px' }}>
                              <Form.Check
                                className="me-2 select-resume"
                                type={'checkbox'}
                                id="select-all"
                                disabled={
                                  formikUploadResume?.values?.resumes
                                    ?.length !== socketData?.length
                                }
                                checked={
                                  Boolean(filteredResumeData?.length) &&
                                  (selectAll ||
                                    filteredResumeData?.length ===
                                      filteredResumeData?.filter(
                                        (resume) => resume?.isChecked
                                      )?.length)
                                }
                                onChange={(e) => {
                                  setCheckAllValue(e?.target?.checked)
                                  const extractedResumeData =
                                    _.cloneDeep(filteredResumeData)
                                  const extractionSuccessfull =
                                    extractedResumeData?.filter(
                                      (resume) =>
                                        !resume?.extracted_data?.name ||
                                        resume?.extracted_data?.phone_number ===
                                          '--' ||
                                        resume?.extracted_data?.email === '--'
                                    )?.length
                                  if (
                                    extractionSuccessfull &&
                                    e?.target?.checked
                                  ) {
                                    setShowConfirmationModal(true)
                                  } else {
                                    const resumeData = extractedResumeData?.map(
                                      (resumes) => ({
                                        ...resumes,
                                        isChecked: e?.target?.checked,
                                      })
                                    )
                                    const invalidResume =
                                      invalidResumesData?.map((resumes) => ({
                                        ...resumes,
                                        isChecked: e?.target?.checked,
                                      }))
                                    setInvalidResumesData(invalidResume)
                                    setSelectAll(e?.target?.checked)
                                    const ids = extractedResumeData?.map(
                                      (candidate) => candidate?.id
                                    )

                                    setFieldValue(
                                      'selectedCandidate',
                                      (
                                        formikUploadResume?.values
                                          ?.selectedCandidate as any
                                      )?.map((candidate) => {
                                        if (
                                          !candidate?.extracted_data?.name ||
                                          candidate?.extracted_data
                                            ?.phone_number === '--' ||
                                          candidate?.extracted_data?.email ===
                                            '--'
                                        )
                                          return candidate
                                        if (ids?.includes(candidate?.id)) {
                                          return {
                                            ...candidate,
                                            isChecked: e?.target?.checked,
                                          }
                                        }
                                        return candidate
                                      })
                                    )
                                    setFilteredResumeData(resumeData)
                                  }
                                }}
                              />
                            </th>
                            <th style={{ width: '64px' }}>No.</th>
                            <th>File Name</th>
                            <th>Progress</th>
                            <th className="text-end">&nbsp;</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredResumeData?.length ? (
                            filteredResumeData
                              ?.slice(
                                ((page - 1) * RESUMES_PER_PAGE) %
                                  (filteredResumeData?.length || 1),
                                page * RESUMES_PER_PAGE
                              )
                              ?.map((val, index) => {
                                return (
                                  <CustomRow
                                    key={val?.id}
                                    id={index}
                                    row={val}
                                    setFieldValue={setFieldValue}
                                    setFilteredResumeData={
                                      setFilteredResumeData
                                    }
                                    filteredResumeData={filteredResumeData}
                                    setInvalidResumesData={
                                      setInvalidResumesData
                                    }
                                    setShowSkillSet={setShowSkillsSet}
                                    setSkills={setSkills}
                                    setSelectAll={setSelectAll}
                                  />
                                )
                              })
                          ) : (
                            <tr>
                              <td colSpan={8} className="text-center">
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>

                    {filteredResumeData?.length > RESUMES_PER_PAGE && (
                      <Pagination
                        page={page}
                        setPage={setPage}
                        itemsPerPage={RESUMES_PER_PAGE}
                        backgroundColor="bg-white"
                        tableCount={filteredResumeData?.length}
                      />
                    )}
                    <ConfirmationModal
                      show={showConfirmationModal}
                      setShow={setShowConfirmationModal}
                      title="Confirm"
                      description="Candidates whose name, email address and phone number are not read will not be selected."
                      onSuccess={() => {
                        const extractedResumeData =
                          _.cloneDeep(filteredResumeData)
                        const extractionSuccessfull = extractedResumeData?.map(
                          (resume) => {
                            if (
                              !resume?.extracted_data?.name ||
                              resume?.extracted_data?.phone_number === '--' ||
                              resume?.extracted_data?.email === '--'
                            ) {
                              return resume
                            }
                            return { ...resume, isChecked: checkAllValue }
                          }
                        )
                        setFilteredResumeData(extractionSuccessfull)
                        const ids = extractedResumeData?.map(
                          (candidate) => candidate?.id
                        )
                        setFieldValue(
                          'selectedCandidate',
                          (
                            formikUploadResume?.values?.selectedCandidate as any
                          )?.map((candidate) => {
                            if (
                              !candidate?.extracted_data?.name ||
                              candidate?.extracted_data?.phone_number ===
                                '--' ||
                              candidate?.extracted_data?.email === '--'
                            )
                              return candidate
                            if (ids?.includes(candidate?.id)) {
                              return {
                                ...candidate,
                                isChecked: checkAllValue,
                              }
                            }
                            return candidate
                          })
                        )
                        setShowConfirmationModal(false)
                      }}
                      onCancel={() => {
                        setShowConfirmationModal(false)
                      }}
                    />

                    <div>{errorLabels}</div>
                    {invalidResumesData?.length > 0 && (
                      <>
                        <div className="mb-4 mt-4 d-flex align-items-center">
                          <div>
                            <div className="h4 d-block">
                              Duplicate Resume List
                            </div>
                          </div>
                        </div>

                        <InvalidResumeDataList
                          socketData={socketData}
                          invalidResumesData={invalidResumesData}
                          setInvalidResumesData={setInvalidResumesData}
                          selectUploadedResumes={false}
                          resumes={undefined}
                          selectedCandidate={[]}
                          resume_data={undefined}
                          setFilteredResumeData={setFilteredResumeData}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </Container>
    </>
  )
}

export default Preview
