import React from 'react'
import { Modal } from 'react-bootstrap'

interface Props {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  src: string
}

const ImageModal = ({ show, setShow, src }: Props) => {
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="sm"
      centered
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header className="border border-bottom-0 rounded-top" closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body className="h-100 w-100 border border-top-0 rounded-bottom d-flex justify-content-center align-items-center">
        <img src={`${process.env.REACT_APP_IMAGE_URL}${src}`} />
      </Modal.Body>
    </Modal>
  )
}

export default ImageModal
