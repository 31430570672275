import React, { useState } from 'react'
import { Dropdown, Image, Nav, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import AddIndividuallyIcon from '../../assets/images/add-individually.svg'
import DownwhiteIcon from '../../assets/images/down-white-arrow.svg'
import UploadResumes from '../../assets/images/upload-resumes-icon.svg'
import UploadSpreadsheet from '../../assets/images/upload-spreadsheet-icon.svg'
import AllCandidateTable from './AllCandidateTable'
import BookmarkedCandidateTable from './BookmarkedCandidateTable'
import ResumesTable from './ResumesTable'

const CandidateManagement = () => {
  const [tabKey, setTabKey] = useState('first')
  return (
    <>
      <div className="p-5">
        <div className="d-flex align-items-center mb-3">
          <h5 className="mb-4">Candidate Management</h5>
          <Dropdown className="ms-auto table-filter-dropdown">
            <Dropdown.Toggle id="dropdown-basic">
              Add Candidate <Image src={DownwhiteIcon} />
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: '210px' }}>
              {/* <Dropdown.Item as={Link} to="/add-candidate">
                <Image className="me-2" src={AddIndividuallyIcon} />
                Add Individually
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/upload-candidates">
                <Image className="me-2" src={UploadSpreadsheet} />
                Upload Spreadsheet
              </Dropdown.Item> */}
              <Dropdown.Item as={Link} to="/upload-candidates-resumes">
                <Image className="me-2" src={UploadResumes} />
                Upload Resumes
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Tab.Container defaultActiveKey="first">
          <div className="card-header p-0 bg-transparent">
            <Nav variant="pills" className="tab-light-border align-items-end">
              <Nav.Item>
                <Nav.Link
                  eventKey="first"
                  onClick={() => {
                    setTabKey('first')
                  }}
                >
                  All
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="second"
                  onClick={() => {
                    setTabKey('second')
                  }}
                >
                  Bookmarked
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="third"
                  onClick={() => {
                    setTabKey('third')
                  }}
                >
                  Resumes
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="card-body px-0 py-4 my-1">
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {tabKey === 'first' ? <AllCandidateTable /> : null}
                {/* <AllCandidateTable /> */}
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                {tabKey === 'second' ? <BookmarkedCandidateTable /> : null}
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                {tabKey === 'third' ? <ResumesTable /> : null}
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </>
  )
}

export default CandidateManagement
