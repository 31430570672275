import { TextField } from '@mui/material'
import { useField, useFormikContext } from 'formik'
import _, { at } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button, Col, FormLabel, Row } from 'react-bootstrap'

import { RESULT_BUFFER_TIME } from '../../../src/util/utils'

interface Time {
  hours: string | number
  minutes: string | number
  ampm: string
}

interface Props {
  //   value: any
  //   handleChange: () => void
  date: any
  name: string
  id: string
  value?: any
  defaultValue?: any
  disabled?: boolean
}
const padZero = (value: number, length?: number) => {
  length = length || 2 // defaults to 2 if no parameter is passed
  return (new Array(length).join('0') + value).slice(length * -1)
}
const CustomTimePicker: React.FC<Props> = ({
  date,
  name,
  id,
  value,
  disabled,
}) => {
  const formikProps = useFormikContext()
  const [field, meta, helper] = useField(name)
  const [time, setTime] = useState<Time>({
    hours: formikProps?.getFieldProps(name)?.value
      ? padZero(
          moment(formikProps?.getFieldProps(name)?.value)?.hours() % 12 || 12
        )
      : '',
    minutes: formikProps?.getFieldProps(name)?.value
      ? padZero(moment(formikProps?.getFieldProps(name)?.value)?.minutes() || 0)
      : '',
    ampm: formikProps?.getFieldProps(name)?.value
      ? moment(formikProps?.getFieldProps(name)?.value)?.format('A')
      : 'PM',
  })
  const [resultTime, setResultTime] = useState<Time>({
    hours: formikProps?.getFieldProps(name)?.value
      ? padZero(
          moment(formikProps?.getFieldProps(name)?.value)?.hours() % 12 || 12
        )
      : '',
    minutes: formikProps?.getFieldProps(name)?.value
      ? padZero(moment(formikProps?.getFieldProps(name)?.value)?.minutes() || 0)
      : '',
    ampm: formikProps?.getFieldProps(name)?.value
      ? moment(formikProps?.getFieldProps(name)?.value)?.format('A')
      : 'PM',
  })
  const [error, setError] = useState({
    hours: '',
    minutes: '',
  })
  const formValues = formikProps?.values as Record<string, string>

  const renderHelperText = () => {
    const [touched, error] = at(meta, 'touched', 'error')
    if (touched && error) {
      return error
    }
  }

  const handleAddTime = (value: number, label: string) => {
    if (disabled || formValues?.disableAll) return
    if (name !== 'resultDeclarationTime') {
      formikProps?.setFieldTouched('resultDeclarationTime', false, false)
    } else {
      formikProps?.setFieldTouched('resultDeclarationTime', true)
    }
    if (name !== 'resultDeclarationTime') {
      formikProps?.setFieldTouched('resultDeclarationTime', false, false)
    } else {
      formikProps?.setFieldTouched('resultDeclarationTime', true, false)
    }
    switch (label) {
      case 'm':
        if (value >= 59) {
          setTime((prev) => ({ ...prev, minutes: '00' }))
          return
        }
        setTime((prev) => ({ ...prev, minutes: padZero(value + 1) }))
        break
      case 'h':
        if (value >= 12) {
          setTime((prev) => ({ ...prev, hours: '01' }))
          return
        }
        setTime((prev) => ({ ...prev, hours: padZero(value + 1) }))
        break
    }
  }

  const handleSubtractTime = (value: number, label: string) => {
    if (disabled || formValues?.disableAll) return
    if (name !== 'resultDeclarationTime') {
      formikProps?.setFieldTouched('resultDeclarationTime', false, false)
    } else {
      formikProps?.setFieldTouched('resultDeclarationTime', true, false)
    }
    switch (label) {
      case 'm':
        if (value <= 0) {
          setTime((prev) => ({ ...prev, minutes: 59 }))
          return
        }
        setTime((prev) => ({ ...prev, minutes: padZero(value - 1) }))
        break
      case 'h':
        if (value <= 1 || value > 12) {
          setTime((prev) => ({ ...prev, hours: '12' }))
          return
        }
        setTime((prev) => ({ ...prev, hours: padZero(value - 1) }))
        break
    }
  }

  const handleChangeAmPm = () => {
    setTime((prev) => ({
      ...prev,
      ampm: prev.ampm === 'AM' ? 'PM' : 'AM',
    }))
  }

  useEffect(() => {
    if (!date) return
    if (!time?.minutes || time?.minutes < 0 || time?.minutes > 60) return
    if (!time?.hours || time?.hours < 0 || time?.hours > 12) return
    if (
      name === 'examValidTillTime' &&
      formValues?.examValidTillDate &&
      formValues?.examDuration &&
      formValues?.examValidFromTime
    ) {
      formikProps.setFieldValue(
        'resultDeclarationTime',
        moment(date || formValues?.examValidTillDate)
          ?.set({
            hours:
              time?.ampm === 'PM'
                ? +time?.hours === 12
                  ? 12
                  : +time?.hours + 12
                : +time?.hours === 12
                ? 0
                : +time?.hours,
            minutes: +time?.minutes,
          })
          .add({
            minute: +formValues?.examDuration + RESULT_BUFFER_TIME || 0,
          })
          ?.toDate()
      )
      formikProps.setFieldValue(
        'resultDeclarationDate',
        moment(date || formValues?.examValidTillDate)
          ?.set({
            hours:
              time?.ampm === 'PM'
                ? +time?.hours === 12
                  ? 12
                  : +time?.hours + 12
                : +time?.hours === 12
                ? 0
                : +time?.hours,
            minutes: +time?.minutes,
          })
          ?.add({
            minute: +formValues?.examDuration + RESULT_BUFFER_TIME || 0,
          })
          ?.toDate()
      )
    }
    if (
      name === 'examValidFromTime' &&
      formValues?.examValidFromDate &&
      formValues?.examDuration &&
      formValues?.examValidTillDate &&
      formValues?.examValidTillTime
    ) {
      // !formValues?.resultDeclarationTime &&
      formikProps.setFieldValue(
        'resultDeclarationTime',
        moment(formValues?.examValidTillTime)
          .add({
            minute: +formValues?.examDuration + RESULT_BUFFER_TIME || 0,
          })
          ?.toDate()
      )
      // !formValues?.resultDeclarationDate &&
      formikProps.setFieldValue(
        'resultDeclarationDate',
        moment(formValues?.examValidTillDate)
          ?.set({
            hours: moment(formValues?.examValidTillTime || '')?.hours(),
            minutes: moment(formValues?.examValidTillTime || '')?.minutes(),
          })
          ?.add({
            minute: +formValues?.examDuration + RESULT_BUFFER_TIME || 0,
          })
          ?.toDate()
      )
    }
    formikProps?.setFieldValue(
      name,
      moment(date)
        ?.set({
          hours:
            time?.ampm === 'PM'
              ? +time?.hours === 12
                ? 12
                : +time?.hours + 12
              : +time?.hours === 12
              ? 0
              : +time?.hours,
          minutes: +time?.minutes,
          day: moment(date)?.day(),
          month: moment(date)?.month(),
          year: moment(date)?.year(),
        })
        ?.toDate()
    )
    !meta?.touched &&
      name !== 'resultDeclarationTime' &&
      helper?.setTouched(true, false)
  }, [time?.hours, time?.minutes, time?.ampm])
  useEffect(() => {
    helper.setError(meta.error)
  }, [meta.value])

  useEffect(() => {
    if (formikProps?.getFieldProps(name)?.value && !time?.minutes) {
      setTime({
        hours: padZero(moment(value)?.hours() % 12 || 12),
        minutes: padZero(moment(value)?.minutes()),
        ampm: moment(value)?.format('A'),
      })
    }
  }, [!formikProps?.getFieldProps(name)?.value])

  useEffect(() => {
    const setResultDeclarationTime = setTimeout(() => {
      if (value && name === 'resultDeclarationTime') {
        setTime({
          hours: moment(value)?.hours() % 12 || 12,
          minutes: moment(value)?.minutes(),
          ampm: moment(value)?.format('A'),
        })
        setResultTime({
          hours: padZero(moment(value)?.hours() % 12 || 12),
          minutes: padZero(moment(value)?.minutes()),
          ampm: moment(value)?.format('A'),
        })
      }
    }, 100)
    return () => {
      clearTimeout(setResultDeclarationTime)
    }
  }, [value])

  return (
    <Row>
      <Col
        lg={4}
        className="d-flex flex-wrap flex-column justify-content-center"
      >
        {/* <FormLabel> Hours*</FormLabel> */}
        <i
          className="fa fa-angle-up d-block m-auto pointer font-size-26 text-primary"
          onClick={() => handleAddTime(+time?.hours, 'h')}
        ></i>
        <TextField
          className={`my-1 ${
            disabled || formValues?.disableAll ? 'disabled' : ''
          }`}
          value={
            name === 'resultDeclarationTime'
              ? `${resultTime?.hours}`
              : `${time?.hours}`
          }
          disabled={disabled || Boolean(formValues?.disableAll)}
          //   style={{ width: '100%' }}
          type="number"
          error={!!error?.hours}
          inputProps={{
            min: 1,
            max: 12,
            placeholder: 'HH',
            onKeyDown: (event) => {
              const key = event.key
              const notAllowedKeys = ['E', 'e', '+', '-']
              if (notAllowedKeys.includes(key)) {
                event?.preventDefault()
              }
            },
          }}
          onBlur={() => {
            if (+time?.hours > 12) {
              setTime((prev) => ({
                ...prev,
                hours:
                  +(+time?.hours)?.toString()?.slice(0, 2) > 12
                    ? `0${(+time?.hours)?.toString()?.slice(0, 1)}`
                    : +(+time?.hours)?.toString()?.slice(0, 2),
              }))
            } else if (+time?.hours < 1 || !time?.hours) {
              setTime((prev) => ({
                ...prev,
                hours: '01',
              }))
            } else if (+time?.hours <= 12) {
              setTime((prev) => ({
                ...prev,
                hours: padZero(+(+time?.hours)?.toString()?.slice(0, 2)),
              }))
            }
            if (name === 'resultDeclarationTime') {
              if (+resultTime?.hours < 1 || !resultTime?.hours) {
                setTime((prev) => ({
                  ...prev,
                  hours: '01',
                }))
                setResultTime((prev) => ({
                  ...prev,
                  hours: '01',
                }))
              } else if (+resultTime?.hours > 12) {
                setTime((prev) => ({
                  ...prev,
                  hours:
                    +(+resultTime?.hours)?.toString()?.slice(0, 2) > 12
                      ? `0${(+resultTime?.hours)?.toString()?.slice(0, 1)}`
                      : +(+resultTime?.hours)?.toString()?.slice(0, 2),
                }))
                setResultTime((prev) => ({
                  ...prev,
                  hours:
                    +(+resultTime?.hours)?.toString()?.slice(0, 2) > 12
                      ? `0${resultTime?.hours?.toString()?.slice(0, 1)}`
                      : +(+resultTime?.hours)?.toString()?.slice(0, 2),
                }))
              } else {
                setTime(resultTime)
              }
            }
            setError({ ...error, hours: '' })
          }}
          onChange={(e) => {
            if (
              !e?.target?.value ||
              +e?.target?.value > 12 ||
              +e?.target?.value < 1
            ) {
              setError({ ...error, hours: 'Hours should be between 1-12' })
            } else {
              setError({ ...error, hours: '' })
            }
            if (name === 'resultDeclarationTime') {
              setResultTime((prev) => ({
                ...prev,
                hours: e?.target?.value,
              }))
            } else {
              setTime((prev) => ({
                ...prev,
                hours: e?.target?.value,
              }))
            }
          }}
        />
        <i
          className="fas fa-angle-down d-block mx-auto font-size-26 text-primary pointer"
          onClick={() => handleSubtractTime(+time?.hours, 'h')}
        ></i>
        {/* {error?.hours && <span className="text-danger">{error?.hours}</span>} */}
      </Col>
      <Col
        lg={4}
        className="d-flex flex-wrap flex-column justify-content-center"
      >
        {/* <FormLabel>Minutes*</FormLabel> */}
        <i
          className="fas fa-angle-up d-block m-auto font-size-26 text-primary pointer"
          onClick={() => handleAddTime(+time?.minutes, 'm')}
        ></i>
        <TextField
          className={`my-1 ${
            disabled || formValues?.disableAll ? 'disabled' : ''
          }`}
          value={
            name === 'resultDeclarationTime'
              ? `${resultTime?.minutes}`
              : `${time?.minutes}`
          }
          style={{ width: '100%' }}
          type="number"
          error={!!error?.minutes}
          inputProps={{
            min: 0,
            max: 59,
            placeholder: 'MM',
          }}
          disabled={disabled || Boolean(formValues?.disableAll)}
          onBlur={() => {
            if (+time?.minutes > 59) {
              setTime((prev) => ({
                ...prev,
                minutes:
                  +(+time?.minutes)?.toString()?.slice(0, 2) > 59
                    ? `0${(+time?.minutes)?.toString()?.slice(0, 1)}`
                    : +(+time?.minutes)?.toString()?.slice(0, 2),
              }))
            }
            if (+time?.minutes <= 0 || !time?.minutes) {
              setTime((prev) => ({
                ...prev,
                minutes: '00',
              }))
            }
            if (+time?.minutes >= 0 && +time?.minutes <= 59) {
              setTime((prev) => ({
                ...prev,
                minutes: padZero(+(+time?.minutes)?.toString()?.slice(0, 2)),
              }))
            }
            if (name === 'resultDeclarationTime') {
              if (+time?.minutes > 59) {
                setTime((prev) => ({
                  ...prev,
                  minutes:
                    +(+resultTime?.minutes)?.toString()?.slice(0, 2) > 59
                      ? `0${(+resultTime?.minutes)?.toString()?.slice(0, 1)}`
                      : +(+resultTime?.minutes)?.toString()?.slice(0, 2),
                }))
                setResultTime((prev) => ({
                  ...prev,
                  minutes:
                    +resultTime?.minutes?.toString()?.slice(0, 2) > 59
                      ? `0${resultTime?.minutes?.toString()?.slice(0, 1)}`
                      : +(+resultTime?.minutes)?.toString()?.slice(0, 2),
                }))
              } else if (+time?.minutes <= 0 || !time?.minutes) {
                setResultTime((prev) => ({
                  ...prev,
                  minutes: '00',
                }))
                setTime((prev) => ({
                  ...prev,
                  minutes: '00',
                }))
              } else {
                setTime(resultTime)
              }
            }
            setError({ ...error, minutes: '' })
          }}
          onChange={(e) => {
            if (
              !e?.target?.value ||
              +e?.target?.value < 0 ||
              +e?.target?.value > 60
            ) {
              setError({ ...error, minutes: 'Minutes should be between 0-59' })
            } else {
              setError({ ...error, minutes: '' })
            }
            if (name === 'resultDeclarationTime') {
              setResultTime((prev) => ({ ...prev, minutes: e?.target?.value }))
            } else {
              setTime((prev) => ({ ...prev, minutes: e?.target?.value }))
            }
          }}
        />
        <i
          className="fas fa-angle-down d-block mx-auto font-size-26 text-primary pointer"
          onClick={() => handleSubtractTime(+time?.minutes, 'm')}
        ></i>
        {/* {error?.minutes && (
          <span className="text-danger">{error?.minutes}</span>
        )} */}
      </Col>
      <Col lg={4}>
        {/* <FormLabel className="mb-4">AM/PM</FormLabel> */}
        <Button
          disabled={disabled || Boolean(formValues?.disableAll)}
          className="min-h-55"
          style={{ marginTop: '2rem' }}
          onClick={handleChangeAmPm}
        >
          {time?.ampm}
        </Button>
      </Col>
      {meta?.error && meta?.touched && (
        <p className="text-danger" style={{ fontSize: '0.75rem' }}>
          {renderHelperText()}
        </p>
      )}
    </Row>
  )
}

export default CustomTimePicker
