import { combineReducers } from 'redux'

import authReducer from './auth/authReducer'
import candidateManagementReducer from './candidate-management/candidateManagementReducer'
import commonReducer from './common/commonReducer'
import companiesReducer from './companies/companiesReducer'
import employeeReducer from './employee-management/employeeReducer'
import examReducer from './exam/examReducer'
import jobDescriptionReducer from './job-description/jobDescriptionReducer'
import organisationReducer from './organisation/organisationReducer'
import proctoringReducer from './proctoring-exam/proctoringExamReducer'
import questionPaperReducer from './question-paper/questionPaperReducer'
import resumeLibraryReducer from './resume-library/resumeLibraryReducer'

// Combine all reducers as root reducer
export default combineReducers({
  authReducer,
  examReducer,
  questionPaperReducer,
  employeeReducer,
  companiesReducer,
  organisationReducer,
  jobDescriptionReducer,
  commonReducer,
  proctoringReducer,
  resumeLibraryReducer,
  candidateManagementReducer,
})
