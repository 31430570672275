import {
  Container,
  Dropdown,
  Form,
  Image,
  InputGroup,
  Nav,
  Tab,
  Table,
} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

import Cart from '../../../assets/images/cart.svg'
import CartTrolly from '../../../assets/images/cart-trolly.svg'
import DownwhiteIcon from '../../../assets/images/down-white-arrow.svg'
import FilterIcon from '../../../assets/images/filter-icon.svg'
import CompanyDetailIcon from '../../../assets/images/question-paper.png'
import RemoveIcon from '../../../assets/images/remove.svg'
import SearchIcon from '../../../assets/images/search-icon.svg'
import UserImagesIcon from '../../../assets/images/User-images.svg'
import VerticalDotsIcon from '../../../assets/images/vertical-dots.svg'

const PurchaseRequest = () => {
  const navigate = useNavigate()

  return (
    <Container className="p-5">
      <div className="d-flex align-items-center justify-content-between pb-1">
        <h4>Purchase Request</h4>
        <Link to={'/shopping-cart'}>
          <Image src={CartTrolly} className="me-2" />
          <span className="text-primary">View Cart (1)</span>
        </Link>
      </div>
      <p>3 Pending Request</p>
      <div className="card-body card-body py-4 my-3">
        <div className="d-flex align-items-center mb-4">
          <InputGroup className="search-box">
            <InputGroup.Text id="basic-addon1">
              <Image src={SearchIcon} />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          <div className="filter-btn ms-3">
            <Image src={FilterIcon} className="me-2" />
            Filter
          </div>
        </div>
        <div className="table-responsive">
          <Table bordered hover className="overflow-scroll">
            <thead>
              <tr className="border-0">
                <th>Question Paper</th>
                <th>Topics</th>
                <th>Complexity</th>
                <th># Ques.</th>
                <th>Requested by</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Link to={'/question-paper-preview'}>
                    <Image src={CompanyDetailIcon} className="me-2" />
                    Full stack dev test for Intermediate
                  </Link>
                </td>
                <td>Node JS, React JS, +2</td>
                <td>Beginner</td>
                <td>100</td>
                <td>23 Sep, 2023</td>
                <td className="text-center">
                  <button
                    className="btn btn-light"
                    onClick={() => navigate('/shopping-cart')}
                  >
                    Go to Cart
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  <Image src={CompanyDetailIcon} className="me-2" />
                  Java skill assessment Dec 2022
                </td>
                <td>Angular, Mainframe,+4</td>
                <td>Intermediate </td>
                <td>100</td>
                <td>
                  {' '}
                  <div className="d-flex align-items-start">
                    <Image src={UserImagesIcon} className="mt-1" />
                    <div className="ms-2">
                      <span className="text-dark">Gorge Filly</span>
                      <br />
                    </div>
                  </div>
                </td>
                <td className="text-center">
                  <Image src={RemoveIcon} className="me-2" />
                  <Image src={Cart} className="me-2" />
                </td>
              </tr>
              <tr>
                <td>
                  <Image src={CompanyDetailIcon} className="me-2" />
                  Developer - Recruitment, Jan 2022
                </td>
                <td>Angular, Mainframe,+4</td>
                <td>Advance</td>
                <td>100</td>
                <td>23 Sep, 2023</td>

                <td className="text-center">
                  <Image src={RemoveIcon} className="me-2" />
                  <Image src={Cart} className="me-2" />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </Container>
  )
}

export default PurchaseRequest
