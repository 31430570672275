import { Action, Store } from 'redux'
import { ThunkAction } from 'redux-thunk'

import {
  CANDIDATE_SUBMISSION_STATE_URL,
  EMPLOYEE_ASSIGNMENT_HISTORY_URL,
  EMPLOYEE_JOB_DESCRIPTION_DETAILS_URL,
  GET_CANDIDATE_LIST_URL,
  GET_EMPLOYEE_LIST_URL,
  GET_JOB_DESCRIPTION_LIST_URL,
  GET_JOB_DESCRIPTION_SUBMISSION_LIST_URL,
  JOB_DESCRIPTION_BOOKMARK_URL,
  REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_URL,
  SUBMIT_RESUME_TO_JD_URL,
} from '../../service/apiEndPoints'
import { apiCall, METHOD } from '../../service/baseApiCall'
import { errorToast, successToast } from '../../util/utils'
import {
  ASSIGN_RECRUITER_TO_JD,
  CANDIDATE_SUBMISSION_STATE,
  GET_CANDIDATE_LIST,
  GET_JOB_DESCRIPTION_LIST,
  GET_JOB_DESCRIPTION_SUBMISSION_LIST,
  GET_RECRUITER_LIST,
  JOB_DESCRIPTION_BOOKMARK,
  REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES,
  SUBMIT_RESUME_TO_JD,
} from './jobDescriptionConst'

/**
 * Get job description list
 * @returns
 */
export const getJobDescriptionListThunk =
  (
    status: string,
    business_category: any,
    city: any,
    submission?: boolean,
    bookmarks?: boolean,
    search?: string,
    ordering?: string,
    _handleSuccess?: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_JOB_DESCRIPTION_LIST.GET_JOB_DESCRIPTION_REQUEST,
    })
    apiCall(
      GET_JOB_DESCRIPTION_LIST_URL,
      (res) => {
        dispatch({
          type: GET_JOB_DESCRIPTION_LIST.GET_JOB_DESCRIPTION_SUCCESS,
          payload: res?.data,
        })
        _handleSuccess && _handleSuccess()
      },
      () => {
        dispatch({
          type: GET_JOB_DESCRIPTION_LIST.GET_JOB_DESCRIPTION_ERROR,
        })
      },
      {
        params: {
          status: status || undefined,
          business_category: business_category || undefined,
          city: city || undefined,
          submission: submission || undefined,
          bookmarks: bookmarks || undefined,
          search: search || undefined,
          ordering: ordering || undefined,
        },
      },
      METHOD.GET,
      { showToast: false }
    )
  }

/**
 * Get job description list
 * @returns
 */
export const getRecruiterListThunk =
  (
    agencyId: number | string,
    user: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    const params: any = new URLSearchParams()

    params.append('agency', agencyId)
    if (user === 'Recruiting Manager') {
      params.append('user_role', 'recruiter')
    } else if (user === 'Recruitment Head') {
      params.append('user_role', 'recruiter')
      params.append('user_role', 'recruiting-manager')
    }
    dispatch({
      type: GET_RECRUITER_LIST.GET_RECRUITER_REQUEST,
    })
    apiCall(
      GET_EMPLOYEE_LIST_URL,
      (res) => {
        dispatch({
          type: GET_RECRUITER_LIST.GET_RECRUITER_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_RECRUITER_LIST.GET_RECRUITER_ERROR,
        })
      },
      {
        params: params,
      },
      METHOD.GET,
      { showToast: false }
    )
  }

/**
 * Assign recruitement
 * @returns
 */
export const assignRecruiterThunk =
  (
    payload,
    _handleSuccess?: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: ASSIGN_RECRUITER_TO_JD.ASSIGN_RECRUITER_TO_JD_REQUEST,
    })
    apiCall(
      EMPLOYEE_ASSIGNMENT_HISTORY_URL,
      () => {
        dispatch({
          type: ASSIGN_RECRUITER_TO_JD.ASSIGN_RECRUITER_TO_JD_SUCCESS,
        }),
          successToast('Recruiter assigned successfully.')
        _handleSuccess && _handleSuccess()
      },
      () => {
        dispatch({
          type: ASSIGN_RECRUITER_TO_JD.ASSIGN_RECRUITER_TO_JD_ERROR,
        })
      },
      { ...payload },
      METHOD.POST,
      { showToast: false }
    )
  }

/**
 * Get job description submission states list
 * @returns
 */
export const getJobDescriptionSubmissionListThunk =
  (
    jobDescription: number | string,
    search?: string,
    page?: number,
    pageSize?: number
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_JOB_DESCRIPTION_SUBMISSION_LIST.GET_JOB_DESCRIPTION_SUBMISSION_REQUEST,
    })
    apiCall(
      GET_JOB_DESCRIPTION_SUBMISSION_LIST_URL,
      (res) => {
        dispatch({
          type: GET_JOB_DESCRIPTION_SUBMISSION_LIST.GET_JOB_DESCRIPTION_SUBMISSION_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_JOB_DESCRIPTION_SUBMISSION_LIST.GET_JOB_DESCRIPTION_SUBMISSION_ERROR,
        })
      },
      {
        params: {
          job_description: jobDescription,
          search: search || undefined,
          page: page || undefined,
          page_size: pageSize || undefined,
        },
      },
      METHOD.GET,
      { showToast: false }
    )
  }

export const removeCandidateFromSubmissionStateThunk =
  (
    id,
    _handleSuccess: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES.REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_REQUEST,
    })
    apiCall(
      REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_URL(id),
      (res: any) => {
        dispatch({
          type: REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES.REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_SUCCESS,
          payload: res?.data,
        }),
          successToast('Candidate Removed')
        _handleSuccess()
      },
      (errData: any) => {
        dispatch({
          type: REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES.REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_ERROR,
        })
      },
      {},
      METHOD.DELETE,
      { showToast: true }
    )
  }

export const jdBookmarkThunk =
  (
    payload,
    _handleSuccess: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: JOB_DESCRIPTION_BOOKMARK.JOB_DESCRIPTION_BOOKMARK_REQUEST,
    })
    apiCall(
      JOB_DESCRIPTION_BOOKMARK_URL,
      (res) => {
        dispatch({
          type: JOB_DESCRIPTION_BOOKMARK.JOB_DESCRIPTION_BOOKMARK_SUCCESS,
        }),
          _handleSuccess()
      },
      (err) => {
        dispatch({
          type: JOB_DESCRIPTION_BOOKMARK.JOB_DESCRIPTION_BOOKMARK_ERROR,
        })
      },
      payload,
      METHOD.POST,
      { showToast: false }
    )
  }

export const getCandidateListThunk =
  (
    job_description: string | number,
    page?: number,
    search?: string,
    bookmarks?: boolean,
    skills?: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_CANDIDATE_LIST.GET_CANDIDATE_REQUEST,
    })
    apiCall(
      GET_CANDIDATE_LIST_URL,
      (res) => {
        dispatch({
          type: GET_CANDIDATE_LIST.GET_CANDIDATE_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_CANDIDATE_LIST.GET_CANDIDATE_ERROR,
        })
      },
      {
        params: {
          job_description: job_description,
          page: page || undefined,
          search: search || undefined,
          bookmarks: bookmarks || undefined,
          skills: skills || undefined,
        },
      },
      METHOD.GET,
      { showToast: false }
    )
  }

export const candidateSubmissionStateThunk =
  (
    payload,
    _handleSuccess: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CANDIDATE_SUBMISSION_STATE.CANDIDATE_SUBMISSION_STATE_REQUEST,
    })
    apiCall(
      CANDIDATE_SUBMISSION_STATE_URL,
      (res) => {
        dispatch({
          type: CANDIDATE_SUBMISSION_STATE.CANDIDATE_SUBMISSION_STATE_SUCCESS,
        }),
          _handleSuccess()
      },
      (err) => {
        dispatch({
          type: CANDIDATE_SUBMISSION_STATE.CANDIDATE_SUBMISSION_STATE_ERROR,
        })
      },
      payload,
      METHOD.POST,
      { showToast: true }
    )
  }

export const submitResumeToJDThunk =
  (
    jdId,
    payload,
    _handleSuccess?: () => void
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SUBMIT_RESUME_TO_JD.SUBMIT_RESUME_TO_JD_REQUEST,
    })
    apiCall(
      SUBMIT_RESUME_TO_JD_URL(jdId),
      (res) => {
        dispatch({
          type: SUBMIT_RESUME_TO_JD.SUBMIT_RESUME_TO_JD_SUCCESS,
        }),
          _handleSuccess && _handleSuccess()
      },
      (err) => {
        dispatch({
          type: SUBMIT_RESUME_TO_JD.SUBMIT_RESUME_TO_JD_ERROR,
        })
      },
      payload,
      METHOD.POST,
      { showToast: true }
    )
  }
