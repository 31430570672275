import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface Props {
  title: string
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  onSuccess: () => void
  onCancel: () => void
  saveButtonText?: string
  description?: string
  backdrop?: boolean | 'static'
}

const ConfirmationModal: React.FC<Props> = ({
  show,
  setShow,
  title,
  onCancel,
  onSuccess,
  saveButtonText,
  description,
  backdrop,
}) => {
  const handleClose = () => setShow(false)
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="sm"
      centered
      contentClassName="p-3 border-radius-0"
      backdrop={backdrop || undefined}
    >
      <Modal.Body>
        <p className="font-size-20 fw-600 lh-30">{title}</p>
        <p className="fw-400 text-black-400 font-size-16 ">
          {description || 'This job role will be visible to all the HRs.'}
        </p>
        <div className="float-right mt-4">
          <Button
            variant="btn btn-secondary"
            className="me-3"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button onClick={onSuccess}>{saveButtonText || 'Save'}</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ConfirmationModal
