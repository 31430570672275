import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  LinearProgress,
} from '@mui/material'
import { ApexOptions } from 'apexcharts'
import moment from 'moment'
import React, { useEffect } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Col, Container, Image, Row } from 'react-bootstrap'
import GaugeChart from 'react-gauge-chart'
import { IoMdArrowBack } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { Link, useLocation, useParams } from 'react-router-dom'

import AnswerAnalysisIcon from '../../../assets/images/answer-analysis.png'
import ResumeIcon from '../../../assets/images/blue-resume.svg'
import BlueShareIcon from '../../../assets/images/blue-share.svg'
import DownloadBlueIcon from '../../../assets/images/download-blue.svg'
import ExamPerfomanceIcon from '../../../assets/images/exam-perfomance.png'
import ExamReporterIcon from '../../../assets/images/exam-reporter-profile.svg'
import ExamScoreIcon from '../../../assets/images/exam-score.png'
import Proc from '../../../assets/images/proc.png'
import RightBlackArrowIcon from '../../../assets/images/right-black-arrow.svg'
import SkillMeterIcon from '../../../assets/images/skill-meter.png'
import Time from '../../../assets/images/stop-watch.svg'
import TopicAnalysisIcon from '../../../assets/images/topic-analysis.png'
import { Loader } from '../../../components/Loader'
import { getCandidateReportThunk } from '../../../redux/exam/examActions'
import {
  getUserProctoringImages,
  getUserProctoringImagesZip,
} from '../../../redux/proctoring-exam/proctoringExamAction'
import { Store } from '../../../redux/rootType'
import { dispatch } from '../../../redux/store'
import { convertUtcToItcFormat, secondsToHms } from '../../../util/utils'
import DonutChart from './DonutChart'

// const candidateReportData = {
//   id: 784,
//   exam: {
//     id: 1576,
//     name: 'Test Update Exam',
//     questions_count: 50,
//     passing_marks: 30,
//     total_marks: 50,
//     duration: 10,
//     topper_score: 49,
//     average_score: 30,
//     average_time_taken: 10,
//     valid_from: '2023-06-13T16:06:28+05:30',
//     valid_to: '2023-06-13T16:08:28+05:30',
//     randomize_questions: false,
//     passing_percentage: 30,
//   },
//   topic_wise_score: [
//     {
//       id: 981,
//       total_questions: 10,
//       topic: {
//         id: 41,
//         subject: 25,
//         name: 'Aptitude',
//         status: 'A',
//       },
//       marks_obtained: '0.00',
//       total_marks: 0,
//       unattempted_questions: 10,
//       correct_answers: 0,
//       partially_correct_answers: 0,
//       incorrect_answers: 0,
//       total_gems: 200,
//       gems_earned: 0,
//     },
//   ],
//   user: {
//     id: 35,
//     first_name: 'avis',
//     last_name: 'g',
//     username: 'candidateavi1',
//     email: 'avinash1gurivelli+2@gmail.com',
//     photo: null,
//     mobile_number: '+917404987684',
//   },
//   skill_level: 'Beginner',
//   batch: {},
//   attempted_questions: 40,
//   unattempted_questions: 10,
//   correct_answers: 40,
//   partially_correct_answers: 20,
//   incorrect_answers: 10,
//   marks_obtained: '30.00',
//   total_marks: 50,
//   attempt_status: 'NA',
//   evaluation_status: null,
//   percentage: '30.00',
//   rank: null,
//   start_time: '2023-06-13T16:06:28.991726+05:30',
//   end_time: '2023-06-13T16:16:28.991726+05:30',
//   access_report: false,
//   total_gems: 200,
//   gems_earned: 0,
//   overall_feedback: null,
//   overall_feedback_submitted_at: '2023-06-13T16:06:28.991772+05:30',
//   submission_type: 'U',
//   screen_violation_count: null,
//   grade: 4,
//   user_env_details: 8613,
// }
const ExamReport = () => {
  const chartStyle = {
    width: 350,
  }
  const params = useParams()
  const candidateId = params['Id']

  const location = useLocation()
  const examName = location?.state?.examName || ''
  const examId = location?.state?.examId || ''
  const navKey = location?.state?.navKey || ''

  /**
   * ==================================================
   * Data from redux store
   * ==================================================
   */
  const candidateReportData = useSelector(
    (state: Store) => state?.examReducer?.candidateReportData
  )

  const isLoadingCandidateReportData = useSelector(
    (state: Store) => state?.examReducer?.isLoadingCandidateReportData
  )

  const isLoadingProctoring = useSelector(
    (state: Store) => state?.proctoringReducer?.isLoadingProctoring
  )
  const isLoadingZipProctoring = useSelector(
    (state: Store) => state?.proctoringReducer?.isLoadingZipProctoring
  )
  const userMovementData = useSelector(
    (state: Store) => state?.proctoringReducer?.userMovementData
  )
  const userMovementDataZip = useSelector(
    (state: Store) => state?.proctoringReducer?.userMovementDataZip
  )

  const radialChartOptions: {
    series: (string | number)[]
    options: ApexOptions
  } = {
    series: [parseInt(candidateReportData?.percentage) || 0],

    options: {
      chart: {
        // height: 550,
        type: 'radialBar',
        height: '100px',
      },
      grid: {
        padding: {
          top: -25,
          bottom: -29,
        },
      },
      plotOptions: {
        radialBar: {
          // startAngle: -135,
          // endAngle: 135,
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            value: {
              show: true,
              offsetY: -14,
              fontSize: '20',
              color: '#000000',
              fontWeight: 600,
            },
            name: {
              show: true,
              offsetY: 30,
              fontSize: '14',
              color: '#000000',
              fontWeight: 300,
            },
          },
        },
      },

      labels: [
        `Score ${parseInt(candidateReportData?.marks_obtained) || 0}/${
          candidateReportData?.total_marks || 0
        }`,
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
              height: 300,
            },
            legend: {
              display: false,
              position: 'bottom',
            },
          },
        },
      ],
    },
  }

  const radialChartOptionsScore: {
    series: (string | number)[]
    options: ApexOptions
  } = {
    series: [
      candidateReportData ? parseInt(candidateReportData?.marks_obtained) : 0,
    ],

    options: {
      chart: {
        // height: 550,
        type: 'radialBar',
        height: '100px',
      },
      grid: {
        padding: {
          top: -25,
          bottom: -29,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            value: {
              show: true,
              offsetY: -14,
              fontSize: '20',
              color: '#000000',
              fontWeight: 600,
              formatter: (val) => {
                return `${val} / ${
                  candidateReportData
                    ? parseInt(candidateReportData?.total_marks)
                    : 0
                }`
              },
            },
            name: {
              show: true,
              offsetY: 30,
              fontSize: '14',
              color: '#000000',
              fontWeight: 300,
            },
          },
        },
      },
      colors: ['#B0BD1D'],
      labels: [`Exam Score`],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
              height: 300,
            },
            legend: {
              display: false,
              position: 'bottom',
            },
          },
        },
      ],
    },
  }

  useEffect(() => {
    dispatch(getCandidateReportThunk(examId, +candidateId!))
    dispatch(getUserProctoringImages(examId, +candidateId!))
    dispatch(getUserProctoringImagesZip(examId, +candidateId!))
  }, [])

  return (
    <Container className="p-5 shadow-card-box">
      <div className="mb-3 pb-1">
        <span className="text-muted">Exam</span>
        <Image src={RightBlackArrowIcon} className="mx-2" />
        <Link to="/exams">
          <span className="text-muted">{navKey}</span>
        </Link>
        <Image src={RightBlackArrowIcon} className="mx-2" />
        <Link to={`/exam-details/${examId}`} state={{ tabKey: navKey }}>
          <span className="text-muted">{examName}</span>
        </Link>
        <Image src={RightBlackArrowIcon} className="mx-2" />
        <span className="text-black">Exam Report</span>
      </div>
      {isLoadingCandidateReportData ? (
        <Loader height="500px" />
      ) : (
        <div className="card">
          <div className="card-body p-4 mx-3 my-2">
            <div className="d-flex mb-4 pb-3 align-items-center">
              <Link
                to={`/exam-details/${examId}`}
                state={{ tabKey: navKey }}
                className="h4 d-block"
              >
                <IoMdArrowBack className="me-2" />
                Exam Report
              </Link>
              <span className="ms-auto light-primary-box">
                <Image src={ResumeIcon} className="me-1" />
                View Resume
              </span>
              <span className="ms-3 light-primary-box p-2">
                <Image src={BlueShareIcon} />
              </span>
              <span className="ms-3 light-primary-box p-2">
                <Image src={DownloadBlueIcon} />
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center pb-2">
              <div className="d-flex align-items-center">
                <Image src={ExamReporterIcon} />
                <div className="ms-3">
                  <h6 className="mb-1">
                    {`${candidateReportData?.user?.first_name} ${candidateReportData?.user?.last_name}`}
                  </h6>
                  <p className="mb-0 sub-title-clr">
                    {candidateReportData?.user?.role?.label} (
                    {candidateReportData?.user?.total_experience}{' '}
                    {candidateReportData?.user?.total_experience > 1
                      ? 'years'
                      : 'year'}
                    )
                  </p>
                </div>
              </div>
              <div className="vetical-saprater" />
              <div className="">
                <p className="mb-1 custom-text-clr fw-bold">Exam Name</p>
                <h6 className="mb-0 fw-normal">
                  {candidateReportData?.exam?.name}
                </h6>
              </div>
              <div className="">
                <p className="mb-1 custom-text-clr fw-bold">Questions</p>
                <h6 className="mb-0 fw-normal">
                  {candidateReportData?.exam?.questions_count}
                </h6>
              </div>
              <div className="">
                <p className="mb-1 custom-text-clr fw-bold">Attempted on </p>
                <h6 className="mb-0 fw-normal">
                  {moment(candidateReportData?.start_time).format(
                    'DD MMM, YYYY'
                  )}
                </h6>
              </div>
            </div>
            <hr className="my-4" />
            <h5 className="mb-4">Exam Report</h5>
            <Row lg={2} className="mb-4">
              <Col lg={5}>
                <div className="card page-content-card h-100">
                  <div className="card-header d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <img src={ExamScoreIcon} alt="Recent Exam" />
                      <h5 className="fw-semibold ms-12 mb-0">Exam Score</h5>
                    </div>
                  </div>
                  <div className="card-body d-flex align-items-center">
                    <Row className="align-items-center w-100">
                      <Col lg={8}>
                        {candidateReportData && (
                          <ReactApexChart
                            options={radialChartOptionsScore?.options}
                            series={
                              radialChartOptionsScore
                                ? (radialChartOptionsScore?.series as any)
                                : []
                            }
                            type="radialBar"
                            // height={255}
                            // width={500}
                          />
                        )}
                      </Col>
                      <Col lg={4}>
                        <div className="d-flex flex-column">
                          <div className="d-flex justify-content-between mb-2">
                            <h6 className="text-muted">Exam score</h6>
                            <h6 className="text-muted">
                              {candidateReportData?.exam?.total_marks || '-'}
                            </h6>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="text-muted">Average score</p>
                            <p>
                              {candidateReportData?.exam?.average_score?.toString() ||
                                '-'}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="text-muted">Topper score</p>
                            <p>
                              {candidateReportData?.exam?.topper_score?.toString() ||
                                '-'}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={7}>
                <div className="card page-content-card h-100">
                  <div className="card-header d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <img src={AnswerAnalysisIcon} alt="Recent Exam" />
                      <h5 className="fw-semibold ms-12 mb-0">
                        Answer Analysis
                      </h5>
                    </div>

                    <Link
                      to={`/view-answer`}
                      className="ms-auto fw-semibold"
                      state={{
                        examId: examId,
                        studentId: candidateId,
                        examName: examName,
                        navKey: navKey,
                      }}
                    >
                      View answers
                    </Link>
                  </div>
                  <div className="card-body d-flex align-items-center">
                    <Row className="align-items-center w-100">
                      <Col lg={8}>
                        {candidateReportData && (
                          <DonutChart
                            series={[
                              candidateReportData?.correct_answers || 0,
                              candidateReportData?.partially_correct_answers ||
                                0,
                              candidateReportData?.incorrect_answers || 0,
                              candidateReportData?.unattempted_questions || 0,
                            ]}
                            questionCount={
                              candidateReportData?.exam?.questions_count || 0
                            }
                            donutSize="80%"
                          />
                        )}
                      </Col>
                      <Col lg={4}>
                        <ul className="list-unstyled exam-count-legend text-black-100 m-0">
                          <li className="d-flex align-items-center">
                            <span
                              style={{ backgroundColor: '#4CAF50' }}
                              className="round-circle"
                            ></span>{' '}
                            Correct
                            <span className="text-body fw-semibold ms-auto">
                              {candidateReportData?.correct_answers}
                            </span>
                          </li>
                          <li className="d-flex align-items-center">
                            <span
                              style={{ backgroundColor: '#F0B357' }}
                              className="round-circle"
                            ></span>{' '}
                            Partially Correct
                            <span className="text-body fw-semibold ms-auto">
                              {candidateReportData?.partially_correct_answers}
                            </span>
                          </li>
                          <li className="d-flex align-items-center">
                            <span
                              style={{ backgroundColor: '#F24A4A' }}
                              className="round-circle"
                            ></span>{' '}
                            Incorrect
                            <span className="text-body fw-semibold ms-auto">
                              {candidateReportData?.incorrect_answers}
                            </span>
                          </li>
                          <li className="d-flex align-items-center">
                            <span
                              style={{ backgroundColor: '#E9E9E9' }}
                              className="round-circle"
                            ></span>{' '}
                            Unanswered
                            <span className="text-body fw-semibold ms-auto">
                              {candidateReportData?.unattempted_questions}
                            </span>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col lg={7}>
                <div className="card h-100">
                  <div className="card-header d-flex align-items-center">
                    <Image src={SkillMeterIcon} />
                    <h6 className="ms-2 ps-1 mb-0">Skill meter</h6>
                  </div>

                  <div className="card-body d-flex align-items-center">
                    <Row className="align-items-center w-100">
                      <Col lg={8} className="text-center">
                        {candidateReportData && (
                          <GaugeChart
                            id="gauge-chart5"
                            nrOfLevels={4}
                            arcsLength={[0.3, 0.4, 0.2, 0.1]}
                            arcWidth={0.12}
                            colors={[
                              '#F24A4A',
                              '#F0B357',
                              '#4CAF50',
                              '#219EBC',
                            ]}
                            percent={
                              parseInt(candidateReportData?.percentage) / 100
                            }
                            arcPadding={0}
                            cornerRadius={0}
                            style={chartStyle}
                            hideText={true}
                          />
                        )}
                        <p>
                          Score - {parseInt(candidateReportData?.percentage)}%
                        </p>
                      </Col>
                      <Col lg={4}>
                        <ul className="list-unstyled exam-count-legend text-black-100 m-0">
                          <li className="d-flex align-items-center">
                            <span
                              style={{ backgroundColor: '#F24A4A' }}
                              className="round-circle"
                            />{' '}
                            Beginner
                          </li>
                          <li className="d-flex align-items-center">
                            <span
                              style={{ backgroundColor: '#F0B357' }}
                              className="round-circle"
                            />{' '}
                            Average
                          </li>
                          <li className="d-flex align-items-center">
                            <span
                              style={{ backgroundColor: '#4CAF50' }}
                              className="round-circle"
                            />{' '}
                            Expert
                          </li>
                          <li className="d-flex align-items-center">
                            <span
                              style={{ backgroundColor: '#219EBC' }}
                              className="round-circle"
                            />{' '}
                            Master
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={5}>
                <div className="card h-100">
                  <div className="card-header d-flex align-items-center">
                    <Image src={ExamPerfomanceIcon} />
                    <h6 className="ms-2 ps-1 mb-0">Exam Performance</h6>
                  </div>
                  <div className="card-body d-flex align-items-center">
                    <Row className="align-items-center w-100">
                      <Col lg={8}>
                        {candidateReportData && (
                          <ReactApexChart
                            options={radialChartOptions?.options}
                            series={radialChartOptions?.series as any}
                            type="radialBar"
                            height={255}
                          />
                        )}
                      </Col>
                      <Col lg={4}>
                        <div className="d-flex flex-column text-center">
                          <div>
                            <h6 className="text-muted">Exam Duration</h6>
                            <p>
                              <strong>
                                {candidateReportData?.exam?.duration} min
                                {/* 10 min */}
                              </strong>
                            </p>
                          </div>
                          <div>
                            <h6 className="text-muted">Time taken</h6>
                            <p>
                              <strong>
                                {secondsToHms(
                                  moment(candidateReportData?.end_time).diff(
                                    candidateReportData?.start_time,
                                    'second'
                                  )
                                )}
                                {/* 12 */}
                              </strong>
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="card">
              <div className="card-header d-flex align-items-center">
                <Image src={TopicAnalysisIcon} />
                <h6 className="ms-2 ps-1 mb-0">Topic Analysis</h6>
              </div>
              <div className="card-body mx-5">
                <Row>
                  {candidateReportData?.topic_wise_score &&
                    candidateReportData?.topic_wise_score?.map((topicData) => {
                      return (
                        <Col>
                          <p className="d-flex justify-content-between">
                            <span>{topicData?.topic?.name}</span>
                            {/* <span>{topicData?.topic?.subject}%</span> */}
                            <span>
                              {(100 * parseFloat(topicData?.marks_obtained)) /
                                topicData?.total_marks}
                              %
                            </span>
                          </p>
                          <LinearProgress
                            className="mb-4"
                            variant="determinate"
                            value={
                              topicData?.total_marks
                                ? (100 *
                                    parseFloat(topicData?.marks_obtained)) /
                                  topicData?.total_marks
                                : 0
                            }
                            color="success"
                            classes={{ root: 'custom-progress' }}
                          />
                        </Col>
                      )
                    })}
                  {/* <Col>
                  <p className="d-flex justify-content-between">
                    <span>SQL</span>
                    <span>75%</span>
                  </p>
                  <LinearProgress
                    className="mb-4"
                    variant="determinate"
                    value={75}
                    color="success"
                    classes={{ root: 'custom-progress' }}
                  />
                </Col>
                <Col>
                  <p className="d-flex justify-content-between">
                    <span>React</span>
                    <span>55%</span>
                  </p>
                  <LinearProgress
                    className="mb-4"
                    variant="determinate"
                    value={55}
                    color="secondary"
                    classes={{ root: 'custom-progress' }}
                  />
                </Col> */}
                </Row>
                {/* <Row>
                <Col>
                  <p className="d-flex justify-content-between">
                    <span>Python</span>
                    <span>25%</span>
                  </p>
                  <LinearProgress
                    className="mb-4"
                    variant="determinate"
                    value={25}
                    color="secondary"
                    classes={{ root: 'custom-progress' }}
                  />
                </Col>
                <Col>
                  <p className="d-flex justify-content-between">
                    <span>React</span>
                    <span>55%</span>
                  </p>
                  <LinearProgress
                    className="mb-4"
                    variant="determinate"
                    value={55}
                    color="inherit"
                    classes={{ root: 'custom-progress' }}
                  />
                </Col>
              </Row> */}
              </div>
            </div>
            <div className="my-4 pt-4 pb-2 candidate-border">
              <div>
                <h5 className="mb-4 ml-0">Web Proctoring</h5>
                {/* <p>
                    <img src={Frame} alt="" className="me-2" />
                    Window Violation <span className="ms-3 fw-bold">2</span>
                  </p> */}
                <p>
                  <img src={Time} alt="" className="me-2" />
                  Violation Duration{' '}
                  <span className="ms-3 fw-bold">
                    {' '}
                    {((candidateReportData?.submission_type === 'P' &&
                      userMovementData?.length) ||
                      0) / 5}{' '}
                    {/* {candidateReportData?.screen_violation_count}  */}
                    Sec
                  </span>
                </p>
              </div>
            </div>

            <div>
              <h4>Image Proctoring</h4>
              <div className="d-flex align-items-center justify-content-between mt-4 mb-3">
                <p>
                  Violation Detected Images ( {userMovementData?.length || 0} )
                </p>
                {candidateReportData?.submission_type === 'P' &&
                  userMovementData?.length > 0 && (
                    <>
                      {userMovementDataZip?.zip_url ? (
                        <a
                          href={userMovementDataZip?.zip_url}
                          download={`${candidateReportData?.user?.first_name}_${
                            candidateReportData?.user?.last_name || ''
                          }`}
                          className="ms-3 light-primary-box p-2"
                        >
                          <img src={DownloadBlueIcon} alt="download" />
                        </a>
                      ) : (
                        <button
                          onClick={() => {
                            if (
                              examId &&
                              candidateId &&
                              !userMovementDataZip?.zip_url
                            ) {
                              getUserProctoringImagesZip(examId, candidateId)
                            }
                          }}
                          className="btn btn-secondary hover-image-download"
                        >
                          {isLoadingZipProctoring ? (
                            <i className="fas fa-spinner fa-spin"></i>
                          ) : (
                            <img src={DownloadBlueIcon} alt="download" />
                          )}
                        </button>
                      )}
                    </>
                  )}
              </div>
            </div>

            {/* {candidateReportData?.screen_violation_count ? ( */}
            <div>
              {isLoadingProctoring ? (
                <Loader />
              ) : candidateReportData?.submission_type == 'P' &&
                userMovementData?.length > 0 ? (
                <ImageList
                  sx={{ width: 'auto', height: 450 }}
                  cols={5}
                  gap={20}
                >
                  {/* {Array.from(Array(10).keys()).map((item, idx) => ( */}
                  {userMovementData?.map((item, idx) => (
                    <ImageListItem key={idx}>
                      <img
                        // src={Proc}
                        // srcSet={Proc}
                        src={item?.url}
                        srcSet={item?.url}
                        alt={'face'}
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title={item?.movement}
                        subtitle={convertUtcToItcFormat(`${item?.time}`)}
                        position="below"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              ) : (
                <div> No data found</div>
              )}
            </div>
          </div>
        </div>
      )}
    </Container>
  )
}

export default ExamReport
