import { Action, Store } from 'redux'
import { ThunkAction } from 'redux-thunk'

import {
  BUSINESS_CATEGORY_URL,
  CITY_URL,
  COMPANY_BOOKMARK_URL,
  CONNECTION_REQUEST_UPDATE_URL,
  GET_COMPANIES_LIST_URL,
  GET_COMPANY_ACTIVE_JD_URL,
  GET_COMPANY_DETAILS_URL,
  SENT_CONNECTION_REQUEST_URL,
} from '../../service/apiEndPoints'
import { apiCall, METHOD } from '../../service/baseApiCall'
import { errorToast, successToast } from '../../util/utils'
import {
  BUSINESS_CATEGORY_LIST,
  CITY_LIST,
  COMPANY_BOOKMARK,
  CONNECTION_REQUEST_UPDATE,
  GET_COMPANIES_LIST,
  GET_COMPANY_ACTIVE_JD,
  GET_COMPANY_DETAILS,
  SENT_CONNECTION_REQUEST,
} from './companiesConst'

/**
 * Get companies list
 * @returns
 */
export const getCompaniesListThunk =
  (
    search: string,
    primary_address: any,
    business_category: any,
    is_bookmark?: boolean,
    is_client?: boolean,
    connection_status?: string,
    connection_reqeust_by?: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_COMPANIES_LIST.GET_COMPANIES_LIST_REQUEST,
    })
    apiCall(
      GET_COMPANIES_LIST_URL,
      (res) => {
        dispatch({
          type: GET_COMPANIES_LIST.GET_COMPANIES_LIST_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_COMPANIES_LIST.GET_COMPANIES_LIST_ERROR,
        })
      },
      {
        params: {
          search: search || undefined,
          is_bookmark: is_bookmark || undefined,
          is_client: is_client || undefined,
          connection_status: connection_status ?? undefined,
          connection_reqeust_by: connection_reqeust_by ?? undefined,
          primary_address: primary_address || undefined,
          business_category: business_category || undefined,
        },
      },
      METHOD.GET,
      { showToast: false }
    )
  }

/**
 * get company details
 * @param compId
 * @returns
 */
export const getCompanyDetailsThunk =
  (
    compId: string | number | undefined
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_COMPANY_DETAILS.GET_COMPANY_DETAILS_REQUEST,
    })
    apiCall(
      GET_COMPANY_DETAILS_URL(compId),
      (res) => {
        dispatch({
          type: GET_COMPANY_DETAILS.GET_COMPANY_DETAILS_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_COMPANY_DETAILS.GET_COMPANY_DETAILS_ERROR,
        })
      },
      {},
      METHOD.GET,
      { showToast: false }
    )
  }

/**
 * get company active jd
 * @param compId
 * @returns
 */
export const getCompanyActiveJdThunk =
  (
    compId: string | number | undefined,
    status: string
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_COMPANY_ACTIVE_JD.GET_COMPANY_ACTIVE_JD_REQUEST,
    })
    apiCall(
      GET_COMPANY_ACTIVE_JD_URL,
      (res) => {
        dispatch({
          type: GET_COMPANY_ACTIVE_JD.GET_COMPANY_ACTIVE_JD_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: GET_COMPANY_ACTIVE_JD.GET_COMPANY_ACTIVE_JD_ERROR,
        })
      },
      {
        params: {
          institute: compId,
          status: status,
        },
      },
      METHOD.GET,
      { showToast: false }
    )
  }

/**
 * Company bookmark
 * @param compId
 * @param is_bookmark
 * @returns
 */
export const companyBookmarkThunk =
  (
    compId: string | number | undefined,
    is_bookmark: boolean,
    updatedDataFun
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: COMPANY_BOOKMARK.COMPANY_BOOKMARK_REQUEST,
    })
    apiCall(
      COMPANY_BOOKMARK_URL,
      () => {
        dispatch({
          type: COMPANY_BOOKMARK.COMPANY_BOOKMARK_SUCCESS,
        })
        updatedDataFun()
      },
      () => {
        dispatch({
          type: COMPANY_BOOKMARK.COMPANY_BOOKMARK_ERROR,
        })
      },
      {
        institute: compId,
        is_bookmark: is_bookmark,
      },
      METHOD.POST,
      { showToast: false }
    )
  }

/**
 * connection request
 * @param compId
 * @param agencyId
 * @param requested_by
 * @returns
 */
export const sentConnectionRequestThunk =
  (
    compId: string | number | undefined,
    agencyId: string | number,
    requested_by: string,
    values: any,
    showSuccessModal
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: SENT_CONNECTION_REQUEST.SENT_CONNECTION_REQUEST_REQUEST,
    })
    apiCall(
      SENT_CONNECTION_REQUEST_URL,
      () => {
        dispatch({
          type: SENT_CONNECTION_REQUEST.SENT_CONNECTION_REQUEST_SUCCESS,
        })
        showSuccessModal()
      },
      () => {
        dispatch({
          type: SENT_CONNECTION_REQUEST.SENT_CONNECTION_REQUEST_ERROR,
        })
      },
      {
        institute: compId,
        agency: agencyId,
        requested_by: requested_by,
        agency_request_details:
          requested_by === 'A' ? JSON.stringify(values) : undefined,
      },
      METHOD.POST,
      { showToast: false }
    )
  }

/**
 * update connection request
 * @param connectionId
 * @param status
 * @param updatedDataFun
 * @returns
 */
export const connectionRequestUpdateThunk =
  (
    connectionId: number,
    status: string,
    updatedDataFun
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CONNECTION_REQUEST_UPDATE.CONNECTION_REQUEST_UPDATE_REQUEST,
    })
    apiCall(
      CONNECTION_REQUEST_UPDATE_URL(connectionId),
      () => {
        dispatch({
          type: CONNECTION_REQUEST_UPDATE.CONNECTION_REQUEST_UPDATE_SUCCESS,
        })
        updatedDataFun()
      },
      () => {
        dispatch({
          type: CONNECTION_REQUEST_UPDATE.CONNECTION_REQUEST_UPDATE_ERROR,
        })
      },
      {
        status: status,
      },
      METHOD.PATCH,
      { showToast: false }
    )
  }

/**
 * business category list
 * @returns
 */
export const getBusinessCategoryListThunk =
  (): ThunkAction<void, Store, unknown, Action<string>> => (dispatch) => {
    dispatch({
      type: BUSINESS_CATEGORY_LIST.BUSINESS_CATEGORY_LIST_REQUEST,
    })
    apiCall(
      BUSINESS_CATEGORY_URL,
      (res) => {
        dispatch({
          type: BUSINESS_CATEGORY_LIST.BUSINESS_CATEGORY_LIST_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: BUSINESS_CATEGORY_LIST.BUSINESS_CATEGORY_LIST_ERROR,
        })
      },
      {},
      METHOD.GET,
      { showToast: false }
    )
  }

/**
 * city list
 * @param isInstitute
 * @returns
 */
export const getCityListThunk =
  (isInstitute: boolean): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: CITY_LIST.CITY_LIST_REQUEST,
    })
    apiCall(
      CITY_URL,
      (res) => {
        dispatch({
          type: CITY_LIST.CITY_LIST_SUCCESS,
          payload: res?.data,
        })
      },
      () => {
        dispatch({
          type: CITY_LIST.CITY_LIST_ERROR,
        })
      },
      {
        params: {
          is_institute: isInstitute,
        },
      },
      METHOD.GET,
      { showToast: false }
    )
  }
