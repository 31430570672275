import React, { useState } from 'react'
import {
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Modal,
  Nav,
  Row,
  Tab,
  Table,
} from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { Link } from 'react-router-dom'

import DeleteAction from '../../../assets/images/delete-action.svg'
import Editicon from '../../../assets/images/edit-action.svg'
import EmailIcon from '../../../assets/images/email-icon.svg'
import IntegrationIcon from '../../../assets/images/integration-icon.svg'
import MultiplePerson from '../../../assets/images/multiple-person.svg'
import NotificationSetting from '../../../assets/images/notification-setting.svg'
import SearchIcon from '../../../assets/images/search-icon.svg'

const SystemSetting = () => {
  const [show2, setShow2] = useState(false)

  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  return (
    <>
      <Container className="px-4 py-5 shadow-card-box">
        <div className="d-flex align-items-center mb-4 pb-2">
          <Link to="/" className="h4 mb-0">
            <IoMdArrowBack className="me-2" /> Settings
          </Link>
          <InputGroup className="ms-auto search-box">
            <InputGroup.Text id="basic-addon1">
              <Image src={SearchIcon} />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          <button onClick={handleShow2} className="btn btn-primary ms-3">
            Add Email Template
          </button>
        </div>
        <div className="verical-tab mb-5">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col lg={4}>
                <div className="card">
                  <div className="card-body">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          <Image src={NotificationSetting} className="me-2" />{' '}
                          Notification Settings
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link className="me-2" eventKey="second">
                          <Image src={EmailIcon} className="me-2" /> Email
                          Template
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link className="mb-0" eventKey="third">
                          <Image src={IntegrationIcon} className="me-2" />{' '}
                          Integrations
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className="card">
                  <div className="card-body p-4">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <h5 className="mb-4">Notification Settings</h5>
                        <div className="table-responsive">
                          <Table bordered hover className="overflow-scroll">
                            <thead>
                              <tr className="border-0">
                                <th>Category </th>
                                <th>Activity Type</th>
                                <th>In-App</th>
                                <th>Mail</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>General Updates</td>
                                <td className="Gray26">
                                  Daily Summary <br />
                                  <br /> Weekly Digest
                                </td>
                                <td>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexSwitchCheckDefault"
                                    ></label>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault1"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexSwitchCheckDefault1"
                                    ></label>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Recruitment Activities</td>
                                <td className="Gray26">
                                  Job Posting Updates
                                  <br />
                                  <br />
                                  Applications Status Updates
                                  <br />
                                  <br />
                                  Shortlisted Candidates
                                </td>
                                <td>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault2"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexSwitchCheckDefault2"
                                    ></label>
                                  </div>
                                </td>
                                <td>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexSwitchCheckDefault3"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexSwitchCheckDefault3"
                                    ></label>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <h5 className="mb-4">My Template</h5>
                        <div className="table-responsive mb-5">
                          <Table bordered hover className="overflow-scroll">
                            <thead>
                              <tr className="border-0">
                                <th>Name</th>
                                <th>Subject</th>
                                <th>Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <span className="text-primary">
                                    Assessment Invite{' '}
                                    <Image
                                      src={MultiplePerson}
                                      className="ms-1"
                                    />{' '}
                                  </span>
                                </td>
                                <td>Welcome to Java Assessment </td>
                                <td>Candidate Email </td>
                                <td>
                                  <Image src={Editicon} />{' '}
                                  <Image className="ms-2" src={DeleteAction} />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <h5 className="mb-4">Organisation’s Template</h5>
                        <div className="table-responsive">
                          <Table bordered hover className="overflow-scroll">
                            <thead>
                              <tr className="border-0">
                                <th>Name</th>
                                <th>Subject</th>
                                <th>Type</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <span className="text-primary">
                                    Assessment Invite{' '}
                                  </span>
                                </td>
                                <td>Welcome to Java Assessment </td>
                                <td>Candidate Email </td>
                                <td>
                                  <Image src={Editicon} />{' '}
                                  <Image className="ms-2" src={DeleteAction} />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                  <div className="card-footer p-4 text-end">
                    <button className="btn btn-light">Reset</button>
                    <button className="btn btn-primary ms-3">
                      Save Changes
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Add Email Template</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body py-4">
          <Row lg={2}>
            <Col>
              <Form.Group
                className="form-group"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Template Name</Form.Label>
                <Form.Control
                  type="text"
                  value={'Assessment Invite '}
                  name="Batch name for exam"
                  placeholder="Batch name for exam"
                  className="form-control"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Label>Template Type</Form.Label>
              <Form.Select aria-label="Default select example">
                <option value="1">Candidate Email</option>
                <option value="2">Candidate Email</option>
                <option value="3">Candidate Email</option>
              </Form.Select>
            </Col>
          </Row>
          <Form.Group
            className="form-group"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Subject</Form.Label>
            <textarea
              className="form-control"
              rows={3}
              value={
                'You’ve been assigned an exam. Kindly use your previous/old credentials to login. Good Luck!'
              }
              style={{ resize: 'none' }}
              cols={2}
            ></textarea>
          </Form.Group>
          <Form.Group
            className="form-group"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Body</Form.Label>
            <textarea
              className="form-control"
              rows={5}
              value={'Potential candidate.'}
              style={{ resize: 'none' }}
              cols={8}
            ></textarea>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Form.Check
            type={'checkbox'}
            id={`5`}
            label={`Share template with everyone`}
          />
          <button
            onClick={handleClose2}
            className="btn  btn-outline-dark px-4 ms-auto"
            type="button"
          >
            Cancel
          </button>
          <button className="btn  btn-primary px-4" type="button">
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SystemSetting
