import { ImageList, ImageListItem, Pagination } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import RightBlackArrowIcon from '../../../assets/images/right-black-arrow.svg'
import { Loader } from '../../../components/Loader'
import { getCandidateSubmissionReportListThunk } from '../../../redux/exam/examActions'
import { Store } from '../../../redux/rootType'
import { dispatch } from '../../../redux/store'
import { dangerouslySetHTML, secondsToHms } from '../../../util/utils'
import ImageModal from './exam_detail_component/ImageModal'

const answerStatus = {
  incorrect: 'I',
  correct: 'C',
  partiallyCorrect: 'PC',
}
const questionStatus = {
  answered: 'A',
  unanswered: 'U',
  answeredReview: 'AR',
  unansweredReview: 'NR',
  activeQuestion: 'active',
  review: 'R',
}
const getAttemptClass = (status: string) => {
  switch (status) {
    case answerStatus.correct:
      return 'green'
    case answerStatus.incorrect:
      return 'danger'
    case answerStatus.partiallyCorrect:
      return 'warning'
    case questionStatus.unanswered:
      return 'unattempted'
    default:
      return ''
  }
}
const examReport: any = {
  id: 6841,
  exam: {
    id: 3231,
    name: 'image exam-6',
    questions_count: 10,
    passing_marks: 16,
    total_marks: 41,
    duration: 40,
    topper_score: null,
    average_score: null,
    average_time_taken: null,
    valid_from: '2024-02-28T12:35:00+05:30',
    valid_to: '2024-02-28T13:00:00+05:30',
    randomize_questions: false,
    passing_percentage: '40.00',
  },
  user: {
    id: 143,
    username: 'kush',
    first_name: 'kush',
    last_name: 'suthar',
    email: 'kush.s+10@peerbits.com',
    photo: null,
    avatar: null,
    thumbnail: null,
    role: {
      id: 13,
      name: 'external-candidate',
      label: 'External Candidate',
      role_type: null,
    },
    status: 'Corporate',
    institute: null,
    departments: [],
    mobile_number: null,
    is_active: true,
    is_deleted: false,
    date_joined: '2023-03-24T12:01:28+05:30',
    educations: [],
  },
  user_answer_submission: [
    {
      id: 260097,
      question: {
        id: 995031,
        subject: [
          {
            id: 216,
            name: 'Accounts',
          },
          {
            id: 25,
            name: 'Aptitude',
          },
        ],
        exam_type: [
          {
            id: 12,
            name: 'Pre-Interview Screening',
            exam_category: 'CR',
          },
        ],
        grade: [],
        topic: [
          {
            id: 41,
            subject: 25,
            name: 'Aptitude',
          },
          {
            id: 8227,
            subject: 216,
            name: 'Accounting Process-Rectification Of Error',
          },
        ],
        sub_topic: [],
        reasoning_type: null,
        academic_standard: [],
        created_by: {
          id: 339,
          username: 'avi.nashhr4455',
          email: 'avinash1gurivelli+1290@gmail.com',
          mobile_number: '8142086796',
          first_name: 'avi',
          last_name: 'nashhr',
          role: 'HR Head',
          gender: null,
          grade: null,
          dob: null,
          section: null,
          fathers_name: null,
          mothers_name: null,
          guardian_contact_number: null,
          total_gems: null,
          rank: null,
          daily_challenge: null,
          school: null,
          qualification: null,
          experience: null,
          current_student_count: null,
          total_student_count: null,
          area_of_expertise: null,
          is_superteacher: null,
          institute: {
            id: 40,
            name: 'branch hyd testing institute',
            is_editable: false,
          },
          address: {
            id: 49,
            city: {
              id: 82,
              name: 'Srikakulam',
            },
            state: {
              id: 4,
              name: 'Andhra Pradesh',
            },
            country: {
              id: 1,
              name: 'India',
            },
            address_line_1: null,
            address_line_2: null,
            pincode: 532001,
          },
          photo: 'http://api.prep.study/media/profile/339/profile.jpg',
          avatar: null,
          thumbnail: null,
          has_completed_profile: false,
          status: 'Corporate',
          is_active: true,
          is_deleted: false,
          badge: null,
          educations: [
            {
              id: 471,
              qualification: {
                id: 3,
                name: 'B.Tech',
                type: 'B',
              },
              college: 'NIT calicut',
              status: 'A',
              grade_status: 'C',
              grade_value: '0.00',
              started_year: null,
              completed_year: 2020,
              created_at: '2024-02-12T18:37:49.449513+05:30',
            },
          ],
        },
        updated_by: null,
        institute: {
          id: 40,
          parent: {
            id: 40,
            name: 'branch hyd testing institute',
          },
          name: 'branch hyd testing institute',
          logo: null,
          tag_line: null,
          description: '',
          primary_address: {
            address_line_1: "JP's Royal Residency, H.No. 8-1-68/3/117, Flat No",
            address_line_2: '3433',
            city: {
              id: 2,
              name: 'Mumbai',
            },
            state: {
              id: 2,
              name: 'Maharashtra',
            },
            country: {
              id: 1,
              name: 'India',
            },
            pin: 532000001,
            contact_address:
              " JP's Royal Residency, H.No. 8-1-68/3/117, Flat No 3433 Mumbai Maharashtra India 532000001",
          },
          alternate_address: null,
          contact_number: '0814086794',
          alternate_number: null,
          email: 'avinash1gurivelli+127@gmail.com',
          business_category: {
            id: 1,
            business_type: {
              id: 1,
              name: 'Private',
            },
            name: 'Information Technology',
          },
          member_count: 12,
          email_prefix: null,
        },
        owner: null,
        body_images: [
          {
            id: 121336,
            image: '/media/download_1.png',
            question: 995031,
          },
        ],
        options: [
          {
            id: 3932183,
            image: '/media/profile1.jpg',
            text: 'True',
            is_correct: false,
            question: 995031,
          },
          {
            id: 3932184,
            image: '/media/mumbai.jpeg',
            text: 'False',
            is_correct: true,
            question: 995031,
          },
        ],
        question_type: 'TF',
        status: 'A',
        visibility: 'PUBLIC',
        cost: '0.00',
        language: 'en',
        score: 1,
        text: 'bQuestion image',
        hint: null,
        explanation: null,
        difficulty_level: 'M',
        time_to_answer: 60,
        created_at: '2024-02-15T17:45:41.878500+05:30',
        updated_at: '2024-02-15T17:45:41.878525+05:30',
        is_copyrighted: null,
        code: null,
      },
      question_status: 'A',
      answer_status: 'I',
      duration: 0,
      marks_obtained: 0,
      evaluation_status: null,
      question_marks: 1,
      essay_answer: null,
      time_taken: 34,
      total_gems: 0,
      is_optional: false,
      gems_earned: 0,
      feedback: null,
      feedback_submitted_at: '2024-02-28T12:35:14.974870+05:30',
      user_exam_submission_details: 6841,
      options: [3932183],
    },
    {
      id: 260098,
      question: {
        id: 949539,
        subject: [
          {
            id: 216,
            name: 'Accounts',
          },
        ],
        exam_type: [
          {
            id: 12,
            name: 'Pre-Interview Screening',
            exam_category: 'CR',
          },
        ],
        grade: [],
        topic: [
          {
            id: 8223,
            subject: 216,
            name: 'Abatement Of Pollution',
          },
        ],
        sub_topic: [],
        reasoning_type: {
          id: 2,
          title: 'Numerical',
          description: '',
        },
        academic_standard: [],
        created_by: {
          id: 225901,
          username: 'akash2379',
          email: 'akash.b+1100@peerbits.com',
          mobile_number: '4546788686',
          first_name: 'AkashHrProd',
          last_name: 'Burde',
          role: 'HR Head',
          gender: 'MALE',
          grade: null,
          dob: null,
          section: null,
          fathers_name: null,
          mothers_name: null,
          guardian_contact_number: null,
          total_gems: null,
          rank: null,
          daily_challenge: null,
          school: null,
          qualification: null,
          experience: null,
          current_student_count: null,
          total_student_count: null,
          area_of_expertise: null,
          is_superteacher: null,
          institute: {
            id: 10,
            name: 'Crash Data System',
            is_editable: false,
          },
          address: {
            id: 48,
            city: {
              id: 180,
              name: 'Chandigarh',
            },
            state: {
              id: 8,
              name: 'Chandigarh',
            },
            country: {
              id: 1,
              name: 'India',
            },
            address_line_1: null,
            address_line_2: null,
            pincode: 440017,
          },
          photo: 'http://api.prep.study/media/profile/225901/profile.jpg',
          avatar: null,
          thumbnail: null,
          has_completed_profile: false,
          status: 'Corporate',
          is_active: true,
          is_deleted: false,
          badge: null,
          educations: [
            {
              id: 404,
              qualification: {
                id: 3,
                name: 'B.Tech',
                type: 'B',
              },
              college: 'Test',
              status: 'A',
              grade_status: 'C',
              grade_value: '0.00',
              started_year: null,
              completed_year: 2018,
              created_at: '2023-11-30T18:28:38.678863+05:30',
            },
          ],
        },
        updated_by: {
          id: 225901,
          username: 'akash2379',
          email: 'akash.b+1100@peerbits.com',
          mobile_number: '4546788686',
          first_name: 'AkashHrProd',
          last_name: 'Burde',
          role: 'HR Head',
          gender: 'MALE',
          grade: null,
          dob: null,
          section: null,
          fathers_name: null,
          mothers_name: null,
          guardian_contact_number: null,
          total_gems: null,
          rank: null,
          daily_challenge: null,
          school: null,
          qualification: null,
          experience: null,
          current_student_count: null,
          total_student_count: null,
          area_of_expertise: null,
          is_superteacher: null,
          institute: {
            id: 10,
            name: 'Crash Data System',
            is_editable: false,
          },
          address: {
            id: 48,
            city: {
              id: 180,
              name: 'Chandigarh',
            },
            state: {
              id: 8,
              name: 'Chandigarh',
            },
            country: {
              id: 1,
              name: 'India',
            },
            address_line_1: null,
            address_line_2: null,
            pincode: 440017,
          },
          photo: 'http://api.prep.study/media/profile/225901/profile.jpg',
          avatar: null,
          thumbnail: null,
          has_completed_profile: false,
          status: 'Corporate',
          is_active: true,
          is_deleted: false,
          badge: null,
          educations: [
            {
              id: 404,
              qualification: {
                id: 3,
                name: 'B.Tech',
                type: 'B',
              },
              college: 'Test',
              status: 'A',
              grade_status: 'C',
              grade_value: '0.00',
              started_year: null,
              completed_year: 2018,
              created_at: '2023-11-30T18:28:38.678863+05:30',
            },
          ],
        },
        institute: {
          id: 10,
          parent: {
            id: 10,
            name: 'Crash Data System',
          },
          name: 'Crash Data System',
          logo: '/media/profile/10/profile_oQicsqk.jpg',
          tag_line: 'Tagline12',
          description: 'sdfasfaf',
          primary_address: {
            address_line_1: 'Tetjh',
            address_line_2: 'Test',
            city: {
              id: 144,
              name: 'Aurangabad',
            },
            state: {
              id: 7,
              name: 'Bihar',
            },
            country: {
              id: 1,
              name: 'India',
            },
            pin: 380013,
            contact_address: ' Tetjh Test Aurangabad Bihar India 380013',
          },
          alternate_address: {
            address_line_1: 'Ahemdabad2',
            address_line_2: '',
            city: {
              id: 228,
              name: 'North Goa',
            },
            state: {
              id: 12,
              name: 'Goa',
            },
            country: {
              id: 1,
              name: 'India',
            },
            pin: 301223,
            contact_address: ' Ahemdabad2 North Goa Goa India 301223',
          },
          contact_number: '9876544545',
          alternate_number: null,
          email: 'kush.s+118@peerbits.com',
          business_category: {
            id: 1,
            business_type: {
              id: 1,
              name: 'Private',
            },
            name: 'Information Technology',
          },
          member_count: 41,
          email_prefix: 'Crash Data System',
        },
        owner: null,
        body_images: [],
        options: [
          {
            id: 3751049,
            image: null,
            text: 'A',
            is_correct: true,
            question: 949539,
          },
          {
            id: 3751050,
            image: null,
            text: 'B',
            is_correct: false,
            question: 949539,
          },
          {
            id: 3751051,
            image: null,
            text: 'C',
            is_correct: false,
            question: 949539,
          },
          {
            id: 3751052,
            image: null,
            text: 'D',
            is_correct: false,
            question: 949539,
          },
        ],
        question_type: 'MCQ',
        status: 'A',
        visibility: 'PUBLIC',
        cost: '0.00',
        language: 'en',
        score: 10,
        text: 'What is main role of Quality analyst123456?',
        hint: 'QA',
        explanation: 'Test',
        difficulty_level: 'M',
        time_to_answer: 120,
        created_at: '2023-08-16T10:36:42.651833+05:30',
        updated_at: '2023-08-16T16:57:41.443555+05:30',
        is_copyrighted: null,
        code: null,
      },
      question_status: 'R',
      answer_status: 'I',
      duration: 0,
      marks_obtained: 0,
      evaluation_status: null,
      question_marks: 10,
      essay_answer: null,
      time_taken: 9,
      total_gems: 0,
      is_optional: false,
      gems_earned: 0,
      feedback: null,
      feedback_submitted_at: '2024-02-28T12:35:18.204636+05:30',
      user_exam_submission_details: 6841,
      options: [3751051],
    },
    {
      id: 260099,
      question: {
        id: 967007,
        subject: [
          {
            id: 25,
            name: 'Aptitude',
          },
        ],
        exam_type: [
          {
            id: 12,
            name: 'Pre-Interview Screening',
            exam_category: 'CR',
          },
        ],
        grade: [],
        topic: [
          {
            id: 12496,
            subject: 25,
            name: 'Reading Comprehension',
          },
          {
            id: 12497,
            subject: 25,
            name: 'Logical Reasoning',
          },
          {
            id: 12498,
            subject: 25,
            name: 'Programming Fundamental',
          },
        ],
        sub_topic: [],
        reasoning_type: null,
        academic_standard: [],
        created_by: {
          id: 226795,
          username: 'Shettigar.Vaishali5030',
          email: 'vaishalishettigar+12@prep.study',
          mobile_number: '9892969584',
          first_name: 'Vaishali',
          last_name: 'Shettigar',
          role: 'External Candidate',
          gender: null,
          grade: null,
          dob: null,
          section: null,
          fathers_name: null,
          mothers_name: null,
          guardian_contact_number: null,
          total_gems: null,
          rank: null,
          daily_challenge: null,
          school: null,
          qualification: null,
          experience: null,
          current_student_count: null,
          total_student_count: null,
          area_of_expertise: null,
          is_superteacher: null,
          institute: null,
          address: null,
          photo: null,
          avatar: null,
          thumbnail: null,
          has_completed_profile: false,
          status: 'Corporate',
          is_active: true,
          is_deleted: false,
          badge: null,
          educations: [],
        },
        updated_by: null,
        institute: null,
        owner: null,
        body_images: [],
        options: [
          {
            id: 3820471,
            image: null,
            text: '13/90',
            is_correct: false,
            question: 967007,
          },
          {
            id: 3820472,
            image: null,
            text: '12/90',
            is_correct: false,
            question: 967007,
          },
          {
            id: 3820473,
            image: null,
            text: '78/90',
            is_correct: false,
            question: 967007,
          },
          {
            id: 3820474,
            image: null,
            text: '77/90',
            is_correct: true,
            question: 967007,
          },
        ],
        question_type: 'MCQ',
        status: 'A',
        visibility: 'PUBLIC',
        cost: '0.00',
        language: 'en',
        score: 2,
        text: 'Out of all the 2-digit integers between 1 and 100, a 2-digit number has to be selected at random. What is the probability that the selected number is not divisible by 7?',
        hint: '',
        explanation: '',
        difficulty_level: 'E',
        time_to_answer: 60,
        created_at: '2023-10-17T10:38:28.092871+05:30',
        updated_at: '2023-10-17T10:39:01.925576+05:30',
        is_copyrighted: false,
        code: null,
      },
      question_status: 'A',
      answer_status: 'I',
      duration: 0,
      marks_obtained: 0,
      evaluation_status: null,
      question_marks: 2,
      essay_answer: null,
      time_taken: 2,
      total_gems: 0,
      is_optional: false,
      gems_earned: 0,
      feedback: null,
      feedback_submitted_at: '2024-02-28T12:35:19.864747+05:30',
      user_exam_submission_details: 6841,
      options: [3820471],
    },
    {
      id: 260100,
      question: {
        id: 24946,
        subject: [
          {
            id: 25,
            name: 'Aptitude',
          },
        ],
        exam_type: [
          {
            id: 6,
            name: 'Human Resource Management',
            exam_category: 'CR',
          },
          {
            id: 7,
            name: 'Computer Applications',
            exam_category: 'CR',
          },
          {
            id: 8,
            name: 'Sales',
            exam_category: 'CR',
          },
          {
            id: 9,
            name: 'Marketing',
            exam_category: 'CR',
          },
          {
            id: 10,
            name: 'Operations',
            exam_category: 'CR',
          },
          {
            id: 11,
            name: 'Technology',
            exam_category: 'CR',
          },
          {
            id: 12,
            name: 'Pre-Interview Screening',
            exam_category: 'CR',
          },
          {
            id: 13,
            name: 'Personality Assement for External Candidate',
            exam_category: 'CR',
          },
          {
            id: 14,
            name: 'Personality Assement for Internal Candidate',
            exam_category: 'CR',
          },
          {
            id: 15,
            name: 'Campus Hiring',
            exam_category: 'CR',
          },
          {
            id: 16,
            name: 'Reskilling Internal Teams',
            exam_category: 'CR',
          },
          {
            id: 5,
            name: 'Computer languages',
            exam_category: 'CR',
          },
        ],
        grade: [],
        topic: [
          {
            id: 60,
            subject: 25,
            name: 'Problems On Trains',
          },
        ],
        sub_topic: [],
        reasoning_type: null,
        academic_standard: [],
        created_by: {
          id: 54,
          username: 'i-candidateavi6',
          email: 'avinash1gurivelli+8@gmail.com',
          mobile_number: null,
          first_name: 'avi',
          last_name: 'nash',
          role: 'Internal Candidate',
          gender: null,
          grade: null,
          dob: null,
          section: null,
          fathers_name: null,
          mothers_name: null,
          guardian_contact_number: null,
          total_gems: null,
          rank: null,
          daily_challenge: null,
          school: null,
          qualification: null,
          experience: null,
          current_student_count: null,
          total_student_count: null,
          area_of_expertise: null,
          is_superteacher: null,
          institute: null,
          address: null,
          photo: null,
          avatar: null,
          thumbnail: null,
          has_completed_profile: false,
          status: null,
          is_active: true,
          is_deleted: false,
          badge: null,
          educations: null,
        },
        updated_by: null,
        institute: null,
        owner: null,
        body_images: [],
        options: [
          {
            id: 72399,
            image: null,
            text: 'p+q+r+s=w+𝓍+𝒴+𝓏',
            is_correct: true,
            question: 24946,
          },
          {
            id: 72400,
            image: null,
            text: 'p+q+r+s&ltw+𝓍+𝒴+𝓏',
            is_correct: false,
            question: 24946,
          },
          {
            id: 72401,
            image: null,
            text: 'p+q+r+s&gtw+𝓍+𝒴+𝓏',
            is_correct: false,
            question: 24946,
          },
          {
            id: 72402,
            image: null,
            text: 'Either (B) or (C)',
            is_correct: false,
            question: 24946,
          },
        ],
        question_type: 'MCQ',
        status: 'A',
        visibility: 'PUBLIC',
        cost: '0.00',
        language: 'en',
        score: 4,
        text: 'ABCD and MNOP are quadrilaterals as shown in the figure.\n; ;\n;Which of the following is correct?',
        hint: null,
        explanation: null,
        difficulty_level: 'D',
        time_to_answer: 45,
        created_at: '2023-05-07T08:08:26.036678+05:30',
        updated_at: '2023-05-30T11:00:49.381359+05:30',
        is_copyrighted: null,
        code: null,
      },
      question_status: 'A',
      answer_status: 'I',
      duration: 0,
      marks_obtained: 0,
      evaluation_status: null,
      question_marks: 4,
      essay_answer: null,
      time_taken: 19,
      total_gems: 0,
      is_optional: false,
      gems_earned: 0,
      feedback: null,
      feedback_submitted_at: '2024-02-28T12:35:24.074131+05:30',
      user_exam_submission_details: 6841,
      options: [72401],
    },
    {
      id: 260101,
      question: {
        id: 24945,
        subject: [
          {
            id: 25,
            name: 'Aptitude',
          },
        ],
        exam_type: [
          {
            id: 6,
            name: 'Human Resource Management',
            exam_category: 'CR',
          },
          {
            id: 7,
            name: 'Computer Applications',
            exam_category: 'CR',
          },
          {
            id: 8,
            name: 'Sales',
            exam_category: 'CR',
          },
          {
            id: 9,
            name: 'Marketing',
            exam_category: 'CR',
          },
          {
            id: 10,
            name: 'Operations',
            exam_category: 'CR',
          },
          {
            id: 11,
            name: 'Technology',
            exam_category: 'CR',
          },
          {
            id: 12,
            name: 'Pre-Interview Screening',
            exam_category: 'CR',
          },
          {
            id: 13,
            name: 'Personality Assement for External Candidate',
            exam_category: 'CR',
          },
          {
            id: 14,
            name: 'Personality Assement for Internal Candidate',
            exam_category: 'CR',
          },
          {
            id: 15,
            name: 'Campus Hiring',
            exam_category: 'CR',
          },
          {
            id: 16,
            name: 'Reskilling Internal Teams',
            exam_category: 'CR',
          },
          {
            id: 5,
            name: 'Computer languages',
            exam_category: 'CR',
          },
        ],
        grade: [],
        topic: [
          {
            id: 69,
            subject: 25,
            name: 'Time And Distance',
          },
        ],
        sub_topic: [],
        reasoning_type: null,
        academic_standard: [],
        created_by: {
          id: 54,
          username: 'i-candidateavi6',
          email: 'avinash1gurivelli+8@gmail.com',
          mobile_number: null,
          first_name: 'avi',
          last_name: 'nash',
          role: 'Internal Candidate',
          gender: null,
          grade: null,
          dob: null,
          section: null,
          fathers_name: null,
          mothers_name: null,
          guardian_contact_number: null,
          total_gems: null,
          rank: null,
          daily_challenge: null,
          school: null,
          qualification: null,
          experience: null,
          current_student_count: null,
          total_student_count: null,
          area_of_expertise: null,
          is_superteacher: null,
          institute: null,
          address: null,
          photo: null,
          avatar: null,
          thumbnail: null,
          has_completed_profile: false,
          status: null,
          is_active: true,
          is_deleted: false,
          badge: null,
          educations: null,
        },
        updated_by: null,
        institute: null,
        owner: null,
        body_images: [],
        options: [
          {
            id: 72395,
            image: null,
            text: '45&ordm',
            is_correct: false,
            question: 24945,
          },
          {
            id: 72396,
            image: null,
            text: '65&ordm',
            is_correct: true,
            question: 24945,
          },
          {
            id: 72397,
            image: null,
            text: '85&ordm',
            is_correct: false,
            question: 24945,
          },
          {
            id: 72398,
            image: null,
            text: '90&ordm',
            is_correct: false,
            question: 24945,
          },
        ],
        question_type: 'MCQ',
        status: 'A',
        visibility: 'PUBLIC',
        cost: '0.00',
        language: 'en',
        score: 4,
        text: 'In the given figure, line RT is drawn parallel to SQ. If;&ang;QPS=100∘,;&ang;POS;=;40∘,;;&ang;PSR=85∘;;and;&ang;QRS=70∘;, then;;&ang;QRT=_______\n\n.;',
        hint: null,
        explanation: null,
        difficulty_level: 'D',
        time_to_answer: 45,
        created_at: '2023-05-07T08:08:25.379556+05:30',
        updated_at: '2023-05-30T11:00:49.354049+05:30',
        is_copyrighted: null,
        code: null,
      },
      question_status: 'U',
      answer_status: null,
      duration: 0,
      marks_obtained: 0,
      evaluation_status: null,
      question_marks: 4,
      essay_answer: null,
      time_taken: 2,
      total_gems: 0,
      is_optional: false,
      gems_earned: 0,
      feedback: null,
      feedback_submitted_at: '2024-02-28T12:35:25.371009+05:30',
      user_exam_submission_details: 6841,
      options: [],
    },
    {
      id: 260102,
      question: {
        id: 24944,
        subject: [
          {
            id: 25,
            name: 'Aptitude',
          },
        ],
        exam_type: [
          {
            id: 6,
            name: 'Human Resource Management',
            exam_category: 'CR',
          },
          {
            id: 7,
            name: 'Computer Applications',
            exam_category: 'CR',
          },
          {
            id: 8,
            name: 'Sales',
            exam_category: 'CR',
          },
          {
            id: 9,
            name: 'Marketing',
            exam_category: 'CR',
          },
          {
            id: 10,
            name: 'Operations',
            exam_category: 'CR',
          },
          {
            id: 11,
            name: 'Technology',
            exam_category: 'CR',
          },
          {
            id: 12,
            name: 'Pre-Interview Screening',
            exam_category: 'CR',
          },
          {
            id: 13,
            name: 'Personality Assement for External Candidate',
            exam_category: 'CR',
          },
          {
            id: 14,
            name: 'Personality Assement for Internal Candidate',
            exam_category: 'CR',
          },
          {
            id: 15,
            name: 'Campus Hiring',
            exam_category: 'CR',
          },
          {
            id: 16,
            name: 'Reskilling Internal Teams',
            exam_category: 'CR',
          },
          {
            id: 5,
            name: 'Computer languages',
            exam_category: 'CR',
          },
        ],
        grade: [],
        topic: [
          {
            id: 59,
            subject: 25,
            name: 'Problems On Numbers',
          },
        ],
        sub_topic: [],
        reasoning_type: null,
        academic_standard: [],
        created_by: {
          id: 54,
          username: 'i-candidateavi6',
          email: 'avinash1gurivelli+8@gmail.com',
          mobile_number: null,
          first_name: 'avi',
          last_name: 'nash',
          role: 'Internal Candidate',
          gender: null,
          grade: null,
          dob: null,
          section: null,
          fathers_name: null,
          mothers_name: null,
          guardian_contact_number: null,
          total_gems: null,
          rank: null,
          daily_challenge: null,
          school: null,
          qualification: null,
          experience: null,
          current_student_count: null,
          total_student_count: null,
          area_of_expertise: null,
          is_superteacher: null,
          institute: null,
          address: null,
          photo: null,
          avatar: null,
          thumbnail: null,
          has_completed_profile: false,
          status: null,
          is_active: true,
          is_deleted: false,
          badge: null,
          educations: null,
        },
        updated_by: null,
        institute: null,
        owner: null,
        body_images: [],
        options: [
          {
            id: 72391,
            image: null,
            text: 'Copper',
            is_correct: false,
            question: 24944,
          },
          {
            id: 72392,
            image: null,
            text: 'Graphite',
            is_correct: true,
            question: 24944,
          },
          {
            id: 72393,
            image: null,
            text: 'Aluminum',
            is_correct: false,
            question: 24944,
          },
          {
            id: 72394,
            image: null,
            text: 'Silver',
            is_correct: false,
            question: 24944,
          },
        ],
        question_type: 'MCQ',
        status: 'A',
        visibility: 'PUBLIC',
        cost: '0.00',
        language: 'en',
        score: 4,
        text: 'Identify the non metal which is a good conductor of electricity.',
        hint: null,
        explanation: null,
        difficulty_level: 'E',
        time_to_answer: 45,
        created_at: '2023-05-07T08:08:24.777617+05:30',
        updated_at: '2023-05-30T11:00:49.326543+05:30',
        is_copyrighted: null,
        code: null,
      },
      question_status: 'A',
      answer_status: 'I',
      duration: 0,
      marks_obtained: 0,
      evaluation_status: null,
      question_marks: 4,
      essay_answer: null,
      time_taken: 8,
      total_gems: 0,
      is_optional: false,
      gems_earned: 0,
      feedback: null,
      feedback_submitted_at: '2024-02-28T12:35:28.396933+05:30',
      user_exam_submission_details: 6841,
      options: [72391],
    },
    {
      id: 260103,
      question: {
        id: 24943,
        subject: [
          {
            id: 25,
            name: 'Aptitude',
          },
        ],
        exam_type: [
          {
            id: 6,
            name: 'Human Resource Management',
            exam_category: 'CR',
          },
          {
            id: 7,
            name: 'Computer Applications',
            exam_category: 'CR',
          },
          {
            id: 8,
            name: 'Sales',
            exam_category: 'CR',
          },
          {
            id: 9,
            name: 'Marketing',
            exam_category: 'CR',
          },
          {
            id: 10,
            name: 'Operations',
            exam_category: 'CR',
          },
          {
            id: 11,
            name: 'Technology',
            exam_category: 'CR',
          },
          {
            id: 12,
            name: 'Pre-Interview Screening',
            exam_category: 'CR',
          },
          {
            id: 13,
            name: 'Personality Assement for External Candidate',
            exam_category: 'CR',
          },
          {
            id: 14,
            name: 'Personality Assement for Internal Candidate',
            exam_category: 'CR',
          },
          {
            id: 15,
            name: 'Campus Hiring',
            exam_category: 'CR',
          },
          {
            id: 16,
            name: 'Reskilling Internal Teams',
            exam_category: 'CR',
          },
          {
            id: 5,
            name: 'Computer languages',
            exam_category: 'CR',
          },
        ],
        grade: [],
        topic: [
          {
            id: 63,
            subject: 25,
            name: 'Races And Games',
          },
        ],
        sub_topic: [],
        reasoning_type: null,
        academic_standard: [],
        created_by: {
          id: 54,
          username: 'i-candidateavi6',
          email: 'avinash1gurivelli+8@gmail.com',
          mobile_number: null,
          first_name: 'avi',
          last_name: 'nash',
          role: 'Internal Candidate',
          gender: null,
          grade: null,
          dob: null,
          section: null,
          fathers_name: null,
          mothers_name: null,
          guardian_contact_number: null,
          total_gems: null,
          rank: null,
          daily_challenge: null,
          school: null,
          qualification: null,
          experience: null,
          current_student_count: null,
          total_student_count: null,
          area_of_expertise: null,
          is_superteacher: null,
          institute: null,
          address: null,
          photo: null,
          avatar: null,
          thumbnail: null,
          has_completed_profile: false,
          status: null,
          is_active: true,
          is_deleted: false,
          badge: null,
          educations: null,
        },
        updated_by: null,
        institute: null,
        owner: null,
        body_images: [],
        options: [
          {
            id: 72383,
            image: null,
            text: '0.03moles',
            is_correct: false,
            question: 24943,
          },
          {
            id: 72384,
            image: null,
            text: '0.04moles',
            is_correct: false,
            question: 24943,
          },
          {
            id: 72385,
            image: null,
            text: '0.05moles',
            is_correct: true,
            question: 24943,
          },
          {
            id: 72386,
            image: null,
            text: '0.01moles',
            is_correct: false,
            question: 24943,
          },
        ],
        question_type: 'MCQ',
        status: 'A',
        visibility: 'PUBLIC',
        cost: '0.00',
        language: 'en',
        score: 4,
        text: 'How many moles are present in;5.3g;of anhydrous sodium carbonate?',
        hint: null,
        explanation: null,
        difficulty_level: 'M',
        time_to_answer: 45,
        created_at: '2023-05-07T08:08:23.609823+05:30',
        updated_at: '2023-05-30T11:00:49.297953+05:30',
        is_copyrighted: null,
        code: null,
      },
      question_status: 'A',
      answer_status: 'I',
      duration: 0,
      marks_obtained: 0,
      evaluation_status: null,
      question_marks: 4,
      essay_answer: null,
      time_taken: 6,
      total_gems: 0,
      is_optional: false,
      gems_earned: 0,
      feedback: null,
      feedback_submitted_at: '2024-02-28T12:35:31.069118+05:30',
      user_exam_submission_details: 6841,
      options: [72384],
    },
    {
      id: 260104,
      question: {
        id: 24942,
        subject: [
          {
            id: 25,
            name: 'Aptitude',
          },
        ],
        exam_type: [
          {
            id: 6,
            name: 'Human Resource Management',
            exam_category: 'CR',
          },
          {
            id: 7,
            name: 'Computer Applications',
            exam_category: 'CR',
          },
          {
            id: 8,
            name: 'Sales',
            exam_category: 'CR',
          },
          {
            id: 9,
            name: 'Marketing',
            exam_category: 'CR',
          },
          {
            id: 10,
            name: 'Operations',
            exam_category: 'CR',
          },
          {
            id: 11,
            name: 'Technology',
            exam_category: 'CR',
          },
          {
            id: 12,
            name: 'Pre-Interview Screening',
            exam_category: 'CR',
          },
          {
            id: 13,
            name: 'Personality Assement for External Candidate',
            exam_category: 'CR',
          },
          {
            id: 14,
            name: 'Personality Assement for Internal Candidate',
            exam_category: 'CR',
          },
          {
            id: 15,
            name: 'Campus Hiring',
            exam_category: 'CR',
          },
          {
            id: 16,
            name: 'Reskilling Internal Teams',
            exam_category: 'CR',
          },
          {
            id: 5,
            name: 'Computer languages',
            exam_category: 'CR',
          },
        ],
        grade: [],
        topic: [
          {
            id: 44,
            subject: 25,
            name: 'Areas',
          },
        ],
        sub_topic: [],
        reasoning_type: null,
        academic_standard: [],
        created_by: {
          id: 54,
          username: 'i-candidateavi6',
          email: 'avinash1gurivelli+8@gmail.com',
          mobile_number: null,
          first_name: 'avi',
          last_name: 'nash',
          role: 'Internal Candidate',
          gender: null,
          grade: null,
          dob: null,
          section: null,
          fathers_name: null,
          mothers_name: null,
          guardian_contact_number: null,
          total_gems: null,
          rank: null,
          daily_challenge: null,
          school: null,
          qualification: null,
          experience: null,
          current_student_count: null,
          total_student_count: null,
          area_of_expertise: null,
          is_superteacher: null,
          institute: null,
          address: null,
          photo: null,
          avatar: null,
          thumbnail: null,
          has_completed_profile: false,
          status: null,
          is_active: true,
          is_deleted: false,
          badge: null,
          educations: null,
        },
        updated_by: null,
        institute: null,
        owner: null,
        body_images: [],
        options: [
          {
            id: 72379,
            image: null,
            text: 'Rs.810',
            is_correct: false,
            question: 24942,
          },
          {
            id: 72380,
            image: null,
            text: 'Rs.800',
            is_correct: true,
            question: 24942,
          },
          {
            id: 72381,
            image: null,
            text: 'Rs.790',
            is_correct: false,
            question: 24942,
          },
          {
            id: 72382,
            image: null,
            text: 'Rs.780',
            is_correct: false,
            question: 24942,
          },
        ],
        question_type: 'MCQ',
        status: 'A',
        visibility: 'PUBLIC',
        cost: '0.00',
        language: 'en',
        score: 4,
        text: 'A man loses 12-% of his money and after spending 70% of the remainder, he was left with Rs.210. The money that he had at the beginning is',
        hint: null,
        explanation: null,
        difficulty_level: 'E',
        time_to_answer: 45,
        created_at: '2023-05-07T08:08:23.024942+05:30',
        updated_at: '2023-05-30T11:00:49.269836+05:30',
        is_copyrighted: null,
        code: null,
      },
      question_status: 'A',
      answer_status: 'I',
      duration: 0,
      marks_obtained: 0,
      evaluation_status: null,
      question_marks: 4,
      essay_answer: null,
      time_taken: 10,
      total_gems: 0,
      is_optional: false,
      gems_earned: 0,
      feedback: null,
      feedback_submitted_at: '2024-02-28T12:35:32.914543+05:30',
      user_exam_submission_details: 6841,
      options: [72381],
    },
    {
      id: 260105,
      question: {
        id: 24941,
        subject: [
          {
            id: 25,
            name: 'Aptitude',
          },
        ],
        exam_type: [
          {
            id: 6,
            name: 'Human Resource Management',
            exam_category: 'CR',
          },
          {
            id: 7,
            name: 'Computer Applications',
            exam_category: 'CR',
          },
          {
            id: 8,
            name: 'Sales',
            exam_category: 'CR',
          },
          {
            id: 9,
            name: 'Marketing',
            exam_category: 'CR',
          },
          {
            id: 10,
            name: 'Operations',
            exam_category: 'CR',
          },
          {
            id: 11,
            name: 'Technology',
            exam_category: 'CR',
          },
          {
            id: 12,
            name: 'Pre-Interview Screening',
            exam_category: 'CR',
          },
          {
            id: 13,
            name: 'Personality Assement for External Candidate',
            exam_category: 'CR',
          },
          {
            id: 14,
            name: 'Personality Assement for Internal Candidate',
            exam_category: 'CR',
          },
          {
            id: 15,
            name: 'Campus Hiring',
            exam_category: 'CR',
          },
          {
            id: 16,
            name: 'Reskilling Internal Teams',
            exam_category: 'CR',
          },
          {
            id: 5,
            name: 'Computer languages',
            exam_category: 'CR',
          },
        ],
        grade: [],
        topic: [
          {
            id: 45,
            subject: 25,
            name: 'Averages',
          },
        ],
        sub_topic: [],
        reasoning_type: null,
        academic_standard: [],
        created_by: {
          id: 54,
          username: 'i-candidateavi6',
          email: 'avinash1gurivelli+8@gmail.com',
          mobile_number: null,
          first_name: 'avi',
          last_name: 'nash',
          role: 'Internal Candidate',
          gender: null,
          grade: null,
          dob: null,
          section: null,
          fathers_name: null,
          mothers_name: null,
          guardian_contact_number: null,
          total_gems: null,
          rank: null,
          daily_challenge: null,
          school: null,
          qualification: null,
          experience: null,
          current_student_count: null,
          total_student_count: null,
          area_of_expertise: null,
          is_superteacher: null,
          institute: null,
          address: null,
          photo: null,
          avatar: null,
          thumbnail: null,
          has_completed_profile: false,
          status: null,
          is_active: true,
          is_deleted: false,
          badge: null,
          educations: null,
        },
        updated_by: null,
        institute: null,
        owner: null,
        body_images: [],
        options: [
          {
            id: 72375,
            image: null,
            text: '19 days',
            is_correct: true,
            question: 24941,
          },
          {
            id: 72376,
            image: null,
            text: '25 days',
            is_correct: false,
            question: 24941,
          },
          {
            id: 72377,
            image: null,
            text: '35 days',
            is_correct: false,
            question: 24941,
          },
          {
            id: 72378,
            image: null,
            text: '30 days',
            is_correct: false,
            question: 24941,
          },
        ],
        question_type: 'MCQ',
        status: 'A',
        visibility: 'PUBLIC',
        cost: '0.00',
        language: 'en',
        score: 4,
        text: 'P and Q can do a job in 25 days and 30 days respectively. They work together for 5 days and then p leaves. Q will finish the rest of work in how many days?',
        hint: null,
        explanation: null,
        difficulty_level: 'E',
        time_to_answer: 45,
        created_at: '2023-05-07T08:08:22.417897+05:30',
        updated_at: '2023-05-30T11:00:49.246570+05:30',
        is_copyrighted: null,
        code: null,
      },
      question_status: 'R',
      answer_status: 'I',
      duration: 0,
      marks_obtained: 0,
      evaluation_status: null,
      question_marks: 4,
      essay_answer: null,
      time_taken: 4,
      total_gems: 0,
      is_optional: false,
      gems_earned: 0,
      feedback: null,
      feedback_submitted_at: '2024-02-28T12:35:35.034827+05:30',
      user_exam_submission_details: 6841,
      options: [72377],
    },
    {
      id: 260106,
      question: {
        id: 24940,
        subject: [
          {
            id: 25,
            name: 'Aptitude',
          },
        ],
        exam_type: [
          {
            id: 6,
            name: 'Human Resource Management',
            exam_category: 'CR',
          },
          {
            id: 7,
            name: 'Computer Applications',
            exam_category: 'CR',
          },
          {
            id: 8,
            name: 'Sales',
            exam_category: 'CR',
          },
          {
            id: 9,
            name: 'Marketing',
            exam_category: 'CR',
          },
          {
            id: 10,
            name: 'Operations',
            exam_category: 'CR',
          },
          {
            id: 11,
            name: 'Technology',
            exam_category: 'CR',
          },
          {
            id: 12,
            name: 'Pre-Interview Screening',
            exam_category: 'CR',
          },
          {
            id: 13,
            name: 'Personality Assement for External Candidate',
            exam_category: 'CR',
          },
          {
            id: 14,
            name: 'Personality Assement for Internal Candidate',
            exam_category: 'CR',
          },
          {
            id: 15,
            name: 'Campus Hiring',
            exam_category: 'CR',
          },
          {
            id: 16,
            name: 'Reskilling Internal Teams',
            exam_category: 'CR',
          },
          {
            id: 5,
            name: 'Computer languages',
            exam_category: 'CR',
          },
        ],
        grade: [],
        topic: [
          {
            id: 64,
            subject: 25,
            name: 'Ratio And Proportion',
          },
        ],
        sub_topic: [],
        reasoning_type: null,
        academic_standard: [],
        created_by: {
          id: 54,
          username: 'i-candidateavi6',
          email: 'avinash1gurivelli+8@gmail.com',
          mobile_number: null,
          first_name: 'avi',
          last_name: 'nash',
          role: 'Internal Candidate',
          gender: null,
          grade: null,
          dob: null,
          section: null,
          fathers_name: null,
          mothers_name: null,
          guardian_contact_number: null,
          total_gems: null,
          rank: null,
          daily_challenge: null,
          school: null,
          qualification: null,
          experience: null,
          current_student_count: null,
          total_student_count: null,
          area_of_expertise: null,
          is_superteacher: null,
          institute: null,
          address: null,
          photo: null,
          avatar: null,
          thumbnail: null,
          has_completed_profile: false,
          status: null,
          is_active: true,
          is_deleted: false,
          badge: null,
          educations: null,
        },
        updated_by: null,
        institute: null,
        owner: null,
        body_images: [],
        options: [
          {
            id: 72371,
            image: null,
            text: 'Screenless',
            is_correct: true,
            question: 24940,
          },
          {
            id: 72372,
            image: null,
            text: 'Touch Screen',
            is_correct: false,
            question: 24940,
          },
          {
            id: 72373,
            image: null,
            text: 'Retina',
            is_correct: false,
            question: 24940,
          },
          {
            id: 72374,
            image: null,
            text: 'Haptic',
            is_correct: false,
            question: 24940,
          },
        ],
        question_type: 'MCQ',
        status: 'A',
        visibility: 'PUBLIC',
        cost: '0.00',
        language: 'en',
        score: 4,
        text: '_______ Display is an emerging technology in IT that will display the information directly projecting images onto the human retina.',
        hint: null,
        explanation: null,
        difficulty_level: 'D',
        time_to_answer: 45,
        created_at: '2023-05-07T08:08:21.822167+05:30',
        updated_at: '2023-05-30T11:00:49.225371+05:30',
        is_copyrighted: null,
        code: null,
      },
      question_status: 'A',
      answer_status: 'I',
      duration: 0,
      marks_obtained: 0,
      evaluation_status: null,
      question_marks: 4,
      essay_answer: null,
      time_taken: 8,
      total_gems: 0,
      is_optional: false,
      gems_earned: 0,
      feedback: null,
      feedback_submitted_at: '2024-02-28T12:35:42.816012+05:30',
      user_exam_submission_details: 6841,
      options: [72373],
    },
  ],
  attempted_questions: 9,
  unattempted_questions: 1,
  correct_answers: 0,
  partially_correct_answers: 0,
  incorrect_answers: 9,
  marks_obtained: '0.00',
  total_marks: 41,
  attempt_status: 'F',
  evaluation_status: null,
  percentage: '0.00',
  rank: null,
  start_time: '2024-02-28T12:35:05.651024+05:30',
  end_time: '2024-02-28T12:36:02.566107+05:30',
  access_report: false,
  total_gems: 0,
  gems_earned: 0,
  overall_feedback: null,
  overall_feedback_submitted_at: '2024-02-28T12:35:05.651076+05:30',
  submission_type: 'U',
  screen_violation_count: null,
  grade: null,
  user_env_details: 26717,
}
const ViewAnswer = () => {
  /**
   * ==================================================
   * const variable
   * ==================================================
   */
  const location = useLocation()
  const studentId = location?.state?.studentId || ''
  const examId = location?.state?.examId || ''
  const examName = location?.state?.examName || ''
  const navKey = location?.state?.navKey || ''
  const navigate = useNavigate()

  /**
   * ==================================================
   * Data from redux store
   * ==================================================
   */
  const candidateSubmissionReportList = useSelector(
    (state: Store) => state?.examReducer?.candidateSubmissionReportList
  )

  const isLoadingCandidateSubmissionReportList = useSelector(
    (state: Store) => state?.examReducer?.isLoadingCandidateSubmissionReportList
  )

  /**
   * ==================================================
   * Component state
   * ==================================================
   */
  const [page, setPage] = useState(1)
  const [activeQuestionId, setActiveQuestionId] = useState('')
  const [showImageModal, setShowImageModal] = useState(false)
  const [clickeImagePath, setClickedImagePath] = useState('')
  const isLoading = false
  const questions = candidateSubmissionReportList
    ? candidateSubmissionReportList?.user_answer_submission?.map(
        (submission, index) => ({
          ...submission,
          index: index + 1,
        })
      )
    : []
  const [paginatedData, setPaginatedData] = useState(
    (questions || [])?.slice(0, 5)
  )

  /**
   * ==================================================
   * functionality
   * ==================================================
   */
  const questionOptionsAlphabets = ['A', 'B', 'C', 'D', 'E', 'F']
  const getAnswers = (question: typeof questions[0]) => {
    const correctOptionsArray = Array.from({ length: 6 }, () => '')
    let correctOptions: string | string[] = question?.options?.map(
      (questionId) => {
        const questionIndex = question?.question?.options?.findIndex(
          (innerQuestion) => innerQuestion?.id === questionId
        )
        return questionIndex > -1
          ? (correctOptionsArray[questionIndex] =
              questionOptionsAlphabets[questionIndex])
          : ''
      }
    )
    correctOptions = correctOptionsArray
      ?.filter((option) => option)
      .join(', ') as string
    const status =
      question?.question_status === 'U'
        ? 'U'
        : question?.question?.question_type === 'ES'
        ? 'ES'
        : question.answer_status
    switch (status) {
      case 'C':
        return (
          <span className={'ms-2 text-success h6 font-weight-bold '}>
            {' '}
            {correctOptions} is correct{' '}
          </span>
        )
      case 'PC':
        return (
          <span className={'ms-2 text-orange h6 font-weight-bold '}>
            {' '}
            {correctOptions} is partially correct{' '}
          </span>
        )
      case 'I':
        return (
          <span className={'ms-2 text-danger h6 font-weight-bold '}>
            {' '}
            {correctOptions} is In-Correct{' '}
          </span>
        )
      case 'U':
        return (
          <span className={'ms-2 h6 font-weight-bold '}>
            Un-attempted question
          </span>
        )
      default:
        return (
          <div
            dangerouslySetInnerHTML={{ __html: question?.essay_answer }}
            className="ms-2 h6 font-weight-bold"
          ></div>
        )
    }
  }

  const _handleImageClick = (item: { image: string }) => {
    setClickedImagePath(item?.image)
    setShowImageModal(true)
  }

  /**
   * ==================================================
   * use effect handling
   * ==================================================
   */

  useEffect(() => {
    dispatch(getCandidateSubmissionReportListThunk(examId, studentId))
  }, [])
  useEffect(() => {
    const newOffset = ((page - 1) * 5) % questions?.length
    const newLimit = page * 5
    setPaginatedData(questions?.slice(newOffset, newLimit))
  }, [page, questions?.length])

  useEffect(() => {
    document.getElementById(activeQuestionId)?.scrollIntoView()
  }, [paginatedData])

  return (
    <Container className="p-5 shadow-card-box">
      <div className="mb-3 pb-1">
        <span className="text-muted">Exam</span>
        <Image src={RightBlackArrowIcon} className="mx-2" />
        <Link to="/exams">
          <span className="text-muted">Created</span>
        </Link>
        <Image src={RightBlackArrowIcon} className="mx-2" />
        <Link to={`/exam-details/${examId}`} state={{ tabKey: navKey }}>
          <span className="text-muted">{examName}</span>
        </Link>
        <Link
          className="pointer"
          to={`/exam-report/${studentId}`}
          state={{
            examId: examId,
            examName: examName,
            navKey: navKey,
          }}
        >
          <Image src={RightBlackArrowIcon} className="mx-2" />
          <span className="text-muted">Exam Report</span>
        </Link>
        <Image src={RightBlackArrowIcon} className="mx-2" />
        <span className="text-dark">View Answers</span>
      </div>
      {isLoadingCandidateSubmissionReportList ? (
        <Loader height="500px" />
      ) : (
        <div className="card">
          <div className="card-body p-4 mx-3 my-2">
            <Link
              to={`/exam-report/${studentId}`}
              state={{ examName: examName, examId: examId, navKey: navKey }}
              className="h4 d-block mb-4"
            >
              <IoMdArrowBack className="me-2" />
              View Answers
            </Link>
            <Row>
              <Col lg={8}>
                <div className="assign-exam-box">
                  {!paginatedData?.length ? <p> No data found</p> : null}
                  {paginatedData?.map((question) => {
                    return (
                      <Card className="mb-4 border shadow-0" key={question?.id}>
                        <Card.Body id={`question-${question?.index}`}>
                          <div className="d-flex align-items-center justify-content-between border-bottom pb-3 mb-4">
                            <p className="m-0">
                              Topic : <strong>Integers</strong>
                            </p>
                            <p className="m-0">
                              Complexity: <strong>Easy</strong>
                            </p>
                          </div>
                          <div>
                            <h5 className="d-flex mb-3 question-pre-tag">
                              {' '}
                              Q.{question?.index}:&nbsp;
                              {dangerouslySetHTML(question?.question?.text)}
                            </h5>
                            {}
                            {question?.question?.body_images?.length ? (
                              <ImageList cols={8}>
                                {question?.question?.body_images.map((item) => (
                                  <ImageListItem key={item?.id}>
                                    item?.image ? (
                                    <img
                                      src={`${process.env.REACT_APP_IMAGE_URL}${item?.image}?w=10&h=10`}
                                      srcSet={`${process.env.REACT_APP_IMAGE_URL}${item?.image}?w=10&h=10&dpr=2 2x`}
                                      alt={item.id?.toString()}
                                      loading="lazy"
                                      className="border rounded mt-2 pe-zoom image-preview-big"
                                      onClick={() => _handleImageClick(item)}
                                    />
                                    ) : null
                                  </ImageListItem>
                                ))}
                              </ImageList>
                            ) : null}
                          </div>
                          {question?.question?.question_type !== 'Essay' ? (
                            <Row xs={1} lg={2} className="ms-4">
                              <Col>
                                <div>
                                  {question?.question?.options?.map(
                                    (option, index) => (
                                      <React.Fragment key={option.id}>
                                        <p
                                          className={`mb-2 d-flex ${
                                            option?.is_correct
                                              ? 'h6 text-success'
                                              : ''
                                          }`}
                                          id={option?.id?.toString()}
                                        >
                                          {`${questionOptionsAlphabets[index]}.`}{' '}
                                          {dangerouslySetHTML(option?.text)}
                                        </p>
                                        {option?.image ? (
                                          <img
                                            src={`${process.env.REACT_APP_IMAGE_URL}${option?.image}?w=10&h=10`}
                                            srcSet={`${process.env.REACT_APP_IMAGE_URL}${option?.image}?w=10&h=1&dpr=2 2x`}
                                            alt={option.id?.toString()}
                                            loading="lazy"
                                            className="border rounded mt-2 pe-zoom question-preview"
                                            onClick={() =>
                                              _handleImageClick(option)
                                            }
                                          />
                                        ) : null}
                                      </React.Fragment>
                                    )
                                  )}
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                          <div className="d-flex align-items-center justify-content-between mt-3 border-top pt-3">
                            <h6 className="m-0">
                              <div className="d-flex align-items-center">
                                <span className="fw-normal">
                                  {' '}
                                  Your Answer:{' '}
                                </span>
                                <div>{getAnswers(question)}</div>
                              </div>
                            </h6>
                            <h6 className="m-0">
                              {question?.question_status !== 'U' ? (
                                <span className="ms-2">
                                  <span className="fw-normal">
                                    Time Taken :
                                  </span>
                                  {secondsToHms(question?.time_taken)}
                                </span>
                              ) : null}
                            </h6>
                          </div>
                        </Card.Body>
                      </Card>
                    )
                  })}
                  {Math.ceil(questions?.length / 5) > 1 && (
                    <Pagination
                      count={
                        questions?.length ? Math.ceil(questions?.length / 5) : 1
                      }
                      page={page}
                      onChange={(
                        event: React.ChangeEvent<unknown>,
                        newPage: number
                      ) => {
                        setPage(newPage)
                        window.scrollTo(0, 0)
                      }}
                      className="mb-4 w-100 d-flex justify-content-center"
                    />
                  )}
                  {showImageModal && clickeImagePath && (
                    <ImageModal
                      show={showImageModal}
                      setShow={setShowImageModal}
                      src={clickeImagePath}
                    />
                  )}
                </div>
              </Col>
              {isLoading ? null : !paginatedData?.length ? null : (
                <Col lg={4} className="answer-grid top-0">
                  <Card className="question-list-card">
                    <Card.Header className="d-flex align-items-center shadow-none text-black-400 border-bottom">
                      Total Questions:
                      <span className="fw-semibold ms-1">
                        {questions?.length}
                      </span>
                    </Card.Header>
                    <Card.Body className="p-3 pb-4 text-body">
                      <div className="question-round-box d-flex flex-wrap justify-content-center">
                        {questions?.map((item, index) => {
                          return (
                            <div
                              className={`pointer box ${getAttemptClass(
                                item?.question_status === 'U'
                                  ? 'U'
                                  : item?.answer_status || ''
                              )}`}
                              onClick={() => {
                                if (page !== Math.ceil((index + 1) / 5))
                                  setPage(Math.ceil((index + 1) / 5))
                                setActiveQuestionId(`question-${index + 1}`)
                                document
                                  .getElementById(`question-${index + 1}`)
                                  ?.scrollIntoView()
                              }}
                              key={item?.id}
                            >
                              {index + 1}
                            </div>
                          )
                        })}
                      </div>
                      <ul className="list-unstyled legend-box page-box">
                        <li className="d-flex flex-wrap align-items-center">
                          <span className="box green"></span>
                          Correct
                          <span className="primary-box ms-auto">
                            {candidateSubmissionReportList?.correct_answers}
                          </span>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <span className="box warning"></span>
                          Partially Correct
                          <span className="primary-box ms-auto">
                            {
                              candidateSubmissionReportList?.partially_correct_answers
                            }
                          </span>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <span className="box danger"></span>
                          In Correct
                          <span className="primary-box ms-auto">
                            {candidateSubmissionReportList?.incorrect_answers}
                          </span>
                        </li>
                        <li className="d-flex flex-wrap align-items-center">
                          <span className="box unattempted"></span>
                          Unattempted
                          <span className="primary-box ms-auto">
                            {
                              candidateSubmissionReportList?.unattempted_questions
                            }
                          </span>
                        </li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </div>
      )}
    </Container>
  )
}

export default ViewAnswer
