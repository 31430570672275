/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  ADD_REVIEW_AND_COMMENT,
  ASSIGN_EXAM,
  CANDIDATE_BOOKMARK,
  CANDIDATE_DATA_DOWNLOAD_EXCEL,
  EXAM_CREATE,
  EXAM_DELETE,
  EXAM_DETAILS,
  EXAM_LIST,
  EXAM_UPDATE,
  EXAM_USERS_LIST,
  GENERATE_QUESTIONS_BY_TOPIC_LIST,
  GET_CANDIDATE_REPORT,
  GET_CANDIDATE_REPORT_LIST,
  GET_CANDIDATE_SUBMISSION_REPORT_LIST,
  GET_DETAILS_OF_EXAM,
  GET_JD_CANDIDATE_LIST,
  GET_JD_EXAM_LIST,
  GET_QUESTIONS_OF_EXAM_DETAILS,
  GET_REVIEW_AND_COMMENT,
  RESET_CANDIDATE_DOWNLOAD_REPORT,
  SUITABLE_FOR_LIST,
} from './examConst'
import { ActionTypes, ExamData } from './examType'

const initialState = {
  isLoading: false,
  errorMessage: '',
  examDetails: {},
  exams: [],
  candidateList: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  suitableForList: [],
  questionListByTopics: [],
  isLoadingQuestionListByTopics: false,
  jdExamList: [],
  isLoadingJDExamList: false,
  examDetailsData: {},
  isLoadingExamDetailsData: false,
  questionsOfExamDetailsList: [],
  isLoadingQuestionsOfExamDetailsList: false,
  candidateReportList: {},
  isLoadingCandidateReportList: false,
  candidateReportData: {},
  isLoadingCandidateReportData: false,
  jdCandidateList: [],
  isLoadingJdCandidateList: false,
  candidateSubmissionReportList: {},
  isLoadingCandidateSubmissionReportList: false,
  isLoadingDeleteExam: {},
  reviewAndComment: {},
  isLoadingReview: false,
  isLoadingGetReview: false,
  isLoadingBookmark: false,
  isGetCandidateDownloadExcelLoading: false,
  candidateListDownloadExcel: [],
  examDataById: {},
}

const examReducer = persistReducer(
  {
    storage,
    key: 'user',
    whitelist: ['examDetails', 'exam'],
  },
  (state: ExamData = initialState, action: ActionTypes) => {
    switch (action.type) {
      case EXAM_LIST.EXAM_LIST_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case EXAM_LIST.EXAM_LIST_SUCCESS:
        const examsListings: Array<any> = action.payload
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          exams: examsListings,
        }

      case EXAM_LIST.EXAM_LIST_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case EXAM_CREATE.EXAM_CREATE_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case EXAM_CREATE.EXAM_CREATE_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case EXAM_CREATE.EXAM_CREATE_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case EXAM_UPDATE.EXAM_UPDATE_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case EXAM_UPDATE.EXAM_UPDATE_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case EXAM_UPDATE.EXAM_UPDATE_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case EXAM_DETAILS.EXAM_DETAILS_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isLoading: true,
        }

      case EXAM_DETAILS.EXAM_DETAILS_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
          examDetails: action.payload,
        }

      case EXAM_DETAILS.EXAM_DETAILS_ERROR:
        return {
          ...state,
          errorMessage: '',
          isLoading: false,
        }

      case ASSIGN_EXAM.ASSIGN_EXAM_REQUEST:
        return {
          ...state,
          isLoading: true,
        }

      case ASSIGN_EXAM.ASSIGN_EXAM_SUCCESS:
        return {
          ...state,
          isLoading: false,
        }

      case ASSIGN_EXAM.ASSIGN_EXAM_ERROR:
        return {
          ...state,
          // errorMessage: action.payload,
          isLoading: false,
        }

      case EXAM_USERS_LIST.EXAM_USERS_LIST_REQUEST:
        return {
          ...state,
          isLoading: true,
        }

      case EXAM_USERS_LIST.EXAM_USERS_LIST_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isLoading: false,
          candidateList: action.payload,
        }

      case EXAM_USERS_LIST.EXAM_USERS_LIST_ERROR:
        return {
          ...state,
          // errorMessage: action.payload,
          isLoading: false,
        }

      case SUITABLE_FOR_LIST.SUITABLE_FOR_LIST_REQUEST:
        return {
          ...state,
          isLoading: true,
        }

      case SUITABLE_FOR_LIST.SUITABLE_FOR_LIST_SUCCESS:
        return {
          ...state,
          suitableForList: action.payload,
          isLoading: false,
        }

      case SUITABLE_FOR_LIST.SUITABLE_FOR_LIST_ERROR:
        return {
          ...state,
          // errorMessage: action.payload,
          isLoading: false,
        }
      case GENERATE_QUESTIONS_BY_TOPIC_LIST.GENERATE_QUESTIONS_BY_TOPIC_LIST_REQUEST:
        return {
          ...state,
          isLoadingQuestionListByTopics: true,
        }

      case GENERATE_QUESTIONS_BY_TOPIC_LIST.GENERATE_QUESTIONS_BY_TOPIC_LIST_SUCCESS:
        return {
          ...state,
          questionListByTopics: action.payload,
          isLoadingQuestionListByTopics: false,
        }

      case GENERATE_QUESTIONS_BY_TOPIC_LIST.GENERATE_QUESTIONS_BY_TOPIC_LIST_ERROR:
        return {
          ...state,
          questionListByTopics: action.payload,
          isLoadingQuestionListByTopics: false,
        }
      case GET_JD_EXAM_LIST.GET_JD_EXAM_LIST_REQUEST:
        return {
          ...state,
          isLoadingJDExamList: true,
        }

      case GET_JD_EXAM_LIST.GET_JD_EXAM_LIST_SUCCESS:
        return {
          ...state,
          jdExamList: action.payload,
          isLoadingJDExamList: false,
        }

      case GET_JD_EXAM_LIST.GET_JD_EXAM_LIST_ERROR:
        return {
          ...state,
          isLoadingJDExamList: false,
        }

      case GET_DETAILS_OF_EXAM.GET_DETAILS_OF_EXAM_REQUEST:
        return {
          ...state,
          examDetailsData: {},
          isLoadingExamDetailsData: true,
        }

      case GET_DETAILS_OF_EXAM.GET_DETAILS_OF_EXAM_SUCCESS:
        return {
          ...state,
          examDetailsData: action.payload,
          isLoadingExamDetailsData: false,
        }

      case GET_DETAILS_OF_EXAM.GET_DETAILS_OF_EXAM_ERROR:
        return {
          ...state,
          isLoadingExamDetailsData: false,
          examDetailsData: {},
        }

      case GET_QUESTIONS_OF_EXAM_DETAILS.GET_QUESTIONS_OF_EXAM_DETAILS_REQUEST:
        return {
          ...state,
          isLoadingQuestionsOfExamDetailsList: true,
        }

      case GET_QUESTIONS_OF_EXAM_DETAILS.GET_QUESTIONS_OF_EXAM_DETAILS_SUCCESS:
        return {
          ...state,
          questionsOfExamDetailsList: action.payload,
          isLoadingQuestionsOfExamDetailsList: false,
        }

      case GET_QUESTIONS_OF_EXAM_DETAILS.GET_QUESTIONS_OF_EXAM_DETAILS_ERROR:
        return {
          ...state,
          isLoadingQuestionsOfExamDetailsList: false,
        }

      case GET_CANDIDATE_REPORT_LIST.GET_CANDIDATE_REPORT_LIST_REQUEST:
        return {
          ...state,
          isLoadingCandidateReportList: true,
        }

      case GET_CANDIDATE_REPORT_LIST.GET_CANDIDATE_REPORT_LIST_SUCCESS:
        return {
          ...state,
          candidateReportList: action.payload,
          isLoadingCandidateReportList: false,
        }

      case GET_CANDIDATE_REPORT_LIST.GET_CANDIDATE_REPORT_LIST_ERROR:
        return {
          ...state,
          isLoadingCandidateReportList: false,
        }

      case GET_CANDIDATE_REPORT.GET_CANDIDATE_REPORT_REQUEST:
        return {
          ...state,
          isLoadingCandidateReportData: true,
        }

      case GET_CANDIDATE_REPORT.GET_CANDIDATE_REPORT_SUCCESS:
        return {
          ...state,
          candidateReportData: action.payload,
          isLoadingCandidateReportData: false,
        }

      case GET_CANDIDATE_REPORT.GET_CANDIDATE_REPORT_ERROR:
        return {
          ...state,
          isLoadingCandidateReportData: false,
        }

      case GET_JD_CANDIDATE_LIST.GET_JD_CANDIDATE_LIST_REQUEST:
        return {
          ...state,
          isLoadingJdCandidateList: true,
        }

      case GET_JD_CANDIDATE_LIST.GET_JD_CANDIDATE_LIST_SUCCESS:
        return {
          ...state,
          jdCandidateList: action.payload,
          isLoadingJdCandidateList: false,
        }

      case GET_JD_CANDIDATE_LIST.GET_JD_CANDIDATE_LIST_ERROR:
        return {
          ...state,
          isLoadingJdCandidateList: false,
        }
      // Reset state with initial value without API call
      case GET_JD_CANDIDATE_LIST.RESET_JD_CANDIDATE_LIST:
        return {
          ...state,
          jdCandidateList: [], // Reset to an empty array
        }

      case GET_CANDIDATE_SUBMISSION_REPORT_LIST.GET_CANDIDATE_SUBMISSION_REPORT_LIST_REQUEST:
        return {
          ...state,
          isLoadingCandidateSubmissionReportList: true,
        }

      case GET_CANDIDATE_SUBMISSION_REPORT_LIST.GET_CANDIDATE_SUBMISSION_REPORT_LIST_SUCCESS:
        return {
          ...state,
          candidateSubmissionReportList: action.payload,
          isLoadingCandidateSubmissionReportList: false,
        }

      case GET_CANDIDATE_SUBMISSION_REPORT_LIST.GET_CANDIDATE_SUBMISSION_REPORT_LIST_ERROR:
        return {
          ...state,
          isLoadingCandidateSubmissionReportList: false,
        }

      case EXAM_DELETE.EXAM_DELETE_REQUEST:
        return {
          ...state,
          isLoadingDeleteExam: true,
        }

      case EXAM_DELETE.EXAM_DELETE_SUCCESS:
        return {
          ...state,
          isLoadingDeleteExam: false,
        }

      case EXAM_DELETE.EXAM_DELETE_ERROR:
        return {
          ...state,
          isLoadingDeleteExam: false,
        }

      case ADD_REVIEW_AND_COMMENT.ADD_REVIEW_AND_COMMENT_REQUEST:
        return {
          ...state,
          isLoadingReview: true,
        }

      case ADD_REVIEW_AND_COMMENT.ADD_REVIEW_AND_COMMENT_SUCCESS:
        return {
          ...state,
          isLoadingReview: false,
        }

      case ADD_REVIEW_AND_COMMENT.ADD_REVIEW_AND_COMMENT_ERROR:
        return {
          ...state,
          isLoadingReview: false,
        }

      case GET_REVIEW_AND_COMMENT.GET_REVIEW_AND_COMMENT_REQUEST:
        return {
          ...state,
          isLoadingGetReview: true,
        }

      case GET_REVIEW_AND_COMMENT.GET_REVIEW_AND_COMMENT_SUCCESS:
        return {
          ...state,
          reviewAndComment: action.payload,
          isLoadingGetReview: false,
        }

      case GET_REVIEW_AND_COMMENT.GET_REVIEW_AND_COMMENT_ERROR:
        return {
          ...state,
          isLoadingGetReview: false,
        }
      // ==================================================
      // Candidate bookmark
      // ==================================================
      case CANDIDATE_BOOKMARK.CANDIDATE_BOOKMARK_REQUEST:
        return {
          ...state,
          isLoadingBookmark: true,
        }

      case CANDIDATE_BOOKMARK.CANDIDATE_BOOKMARK_SUCCESS:
        return {
          ...state,
          isLoadingBookmark: false,
        }

      case CANDIDATE_BOOKMARK.CANDIDATE_BOOKMARK_ERROR:
        return {
          ...state,
          isLoadingBookmark: false,
        }

      // STUDENT_LIST_DOWNLOAD_EXCEL
      case CANDIDATE_DATA_DOWNLOAD_EXCEL.CANDIDATE_DATA_DOWNLOAD_EXCEL_REQUEST:
        return {
          ...state,
          errorMessage: '',
          isGetCandidateDownloadExcelLoading: true,
          candidateListDownloadExcel: {
            count: 0,
            next: '',
            previous: '',
            results: [],
          },
        }
      case CANDIDATE_DATA_DOWNLOAD_EXCEL.CANDIDATE_DATA_DOWNLOAD_EXCEL_SUCCESS:
        return {
          ...state,
          errorMessage: '',
          isGetCandidateDownloadExcelLoading: false,
          candidateListDownloadExcel: (action.payload as any) || [],
        }

      case CANDIDATE_DATA_DOWNLOAD_EXCEL.CANDIDATE_DATA_DOWNLOAD_EXCEL_ERROR:
        return {
          ...state,
          ...action.payload,
          errorMessage: action.payload,
          isGetCandidateDownloadExcelLoading: false,
          candidateListDownloadExcel: [],
        }

      case RESET_CANDIDATE_DOWNLOAD_REPORT:
        return {
          ...state,
          ...action.payload,
          errorMessage: action.payload,
          candidateListDownloadExcel: [],
        }

      // Exam by id
      case EXAM_LIST.EXAM_LIST_BY_ID_REQUEST:
        return {
          ...state,
          ...action.payload,
          errorMessage: '',
          isLoading: true,
          examDataById: {},
        }
      case EXAM_LIST.EXAM_LIST_BY_ID_SUCCESS:
        return {
          ...state,
          ...action.payload,
          errorMessage: '',
          isLoading: false,
          examDataById: action.payload,
        }

      case EXAM_LIST.EXAM_LIST_BY_ID_ERROR:
        return {
          ...state,
          ...action.payload,
          errorMessage: action.payload,
          isLoading: false,
        }

      default:
        return state
    }
  }
)

export default examReducer
