import React, { useState } from 'react'
import { Accordion, Image } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'

import CandidateManagementIcon from '../assets/images/candidate-management.svg'
import ComapnyIcon from '../assets/images/company-icon.svg'
import ExamIconIcon from '../assets/images/exam-icon.svg'
import JobDescription from '../assets/images/job-description.svg'
import OrganizationIcon from '../assets/images/organization-icon.svg'
import QuestionPaperIcon from '../assets/images/question-paper.svg'
import SidebarImg1 from '../assets/images/sidebar-img1.png'
import WhiteLogoSidebar from '../assets/images/sidebar-logo.png'

const Sidebar = () => {
  const [activeAccordion, setActiveAccordion] = useState(null)

  const handleAccordionClick = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index)
  }
  return (
    <>
      <div className="d-flex flex-column h-100 align-items-start sidebar-accordian">
        <NavLink
          to="/home"
          className={`p-3 my-1 mx-2 w-100 hover d-flex rounded ${({
            isActive,
          }: never) => (isActive ? 'active' : '')}`}
        >
          <Image src={SidebarImg1} />
          <span className="text-white ms-2 fw-normal h6 mb-0">Dashboard</span>
        </NavLink>
        <NavLink
          to="/job-description"
          className={`p-3 my-1 mx-2 w-100 hover d-flex rounded ${({
            isActive,
          }: never) => (isActive ? 'active' : '')}`}
        >
          <Image src={JobDescription} />
          <span className="text-white ms-2 fw-normal h6 mb-0">
            Job Description
          </span>
        </NavLink>
        <Accordion className="w-100 px-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Image src={ComapnyIcon} />
              <span className="text-white ms-2 fw-normal h6 mb-0">
                Companies
              </span>
            </Accordion.Header>
            <Accordion.Body
              className="accordion-body pb-0 pt-0 pe-0"
              // style={{ backgroundColor: activeAccordion === 0 ? 'lightblue' : '' }}
            >
              <NavLink
                to="/companies"
                className={`primary-active-item primary-inactive-item ${({
                  isActive,
                }: never) => (isActive ? 'active' : '')}`}
              >
                Companies List
              </NavLink>
              <NavLink
                to="/client-list"
                className={`primary-active-item primary-inactive-item ${({
                  isActive,
                }: never) => (isActive ? 'active' : '')}`}
              >
                Clients
              </NavLink>
              <NavLink
                to="/connection-request"
                className={`primary-active-item primary-inactive-item ${({
                  isActive,
                }: never) => (isActive ? 'active' : '')}`}
              >
                Connection Request
              </NavLink>
              <NavLink
                to="/bookmarked-company"
                className={`primary-active-item primary-inactive-item ${({
                  isActive,
                }: never) => (isActive ? 'active' : '')}`}
              >
                Bookmarked
              </NavLink>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="w-100 px-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Image src={OrganizationIcon} />
              <span className="text-white ms-2 fw-normal h6 mb-0">
                Organisation
              </span>
            </Accordion.Header>
            <Accordion.Body className="accordion-body pb-0 pt-0 pe-0">
              <NavLink
                to="/organisation-profile"
                className={`primary-active-item primary-inactive-item ${({
                  isActive,
                }: never) => (isActive ? 'active' : '')}`}
              >
                Profile
              </NavLink>
              <NavLink
                to="/employee-management"
                className={`primary-active-item primary-inactive-item ${({
                  isActive,
                }: never) => (isActive ? 'active' : '')}`}
              >
                Employee Management
              </NavLink>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <NavLink
          to="/candidate-management"
          className={`p-3 my-1 mx-2 w-100 hover d-flex rounded ${({
            isActive,
          }: never) => (isActive ? 'active' : '')}`}
        >
          <Image src={CandidateManagementIcon} />
          <span className="text-white ms-2 fw-normal h6 mb-0">
            Candidate Management
          </span>
        </NavLink>
        <NavLink
          to="/exams"
          className={`p-3 my-1 mx-2 w-100 hover d-flex rounded ${({
            isActive,
          }: never) => (isActive ? 'active' : '')}`}
        >
          <Image src={ExamIconIcon} />
          <span className="text-white ms-2 fw-normal h6 mb-0">Exam</span>
        </NavLink>

        <Accordion className="w-100 px-4 pb-3">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Image src={QuestionPaperIcon} />
              <span className="text-white ms-2 fw-normal h6 mb-0">
                Question Paper
              </span>
            </Accordion.Header>
            <Accordion.Body className="accordion-body pb-0 pt-0 pe-0">
              <NavLink
                to="/question-paper-library"
                className={`primary-active-item primary-inactive-item ${({
                  isActive,
                }: never) => (isActive ? 'active' : '')}`}
              >
                Library
              </NavLink>
              <NavLink
                to="/shop"
                className={`primary-active-item primary-inactive-item ${({
                  isActive,
                }: never) => (isActive ? 'active' : '')}`}
              >
                Store
              </NavLink>
              <NavLink
                to="/purchase-request"
                className={`primary-active-item primary-inactive-item ${({
                  isActive,
                }: never) => (isActive ? 'active' : '')}`}
              >
                Purchase Requests
              </NavLink>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <span className="mt-auto mb-2 px-3 bottom-link pe-none">
          <small className="text-white" style={{ opacity: 0.7 }}>
            Powered by
          </small>
          <br />
          <Image src={WhiteLogoSidebar} />
        </span>
      </div>
    </>
  )
}

export default Sidebar
