// Auth urls
export const USER_REGISTER = '/api/register'
export const USER_ACCOUNT_VERIFICATION =
  '/api/register/verify-account/<verification_code>'
export const USER_LOGIN = '/api/auth/login'
export const PROFILE_URL = '/api/user'
export const UPDATE_PASSWORD_URL = '/api/password/change'
export const PASSWORD_CHANGE_URL = '/api/password/set-password'
export const PASSWORD_RESET_URL = '/api/password/reset'
export const VERIFY_PASSWORD_URL = '/api/password/verify'

export const SEND_EMAIL_VARIFICATION_CODE_URL =
  '/api/recruitment/agency/send-email-verification-code'
export const VARIFY_EMAIL_URL = '/api/recruitment/agency/verify-email'
export const RESEND_USER_EMAIL_VARIFICATION_CODE_URL =
  '/api/register/resend-verification-code'

// Common urls
export const TOPIC_LIST_URL = '/api/topic'

// Exam urls
export const EXAM_URL = '/api/exam'
export const DELETE_EXAM_URL = '/api/recruitment/exam/'
export const EXAM_TYPE_URL = '/api/exam-type'
export const REASONING_TYPE_URL = '/api/reasoning-type'
export const EXAM_USERS_LIST_URL = '/api/exam/<exam_id>/exam-users-list'
export const SUITABLE_FOR_URL = '/api/suitable-for'
export const GENERATE_QUESTIONS_BY_TOPIC_LIST_URL =
  '/api/exam/generate-questions-by-topic'
export const GET_DETAILS_OF_EXAM_URL = (id) => `/api/recruitment/exam/${id}`
export const GET_QUESTIONS_OF_EXAM_DETAILS_URL = (id) =>
  `/api/recruitment/exam/${id}/questions`
export const GET_CANDIDATE_REPORT_LIST_URL = (id) => `/api/exam/${id}/report`

export const GET_JD_EXAM_LIST_URL = '/api/recruitment/exam'
export const GET_CANDIDATE_REPORT_URL = (id) =>
  `/api/recruitment/exam/${id}/candidate-report`
export const ASSIGN_EXAM_URL = (exam_id) => `/api/exam/${exam_id}/assign_exam`
export const GET_JD_CANDIDATE_LIST_URL = '/api/recruitment/agency/candidates'
export const GET_CANDIDATE_SUBMISSION_REPORT_LIST_URL =
  '/api/report/submission-details'
export const GET_REVIEW_AND_COMMENT_URL = (id) =>
  `/api/review/${id}/candidate-rating`
export const ADD_REVIEW_AND_COMMENT_URL = `/api/review/candidate-rating`
export const CANDIDATE_BOOKMARK_URL = '/api/recruitment/bookmark/candidate'
export const CANDIDATE_LIST_URL = '/api/exam/'
export const ASSIGN_VIEW_REPORT_PERMISSION_URL =
  '/api/report/examId/candidate-access-report'

// Question Paper Url
export const QUESTION_PAPER_URL = '/api/question-paper'
export const QUESTION_PAPER_UPLOAD_URL = '/api/question-paper/upload'

// employee management
export const ADD_EMPLOYEE_URL = '/api/recruitment/employee'
export const GET_EMPLOYEE_LIST_URL = '/api/recruitment/employee'
export const GET_EMPLOYEE_DETAILS_URL = (id: number | string | undefined) =>
  `/api/recruitment/employee/${id}`
export const UPDATE_EMPLOYEE_URL = (id) => `/api/user/${id}`
export const UPDATE_EMPLOYEE_STATUS_URL = (id) =>
  `/api/recruitment/employee/${id}`

export const UPLOAD_EMPLOYEE_URL = (agencyId) =>
  `api/recruitment/employee/${agencyId}/bulk-upload-employee`

// Companies
export const GET_COMPANIES_LIST_URL = '/api/recruitment/client'
export const GET_COMPANY_DETAILS_URL = (compId) => `/api/institute/${compId}`
export const GET_COMPANY_ACTIVE_JD_URL = `/api/job-description`
export const COMPANY_BOOKMARK_URL = `/api/recruitment/bookmark`
export const SENT_CONNECTION_REQUEST_URL = `/api/connection/company-agency`
export const CONNECTION_REQUEST_UPDATE_URL = (connectionId) =>
  `/api/connection/company-agency/${connectionId}`
export const EMPLOYEE_ASSIGNMENT_HISTORY_URL = '/api/recruitment/jd-assignment'
export const EMPLOYEE_JOB_DESCRIPTION_ASSIGNED_URL = 'api/recruitment/stats'
export const EMPLOYEE_JOB_DESCRIPTION_DETAILS_URL = (id) =>
  `/api/recruitment/job_descriptions/${id}`
export const SUITABLE_URL = '/api/suitable-for'
export const CITY_URL = '/api/city'
export const BUSINESS_CATEGORY_URL = '/api/business-category'

// Organisation Profile urls
export const AGENCY_URL = '/api/recruitment/agency'
export const AGENCY_USER_URL = '/api/recruitment/employee'
export const EXPERTISE_URL = '/api/expertise'
export const SERVICE_URL = '/api/recruitment/service'
export const TOOL_URL = '/api/recruitment/tool'

// Job Description urls
export const GET_JOB_DESCRIPTION_LIST_URL = '/api/recruitment/job_descriptions'
export const GET_JOB_DESCRIPTION_SUBMISSION_LIST_URL =
  '/api/recruitment/jd-submission'
export const REMOVE_CANDIDATE_FROM_JOB_DESCRIPTION_SUBMISSION_STATES_URL = (
  id
) => `/api/recruitment/jd-submission/${id}`
export const JOB_DESCRIPTION_BOOKMARK_URL = '/api/recruitment/jd_bookmark'
export const GET_CANDIDATE_LIST_URL =
  '/api/recruitment/jd-submission/candidate-list'
export const CANDIDATE_SUBMISSION_STATE_URL = '/api/recruitment/jd-submission'

// Canidate Management urls
export const CANDIDATE_RESUME_LIST_URL =
  '/api/recruitment/jd-submission/resume-list'
export const PROCESS_RESUME_URL = '/api/resume-reader/'
export const GET_RESUME_LINK_URL = '/get-resume-link'
export const UPLOAD_RESUME_URL = '/api/recruitment/candidate/upload-resume'
export const SUBMIT_RESUME_TO_JD_URL = (jdId) =>
  `/api/recruitment/job_descriptions/${jdId}/resume-submission`
export const GET_ALL_CANDIDATE_LIST_URL = `/api/recruitment/candidate`
