import { useEffect, useState } from 'react'
import { Container, Form, Image, InputGroup, Table } from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import BlackQuestionPaperIcon from '../../../../assets/images/black-question-paper.svg'
import FilterIcon from '../../../../assets/images/filter-icon.svg'
import SearchIcon from '../../../../assets/images/search-icon.svg'
import UserImagesIcon from '../../../../assets/images/User-images.svg'
import { Loader } from '../../../../components/Loader'
import Pagination from '../../../../components/Pagination'
import { questionPaperList } from '../../../../redux/question-paper/questionPaperActions'
import { Store } from '../../../../redux/rootType'
import { dispatch } from '../../../../redux/store'

const itemsPerPage = 10
const CreateExamQuestionPaperLibrary = (props) => {
  const navigate = useNavigate()
  const [questionPaperListings, setQuestionPaperListings] = useState<any>([])
  const [selectedQnPaperIds, setSelectedQnPaperIds] = useState<any>([])
  const [selectedQuestion, setSelectedQuestion] = useState(0)

  // use this with or condition when use selector give error
  // state?.questionPaperReducer?.questionPaperList
  const questionPapers = useSelector(
    (state: Store) => state?.questionPaperReducer?.questionPaperList?.results
  )?.map((val) => ({
    ...val,
    isChecked:
      props.formikCreateExam.values.question_paper_ids_selected.includes(
        val?.id
      )
        ? true
        : false,
  })) as any

  const questionPapersListCount = questionPapers?.length

  const isQuestionPaperLoading = useSelector(
    (state: Store) => state?.questionPaperReducer?.isLoading
  )

  const [page, setPage] = useState(1)
  const [paginatedData, setPaginatedData] = useState(
    (questionPaperListings || [])?.slice(0, itemsPerPage)
  )

  const handleSelectedQnPaper = (itemId, isChecked) => {
    //  Below code is implemented for Checkbox to implement
    //  Radio button functionality as per backend configuration
    const updatedListOfItems = [...questionPaperListings]
    const newResult = updatedListOfItems.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          isChecked: isChecked,
        }
      } else {
        return { ...item, isChecked: false }
      }
    })

    setQuestionPaperListings(newResult)
    const data = newResult
      ?.filter((item) => (item?.isChecked ? item?.id : ''))
      ?.map((item) => item.id)

    setSelectedQuestion(data?.length)
    props.formikCreateExam.setFieldValue('question_paper_ids_selected', data)
  }

  useEffect(() => {
    const newOffset =
      ((page - 1) * itemsPerPage) % (questionPapersListCount || 1)
    const newLimit = page * itemsPerPage

    setPaginatedData(questionPaperListings?.slice(newOffset, newLimit))
    setQuestionPaperListings((prev) => prev)
  }, [page, questionPaperListings])

  useEffect(() => {
    dispatch(questionPaperList(1))
  }, [])

  useEffect(() => {
    if (questionPapers) {
      setQuestionPaperListings(questionPapers)
    }
  }, [questionPapers?.length])

  return (
    <>
      <div className="fixed-top">
        <div className="progress-header">
          <Link to="/exams" className="h5 text-white mb-0">
            <IoMdArrowBack className="me-2" />
            Create New Exam
          </Link>
          <p className="text-white h6 fw-normal ms-auto mb-0">
            Step {props.activeStep + 1}/3
          </p>
        </div>
        <div className="progress-line">
          <div className="progress" style={{ width: '66%' }}></div>
        </div>
      </div>
      {isQuestionPaperLoading ? (
        <Loader height="500px" />
      ) : (
        <Container>
          <div className="d-flex align-items-center mb-3">
            <Image src={BlackQuestionPaperIcon} />
            <h5 className="ms-2 mb-0">Question Paper Library</h5>
          </div>
          <p className="dark-gray mb-4 pb-2">
            Select required question papers from the list
          </p>

          <div className="d-flex align-items-center mb-4">
            <InputGroup className="search-box">
              <InputGroup.Text id="basic-addon1">
                <Image src={SearchIcon} />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
            <div className="filter-btn ms-3">
              <Image src={FilterIcon} className="me-2" />
              Filter
            </div>
            <div className="ms-auto">
              <p className="fw-bold dark-gray mb-2">
                Selected Question Papers:{' '}
                <span className="text-new-primary">{selectedQuestion}</span>
                <span className="new-gray"> (Q {questionPapersListCount})</span>
              </p>
              {props.values?.question_count > 0 && (
                <span className="new-gray fw-bold">
                  *Random {props.values?.question_count} Questions will be
                  selected automatically
                </span>
              )}
            </div>
          </div>
          <Table bordered hover responsive className="overflow-scroll">
            <thead>
              <tr className="border-0">
                <th></th>
                <th>Question Paper</th>
                <th>Topics</th>
                <th>Complexity</th>
                <th># Ques.</th>
                <th>Time</th>
                <th>Created by</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData?.length ? (
                <>
                  {(paginatedData || [])?.map((exam, index) => (
                    <tr>
                      <td>
                        <Form.Check
                          key={exam?.id}
                          type={'checkbox'}
                          id={'Exam' + exam?.id}
                          checked={
                            props.formikCreateExam.values.question_paper_ids_selected.includes(
                              exam?.id
                            ) || exam?.isChecked
                          }
                          onClick={() =>
                            handleSelectedQnPaper(exam?.id, !exam?.isChecked)
                          }
                          name="question_paper_ids_selected"
                          // value={
                          //   props.formikCreateExam.values
                          //     .question_paper_ids_selected
                          // }
                          // onChange={props.formikCreateExam.handleChange}
                          // onBlur={props.formikCreateExam.handleBlur}
                        />
                      </td>
                      <td>{exam?.name}</td>
                      <td>
                        {exam?.subjects
                          ?.slice(0, 3)
                          ?.map((elem, index) => (index < 2 ? elem?.name : ''))
                          ?.toString(', ')}
                      </td>
                      <td>{exam?.difficulty_level || 'Beginner'}</td>
                      <td>{exam?.question_count}</td>
                      <td>{exam?.total_duration}</td>
                      {/* <td>{}</td> */}
                      <td>
                        <div className="d-flex align-items-start">
                          <Image
                            src={exam?.created_by?.photo || UserImagesIcon}
                            className="mt-1"
                          />
                          <div className="ms-2">
                            <span className="text-dark">
                              {exam?.created_by.first_name}{' '}
                              {exam?.created_by.last_name}
                            </span>
                            <br />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={8} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {questionPapersListCount > itemsPerPage ? (
            <Pagination
              page={page}
              setPage={setPage}
              tableCount={questionPapersListCount}
              itemsPerPage={itemsPerPage}
            />
          ) : null}
        </Container>
      )}
    </>
  )
}

export default CreateExamQuestionPaperLibrary
