import { useState } from 'react'
import { Form, Image, InputGroup, Modal, Table } from 'react-bootstrap'

import EmailIcon from '../../../../assets/images/EmailIcon.svg'
import SearchIcon from '../../../../assets/images/search-icon.svg'
import UserImagesIcon from '../../../../assets/images/User-images.svg'

const Email = () => {
  const [showEmail, setShowEmail] = useState(false)
  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <InputGroup className="search-box">
          <InputGroup.Text id="basic-addon1">
            <Image src={SearchIcon} />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search"
            aria-label="Search"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      </div>

      <Table bordered hover className="overflow-scroll">
        <thead>
          <tr className="border-0">
            {/* <th>
      <Form.Check type={'checkbox'} id={`A`} />
    </th> */}
            <th>To</th>
            <th>Subject</th>
            <th>Template Type</th>
            <th>Sent By</th>
            <th>Sent On</th>
            <th>Action</th> const [showEmail, setShowEmail] = useState(false)
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* <td>
      <Form.Check type={'checkbox'} id={`A`} />
    </td> */}
            <td>sam.s@ABCSoftwareSolution.in</td>
            <td>Welcome to the Team !</td>
            <td>Client Email</td>
            <td>
              <div className="d-flex">
                <Image src={UserImagesIcon} />
                <div className="ms-2">
                  <p className="mb-0 text-dark">Gorge Filly</p>
                  <small className="dark-gray">Recruiter </small>
                </div>
              </div>
            </td>
            <td>12 Dec, 2023</td>
            <td>
              <Image src={EmailIcon} onClick={() => setShowEmail(true)} />
            </td>
          </tr>
          <tr>
            {/* <td>
      <Form.Check type={'checkbox'} id={`A`} />
    </td> */}
            <td>sam.s@ABCSoftwareSolution.in</td>
            <td>Top 5 Candidates For UI Development</td>
            <td>Custom</td>
            <td>
              <div className="d-flex">
                <Image src={UserImagesIcon} />
                <div className="ms-2">
                  <p className="mb-0 text-dark">Gorge Filly</p>
                  <small className="dark-gray">Recruiter </small>
                </div>
              </div>
            </td>
            <td>12 Dec, 2023</td>
            <td>
              <Image src={EmailIcon} onClick={() => setShowEmail(true)} />
            </td>
          </tr>
        </tbody>
      </Table>
      <Modal
        show={showEmail}
        onHide={() => setShowEmail(false)}
        className="sideModal request-empale-modal"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton className="p-4 bg-dark text-white">
          Welcome to the Team !
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="p-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <Image src={UserImagesIcon} width={'35px'} />
                <div className="ms-2">
                  <p className="mb-0 text-dark">
                    Gorge Filly{' '}
                    <small className="dark-gray">
                      {' '}
                      {'<gorge09@gmail.com>'}
                    </small>
                  </p>
                  <span className="dark-gray">to: Sam Smith </span>
                </div>
              </div>
              <div>12 Dec 2023, 12:42 am</div>
            </div>
            <hr className="custom-hr" />
            <div>
              <p className="mb-4">Dear Sam Smith,</p>
              <p className="mb-4">
                Welcome to Staffing Company! We're thrilled to have you on
                board. Your expertise and talents make you a great addition to
                our team. <br /> As you embark on this new journey with us, feel
                free to reach out if you have any questions or need assistance.
                We're here to support you. <br /> We're planning a casual
                welcome gathering on 12 Dec, 2023. It's a great opportunity to
                meet your new colleagues and get a feel for our collaborative
                environment. <br />
                Once again, welcome to the team! We're looking forward to
                achieving success together. <br />
              </p>
              <p>Best regards, </p>
              <p>Gorge Filly Recruiter</p>
              <p>Staffing Company</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Email
