import React from 'react'
import { Container, Image, Table } from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { Link } from 'react-router-dom'

import NotificationProfiler from '../../../assets/images/notification-profiler.svg'

const Notifications = () => {
  return (
    <>
      <Container className="p-5 shadow-card-box">
        <Link to="/" className="h4 d-block mb-4">
          <IoMdArrowBack className="me-2" />
          Notifications
        </Link>
        <div className="card">
          <div className="card-body p-3 m-1">
            <div className="table-responsive">
              <Table bordered hover className="overflow-scroll">
                <thead>
                  <tr className="border-0">
                    <th>Category </th>
                    <th>Message</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-0">
                    <td>Organisation Users</td>
                    <td>
                      <div className="d-flex">
                        <Image src={NotificationProfiler} />
                        <p className="m-0 ms-2">
                          James Doe added{' '}
                          <span className="text-primary fw-bold">
                            Kathy Samuel
                          </span>{' '}
                          as recruiter to organisation
                        </p>
                      </div>
                    </td>
                    <td>just now</td>
                  </tr>
                  <tr className="border-0">
                    <td>Candidate Assessments</td>
                    <td>
                      <div className="d-flex">
                        <Image src={NotificationProfiler} />
                        <p className="m-0 ms-2">
                          ABC Software Solution posted 2 new job
                        </p>
                      </div>
                    </td>
                    <td>Today, 10:21 AM</td>
                  </tr>
                  <tr className="border-0">
                    <td>Client Interactions</td>
                    <td>
                      <div className="d-flex">
                        <Image src={NotificationProfiler} />
                        <p className="m-0 ms-2">
                          ABC Software Solution posted new job for{' '}
                          <span className="text-primary fw-bold">
                            React JS Developer
                          </span>
                        </p>
                      </div>
                    </td>
                    <td>2 Jan 23, 10:01 AM</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Notifications
