import { Form as FormikForm, Formik, FormikProvider, useFormik } from 'formik'
import moment from 'moment'
import React, { useEffect } from 'react'
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import * as Yup from 'yup'

import RightBlackArrowIcon from '../../assets/images/right-black-arrow.svg'
import { Loader } from '../../components/Loader'
import {
  examByIdList,
  examUpdate,
  getExamDetailsThunk,
} from '../../redux/exam/examActions'
import { Store } from '../../redux/rootType'
import { dispatch } from '../../redux/store'
import { RESULT_VALIDATION_BUFFER } from '../../validations/examSchema/examSchema'
import CustomTimePicker from './CustomTimePicker'
import DatePickerField from './DatePickerField'

const selectEvolutionModeOptions = [
  {
    value: 1,
    label: 'Normal',
  },
  {
    value: 2,
    label: 'Negative Marking',
  },
]

const EditExamDetails = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const examId = params['Id']
  const examDetails = useSelector(
    (state: Store) => state?.examReducer?.examDataById
  )

  const isLoading = useSelector((state: Store) => state?.examReducer?.isLoading)

  const isValidFromDisabled = moment(examDetails?.valid_from).isBefore(moment())
  const isValidTillDisabled = moment(examDetails?.valid_to).isBefore(moment())
  const isDeclarationDisabled =
    moment(examDetails?.declare_results_at).isBefore(moment()) ||
    moment(examDetails?.declare_results_at).diff(moment(), 'hour') <= 24
  const isDisableAll =
    isValidFromDisabled && isValidTillDisabled && isDeclarationDisabled

  const initialValues = {
    examValidFromDate: examDetails?.valid_from
      ? moment(examDetails?.valid_from)?.toDate()
      : moment(),
    examValidFromTime: examDetails?.valid_from
      ? moment(examDetails?.valid_from)?.toDate()
      : moment(),
    examValidTillDate: examDetails?.valid_to
      ? moment(examDetails?.valid_to)?.toDate()
      : moment(),
    examValidTillTime: examDetails?.valid_to
      ? moment(examDetails?.valid_to)?.toDate()
      : moment(),
    resultDeclarationDate: examDetails?.valid_to
      ? moment(examDetails?.declare_results_at)?.toDate()
      : moment(),
    resultDeclarationTime: examDetails?.valid_to
      ? moment(examDetails?.declare_results_at)?.toDate()
      : moment(),
  }

  const validationSchema = Yup.object().shape({
    examValidFromDate: Yup.date().when({
      is: () => isValidFromDisabled,
      then: Yup.date(),
      otherwise: Yup.date().required(),
    }),
    examValidFromTime: Yup.date().when({
      is: () => isValidFromDisabled,
      then: Yup.date(),
      otherwise: Yup.date()
        .required('Valid from time is required')
        .test('examValidFromTime', 'Error', function (val, testContext) {
          const { examValidFromTime, examValidFromDate } = this.parent
          if (!examValidFromTime) return true
          const selectedDate = moment(examValidFromDate)?.format('YYYY-MM-DD')
          const selectedTime = moment(examValidFromTime).format('HH:mm')

          if (
            moment(`${selectedDate} ${selectedTime}`).isBefore(
              moment(moment().format('YYYY-MM-DD HH:mm'))
            )
          ) {
            return testContext.createError({
              ...testContext,
              message: 'Time must be greater than current time',
            })
          }
          return true
        }),
    }),
    examValidTillDate: Yup.date().when({
      is: () => isValidTillDisabled,
      then: Yup.date(),
      otherwise: Yup.date()
        .required('Valid till date is required')
        .test('examValidTillDate', 'Error', function (val, testContext) {
          const { examValidFromDate, examValidTillTime } = this.parent
          if (!examValidTillTime) return true
          const validTillDate = moment(val).format('YYYY-MM-DD')
          const validFromDate = moment(examValidFromDate).format('YYYY-MM-DD')

          if (moment(`${validTillDate}`).isBefore(moment(`${validFromDate}`))) {
            return testContext.createError({
              ...testContext,
              message: 'Date must be greater than from date',
            })
          }

          return true
        }),
    }),
    examValidTillTime: Yup.date().when({
      is: isValidTillDisabled,
      then: Yup.date(),
      otherwise: Yup.date()
        .required('Valid till time is required')
        .test('examValidTillTime', '', function (val, testContext) {
          const {
            examValidFromTime,
            examValidFromDate,
            examValidTillDate,
            examValidTillTime,
          } = this.parent
          const selectedDate = moment(examValidFromDate)?.format('YYYY-MM-DD')
          const selectedTime = moment(examValidFromTime).format('HH:mm')
          const selectedToDate = moment(examValidTillDate)?.format('YYYY-MM-DD')
          const selectedToTime = moment(examValidTillTime).format('HH:mm')
          if (
            moment(
              moment(`${selectedToDate} ${selectedToTime}`).format(
                'YYYY-MM-DD HH:mm'
              )
            ).isSameOrBefore(
              moment(`${selectedDate} ${selectedTime}`).format(
                'YYYY-MM-DD HH:mm'
              )
            )
          ) {
            return testContext.createError({
              ...testContext,
              message: 'Time must be greater than from time',
            })
          } else if (
            moment(
              moment(`${selectedToDate} ${selectedToTime}`).format(
                'YYYY-MM-DD HH:mm'
              )
            ).isSameOrBefore(moment().format('YYYY-MM-DD HH:mm'))
          ) {
            return testContext.createError({
              ...testContext,
              message: 'Time must be greater than current time',
            })
          }
          return true
        }),
    }),
    resultDeclarationDate: Yup.date().when({
      is: isDeclarationDisabled,
      then: Yup.date(),
      otherwise: Yup.date()
        .required('Declaration date is required')
        .test('resultDeclarationDate', 'Error', function (val, testContext) {
          const { resultDeclarationTime, examValidTillDate } = this.parent
          if (
            moment(moment(val).format('YYYY-MM-DD')).isBefore(
              moment(examValidTillDate).format('YYYY-MM-DD')
            )
          ) {
            return testContext.createError({
              ...testContext,
              message:
                'Result declaration date must be greater than exam till date',
            })
          }
          if (!resultDeclarationTime) return true

          return true
        }),
    }),
    resultDeclarationTime: Yup.date().when({
      is: isDeclarationDisabled,
      then: Yup.date(),
      otherwise: Yup.date()
        .required('Declaration time is required')
        .test('resultDeclarationTime', '', function (val, testContext) {
          const {
            examValidTillTime,
            examValidTillDate,
            resultDeclarationDate,
          } = this.parent
          const selectedDate = moment(examValidTillDate)?.format('YYYY-MM-DD')
          const selectedTime = moment(examValidTillTime).format('HH:mm')
          const declareDate = moment(resultDeclarationDate)?.format(
            'YYYY-MM-DD'
          )
          const declareTime = moment(val).format('HH:mm')
          if (
            moment(
              moment(`${declareDate} ${declareTime}`).format('YYYY-MM-DD HH:mm')
            ).isSameOrBefore(
              moment(`${selectedDate} ${selectedTime}`)
                .add({
                  minute: examDetails?.duration + RESULT_VALIDATION_BUFFER || 0,
                })
                .format('YYYY-MM-DD HH:mm')
            )
          ) {
            return testContext.createError({
              ...testContext,
              message:
                'Result declarations time must be greater than exam valid till time + exam duration + 5 minutes as buffer time.',
            })
          } else if (
            moment(
              moment(`${declareDate} ${declareTime}`).format('YYYY-MM-DD HH:mm')
            ).isSameOrBefore(moment().format('YYYY-MM-DD HH:mm'))
          ) {
            return testContext.createError({
              ...testContext,
              message: 'Time must be greater than current time',
            })
          }
          return true
        }),
    }),
  })

  const handleSubmit = (values: typeof initialValues, actions) => {
    const payload = {
      valid_from: !isValidFromDisabled
        ? `${moment(values.examValidFromDate).format('YYYY-MM-DD')} ${moment(
            values.examValidFromTime
          ).format('HH:mm')}`
        : undefined,
      valid_to: !isValidTillDisabled
        ? `${moment(values.examValidTillDate).format('YYYY-MM-DD')} ${moment(
            values.examValidTillTime
          ).format('HH:mm')}`
        : undefined,
      declare_results_at: !isDeclarationDisabled
        ? `${moment(values.resultDeclarationDate).format(
            'YYYY-MM-DD'
          )} ${moment(values.resultDeclarationTime).format('HH:mm')}`
        : undefined,
    }
    dispatch(
      examId &&
        examUpdate(
          +examId,
          payload,
          () => navigate('/exams'),
          () => actions.setSubmitting(false)
        )
    )
  }

  useEffect(() => {
    examId && dispatch(examByIdList(false, examId))
  }, [])

  return (
    <Container className="my-5 exam-detaila p-10">
      <div className="mb-3 pb-1">
        <span className="dark-gray pointer" onClick={() => navigate('/exams')}>
          Exam
        </span>
        <Image src={RightBlackArrowIcon} className="mx-2" />
        <span className="dark-gray">Update Exam</span>
      </div>
      {/* <div className="d-flex align-items-left mb-3">
        <div className="d-flex align-items-left">
          
          <div className="d-flex mb-30 font-size-14">
            <Link to="/exams" className="text-muted fw-semibold">
              Exam <i className="fas fa-angle-right fa-fw"></i>
            </Link>
            <p className="text-dark fw-semibold m-0">Update Exam</p>
          </div>
        </div>
      </div> */}
      <div className="page-card">
        {isLoading ? (
          <Loader height="500px" />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ handleSubmit, values }) => {
              return (
                <Container className="exam-body">
                  <div className="card-body">
                    <div className="max-width-600 mx-auto">
                      <FormikForm onSubmit={handleSubmit}>
                        {isValidTillDisabled &&
                          isValidFromDisabled &&
                          isDeclarationDisabled && (
                            <p className="text-danger">
                              {' '}
                              You can not edit this exam.
                              <br /> Your 'Exam To' date & time is{' '}
                              {moment(examDetails?.valid_to)?.format(
                                'DD-MM-YYYY hh:mm A'
                              )}
                            </p>
                          )}
                        <p className="m-0 mb-4">
                          Exam Duration :
                          <span className="fw-semibold text-dark">
                            {' '}
                            {examDetails?.duration} min{' '}
                          </span>
                        </p>
                        <Row>
                          <Col lg={6}>
                            <Form.Group
                              className="form-group"
                              controlId="formDesignation"
                            >
                              <Form.Label>Exam Valid Form</Form.Label>
                              <div className="d-flex justify-content-between">
                                <DatePickerField
                                  id="examValidFromDate"
                                  name="examValidFromDate"
                                  disabled={isValidFromDisabled}
                                />
                                {/* <TimePickerField
                            id="examValidFromTime"
                            name="examValidFromTime"
                            submitOnce
                            disabled={isValidFromDisabled}
                          /> */}
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="form-group"
                              controlId="formDesignation"
                            >
                              <CustomTimePicker
                                id="examValidFromTime"
                                name="examValidFromTime"
                                value={values?.examValidFromTime}
                                disabled={isValidFromDisabled}
                                date={values?.examValidFromDate}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="form-group"
                              controlId="formDesignation"
                            >
                              <Form.Label>Exam Valid Till</Form.Label>
                              <div className="d-flex justify-content-between">
                                <DatePickerField
                                  id="examValidTillDate"
                                  name="examValidTillDate"
                                  disabled={isValidTillDisabled}
                                />
                              </div>
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="form-group"
                              controlId="formDesignation"
                            >
                              <CustomTimePicker
                                id="examValidTillTime"
                                name="examValidTillTime"
                                disabled={isValidTillDisabled}
                                date={values?.examValidTillDate}
                              />
                            </Form.Group>
                          </Col>
                          {/* <Col lg={6}>
                            <Form.Group
                              className="form-group"
                              controlId="formDesignation"
                            >
                              <Form.Label>Result Declaration Date</Form.Label>
                              <div className="d-flex justify-content-between">
                                <DatePickerField
                                  id="resultDeclarationDate"
                                  name="resultDeclarationDate"
                                  disabled={isDeclarationDisabled}
                                />
                              </div>
                              {moment(examDetails?.declare_results_at).diff(
                                moment(),
                                'hour'
                              ) <= 24 && (
                                <p className="text-danger">
                                  You can not update result decalaration date
                                  before 1 day of date.
                                </p>
                              )}
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="form-group"
                              controlId="formDesignation"
                            >
                              <CustomTimePicker
                                id="resultDeclarationTime"
                                name="resultDeclarationTime"
                                disabled={isDeclarationDisabled}
                                value={values?.resultDeclarationTime}
                                date={values?.resultDeclarationDate}
                              />
                              {moment(examDetails?.declare_results_at).diff(
                                moment(),
                                'hour'
                              ) <= 24 && (
                                <p className="text-danger">
                                  You can not update result decalaration date
                                  before 1 day of date.
                                </p>
                              )}
                            </Form.Group>
                          </Col> */}
                        </Row>

                        <div className="d-flex justify-content-between">
                          <button
                            type="button"
                            className="btn  btn-secondary"
                            onClick={() => navigate('/exams')}
                          >
                            Cancel
                          </button>
                          <button
                            disabled={isDisableAll}
                            type="submit"
                            className="btn btn-primary"
                          >
                            Update
                          </button>
                        </div>
                      </FormikForm>
                    </div>
                  </div>
                </Container>
              )
            }}
          </Formik>
        )}
      </div>
    </Container>
  )
}

export default EditExamDetails
