import { Action, Store } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { apiCall, METHOD } from '../../service/baseApiCall'
import { errorToast } from '../../util/utils'
import { dispatch } from '../store'
import {
  GET_USER_MOVEMENT_IMAGES_ACTION,
  GET_USER_MOVEMENT_IMAGES_ZIP_ACTION,
} from './proctoringExamConst'

const REACT_APP_BASE_PROCTORING_IMAGE_URL =
  'https://proctoring.prep.study:8001/get-user-images'
const REACT_APP_BASE_PROCTORING_IMAGE_ZIP_URL =
  'https://proctoring.prep.study:8001/get-user-images-zip'

export const getUserProctoringImages =
  (
    examID: string,
    studentId: any
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_MOVEMENT_IMAGES_ACTION.USER_MOVEMENT_IMAGES_REQUEST,
    })
    apiCall(
      `${REACT_APP_BASE_PROCTORING_IMAGE_URL}/${examID}/${studentId}`,
      (res) => {
        dispatch({
          type: GET_USER_MOVEMENT_IMAGES_ACTION.USER_MOVEMENT_IMAGES_SUCCESS,
          payload: res?.url_list,
        })
      },
      () => {
        dispatch({
          type: GET_USER_MOVEMENT_IMAGES_ACTION.USER_MOVEMENT_IMAGES_ERROR,
        })
        errorToast('Something went wrong.')
      },
      {
        params: {},
      },
      METHOD.GET,
      { showToast: false }
    )
  }

export const getUserProctoringImagesZip =
  (
    examID: string,
    studentId: any
  ): ThunkAction<void, Store, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: GET_USER_MOVEMENT_IMAGES_ZIP_ACTION.USER_MOVEMENT_IMAGES_ZIP_REQUEST,
    })
    apiCall(
      `${REACT_APP_BASE_PROCTORING_IMAGE_ZIP_URL}/${examID}/${studentId}`,
      (res) => {
        dispatch({
          type: GET_USER_MOVEMENT_IMAGES_ZIP_ACTION.USER_MOVEMENT_IMAGES_ZIP_SUCCESS,
          payload: res,
        })
      },
      () => {
        dispatch({
          type: GET_USER_MOVEMENT_IMAGES_ZIP_ACTION.USER_MOVEMENT_IMAGES_ZIP_ERROR,
        })
        errorToast('Something went wrong.')
      },
      {
        params: {},
      },
      METHOD.GET,
      { showToast: false }
    )
  }

//   const getUserProctoringImagesZip = (examID, studentId) => {
//     dispatch({
//       type: GET_USER_MOVEMENT_IMAGES_ZIP_ACTION.USER_MOVEMENT_IMAGES_ZIP_INITIALIZATION,
//     })
//     axios
//       .get(`${REACT_APP_BASE_PROCTORING_IMAGE_ZIP_URL}/${examID}/${studentId}`)
//       .then((response) => {
//         dispatch({
//           type: GET_USER_MOVEMENT_IMAGES_ZIP_ACTION.USER_MOVEMENT_IMAGES_ZIP_SUCCESS,
//           payload: response?.data,
//         })
//       })
//       .catch((error) => {
//         dispatch({
//           type: GET_USER_MOVEMENT_IMAGES_ZIP_ACTION.USER_MOVEMENT_IMAGES_ZIP_FAILED,
//         })
//         showErrorToastMessage('Something went wrong.')
//       })
//   }

export // getUserMovementImages,
// getUserProctoringImages,
// getUserProctoringImagesZip,
 {}
