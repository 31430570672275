import { useState } from 'react'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const HelpCenterFaqDetailReadmore = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [show2, setShow2] = useState(false)
  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  const [show3, setShow3] = useState(false)
  const handleClose3 = () => setShow3(false)
  const handleShow3 = () => setShow3(true)

  const [show4, setShow4] = useState(false)
  const handleClose4 = () => setShow4(false)
  const handleShow4 = () => setShow4(true)

  const [show5, setShow5] = useState(false)
  const handleClose5 = () => setShow5(false)
  const handleShow5 = () => setShow5(true)

  return (
    <Container className="px-4 py-5 shadow-card-box">
      <div className="d-flex align-items-center mb-4">
        <Link to="" className="fw-semibold">
          Help center
        </Link>
        <i className="fa fa-angle-right fa-fw ms-1 me-1"></i>
        <Link to="" className="fw-semibold">
          FAQ
        </Link>
        <i className="fa fa-angle-right fa-fw ms-1 me-1"></i>
        <p className="mb-0 light-gray-text fw-medium">Get Started</p>
        <i className="fa fa-angle-right fa-fw ms-1 me-1"></i>
        <p className="mb-0 light-gray-text fw-medium">
          How to create Question Paper?
        </p>
      </div>
      <div className="verical-tab mb-5">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col lg={4}>
              <div className="card">
                <div className="card-body">
                  <Nav variant="pills" className="flex-column">
                    <p className="mb-3 ps-4 pb-3 fw-bold font-size-16 title-border">
                      Get Started
                    </p>
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        How to build strong profile?
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        How to create question paper and get more buys?
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        How to create Question paper?
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="four">What is question set?</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="five">
                        What is upload question set?
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="six">
                        How to create Question paper?
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="card">
                <div className="card-body p-4">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="mb-4">
                        <h1 className="font-size-22 text-body fw-semibold mb-1">
                          How to build strong profile?
                        </h1>
                        <p className="sub-title-clr font-size-16 mb-4">
                          For an organization seeking talent from campuses or
                          the open market, we have the tools and expertise to
                          streamline your recruitment process and fulfil your
                          hiring needs. With our cutting-edge platform, HR
                          professionals can easily conduct comprehensive
                          assessments tailored to their requirements.
                        </p>
                        <p className="sub-title-clr font-size-16 mb-4">
                          With a focus on innovation, efficiency, and tailored
                          strategies, our services are designed to elevate your
                          journey towards success.
                        </p>
                        <p className="sub-title-clr font-size-16 mb-4">
                          Our advanced ecosystem combines cutting-edge features
                          like profile aging analysis, hiring analysis, and
                          automated job description matching to reshape how
                          organisations and individuals achieve their goals.
                        </p>
                      </div>

                      <div className="text-start mt-5 pt-5">
                        <button
                          className="btn btn-light py-2 px-4 font-size-16"
                          type="button"
                        >
                          Back
                        </button>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="mb-4">
                        <h1 className="font-size-22 text-body fw-semibold mb-1">
                          How to create question paper and get more buys?
                        </h1>
                        <p className="sub-title-clr font-size-16 mb-4">
                          For an organization seeking talent from campuses or
                          the open market, we have the tools and expertise to
                          streamline your recruitment process and fulfil your
                          hiring needs. With our cutting-edge platform, HR
                          professionals can easily conduct comprehensive
                          assessments tailored to their requirements.
                        </p>
                        <p className="sub-title-clr font-size-16 mb-4">
                          With a focus on innovation, efficiency, and tailored
                          strategies, our services are designed to elevate your
                          journey towards success.
                        </p>
                        <p className="sub-title-clr font-size-16 mb-4">
                          Our advanced ecosystem combines cutting-edge features
                          like profile aging analysis, hiring analysis, and
                          automated job description matching to reshape how
                          organisations and individuals achieve their goals.
                        </p>
                      </div>
                      <div className="text-start mt-5 pt-5">
                        <button
                          className="btn btn-light py-2 px-4 font-size-16"
                          type="button"
                        >
                          Back
                        </button>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="mb-4">
                        <h1 className="font-size-22 text-body fw-semibold mb-1">
                          How to create Question paper?
                        </h1>
                        <p className="sub-title-clr font-size-16 mb-4">
                          For an organization seeking talent from campuses or
                          the open market, we have the tools and expertise to
                          streamline your recruitment process and fulfil your
                          hiring needs. With our cutting-edge platform, HR
                          professionals can easily conduct comprehensive
                          assessments tailored to their requirements.
                        </p>
                        <p className="sub-title-clr font-size-16 mb-4">
                          With a focus on innovation, efficiency, and tailored
                          strategies, our services are designed to elevate your
                          journey towards success.
                        </p>
                        <p className="sub-title-clr font-size-16 mb-4">
                          Our advanced ecosystem combines cutting-edge features
                          like profile aging analysis, hiring analysis, and
                          automated job description matching to reshape how
                          organisations and individuals achieve their goals.
                        </p>
                      </div>
                      <div className="text-start mt-5 pt-5">
                        <button
                          className="btn btn-light py-2 px-4 font-size-16"
                          type="button"
                        >
                          Back
                        </button>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="four">
                      <div className="mb-4">
                        <h1 className="font-size-22 text-body fw-semibold mb-1">
                          What is question set?
                        </h1>
                        <p className="sub-title-clr font-size-16 mb-4">
                          For an organization seeking talent from campuses or
                          the open market, we have the tools and expertise to
                          streamline your recruitment process and fulfil your
                          hiring needs. With our cutting-edge platform, HR
                          professionals can easily conduct comprehensive
                          assessments tailored to their requirements.
                        </p>
                        <p className="sub-title-clr font-size-16 mb-4">
                          With a focus on innovation, efficiency, and tailored
                          strategies, our services are designed to elevate your
                          journey towards success.
                        </p>
                        <p className="sub-title-clr font-size-16 mb-4">
                          Our advanced ecosystem combines cutting-edge features
                          like profile aging analysis, hiring analysis, and
                          automated job description matching to reshape how
                          organisations and individuals achieve their goals.
                        </p>
                      </div>
                      <div className="text-start mt-5 pt-5">
                        <button
                          className="btn btn-light py-2 px-4 font-size-16"
                          type="button"
                        >
                          Back
                        </button>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="five">
                      <div className="mb-4">
                        <h1 className="font-size-22 text-body fw-semibold mb-1">
                          What is upload question set?
                        </h1>
                        <p className="sub-title-clr font-size-16 mb-4">
                          For an organization seeking talent from campuses or
                          the open market, we have the tools and expertise to
                          streamline your recruitment process and fulfil your
                          hiring needs. With our cutting-edge platform, HR
                          professionals can easily conduct comprehensive
                          assessments tailored to their requirements.
                        </p>
                        <p className="sub-title-clr font-size-16 mb-4">
                          With a focus on innovation, efficiency, and tailored
                          strategies, our services are designed to elevate your
                          journey towards success.
                        </p>
                        <p className="sub-title-clr font-size-16 mb-4">
                          Our advanced ecosystem combines cutting-edge features
                          like profile aging analysis, hiring analysis, and
                          automated job description matching to reshape how
                          organisations and individuals achieve their goals.
                        </p>
                      </div>
                      <div className="text-start mt-5 pt-5">
                        <button
                          className="btn btn-light py-2 px-4 font-size-16"
                          type="button"
                        >
                          Back
                        </button>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="six">
                      <div className="mb-4">
                        <h1 className="font-size-22 text-body fw-semibold mb-1">
                          What is Prep.study?
                        </h1>
                        <p className="sub-title-clr font-size-16 mb-4">
                          For an organization seeking talent from campuses or
                          the open market, we have the tools and expertise to
                          streamline your recruitment process and fulfil your
                          hiring needs. With our cutting-edge platform, HR
                          professionals can easily conduct comprehensive
                          assessments tailored to their requirements.
                        </p>
                        <p className="sub-title-clr font-size-16 mb-4">
                          With a focus on innovation, efficiency, and tailored
                          strategies, our services are designed to elevate your
                          journey towards success.
                        </p>
                        <p className="sub-title-clr font-size-16 mb-4">
                          Our advanced ecosystem combines cutting-edge features
                          like profile aging analysis, hiring analysis, and
                          automated job description matching to reshape how
                          organisations and individuals achieve their goals.
                        </p>
                      </div>
                      <div className="text-start mt-5 pt-5">
                        <button
                          className="btn btn-light py-2 px-4 font-size-16"
                          type="button"
                        >
                          Back
                        </button>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </Container>
  )
}

export default HelpCenterFaqDetailReadmore
