import { ApexOptions } from 'apexcharts'
import _ from 'lodash'
import ReactApexChart from 'react-apexcharts'
import { Col, Container, Image, Row } from 'react-bootstrap'

import Candidate from '../../../../assets/images/candidate-submission.png'
import CandidateSubmittedIcon from '../../../../assets/images/CandidateSubmitted.svg'
import JobPostedIcon from '../../../../assets/images/JobPostedIcon.svg'
import NameBadgeIcon from '../../../../assets/images/name-badge.svg'

const ActivityOverview = () => {
  const maxNumber = Math.ceil((_.max([...[11, 32, 45, 32]]) || 0 + 1) / 10) * 10
  const bars: any = {
    series: [
      {
        name: '',
        data: [11, 32, 45, 32],
      },
    ],
    options: {
      colors: ['#FBD257', '#BFCC2F', '#F24A4A', '#009970'],
      chart: {
        type: 'bar',
        height: 350,
        fontFamily: 'Lato',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        // bar: {
        //   horizontal: false,
        //   columnWidth: '10%',
        //   borderRadius: 3,
        //   borderRadiusApplication: 'start',
        // },
        bar: {
          columnWidth: '15%',
          distributed: true,
        },
      },
      elements: {
        bar: {
          borderWidth: 10,
          borderRadius: 50,
        },
      },
      markers: {
        size: [4, 4, 4],
        hover: {
          size: 7,
          sizeOffset: 0,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        // show: true,
        width: 2,
        curve: 'straight',
        colors: ['#FBD257', '#BFCC2F', '#F24A4A', '#009970'],
      },
      xaxis: {
        title: {
          offsetY: 90,
          text: 'Months',
          style: {
            fontSize: '12px',
            fontFamily: 'Lato',
            fontWeight: 500,
          },
        },
        categories: ['Selected', 'Offered', 'Rejected', 'Placed'],
        labels: {
          style: {
            colors: '#A7A5A5',
            fontSize: '14px',
            fontFamily: 'Lato',
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        forceNiceScale: false,
        decimalsInFloat: 0,
        // title: {
        //   text: 'Submissions',
        //   style: {
        //     fontSize: '12px',
        //     fontFamily: 'Lato',
        //     fontWeight: 500,
        //   },
        // },
        max: maxNumber,
        // labels: {
        //   style: {
        //     colors: '#A7A5A5',
        //     fontSize: '14px',
        //     fontFamily: 'Lato',
        //     fontWeight: 700,
        //   },
        // },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: false,
      },
      // tooltip: {
      //   intersect: false,
      //   shared: true,
      //   // fixed: { enabled: true, position: 'topRight' },
      //   custom: function (params) {
      //     return `<div class="px-3 py-2" >
      //       <b class="m-0">${
      //         params?.w?.globals?.labels[params?.dataPointIndex]
      //       }</b>
      //       <div>
      //       <p class="mb-1 d-flex"> <span
      //       style="background-color: #219EBC"
      //       class="round-circle-small align-self-center"
      //     ></span>${params?.w?.globals?.seriesNames[0]} - ${
      //       params?.series[0][params?.dataPointIndex] || 0
      //     }</p>
      //       <p class="mb-1 d-flex"><span
      //       style="background-color: #ffad38"
      //       class="round-circle-small align-self-center"
      //     ></span>${params?.w?.globals?.seriesNames[1]} - ${
      //       params?.series[1][params?.dataPointIndex] || 0
      //     }</p>
      //       <p class="mb-1 d-flex"><span
      //       style="background-color: #00e19d"
      //       class="round-circle-small align-self-center"
      //     ></span>${params?.w?.globals?.seriesNames[2]} - ${
      //       params?.series[2][params?.dataPointIndex] || 0
      //     }</p>
      //       </div>
      //     </div>`
      //   },
      //   y: {
      //     formatter: function (val) {
      //       return '$ ' + val + ' thousands'
      //     },
      //   },
      // },
    },
  }
  const pieChartOptions: (
    series?: ApexNonAxisChartSeries,
    labels?: string[],
    colors?: string[],
    totalLabel?: string
  ) => { series: number[]; options: ApexOptions } = (
    series,
    labels,
    colors,
    totalLabel
  ) => ({
    series: series ?? [15, 2, 8],
    options: {
      chart: {
        type: 'pie',
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '90%',
            // labels: {
            //   show: true,
            //   name: {
            //     show: true,
            //     color: '#A7A5A5',
            //     fontFamily: 'Lato',
            //     fontWeight: 700,
            //     fontSize: '16px',
            //     formatter: (val) => {
            //       return val
            //     },
            //   },
            //   value: {
            //     show: true,
            //     color: '#404040',
            //     fontFamily: 'Lato',
            //     fontWeight: 600,
            //     fontSize: '24px',
            //   },
            //   total: {
            //     color: '#A7A5A5',
            //     fontSize: '16px',
            //     fontFamily: 'Lato',
            //     fontWeight: 700,
            //     show: true,
            //     label: totalLabel ?? 'Total reviews',
            //   },
            // },
          },
        },
      },
      labels: labels ?? ['UI Developer', 'React', 'Python'],
      colors: colors ?? ['#FF7606', '#00CDEA57', '#F8F4B9'],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0,
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 0.35,
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      tooltip: {
        // fillSeriesColor: false,
        custom: function ({ series, seriesIndex, w }) {
          return `<div class="px-3 py-2" >
                
                  <div>
                  <p class="mb-1 d-flex"> <span
                  style="background-color: ${w.globals?.colors[seriesIndex]}"
                  class="round-circle-small align-self-center"
                ></span>${w.globals.labels[seriesIndex]} - ${
            series[seriesIndex] || 0
          }</p>
                  
                  </div>
                </div>`
        },
      },
    },
  })

  const radialChartOptions: {
    series: (string | number)[]
    options: ApexOptions
  } = {
    series: [70 || 0],

    options: {
      chart: {
        // height: 550,
        type: 'radialBar',
        height: '100px',
      },
      colors: ['#27AE60'],
      grid: {
        padding: {
          top: -25,
          bottom: -29,
        },
      },
      plotOptions: {
        radialBar: {
          // startAngle: -135,
          // endAngle: 135,
          hollow: {
            size: '65%',
          },
          dataLabels: {
            show: true,
            value: {
              show: true,
              offsetY: -14,
              fontSize: '20',
              color: '#000000',
              fontWeight: 600,
            },
            name: {
              show: true,
              offsetY: 30,
              fontSize: '14',
              color: '#000000',
              fontWeight: 300,
            },
          },
        },
      },

      labels: [`Performance`],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
              height: 300,
            },
            legend: {
              display: false,
              position: 'bottom',
            },
          },
        },
      ],
    },
  }
  const maxNumberArea =
    Math.ceil(
      (_.max([...[11, 32, 45, 32, 34, 52, 41, 25, 10, 26, 22, 18]]) || 0 + 1) /
        10
    ) * 10
  const area: any = {
    series: [
      {
        name: 'Total Selected',
        // data: totalAssignedExams,
        data: [11, 32, 45, 32, 34, 52, 41, 25, 10, 26, 22, 18],
      },
    ],

    options: {
      colors: ['#00e19d'],
      chart: {
        type: 'area',
        height: 350,
        fontFamily: 'Lato',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%',
          borderRadius: 3,
          borderRadiusApplication: 'start',
        },
      },
      elements: {
        bar: {
          borderWidth: 10,
          borderRadius: 50,
        },
      },
      // markers: {
      //   size: [4, 4, 4],
      //   hover: {
      //     size: 7,
      //     sizeOffset: 0,
      //   },
      // },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        // show: true,
        width: 2,
        curve: 'smooth',
        colors: ['#00e19d'],
      },

      xaxis: {
        title: {
          offsetY: 90,
          text: 'Months',
          style: {
            fontSize: '12px',
            fontFamily: 'Lato',
            fontWeight: 500,
          },
        },
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        labels: {
          style: {
            colors: '#A7A5A5',
            fontSize: '14px',
            fontFamily: 'Lato',
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        forceNiceScale: false,
        decimalsInFloat: 0,
        title: {
          text: 'Performance (%)',
          style: {
            fontSize: '12px',
            fontFamily: 'Lato',
            fontWeight: 500,
          },
        },
        max: maxNumberArea,
        labels: {
          style: {
            colors: '#A7A5A5',
            fontSize: '14px',
            fontFamily: 'Lato',
            fontWeight: 700,
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.5,
          opacityTo: 0.5,
          stops: [0, 90, 100],
        },
      },
      legend: {
        show: false,
      },
      // tooltip: {
      //   intersect: false,
      //   shared: true,
      //   // fixed: { enabled: true, position: 'topRight' },
      //   custom: function (params) {
      //     return `<div class="px-3 py-2" >
      //       <b class="m-0">${
      //         params?.w?.globals?.labels[params?.dataPointIndex]
      //       }</b>
      //       <div>
      //       <p class="mb-1 d-flex"> <span
      //       style="background-color: #219EBC"
      //       class="round-circle-small align-self-center"
      //     ></span>${params?.w?.globals?.seriesNames[0]} - ${
      //       params?.series[0][params?.dataPointIndex] || 0
      //     }</p>
      //       <p class="mb-1 d-flex"><span
      //       style="background-color: #ffad38"
      //       class="round-circle-small align-self-center"
      //     ></span>${params?.w?.globals?.seriesNames[1]} - ${
      //       params?.series[1][params?.dataPointIndex] || 0
      //     }</p>
      //       <p class="mb-1 d-flex"><span
      //       style="background-color: #00e19d"
      //       class="round-circle-small align-self-center"
      //     ></span>${params?.w?.globals?.seriesNames[2]} - ${
      //       params?.series[2][params?.dataPointIndex] || 0
      //     }</p>
      //       </div>
      //     </div>`
      //   },
      //   y: {
      //     formatter: function (val) {
      //       return '$ ' + val + ' thousands'
      //     },
      //   },
      // },
    },
  }
  return (
    <Container>
      <Row>
        <Col lg={8}>
          <div className="card page-content-card h-100">
            <div className="card-header d-flex align-items-center">
              <div className="d-flex align-items-center">
                <img src={CandidateSubmittedIcon} alt="Candidate Submitted" />
                <h5 className="fw-semibold ms-12 mb-0">Candidate Submitted</h5>
              </div>
            </div>
            <p className="mt-3 px-4">Total Candidates Submitted : 46</p>
            <div>
              <ReactApexChart
                options={bars.options}
                series={bars.series}
                type="bar"
                height={350}
              />
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="card page-content-card h-100">
            <div className="card-header d-flex align-items-center">
              <div className="d-flex align-items-center">
                <img src={JobPostedIcon} alt="Recent Exam" />
                <h5 className="fw-semibold ms-12 mb-0">Job Posted</h5>
              </div>
            </div>
            <p className="mt-3 px-4">Total Jobs Posted : 15</p>
            <div className="card-body d-flex align-items-center">
              <ReactApexChart {...pieChartOptions()} type="pie" height={240} />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="card page-content-card h-100">
            <div className="card-header d-flex align-items-center">
              <div className="d-flex align-items-center">
                <img src={Candidate} alt="Candidate Submitted" />
                <h5 className="fw-semibold ms-12 mb-0">Candidate Submitted</h5>
              </div>
            </div>
            <p className="fw-semibold mt-3 px-4">Assessment Outcomes</p>
            <Row>
              <Col lg={8}>
                <ReactApexChart
                  options={area.options}
                  series={area.series}
                  type="area"
                  height={350}
                />
              </Col>

              <Col lg={4}>
                <ReactApexChart
                  options={radialChartOptions?.options}
                  series={radialChartOptions?.series as any}
                  type="radialBar"
                  height={300}
                />
                <h5 className="p-3 text-center">
                  Overall Shared Candidates Performance
                </h5>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="card page-content-card h-100">
            <div className="card-header d-flex align-items-center">
              <div className="d-flex align-items-center">
                <img src={Candidate} alt="Candidate Submitted" />
                <h5 className="fw-semibold ms-12 mb-0">Rating & Feedback</h5>
              </div>
            </div>
            <div className="p-4">
              <div className="mt-4 d-flex ">
                <div
                  className="me-5 py-2 px-4 rounded text-white text-center"
                  style={{ backgroundColor: '#F0B357' }}
                >
                  <p className="font-size-20">Overall rating 3.5</p>
                  <i
                    className="fa fa-star mb-3"
                    style={{
                      color: '#ffffff',
                      fontSize: '60px',
                    }}
                  ></i>
                  <p className="font-size-18 mb-0">10 Ratings</p>
                </div>
                <div>
                  <div className="text-body font-size-14 lh-21 fw-normal d-flex align-items-center mb-3">
                    <span className="font-size-16 text-black-400 w-50">
                      Candidate Quality
                    </span>
                    <div
                      className="w-150 rounded mx-3"
                      style={{
                        backgroundColor: '#F2F2F2',
                        height: '5px',
                      }}
                    >
                      <div
                        className="rounded"
                        style={{
                          width: `55%`,
                          backgroundColor: 'orange',
                          height: '5px',
                        }}
                      ></div>
                    </div>
                    <span className="font-size-16 text-black-400">5</span>
                  </div>
                  <div className="text-body font-size-14 lh-21 fw-normal d-flex align-items-center mb-3">
                    <span className="font-size-16 text-black-400 w-50">
                      Communication
                    </span>
                    <div
                      className="w-150 rounded mx-3"
                      style={{
                        backgroundColor: '#F2F2F2',
                        height: '5px',
                      }}
                    >
                      <div
                        className="rounded"
                        style={{
                          width: `55%`,
                          backgroundColor: 'orange',
                          height: '5px',
                        }}
                      ></div>
                    </div>
                    <span className="font-size-16 text-black-400">5</span>
                  </div>
                  <div className="text-body font-size-14 lh-21 fw-normal d-flex align-items-center mb-3">
                    <span className="font-size-16 text-black-400 w-50">
                      Timeliness
                    </span>
                    <div
                      className="w-150 rounded mx-3"
                      style={{
                        backgroundColor: '#F2F2F2',
                        height: '5px',
                      }}
                    >
                      <div
                        className="rounded"
                        style={{
                          width: `20%`,
                          backgroundColor: 'orange',
                          height: '5px',
                        }}
                      ></div>
                    </div>
                    <span className="font-size-16 text-black-400">2</span>
                  </div>
                  <div className="text-body font-size-14 lh-21 fw-normal d-flex align-items-center mb-3">
                    <span className="font-size-16 text-black-400 w-50">
                      Fill Rate
                    </span>
                    <div
                      className="w-150 rounded mx-3"
                      style={{
                        backgroundColor: '#F2F2F2',
                        height: '5px',
                      }}
                    >
                      <div
                        className="rounded"
                        style={{
                          width: `80%`,
                          backgroundColor: 'orange',
                          height: '5px',
                        }}
                      ></div>
                    </div>
                    <span className="font-size-16 text-black-400">8</span>
                  </div>
                  <div className="text-body font-size-14 lh-21 fw-normal d-flex align-items-center mb-1">
                    <span className="font-size-16 text-black-400 w-50">
                      Market Knowledge
                    </span>
                    <div
                      className="w-150 rounded mx-3"
                      style={{
                        backgroundColor: '#F2F2F2',
                        height: '5px',
                      }}
                    >
                      <div
                        className="rounded"
                        style={{
                          width: `30%`,
                          backgroundColor: 'orange',
                          height: '5px',
                        }}
                      ></div>
                    </div>
                    <span className="font-size-16 text-black-400">3</span>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center review-border py-5">
                <div className="review-details">
                  <div className="d-flex align-items-center mb-4">
                    <Image
                      src={NameBadgeIcon}
                      className="me-3"
                      width={'35px'}
                    />
                    <div>
                      <span className="font-size-16 fw-normal d-block">
                        RoJhn Cartet
                      </span>
                      {Array.from(Array(5).keys())?.map(() => (
                        <i
                          className="fa fa-star font-size-12 me-1"
                          style={{
                            color: 'orange',
                          }}
                        ></i>
                      ))}

                      <span className="fw-bold font-size-12 lh-14 custom-text-clr">
                        {' '}
                        5 days ago
                      </span>
                    </div>
                  </div>
                  <p className="text-black-400  font-size-16 mb-0">
                    "Working with Staffing Company was a positive experience.
                    Their team provided valuable support, though we rated them
                    3.5 due to occasional communication delays."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default ActivityOverview
