import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {
  BUSINESS_CATEGORY_LIST,
  CITY_LIST,
  COMPANY_BOOKMARK,
  CONNECTION_REQUEST_UPDATE,
  GET_COMPANIES_LIST,
  GET_COMPANY_ACTIVE_JD,
  GET_COMPANY_DETAILS,
  SENT_CONNECTION_REQUEST,
} from './companiesConst'
import { ActionTypes, CompaniesData } from './companiesType'

const initialState = {
  isLoadingCompaniesList: false,
  // companiesList: {
  //   count: 0,
  //   results: [],
  // },
  companiesList: [],
  companyDetails: {},
  isLoadingCompanyDetails: false,
  companyActiveJD: [],
  isLoadingActiveJD: false,
  isLoadingBookmark: false,
  isLoadingConnectionRequestUpdate: false,
  isLoadingSentConnectionRequest: false,
  businessCategoryList: [],
  isLoadingBusinessCategoryList: false,
  cityList: [],
  isLoadingCityList: false,
}

const companiesReducer = persistReducer(
  {
    storage,
    key: 'companies',
    whitelist: [],
  },
  (state: CompaniesData = initialState, action: ActionTypes) => {
    switch (action.type) {
      // ==================================================
      // Companies list
      // ==================================================
      case GET_COMPANIES_LIST.GET_COMPANIES_LIST_REQUEST:
        return {
          ...state,
          isLoadingCompaniesList: true,
        }

      case GET_COMPANIES_LIST.GET_COMPANIES_LIST_SUCCESS:
        return {
          ...state,
          isLoadingCompaniesList: false,
          companiesList: action.payload,
        }

      case GET_COMPANIES_LIST.GET_COMPANIES_LIST_ERROR:
        return {
          ...state,
          isLoadingCompaniesList: false,
        }

      // ==================================================
      // Companies details
      // ==================================================
      case GET_COMPANY_DETAILS.GET_COMPANY_DETAILS_REQUEST:
        return {
          ...state,
          isLoadingCompanyDetails: true,
        }

      case GET_COMPANY_DETAILS.GET_COMPANY_DETAILS_SUCCESS:
        return {
          ...state,
          isLoadingCompanyDetails: false,
          companyDetails: action.payload,
        }

      case GET_COMPANY_DETAILS.GET_COMPANY_DETAILS_ERROR:
        return {
          ...state,
          isLoadingCompanyDetails: false,
        }
      // ==================================================
      // Company active jd
      // ==================================================
      case GET_COMPANY_ACTIVE_JD.GET_COMPANY_ACTIVE_JD_REQUEST:
        return {
          ...state,
          isLoadingActiveJD: true,
        }

      case GET_COMPANY_ACTIVE_JD.GET_COMPANY_ACTIVE_JD_SUCCESS:
        return {
          ...state,
          isLoadingActiveJD: false,
          companyActiveJD: action.payload,
        }

      case GET_COMPANY_ACTIVE_JD.GET_COMPANY_ACTIVE_JD_ERROR:
        return {
          ...state,
          isLoadingActiveJD: false,
        }
      // ==================================================
      // Company bookmark
      // ==================================================
      case COMPANY_BOOKMARK.COMPANY_BOOKMARK_REQUEST:
        return {
          ...state,
          isLoadingBookmark: true,
        }

      case COMPANY_BOOKMARK.COMPANY_BOOKMARK_SUCCESS:
        return {
          ...state,
          isLoadingBookmark: false,
        }

      case COMPANY_BOOKMARK.COMPANY_BOOKMARK_ERROR:
        return {
          ...state,
          isLoadingBookmark: false,
        }

      // ==================================================
      // Sent connection request
      // ==================================================
      case SENT_CONNECTION_REQUEST.SENT_CONNECTION_REQUEST_REQUEST:
        return {
          ...state,
          isLoadingSentConnectionRequest: true,
        }

      case SENT_CONNECTION_REQUEST.SENT_CONNECTION_REQUEST_SUCCESS:
        return {
          ...state,
          isLoadingSentConnectionRequest: false,
        }

      case SENT_CONNECTION_REQUEST.SENT_CONNECTION_REQUEST_ERROR:
        return {
          ...state,
          isLoadingSentConnectionRequest: false,
        }

      // ==================================================
      // Connection request update
      // ==================================================
      case CONNECTION_REQUEST_UPDATE.CONNECTION_REQUEST_UPDATE_REQUEST:
        return {
          ...state,
          isLoadingConnectionRequestUpdate: true,
        }

      case CONNECTION_REQUEST_UPDATE.CONNECTION_REQUEST_UPDATE_SUCCESS:
        return {
          ...state,
          isLoadingConnectionRequestUpdate: false,
        }

      case CONNECTION_REQUEST_UPDATE.CONNECTION_REQUEST_UPDATE_ERROR:
        return {
          ...state,
          isLoadingConnectionRequestUpdate: false,
        }
      // ==================================================
      // city list
      // ==================================================
      case CITY_LIST.CITY_LIST_REQUEST:
        return {
          ...state,
          isLoadingCityList: true,
        }

      case CITY_LIST.CITY_LIST_SUCCESS:
        return {
          ...state,
          isLoadingCityList: false,
          cityList: action.payload,
        }

      case CITY_LIST.CITY_LIST_ERROR:
        return {
          ...state,
          isLoadingCityList: false,
        }
      // ==================================================
      // city list
      // ==================================================
      case BUSINESS_CATEGORY_LIST.BUSINESS_CATEGORY_LIST_REQUEST:
        return {
          ...state,
          isLoadingBusinessCategoryList: true,
        }

      case BUSINESS_CATEGORY_LIST.BUSINESS_CATEGORY_LIST_SUCCESS:
        return {
          ...state,
          isLoadingBusinessCategoryList: false,
          businessCategoryList: action.payload,
        }

      case BUSINESS_CATEGORY_LIST.BUSINESS_CATEGORY_LIST_ERROR:
        return {
          ...state,
          isLoadingBusinessCategoryList: false,
        }
      default:
        return state
    }
  }
)

export default companiesReducer
