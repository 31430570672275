import { Image } from 'react-bootstrap'

import BagIcon from '../../../../assets/images/bag-icon.svg'
import LocationOnblackIcon from '../../../../assets/images/location_on_black.svg'
import SubmissionImg from '../../../../assets/images/submission-img.svg'

const ClosedJob = () => {
  return (
    <div className="card">
      <div className="card-body p-4">
        <div className="mb-4 pb-1 d-flex align-items-center justify-content-between">
          <h6 className="fw-normal mb-0">Sales Manager</h6>

          <span className="badge badge-danger p-2 rounded">Closed</span>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Image src={LocationOnblackIcon} />
            <small className="ms-1 fw-bold new-gray">Location</small>
            <br />
            <span>Chennai</span>
          </div>
          <div>
            <Image src={BagIcon} />
            <small className="ms-1 fw-bold new-gray">Experience</small>
            <br />
            <span>0-2 years</span>
          </div>
          <div className="vetical-saprater" />
          <div>
            <small className="ms-1 fw-bold new-gray">Submission</small>
            <br />
            <span className="d-flex">
              0 <Image src={SubmissionImg} className="ms-1" />
            </span>
          </div>
          <div>
            <small className="ms-1 fw-bold new-gray">Selected</small>
            <br />
            <span className="d-flex">
              0 <Image src={SubmissionImg} className="ms-1" />
            </span>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default ClosedJob
