import TextField from '@mui/material/TextField'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { connect, useField, useFormikContext } from 'formik'
import { at } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

const RESULT_BUFFER_TIME = 5

export default function DatePickerField(props) {
  const [open, setOpen] = useState(false)
  const [field, meta, helper] = useField(props)
  const formikProps = useFormikContext()
  const formValues = formikProps?.values as Record<string, string>
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error')
    if (touched && error) {
      return error
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        className={
          `${props.className} ${
            props.disabled || formValues?.disableAll ? 'disabled' : ''
          }` || ''
        }
        open={open}
        // onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={`${meta.value}`}
        PopperProps={{
          placement: 'bottom-start',
          sx: {
            transformOrigin: { horizontal: 'center', vertical: 'bottom' },
          },
          //popperOptions: { placement: "right-start" }
        }}
        onChange={(newValue) => {
          helper.setTouched(true)
          if (
            field?.name === 'examValidTillDate' &&
            formValues?.examValidFromDate &&
            formValues?.examValidFromTime &&
            formValues?.examValidTillTime &&
            formValues?.examDuration
          ) {
            // !formValues?.resultDeclarationTime &&
            formikProps.setFieldValue(
              'resultDeclarationTime',
              moment(formValues?.examValidTillTime)
                .add({
                  minute: +formValues?.examDuration + RESULT_BUFFER_TIME || 0,
                })
                ?.toDate()
            )
            // !formValues?.resultDeclarationDate &&
            formikProps.setFieldValue(
              'resultDeclarationDate',
              moment(newValue || '')
                ?.set({
                  hours: moment(formValues?.examValidTillTime || '')?.hours(),
                  minutes: moment(
                    formValues?.examValidTillTime || ''
                  )?.minutes(),
                })
                ?.add({
                  minute: +formValues?.examDuration + RESULT_BUFFER_TIME || 0,
                })
                ?.toDate()
            )
          }
          if (
            field?.name === 'examValidFromDate' &&
            formValues?.examValidTillDate &&
            formValues?.examValidFromTime &&
            formValues?.examDuration &&
            formValues?.examValidTillTime
          ) {
            // !formValues?.resultDeclarationTime &&
            formikProps.setFieldValue(
              'resultDeclarationTime',
              moment(formValues?.examValidTillTime)
                .add({
                  minute: +formValues?.examDuration + RESULT_BUFFER_TIME || 0,
                })
                ?.toDate()
            )
            // !formValues?.resultDeclarationDate &&
            formikProps.setFieldValue(
              'resultDeclarationDate',
              moment(formValues?.examValidTillDate)
                ?.set({
                  hours: moment(formValues?.examValidTillTime || '')?.hours(),
                  minutes: moment(
                    formValues?.examValidTillTime || ''
                  )?.minutes(),
                })
                ?.add({
                  minute: +formValues?.examDuration + RESULT_BUFFER_TIME || 0,
                })
                ?.toDate()
            )
          }
          newValue &&
            helper.setValue(moment(moment(newValue).format()).toDate())
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...{
                ...params,
                inputProps: {
                  ...params.inputProps,
                  readOnly: true,
                  name: field?.name || '',
                  id: props?.id || '',
                  placeholder: 'Date ',
                },
                InputLabelProps: {
                  shrink: true,
                },
              }}
              onClick={(event) => {
                if (formValues?.disableAll || props.disabled) return
                setOpen(true)
              }}
              error={(meta.touched && meta.error && true) || false}
              helperText={_renderHelperText()}
              fullWidth={props?.fullWidth || false}
            />
          )
        }}
        disablePast
        {...props}
        disabled={props.disabled || formValues?.disableAll}
      />
    </LocalizationProvider>
  )
}
