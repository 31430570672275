import { Action, Store } from 'redux'
import { ThunkAction } from 'redux-thunk'

import {
  CANDIDATE_RESUME_LIST_URL,
  GET_ALL_CANDIDATE_LIST_URL,
} from '../../service/apiEndPoints'
import { apiCall, METHOD } from '../../service/baseApiCall'
import { errorToast, successToast } from '../../util/utils'
import {
  ALL_CANDIDATE_LIST,
  CANDIDATE_RESUME_LIST,
} from './candidateManagementConst'

// ************** Get Resume Link ******************

export const getCandidateResumeListThunk = (
  payload,
  handleSuccess?: (...args) => void
): ThunkAction<void, Store, unknown, Action<string>> => {
  return (dispatch) => {
    dispatch({
      type: CANDIDATE_RESUME_LIST?.CANDIDATE_RESUME_LIST_REQUEST,
    })
    apiCall(
      CANDIDATE_RESUME_LIST_URL,
      (res) => {
        dispatch({
          type: CANDIDATE_RESUME_LIST.CANDIDATE_RESUME_LIST_SUCCESS,
          payload: res?.data,
        })

        handleSuccess && handleSuccess(res)
      },
      (err) => {
        dispatch({
          type: CANDIDATE_RESUME_LIST.CANDIDATE_RESUME_LIST_ERROR,
        })
        errorToast('Something went wrong')
      },
      { params: { ...payload } },
      METHOD.GET,
      {}
    )
  }
}

export const getAllCandidateListThunk = (
  payload,
  handleSuccess?: (...args) => void
): ThunkAction<void, Store, unknown, Action<string>> => {
  return (dispatch) => {
    dispatch({
      type: ALL_CANDIDATE_LIST?.ALL_CANDIDATE_LIST_REQUEST,
    })
    apiCall(
      GET_ALL_CANDIDATE_LIST_URL,
      (res) => {
        dispatch({
          type: ALL_CANDIDATE_LIST.ALL_CANDIDATE_LIST_SUCCESS,
          payload: res?.data,
        })

        handleSuccess && handleSuccess(res)
      },
      (err) => {
        dispatch({
          type: ALL_CANDIDATE_LIST.ALL_CANDIDATE_LIST_ERROR,
        })
        errorToast('Something went wrong')
      },
      { params: { ...payload } },
      METHOD.GET,
      {}
    )
  }
}
