import { Form, Image, InputGroup, Table } from 'react-bootstrap'

import NameBadgeIcon from '../../../../assets/images/name-badge.svg'
import SearchIcon from '../../../../assets/images/search-icon.svg'
import UserImagesIcon from '../../../../assets/images/User-images.svg'

const SharedCandidates = () => {
  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <InputGroup className="search-box">
          <InputGroup.Text id="basic-addon1">
            <Image src={SearchIcon} />
          </InputGroup.Text>
          <Form.Control
            placeholder="Search"
            aria-label="Search"
            aria-describedby="basic-addon1"
          />
        </InputGroup>
      </div>

      <Table bordered hover className="overflow-scroll">
        <thead>
          <tr className="border-0">
            {/* <th>
                          <Form.Check type={'checkbox'} id={`A`} />
                        </th> */}
            <th>Candidate Name</th>
            <th>Job</th>
            <th>Added by</th>
            <th>Added on</th>
            <th>Match %</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {/* <td>
                          <Form.Check type={'checkbox'} id={`A`} />
                        </td> */}
            <td>
              <Image src={NameBadgeIcon} className="me-1" />
              Raymond Dane
            </td>
            <td>
              <div className="ms-2">
                <p className="mb-0 text-dark"> UX UI designer</p>
                <small className="dark-gray">3-5 Years </small>
              </div>
            </td>
            <td>
              <div className="d-flex">
                <Image src={UserImagesIcon} />
                <div className="ms-2">
                  <p className="mb-0 text-dark">Gorge Filly</p>
                  <small className="dark-gray">Recruiter </small>
                </div>
              </div>
            </td>
            <td>12 Dec, 2023</td>
            <td>20 %</td>
            <td>
              <span className="badge badge-danger p-2 rounded ">Rejected</span>
            </td>
          </tr>

          <tr>
            {/* <td>
                          <Form.Check type={'checkbox'} id={`A`} />
                        </td> */}
            <td>
              <Image src={NameBadgeIcon} className="me-1" />
              Raymond Dane
            </td>
            <td>
              <div className="ms-2">
                <p className="mb-0 text-dark"> UX UI designer</p>
                <small className="dark-gray">3-5 Years </small>
              </div>
            </td>
            <td>
              <div className="d-flex">
                <Image src={UserImagesIcon} />
                <div className="ms-2">
                  <p className="mb-0 text-dark">Gorge Filly</p>
                  <small className="dark-gray">Recruiter </small>
                </div>
              </div>
            </td>
            <td>12 Dec, 2023</td>
            <td>20 %</td>
            <td>
              <span className="badge gray-badge p-2 rounded ">Submitted</span>
            </td>
          </tr>
          <tr>
            {/* <td>
                          <Form.Check type={'checkbox'} id={`A`} />
                        </td> */}
            <td>
              <Image src={NameBadgeIcon} className="me-1" />
              Raymond Dane
            </td>
            <td>
              <div className="ms-2">
                <p className="mb-0 text-dark"> UX UI designer</p>
                <small className="dark-gray">3-5 Years </small>
              </div>
            </td>
            <td>
              <div className="d-flex">
                <Image src={UserImagesIcon} />
                <div className="ms-2">
                  <p className="mb-0 text-dark">Gorge Filly</p>
                  <small className="dark-gray">Recruiter </small>
                </div>
              </div>
            </td>
            <td>12 Dec, 2023</td>
            <td>20 %</td>
            <td>
              <span className="badge badge-info p-2 rounded ">Selected</span>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  )
}

export default SharedCandidates
