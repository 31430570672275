import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  Container,
  Dropdown,
  Form,
  Image,
  Modal,
  Nav,
  Tab,
  Table,
} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import CalenderIcon from '../../../assets/images/calender-icon.svg'
import GrayReviewStarIcon from '../../../assets/images/gray-review-star.svg'
import ReviewStarIcon from '../../../assets/images/review-star.svg'
import RightBlackArrowIcon from '../../../assets/images/right-black-arrow.svg'
import UserImagesIcon from '../../../assets/images/User-images.svg'
import VerticalDotsIcon from '../../../assets/images/vertical-dots.svg'
import { Loader } from '../../../components/Loader'
import {
  examDelete,
  examDetail,
  fetchExamineesForExam,
  getCandidateReportListThunk,
  getExamDetailsThunk,
  getQuestionListOfDetailsExamThunk,
} from '../../../redux/exam/examActions'
import { Store } from '../../../redux/rootType'
import { dispatch } from '../../../redux/store'
import CandidatesTab from './exam_detail_tab/CandidatesTab'
import DetailsAndSettingTab from './exam_detail_tab/DetailsAndSettingTab'
import QuestionsTab from './exam_detail_tab/QuestionsTab'

const ExamDetails = () => {
  /**
   * ==================================================
   * const variable
   * ==================================================
   */
  const params = useParams()
  const examId = params['Id']
  const navigate = useNavigate()
  const location = useLocation()
  const navKey = location?.state?.tabKey || ''

  /**
   * ==================================================
   * Component state
   * ==================================================
   */
  const [show2, setShow2] = useState(false)
  const [open, setOpen] = useState(false)
  const [tabKey, setTabKey] = useState('first')

  /**
   * ==================================================
   * Data from redux store
   * ==================================================
   */

  const eachExamDetails = useSelector(
    (state: Store) => state?.examReducer?.examDetails
  ) as any

  const eachExamExaminees = useSelector(
    (state: Store) => state?.examReducer?.candidateList
  ) as any

  const examDetailsData = useSelector(
    (state: Store) => state?.examReducer?.examDetailsData
  ) as any

  const isLoadingExamDetailsData = useSelector(
    (state: Store) => state?.examReducer?.isLoadingExamDetailsData
  )

  const isLoading = useSelector((state: Store) => state?.examReducer?.isLoading)
  const questionsOfExamDetailsList = useSelector(
    (state: Store) => state?.examReducer?.questionsOfExamDetailsList
  )

  const candidateReportList = useSelector(
    (state: Store) => state?.examReducer?.candidateReportList
  ) as any

  /**
   * ==================================================
   * functionality
   * ==================================================
   */
  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  const handleDeleteExam = () => {
    dispatch(
      examDelete(examId, () => {
        navigate('/exams')
      })
    )
  }

  /**
   * ==================================================
   * use effect handling
   * ==================================================
   */

  useEffect(() => {
    dispatch(getCandidateReportListThunk({ id: examId }))
  }, [])

  useEffect(() => {
    dispatch(getExamDetailsThunk(examId))
    dispatch(getQuestionListOfDetailsExamThunk({ id: examId }))
  }, [])

  // useEffect(() => {
  //   dispatch(examDetail(examId))
  // }, [examId])

  // useEffect(() => {
  //   dispatch(fetchExamineesForExam(examId, 1))
  // }, [examId])

  return (
    <>
      {isLoadingExamDetailsData ? (
        <Loader height="500px" />
      ) : (
        <Container className="p-5 shadow-card-box">
          <div className="mb-3 pb-1">
            <Link to="/exams">
              <span className="text-muted">Exam</span>
            </Link>
            <Image src={RightBlackArrowIcon} className="mx-2" />
            <Link to="/exams">
              <span className="text-muted">{navKey}</span>
            </Link>
            <Image src={RightBlackArrowIcon} className="mx-2" />
            <span className="text-black">{examDetailsData?.name}</span>
          </div>
          <div className="card">
            <div className="card-body p-4 my-2 mx-3">
              <div className="d-flex align-items-center mb-4 pb-3">
                <div className="me-auto">
                  <div className="mb-2 pb-1 d-flex align-items-center">
                    <h4 className="mb-0">{examDetailsData?.name}</h4>
                    {navKey === 'Completed' && (
                      <span className="badge badge-info ms-2">Completed</span>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <Image src={CalenderIcon} />
                    <p className="custom-text-clr mb-0 ms-2">
                      Created on:
                      <span className="sub-title-clr ms-1 fw-bold">
                        {moment(examDetailsData?.created_at).format(
                          'DD MMM, YYYY'
                        )}
                      </span>
                    </p>
                    <span className="mx-2">|</span>
                    <Image
                      src={
                        `${process.env.REACT_APP_IMAGE_URL}${examDetailsData?.created_by?.avatar}` ||
                        UserImagesIcon
                      }
                    />
                    <span className="sub-title-clr ms-1 fw-bold">
                      {examDetailsData?.created_by?.first_name}{' '}
                      {examDetailsData?.created_by?.last_name}
                    </span>
                  </div>
                </div>
                {navKey === 'Created' && (
                  <Link
                    to={`/assign-exam/${examId}`}
                    state={{
                      examName: examDetailsData?.name,
                      examId: examId,
                      navKey: navKey,
                    }}
                  >
                    <button className="btn btn-primary ms-auto">
                      Assign Exam
                    </button>
                  </Link>
                )}
                {(navKey === 'Created' || navKey === 'Assigned') && (
                  <Dropdown className="ms-3">
                    <Dropdown.Toggle as={Link} id="dropdown-basic" to="#">
                      <Nav.Link href="#">
                        <Image src={VerticalDotsIcon} />
                      </Nav.Link>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {navKey !== 'Created' && (
                        <Dropdown.Item
                          onClick={() =>
                            navigate(
                              `/edit-exam-details/${examDetailsData?.id}`
                            )
                          }
                        >
                          Edit Exam Details
                        </Dropdown.Item>
                      )}
                      {navKey !== 'Assigned' && (
                        <Dropdown.Item onClick={handleDeleteExam}>
                          Delete Exam
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
              <h5 className="mb-3">Description</h5>
              <p className="sub-title-clr h6 fw-normal mb-0">
                {examDetailsData.description || 'NA'}
              </p>
            </div>
            <Tab.Container defaultActiveKey="first">
              <div className="card-header py-0 px-0">
                <Nav
                  variant="pills"
                  className="px-4 mx-3 tab-light-border align-items-end"
                >
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      onClick={() => setTabKey('first')}
                    >
                      Details & Settings
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onClick={() => setTabKey('second')}
                    >
                      Questions ({questionsOfExamDetailsList?.length || 0})
                    </Nav.Link>
                  </Nav.Item>
                  {navKey !== 'Created' ? (
                    <Nav.Item>
                      <Nav.Link
                        eventKey="third"
                        onClick={() => setTabKey('third')}
                      >
                        Candidates ({candidateReportList?.length || 0})
                      </Nav.Link>
                    </Nav.Item>
                  ) : null}
                </Nav>
              </div>
              <div className="card-body p-4 my-2 mx-3">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    {tabKey === 'first' ? (
                      <DetailsAndSettingTab
                        examDetailsData={examDetailsData}
                        navKey={navKey}
                      />
                    ) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    {tabKey === 'second' ? (
                      <QuestionsTab
                        questionsOfExamDetailsList={questionsOfExamDetailsList}
                      />
                    ) : null}
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    {tabKey === 'third' ? (
                      <CandidatesTab
                        eachExamExaminees={eachExamExaminees}
                        examName={examDetailsData?.name}
                      />
                    ) : null}
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Tab.Container>
          </div>
        </Container>
      )}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Comment & Rating</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body py-4">
          <div className="d-flex align-items-center assign-client-space">
            <div className="assign-client-modal pe-4">
              <h6 className="mb-1">James Dole</h6>
              <span className="sub-title-clr">jamesdole@gmail.com</span>
            </div>
            <div className="assign-client-modal px-4">
              <small className="fw-bold custom-text-clr">Role</small>
              <p className="mb-0 mt-1">Senior UI designer</p>
            </div>
            <div className="ms-4">
              <small className="fw-bold custom-text-clr">Experience</small>
              <p className="mb-0 mt-1">5 years </p>
            </div>
          </div>
          <p className="fw-bold mb-2">Rate</p>
          <div className="mb-3">
            <Image className="me-2" src={ReviewStarIcon} />
            <Image className="me-2" src={ReviewStarIcon} />
            <Image className="me-2" src={ReviewStarIcon} />
            <Image className="me-2" src={ReviewStarIcon} />
            <Image className="me-2" src={GrayReviewStarIcon} />
          </div>
          <Form.Group
            className="form-group"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>Comment</Form.Label>
            <textarea
              className="form-control"
              rows={5}
              value={'Potential candidate.'}
              style={{ resize: 'none' }}
              cols={8}
            ></textarea>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button className="btn  btn-primary px-4" type="button">
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ExamDetails
