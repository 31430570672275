import ReactApexChart from 'react-apexcharts'

const DonutChart: React.FC<{
  series?: number[]
  colors?: string[]
  labels?: string[]
  questionCount?: number
  type?:
    | 'line'
    | 'area'
    | 'bar'
    | 'histogram'
    | 'pie'
    | 'donut'
    | 'radialBar'
    | 'scatter'
    | 'bubble'
    | 'heatmap'
    | 'treemap'
    | 'boxPlot'
    | 'candlestick'
    | 'radar'
    | 'polarArea'
    | 'rangeBar'
    | ''
    | any
  height?: number
  showPercent?: boolean
  totalPercent?: number
  donutSize?: string
}> = ({
  series,
  colors,
  labels,
  questionCount,
  type,
  height,
  showPercent,
  totalPercent,
  donutSize,
}) => {
  const state = {
    series: series || [4, 5, 6, 7],
    options: {
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: donutSize || '70%',
            labels: {
              show: true,
              name: {
                show: true,
                color: '#000000',
                fontWeight: 600,
                offsetY: 30,
                formatter: (val) => {
                  return val
                },
              },
              value: {
                show: true,
                color: '#000000',
                fontWeight: 600,
                fontSize: '20',
                offsetY: -14,
                formatter: (val) => {
                  const formattedValue = showPercent
                    ? `${((val * 100) / (questionCount || 1)).toFixed(2)}%`
                    : val
                  return formattedValue
                },
              },
              total: {
                color: '#000000',
                fontWeight: 300,
                fontSize: '14',
                show: Boolean(questionCount),
                formatter: (val) => {
                  const formattedValue = showPercent
                    ? `${totalPercent}%`
                    : questionCount
                  return formattedValue
                },
              },
            },
          },
          stroke: {
            width: 0,
          },
        },
      },
      chart: {
        type: type || 'donut',
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      tooltip: {
        marker: { show: true },
        fillSeriesColor: false,
        theme: 'light',
      },

      colors: colors || ['#4CAF50', '#F0B357', '#F24A4A', '#E9E9E9'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
              height: 300,
            },
            legend: {
              display: false,
              position: 'bottom',
            },
          },
        },
      ],
      labels: labels || [
        'Correct',
        ['Partially', 'Correct'],
        'Incorrect',
        'Unattempted',
      ],
    },
  }
  return (
    <ReactApexChart
      options={state?.options as any}
      series={state?.series}
      type={(type || 'donut') as any}
      height={height || 200}
    />
  )
}
export default DonutChart
