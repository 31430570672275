import { Container, Image, Nav, Tab } from 'react-bootstrap'

import EditIcon from '../../../assets/images/edit-icon.svg'
import InactiveIcon from '../../../assets/images/inactive-img.svg'
import LocationOnblueIcon from '../../../assets/images/location_on_blue.svg'
import RecruiterAssign from '../../../assets/images/recruiter-assign.png'
import RightBlackArrowIcon from '../../../assets/images/right-black-arrow.svg'
import ActiveJob from './client_details_components/ActiveJob'
import ActivityOverview from './client_details_components/ActivityOverview'
import ClientDetail from './client_details_components/ClientDetail'
import ClosedJob from './client_details_components/ClosedJob'
import Email from './client_details_components/Email'
import SharedCandidates from './client_details_components/SharedCandidates'

const ClientsDetails = () => {
  return (
    <Container className="p-5">
      <div className="mb-3 pb-1">
        <span className="text-muted">Client</span>
        <Image src={RightBlackArrowIcon} className="mx-2" />
        <span className="text-black">ABC Software solution</span>
      </div>
      <div className="shadow-card-box">
        <div className="card">
          <div className="card-body pt-4 pb-0 px-4 m-3">
            <div className="d-flex align-items-end mb-3">
              <Image src={InactiveIcon} />
              <div className="ms-3">
                <div className="ms-3">
                  <small className="dark-gray">ABC Software Solution</small>
                  <br />
                  <Image src={LocationOnblueIcon} />{' '}
                  <small className="dark-gray">Chennai</small>
                </div>
              </div>
            </div>
            <span>Recruiter Assigned:</span>
            <Image className="mx-2" src={RecruiterAssign} />
            <Image src={EditIcon} />
          </div>
          <Tab.Container defaultActiveKey="first">
            <div className="card-header pb-0 pt-0">
              <Nav
                variant="pills"
                className="tab-light-border align-items-end px-3 mx-2"
              >
                <Nav.Item>
                  <Nav.Link eventKey="first">Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Active Openings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Closed Job</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">Shared Candidate(s)</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fifth">Activity Overview</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="six">Emails</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="card-body p-4 m-3">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <ClientDetail />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <ActiveJob />
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <ClosedJob />
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <SharedCandidates />
                </Tab.Pane>
                <Tab.Pane eventKey="fifth">
                  <ActivityOverview />
                </Tab.Pane>
                <Tab.Pane eventKey="six">
                  <Email />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
    </Container>
  )
}

export default ClientsDetails
