import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Collapse, Form, Image, InputGroup, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import ArrowDownIcon from '../../../../assets/images/arrow_down_icon.svg'
import QuestionPaperImage from '../../../../assets/images/question-paper.png'
import SearchIcon from '../../../../assets/images/search-icon.svg'
import { Loader } from '../../../../components/Loader'
import Pagination from '../../../../components/Pagination'
import { getQuestionListOfDetailsExamThunk } from '../../../../redux/exam/examActions'
import { Store } from '../../../../redux/rootType'
import { dispatch } from '../../../../redux/store'
import {
  dangerouslySetHTML,
  findDifficultyLevel,
  handleQuestionOptions,
} from '../../../../util/utils'
import ImageModal from '../exam_detail_component/ImageModal'

/**
 * ==================================================
 * Global code
 * ==================================================
 */
const tableHeading = [
  { id: 1, heading: '#' },
  { id: 2, heading: 'Question Text' },
  { id: 3, heading: 'Topic' },
  { id: 4, heading: 'Complexity' },
  { id: 5, heading: 'Score' },
  { id: 6, heading: 'Time' },
]
const itemsPerPage = 10
const QuestionsTab = ({ questionsOfExamDetailsList }) => {
  const params = useParams()
  const examId = params['Id']

  /**
   * ==================================================
   * Component state
   * ==================================================
   */
  const [open, setOpen] = useState(false)
  const [allQuestions, setAllQuestions] = useState<any>([])
  const [paginatedData, setPaginatedData] = useState(
    (allQuestions || [])?.slice(0, itemsPerPage)
  )
  const [page, setPage] = useState(1)
  const [questionId, setQuestionId] = useState(0)
  const [showImageModal, setShowImageModal] = useState(false)
  const [clickeImagePath, setClickedImagePath] = useState('')

  /**
   * ==================================================
   * Data from redux store
   * ==================================================
   */

  const isLoadingQuestionsOfExamDetailsList = useSelector(
    (state: Store) => state?.examReducer?.isLoadingQuestionsOfExamDetailsList
  )

  const _handleImageClick = (item: { image: string }) => {
    setClickedImagePath(item?.image)
    setShowImageModal(true)
  }

  const handleSearchDebounce = _.debounce((val: string) => {
    const payload = { id: examId, search: val.trim() }
    dispatch(getQuestionListOfDetailsExamThunk(payload))
  }, 500)

  /**
   * ==================================================
   * use effect handling
   * ==================================================
   */
  useEffect(() => {
    setAllQuestions(questionsOfExamDetailsList || [])
  }, [questionsOfExamDetailsList?.length])

  useEffect(() => {
    const newOffset = ((page - 1) * itemsPerPage) % (allQuestions?.length || 1)
    const newLimit = page * itemsPerPage
    setPaginatedData(allQuestions?.slice(newOffset, newLimit))
    setAllQuestions((prev) => prev)
  }, [page, allQuestions?.length])

  return (
    <React.Fragment>
      <InputGroup className="search-box mb-4 pb-1">
        <InputGroup.Text id="basic-addon1">
          <Image src={SearchIcon} />
        </InputGroup.Text>
        <Form.Control
          placeholder="Search"
          aria-label="Search"
          onChange={(e) => handleSearchDebounce(e?.target?.value)}
          aria-describedby="basic-addon1"
        />
      </InputGroup>
      <Table bordered hover responsive className="overflow-scroll">
        <thead>
          <tr className="border-0">
            {tableHeading.map(({ id, heading }) => {
              return <th key={id}>{heading}</th>
            })}
          </tr>
        </thead>
        <tbody className="accordian-table-body">
          {isLoadingQuestionsOfExamDetailsList ? (
            <tr>
              <td colSpan={8}>
                <Loader height={'500px'} />
              </td>
            </tr>
          ) : paginatedData?.length > 0 ? (
            (paginatedData || [])?.map((item, index) => {
              return (
                <>
                  <tr
                    className="border-0 collapse-row pointer"
                    onClick={() => {
                      setQuestionId(open ? index + 1 : 0)
                      setOpen(!open)
                    }}
                    aria-controls="example-collapse-text"
                    aria-expanded={questionId == index + 1 ? true : false}
                  >
                    <td className="text-center" style={{ width: '50px' }}>
                      <Image className="rotate-img" src={ArrowDownIcon} />
                    </td>

                    <td className="sub-title-clr">
                      <div dangerouslySetInnerHTML={{ __html: item?.text }} />
                    </td>
                    <td style={{ width: '100px' }}>
                      {item?.topic?.name || item?.topic?.join(',')}
                    </td>
                    <td style={{ width: '100px' }}>
                      {findDifficultyLevel(item?.difficulty_level)}
                    </td>
                    <td style={{ width: '100px' }}>{item?.score}</td>
                    <td style={{ width: '100px' }}>
                      {item?.time_to_answer} min
                    </td>
                  </tr>
                  <tr className="border-0">
                    <td colSpan={6} className="p-0 text-dark">
                      <Collapse in={questionId == index + 1 ? true : false}>
                        <div id="example-collapse-text" className="px-5 py-4">
                          <p className="fw-semibold mb-2">Question</p>
                          <p className="font-size-16 sub-title-clr mb-3">
                            {dangerouslySetHTML(item?.text)}
                          </p>
                          {item?.body_images?.length
                            ? item?.body_images?.map((image) => {
                                return (
                                  <Image
                                    src={
                                      image?.body_images
                                        ? `${process.env.REACT_APP_IMAGE_URL}${item?.body_images}`
                                        : QuestionPaperImage
                                    }
                                  />
                                )
                              })
                            : ''}
                          {item?.options?.length > 0 && (
                            <div className="mt-4 pt-3">
                              <p className="fw-semibold">
                                Options (Correct answer is marked in green)
                              </p>
                              {item?.options?.map((options, index) => {
                                return (
                                  <div className="mb-3 d-flex">
                                    <span
                                      className={
                                        options?.is_correct
                                          ? 'Active-answer'
                                          : 'Inactive-answer'
                                      }
                                    >
                                      {handleQuestionOptions(index)}
                                    </span>
                                    <span className="sub-title-clr font-size-16">
                                      {dangerouslySetHTML(options?.text)}
                                    </span>
                                    {options?.image ? (
                                      <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}${options?.image}?w=10&h=10`}
                                        srcSet={`${process.env.REACT_APP_IMAGE_URL}${options?.image}?w=10&h=1&dpr=2 2x`}
                                        alt={options?.id?.toString()}
                                        loading="lazy"
                                        className="border rounded mt-2 pe-zoom question-preview"
                                        onClick={() =>
                                          _handleImageClick(options)
                                        }
                                      />
                                    ) : null}
                                  </div>
                                )
                              })}
                            </div>
                          )}
                          {item?.explanation && (
                            <div className="mt-4 pt-3">
                              <p className="fw-semibold mb-3">Explanation </p>
                              <p className="sub-title-clr font-size-16">
                                {dangerouslySetHTML(item?.explanation)}
                              </p>
                            </div>
                          )}
                        </div>
                      </Collapse>
                    </td>
                  </tr>
                </>
              )
            })
          ) : (
            <tr>
              <td colSpan={8} className="text-center">
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {questionsOfExamDetailsList?.length > itemsPerPage ? (
        <Pagination
          page={page}
          setPage={setPage}
          tableCount={questionsOfExamDetailsList?.length}
          itemsPerPage={itemsPerPage}
        />
      ) : null}
      {showImageModal && clickeImagePath && (
        <ImageModal
          show={showImageModal}
          setShow={setShowImageModal}
          src={clickeImagePath}
        />
      )}
    </React.Fragment>
  )
}

export default QuestionsTab
