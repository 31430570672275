import { useState } from 'react'
import { Card, Col, Form, Image, InputGroup, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import SearchIcon from '../../../assets/images/search_icon.svg'

const HelpCenterFaq = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [show2, setShow2] = useState(false)
  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  const [show3, setShow3] = useState(false)
  const handleClose3 = () => setShow3(false)
  const handleShow3 = () => setShow3(true)

  const [show4, setShow4] = useState(false)
  const handleClose4 = () => setShow4(false)
  const handleShow4 = () => setShow4(true)

  const [show5, setShow5] = useState(false)
  const handleClose5 = () => setShow5(false)
  const handleShow5 = () => setShow5(true)

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <InputGroup
          className="search-box"
          style={{
            maxWidth: 200,
          }}
        ></InputGroup>
      </div>
      <Row className="mt-4 shadow-card-box mb-4 help-faq">
        <Col md={6}>
          <Card className="card-equal-height p-4">
            <Card.Header className="border-bottom-0 ">
              <h6 className="pb-2 text-black-300">Get Started</h6>
            </Card.Header>
            <Card.Body>
              {/* <h6 className="mb-2 text-black-300">Get Started</h6> */}
              <p className="mb-0 font-size-16 text-black-300">
                Checkout these top questions commonly asked by customers like
                yourself.
              </p>
            </Card.Body>
            <Card.Footer>
              <Link to="/HelpCenterFaqDetail" className="fw-semibold">
                View All (12)
              </Link>
            </Card.Footer>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="card-equal-height p-4">
            <Card.Header className="border-bottom-0">
              <h6 className="pb-2 text-black-300">Features</h6>
            </Card.Header>
            <Card.Body>
              {/* <h6 className="mb-2 text-black-300">Features</h6> */}
              <p className="mb-0 font-size-16 text-black-300">
                The most common questions that asked by new customers ask about
                Prep.study capabilities
              </p>
              {/* <Link to="HelpCenterFaqDetail" className="fw-semibold">
                View All (20)
              </Link> */}
            </Card.Body>
            <Card.Footer>
              <Link to="/HelpCenterFaqDetail" className="fw-semibold">
                View All (20)
              </Link>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4 shadow-card-box help-faq">
        <Col md={6}>
          <Card className="card-equal-height p-4 ">
            <Card.Header className="border-bottom-0">
              <h6 className="text-black-300 pb-2">Billing and payment</h6>
            </Card.Header>
            <Card.Body>
              <p className="mb-0 font-size-16 text-black-300">
                Common questions regarding billing and payments.
              </p>
              {/* <Link to="HelpCenterFaqDetail" className="fw-semibold">
                View All (7)
              </Link> */}
            </Card.Body>
            <Card.Footer>
              <Link to="/HelpCenterFaqDetail" className="fw-semibold">
                View All (7)
              </Link>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      {/* <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="text-black-300">What is Prep.study?</Accordion.Header>
          <Accordion.Body>
            <p className='custom-text-clr'>
              For an organization seeking talent from campuses or the open market, we have the tools and expertise to streamline your recruitment process and fulfil your hiring needs. With our cutting-edge platform, HR professionals can easily conduct comprehensive assessments tailored to their requirements.
            </p>
            <Link to="" className="fw-semibold">
              Read More
            </Link>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header className="text-black-300">How to create question paper?</Accordion.Header>
          <Accordion.Body>
            <p className='custom-text-clr'>
              For an organization seeking talent from campuses or the open market, we have the tools and expertise to streamline your recruitment process and fulfil your hiring needs. With our cutting-edge platform, HR professionals can easily conduct comprehensive assessments tailored to their requirements.
            </p>
            <Link to="" className="fw-semibold">
              Read More
            </Link>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header className="text-black-300">Is candidates proctoring available?</Accordion.Header>
          <Accordion.Body>
            <p className='custom-text-clr'>
              For an organization seeking talent from campuses or the open market, we have the tools and expertise to streamline your recruitment process and fulfil your hiring needs. With our cutting-edge platform, HR professionals can easily conduct comprehensive assessments tailored to their requirements.
            </p>
            <Link to="" className="fw-semibold">
              Read More
            </Link>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header className="text-black-300">How long candidates database recorded?</Accordion.Header>
          <Accordion.Body>
            <p className='custom-text-clr'>
              For an organization seeking talent from campuses or the open market, we have the tools and expertise to streamline your recruitment process and fulfil your hiring needs. With our cutting-edge platform, HR professionals can easily conduct comprehensive assessments tailored to their requirements.
            </p>
            <Link to="" className="fw-semibold">
              Read More
            </Link>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header className="text-black-300">Can i bulk upload resumes?</Accordion.Header>
          <Accordion.Body>
            <p className='custom-text-clr'>
              For an organization seeking talent from campuses or the open market, we have the tools and expertise to streamline your recruitment process and fulfil your hiring needs. With our cutting-edge platform, HR professionals can easily conduct comprehensive assessments tailored to their requirements.
            </p>
            <Link to="" className="fw-semibold">
              Read More
            </Link>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
    </>
  )
}

export default HelpCenterFaq
