import { Formik, useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { Loader } from '../../../components/Loader'
import CreateExamDetails from '../../../pages/create_exam/create_new_exam/CreateExamDetails'
import CreateExamSettingPreview from '../../../pages/create_exam/create_new_exam/CreateExamSettingPreview'
import CreateExamQuestionPaperLibrary from '../../../pages/create_exam/create_new_exam/exam_from_library/CreateExamQuestionPaperLibrary'
import { getTopicListThunk } from '../../../redux/common/commonActions'
import {
  examCreate,
  generateQuestionsByTopicListThunk,
  suitableForListThunk,
} from '../../../redux/exam/examActions'
import { Store } from '../../../redux/rootType'
import { dispatch } from '../../../redux/store'
import { errorToast } from '../../../util/utils'
import { ExamSchema } from '../../../validations/examSchema/examSchema'
import CreateExamQuestionsLibrary from './auto_generate_exam/CreateExamQuestionsLibrary'
import CreateExamQuestionPaperStore from './exam_from_store/CreateExamQuestionPaperStore'
import CreateNewExamFormModel from './FormModel/CreateNewExamFormModel'

const { formId, formField } = CreateNewExamFormModel

const ExamDetails = () => {
  const navigate = useNavigate()

  /**
   * ==================================================
   * Component state
   * ==================================================
   */

  const [activeStep, setActiveStep] = useState(0)
  const [formValues, setFormValues] = useState<Record<string, any>>({})
  const [isSaveAndAssign, setIsSaveAndAssign] = useState(false)

  /**
   * ==================================================
   * Data from redux store
   * ==================================================
   */

  const isExamLoading = useSelector(
    (state: Store) => state?.examReducer?.isLoading
  )

  const isLoadingQuestionListByTopics = useSelector(
    (state: Store) => state?.examReducer?.isLoadingQuestionListByTopics
  )

  /**
   * ==================================================
   * functionality
   * ==================================================
   */

  const formikCreateExam = useFormik({
    initialValues: {
      exam_name: '',
      description: '',
      exam_type: [], // Checking whether this can be avoided
      question_paper_selection_type: 0,
      topics: [],
      question_count: 10,
      specifyQuestionCountCheckbox: false,
      question_paper_ids_selected: [],
      // question_paper_ids_selected: 0,
      exam_duration: 0,
      passing_percentage: 10,
      passingPercentageCheckbox: false,
      negativeMarkingCheckbox: false,
      negative_marking: 1,
      randomise: false,
      is_exam_proctored: false,
      violation_limit: 1,
      isAutoGenerateQuestions: true,
      question_set_data: [],
    },
    validationSchema: ExamSchema,
    onSubmit: (values) => {
      // Empty onSubmit function
    },
  })
  const { errors, touched, values } = formikCreateExam
  const _handleSubmit = (isSaveAndAssignExam) => {
    const suitableData = values?.exam_type?.map((item: any) => item?.value)
    const topicsData = values?.topics?.map((item: any) => item?.value)
    const payload: any = {
      name: values?.exam_name,
      description: values?.description,
      examination_type: 'REC',
      suitable_for: suitableData,
      question_count: values?.question_count,
      // is_question_generated: true,
      // allocation_type: 'AG',
      // question_set_data: questionSet,
      duration: +values?.exam_duration,
      passing_percentage: values?.passingPercentageCheckbox
        ? +values?.passing_percentage
        : 50,
      randomize: values?.randomise,
      // agency: 1,
    }

    if (values?.question_paper_selection_type === 1) {
      payload.allocation_type = 'HP'
      payload.question_paper = values?.question_paper_ids_selected[0]
    } else if (values?.question_paper_selection_type === 2) {
      payload.allocation_type = 'AG'
      payload.topics = topicsData
      payload.question_set_data = JSON.stringify(values?.question_set_data)
      payload.is_question_generated = true
    }

    if (values?.negativeMarkingCheckbox) {
      payload.evaluation_mode = 'NM'
      payload.negative_marking = +values?.negative_marking
    }
    if (values.is_exam_proctored) {
      payload.allow_to_move_count = values?.violation_limit
      payload.allow_to_move_time = 5
    }
    if (isSaveAndAssignExam) {
      dispatch(
        examCreate(
          payload,
          () => navigate('/exams'),
          () => '',
          (res) =>
            navigate(`/assign-exam/${res?.data?.exam}`, {
              state: {
                examId: res?.data?.exam,
                navKey: 'Created',
              },
            })
        )
      )
    } else {
      dispatch(
        examCreate(
          payload,
          () => navigate('/exams'),
          () => ''
        )
      )
    }
  }

  const handleExamDetailsStep = (step) => {
    setActiveStep(step + 1)
  }

  const incrementStep = (step) => {
    if (activeStep === 0 && values?.question_paper_selection_type === 2) {
      const topicsData = formikCreateExam?.values?.topics?.map(
        (item: any) => item?.value
      )
      dispatch(
        generateQuestionsByTopicListThunk(
          {
            question_count: formikCreateExam?.values?.question_count,
            topics: topicsData,
          },
          () => {
            setActiveStep(step + 1)
          },
          (err) => {
            errorToast(err?.message?.distribution)
          }
        )
      )
    } else {
      setActiveStep(step + 1)
    }
  }

  const decrementStep = (step) => {
    setActiveStep(step - 1)
    formikCreateExam.setFieldValue('isAutoGenerateQuestions', true)
  }

  const updateFormValues = (values, step) => {
    setFormValues(values)
  }

  function _renderStepContent(step, values, errors) {
    switch (step) {
      case 0:
        return (
          <CreateExamDetails
            formField={formField}
            formikCreateExam={formikCreateExam}
          />
        )
      case 1:
        if (values.question_paper_selection_type === 2) {
          return (
            <CreateExamQuestionsLibrary formikCreateExam={formikCreateExam} />
          )
        } else if (values.question_paper_selection_type === 3) {
          navigate('/shop')
          break
          // return <CreateExamQuestionPaperStore />
        } else {
          return (
            <CreateExamQuestionPaperLibrary
              formField={formField}
              formikCreateExam={formikCreateExam}
              updateFormValues={updateFormValues}
              activeStep={step}
              formValues={formValues}
            />
          )
        }
      case 2:
        return (
          <CreateExamSettingPreview
            formField={formField}
            formikCreateExam={formikCreateExam}
            updateFormValues={updateFormValues}
            activeStep={step}
            formValues={formValues}
          />
        )
      default:
        return <div>Not Found</div>
    }
  }

  useEffect(() => {
    dispatch(suitableForListThunk())
    dispatch(getTopicListThunk())
  }, [])

  return (
    <>
      <div className="fixed-top">
        <div className="progress-header">
          <Link to="/exams" className="h5 text-white mb-0">
            <IoMdArrowBack className="me-2" />
            Create New Exam
          </Link>
          <p className="text-white h6 fw-normal ms-auto mb-0">
            Step {activeStep + 1}/3
          </p>
        </div>
        <div className="progress-line">
          <div className="progress" style={{ width: '33%' }}></div>
        </div>
      </div>
      <div className="card border-0">
        <div className="card-body p-0">
          <Container className="p-5" style={{ marginTop: '80px' }}>
            <Formik
              initialValues={{
                ...formikCreateExam.initialValues,
                ...formValues,
              }}
              validationSchema={formValues?.disableAll ? null : ExamSchema}
              onSubmit={_handleSubmit}
              enableReinitialize
              values={formValues}
            >
              {isExamLoading ? (
                <Loader height="500px" />
              ) : (
                _renderStepContent(activeStep, values, errors)
              )}
            </Formik>
          </Container>
        </div>
        <div className="card-footer px-5 text-end">
          {activeStep > 0 && (
            <button
              className="btn btn-light"
              onClick={() => decrementStep(activeStep)}
            >
              Back
            </button>
          )}
          {activeStep < 2 && (
            <button
              onClick={() => incrementStep(activeStep)}
              className="btn btn-primary ms-3"
              disabled={
                !(
                  formikCreateExam.values?.exam_name?.trim() &&
                  formikCreateExam.values?.exam_name?.trim()?.length < 150 &&
                  formikCreateExam.values.description?.length < 1024 &&
                  // formikCreateExam.values?.description &&
                  formikCreateExam.values?.exam_type?.length &&
                  formikCreateExam.values?.question_paper_selection_type &&
                  (formikCreateExam.values?.question_paper_selection_type !== 2
                    ? true
                    : formikCreateExam.values?.topics?.length) &&
                  (formikCreateExam.values?.specifyQuestionCountCheckbox
                    ? formikCreateExam.values?.question_count >= 10 &&
                      formikCreateExam.values?.question_count <= 200
                    : true) &&
                  !!formikCreateExam.values?.isAutoGenerateQuestions &&
                  !(formikCreateExam.values?.question_paper_selection_type ===
                    1 &&
                  activeStep === 1 &&
                  formikCreateExam.values?.question_paper_ids_selected
                    ?.length === 0
                    ? true
                    : false) &&
                  !isLoadingQuestionListByTopics
                )
              }
            >
              Next Step
            </button>
          )}
          {activeStep == 2 && (
            <button
              className="btn btn-outline-dark ms-3"
              disabled={
                !(
                  +formikCreateExam.values?.exam_duration >= 1 &&
                  +formikCreateExam.values?.exam_duration <= 360 &&
                  (!formikCreateExam.values?.passingPercentageCheckbox
                    ? true
                    : +formikCreateExam.values?.passing_percentage >= 10 &&
                      +formikCreateExam.values?.passing_percentage <= 95) &&
                  (!formikCreateExam.values?.negativeMarkingCheckbox
                    ? true
                    : +formikCreateExam.values?.negative_marking > 0 &&
                      +formikCreateExam.values?.negative_marking < 101)
                )
              }
              onClick={() => {
                _handleSubmit(true)
              }}
            >
              Save & Assign
            </button>
          )}
          {activeStep == 2 && (
            <button
              className="btn btn-primary ms-3"
              type="submit"
              onClick={() => {
                _handleSubmit(false)
              }}
              disabled={
                !(
                  +formikCreateExam.values?.exam_duration >= 1 &&
                  +formikCreateExam.values?.exam_duration <= 360 &&
                  (!formikCreateExam.values?.passingPercentageCheckbox
                    ? true
                    : +formikCreateExam.values?.passing_percentage >= 10 &&
                      +formikCreateExam.values?.passing_percentage <= 95) &&
                  (!formikCreateExam.values?.negativeMarkingCheckbox
                    ? true
                    : +formikCreateExam.values?.negative_marking > 0 &&
                      +formikCreateExam.values?.negative_marking < 101)
                )
              }
            >
              Save
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default ExamDetails
