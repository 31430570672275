import moment from 'moment'
import React from 'react'
import { Form, Image, InputGroup } from 'react-bootstrap'
import Select from 'react-select'

import AssignUserIcon from '../../../../assets/images/assign-user-icon.svg'
import { selectViolationTime } from '../../../../util/utils'

const DetailsAndSettingTab = ({ examDetailsData, navKey }) => {
  return (
    <>
      <div className="d-flex align-items-center mb-5">
        <div>
          <h6 className="mb-2 pb-1">No of Questions</h6>
          <p className="Gray26 h6 fw-normal mb-0">
            {examDetailsData?.question_count}
          </p>
        </div>
        <div className="vetical-saprater mx-5" />
        <div>
          <h6 className="mb-2 pb-1">Exam Duration</h6>
          <p className="Gray26 h6 fw-normal mb-0">
            {examDetailsData?.duration} min
          </p>
        </div>
        {navKey !== 'Created' && (
          <>
            <div className="vetical-saprater mx-5" />
            <div>
              <h6 className="mb-2 pb-1">Exam Start Date</h6>
              <p className="Gray26 h6 fw-normal mb-0">
                {moment(examDetailsData?.valid_from).format(
                  'DD MMM, YYYY; h:mm A'
                )}{' '}
              </p>
            </div>
            <div className="vetical-saprater mx-5" />
            <div>
              <h6 className="mb-2 pb-1">Exam Valid Till</h6>
              <p className="Gray26 h6 fw-normal mb-0">
                {moment(examDetailsData?.valid_to).format(
                  'DD MMM, YYYY; h:mm A'
                )}
              </p>
            </div>
          </>
        )}
      </div>
      <h6>Topics Included</h6>
      <ul className="mb-5 ps-4">
        {examDetailsData?.suitable_for?.map((item) => {
          return <li key={item?.id}>{item?.name}</li>
        })}
      </ul>
      <h6>Suitable for</h6>
      <ul className="mb-5 ps-4">
        {examDetailsData?.topics?.map((item) => {
          return <li key={item?.id}>{item?.name}</li>
        })}
      </ul>
      <h6>Passing Grade</h6>
      <div className="d-flex mb-5 align-items-center">
        <p className="h6 mb-0 sub-title-clr fw-normal">
          Set passing grade percentage
        </p>
        <InputGroup className="ms-2 w-25">
          <Form.Control
            aria-label="Exam Duration"
            name="passing_percentage"
            disabled
            value={Math.floor(examDetailsData?.passing_percentage || 30)}
          />
          <InputGroup.Text>%</InputGroup.Text>
        </InputGroup>
      </div>
      {/* <div className="d-flex mb-5">
        <h6 className="mb-0 fw-normal sub-title-clr">
          Set passing grade percentage
        </h6>
      </div> */}
      <h6>Negative Marking</h6>
      <div className="d-flex mb-5 align-items-center">
        <p className="h6 mb-0 sub-title-clr fw-normal">
          Set negative marking percentage
        </p>
        <InputGroup className="ms-2 w-25">
          <Form.Control
            aria-label="Exam Duration"
            name="passing_percentage"
            disabled
            value={
              examDetailsData?.evaluation_mode === 'NM'
                ? parseInt(examDetailsData?.negative_marking)
                : 0
            }
          />
          <InputGroup.Text>%</InputGroup.Text>
        </InputGroup>
      </div>
      {/* <div className="d-flex mb-5">
        <h6 className="mb-0 fw-normal sub-title-clr">
          Set negative marking percentage
        </h6>
      </div> */}
      <h6>Randomise</h6>
      <div className="d-flex mb-5">
        <h6 className="mb-0 fw-normal sub-title-clr">
          Questions and answers options will be shuffled for each candidates.
        </h6>
      </div>
      {examDetailsData?.allow_to_move_count && (
        <>
          <h6>Exam Proctoring</h6>
          <div className="d-flex mb-3 pb-1">
            <h6 className="mb-0 fw-normal sub-title-clr">
              Images of candidate will be captured for every 5 sec. Violation
              limit can be set for exam termination.
            </h6>
          </div>
          <Form.Label>Violation limit</Form.Label>
          <Select
            name="violation_limit"
            hideSelectedOptions={false}
            options={selectViolationTime}
            className="w-25"
            classNamePrefix="react-select"
            placeholder="Select Violation Limit"
            // isMulti
            isDisabled
            closeMenuOnSelect={true}
            value={
              selectViolationTime?.find(
                (type) => type.value === examDetailsData?.allow_to_move_count
              ) || null
            }
          />
        </>
      )}
      <hr className="my-5" />
      <h6 className="mb-3 pb-1">Created by</h6>
      <div className="d-flex">
        <Image
          src={
            `${process.env.REACT_APP_IMAGE_URL}${examDetailsData?.created_by?.avatar}` ||
            AssignUserIcon
          }
        />
        <div className="ms-2 ps-1">
          <h6 className="mb-0">
            {`${examDetailsData?.created_by?.first_name} ${examDetailsData?.created_by?.last_name}`}
          </h6>
          <small className="custom-text-clr">
            {examDetailsData?.created_by?.role?.label}
          </small>
        </div>
      </div>
    </>
  )
}

export default DetailsAndSettingTab
