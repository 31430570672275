import { useEffect, useState } from 'react'
import { Col, Container, Form, Image, InputGroup, Row } from 'react-bootstrap'
import { IoMdArrowBack } from 'react-icons/io'
import { Link } from 'react-router-dom'
import Select from 'react-select'

import BlackQuestionPaperIcon from '../../../assets/images/black-question-paper.svg'
import DescriptionblackIcon from '../../../assets/images/description_black.svg'
import StopWatchIcon from '../../../assets/images/stop-watch.svg'
import { selectViolationTime } from '../../../util/utils'

const CreateExamSettingPreview = (props) => {
  const [showPassingPercentage, setShowPassingPercentage] = useState(false)
  const [showNegativeMarking, setShowNegativeMarking] = useState(false)
  const [showExamProctoring, setShowExamProctoring] = useState(false)

  useEffect(() => {
    props.updateFormValues({
      ...props.formValues,
      ...{
        exam_duration: props.formikCreateExam.values?.exam_duration,
        passing_percentage: props.formikCreateExam.values?.passing_percentage,
        passingPercentageCheckbox:
          props.formikCreateExam.values?.passingPercentageCheckbox,
        negative_marking: props.formikCreateExam.values?.negative_marking,
        randomise: props.formikCreateExam.values?.randomise,
        is_exam_proctored: props.formikCreateExam.values?.is_exam_proctored,
        violation_limit: props.formikCreateExam.values?.violation_limit,
      },
    })
  }, [props.formikCreateExam.values])

  return (
    <>
      <div className="fixed-top">
        <div className="progress-header">
          <Link to="/exams" className="h5 text-white mb-0">
            <IoMdArrowBack className="me-2" />
            Create New Exam
          </Link>
          <p className="text-white h6 fw-normal ms-auto mb-0">
            Step {props.activeStep + 1}/3
          </p>
        </div>
        <div className="progress-line">
          <div className="progress" style={{ width: '66%' }}></div>
        </div>
      </div>
      <Container className="p-5" style={{ marginTop: '80px' }}>
        <Row className="justify-content-center">
          <Col lg={6}>
            <div className="d-flex align-items-center mb-4 pb-3">
              <Image src={BlackQuestionPaperIcon} />
              <h5 className="ms-2 mb-0">Exam Settings & Preview</h5>
            </div>
            <div className="d-flex align-items-start mb-4">
              <Image src={StopWatchIcon} />
              <div className="ms-2 ps-1">
                <h6 className="mb-0">Exam Duration</h6>
                <div className="d-flex align-items-center">
                  <p className="h6 mb-0 sub-title-clr fw-normal">
                    Set custom exam duration
                  </p>
                  <InputGroup className="ms-2 w-25">
                    <Form.Control
                      aria-label="Exam Duration"
                      name="exam_duration"
                      value={props.formikCreateExam.values?.exam_duration}
                      onChange={props.formikCreateExam.handleChange}
                      onBlur={props.formikCreateExam.handleBlur}
                    />
                    <InputGroup.Text>min</InputGroup.Text>
                  </InputGroup>
                </div>
                {props.formikCreateExam.errors.exam_duration &&
                  props.formikCreateExam.touched.exam_duration && (
                    <label className="text-danger">
                      {props.formikCreateExam.errors.exam_duration as string}
                    </label>
                  )}
              </div>
            </div>
            <div className="d-flex align-items-start mb-4">
              <Form.Check
                type={'checkbox'}
                onClick={() => {
                  props.formikCreateExam.setFieldValue(
                    'passingPercentageCheckbox',
                    !showPassingPercentage
                  )
                  setShowPassingPercentage(!showPassingPercentage)
                }}
                name="passing_percentage"
              />
              <div className="ms-2 ps-1">
                <h6 className="mb-0">Passing Percentage</h6>
                {showPassingPercentage && (
                  <div className="d-flex align-items-center">
                    <p className="h6 mb-0 sub-title-clr fw-normal">
                      Set passing grade percentage
                    </p>
                    <InputGroup className="ms-2 w-25">
                      <Form.Control
                        aria-label="Exam Duration"
                        name="passing_percentage"
                        value={
                          props.formikCreateExam.values?.passing_percentage
                        }
                        onChange={props.formikCreateExam.handleChange}
                        onBlur={props.formikCreateExam.handleBlur}
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                  </div>
                )}
                {props.formikCreateExam.values?.passingPercentageCheckbox &&
                  props.formikCreateExam.errors.passing_percentage &&
                  props.formikCreateExam.touched.passing_percentage && (
                    <label className="text-danger">
                      {
                        props.formikCreateExam.errors
                          .passing_percentage as string
                      }
                    </label>
                  )}
              </div>
            </div>
            <div className="d-flex align-items-start mb-4">
              <Form.Check
                type={'checkbox'}
                onClick={() => {
                  props.formikCreateExam.setFieldValue(
                    'negativeMarkingCheckbox',
                    !showNegativeMarking
                  )
                  setShowNegativeMarking(!showNegativeMarking)
                }}
                name="negative_marking"
              />
              <div className="ms-2 ps-1">
                <h6 className="mb-0">Negative Marking</h6>
                {showNegativeMarking && (
                  <div className="d-flex align-items-center">
                    <p className="h6 mb-0 sub-title-clr fw-normal">
                      Set negative marking percentage
                    </p>
                    <InputGroup className="ms-2 w-25">
                      <Form.Control
                        aria-label="Exam Duration"
                        name="negative_marking"
                        value={props.formikCreateExam.values?.negative_marking}
                        onChange={props.formikCreateExam.handleChange}
                        onBlur={props.formikCreateExam.handleBlur}
                      />
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup>
                  </div>
                )}
                {props.formikCreateExam.values.negativeMarkingCheckbox &&
                  props.formikCreateExam.errors.negative_marking &&
                  props.formikCreateExam.touched.negative_marking && (
                    <label className="text-danger">
                      {props.formikCreateExam.errors.negative_marking as string}
                    </label>
                  )}
              </div>
            </div>
            <div className="d-flex align-items-start mb-4">
              <Form.Check
                type={'checkbox'}
                name="randomise"
                value={props.formikCreateExam.values?.randomise}
                onChange={props.formikCreateExam.handleChange}
                onBlur={props.formikCreateExam.handleBlur}
              />
              <div className="ms-2 ps-1">
                <h6 className="mb-2">Randomise</h6>
                <p className="h6 mb-0 sub-title-clr fw-normal">
                  Questions and answers options will be shuffled for each
                  candidates.
                </p>
              </div>
            </div>
            <div className="d-flex align-items-start mb-4">
              <Form.Check
                type={'checkbox'}
                onClick={() => setShowExamProctoring(!showExamProctoring)}
                name="is_exam_proctored"
                value={props.formikCreateExam.values?.is_exam_proctored}
                onChange={props.formikCreateExam.handleChange}
                onBlur={props.formikCreateExam.handleBlur}
              />
              <div className="ms-2 ps-1">
                <h6 className="mb-2">Exam Proctoring</h6>
                {showExamProctoring && (
                  <div>
                    <div className="d-flex mb-3">
                      <Image src={DescriptionblackIcon} />
                      <a
                        href={`${process.env.PUBLIC_URL}/sample-files/Sample Exam Report.pdf`}
                        download={`Sample Exam Report.pdf`}
                        className="text-primary fw-bold ms-1"
                      >
                        {' '}
                        View sample report
                      </a>
                    </div>
                    <p className="h6 mb-3 pb-1 sub-title-clr fw-normal">
                      Images of candidate will be captured for every 5 sec.
                      Violation limit can be set for exam termination.
                    </p>
                    <Form.Label>Violation limit</Form.Label>
                    <Select
                      name="violation_limit"
                      hideSelectedOptions={false}
                      options={selectViolationTime}
                      className="react-select-container"
                      classNamePrefix="react-select"
                      placeholder="Select Violation Limit"
                      // isMulti
                      closeMenuOnSelect={true}
                      onChange={(value) => {
                        props.formikCreateExam.setFieldValue(
                          'violation_limit',
                          value?.value
                        )
                      }}
                      value={
                        selectViolationTime?.find(
                          (type) =>
                            type.value ===
                            props.formikCreateExam.values.violation_limit
                        ) || null
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CreateExamSettingPreview
