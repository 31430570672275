import { TextField } from '@mui/material'
import { useFormikContext } from 'formik'
import _ from 'lodash'
import { useRef, useState } from 'react'

interface Props {
  value: string
  rowId: number
  setFilteredResumeData: any
  setFieldValue: any
  changeKey: string
  type?: string
  errorMessage?: string
  handleError?: any
  regex?: RegExp
  setAllResumes?: any
  filteredResumesData?: any
  allResumes?: any
}

const EditableLabel = ({
  value,
  rowId,
  setFilteredResumeData,
  setFieldValue,
  changeKey,
  type = 'text',
  regex,
  errorMessage,
  setAllResumes,
  filteredResumesData,
}: Props) => {
  const COUNTRY_CODE = '+91'
  const formikContext = useFormikContext()
  const [isEditing, setIsEditing] = useState(false)
  const [inputValue, setInputValue] = useState(value)
  const [error, setError] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const handleLabelClick = () => {
    if (changeKey === 'phone_number' && value?.includes(COUNTRY_CODE)) {
      setInputValue(value?.split(' ')?.[1]?.split('-')?.join('') || '')
    }
    setIsEditing(true)
    setTimeout(() => inputRef?.current?.focus(), 0) // Focus on input after rendering
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const handleError = () => {
    // const regex = /^\+\d{1,3}\d{10}$/
    if (regex && regex?.test(inputValue)) {
      setError('')
      setIsEditing(false)
      return { error: false }
    }
    setError(errorMessage || 'Please enter valid value')
    return { error: errorMessage || 'Please enter valid value' }
  }

  const handleInputBlur = () => {
    const { error } = handleError()
    // setIsEditing(false)
    // // Save the changed value or perform any action here (e.g., API call)
    const prevDataCopy = _.cloneDeep(filteredResumesData)
    const selectedCandidate = (formikContext?.values as Record<string, any>)
      ?.selectedCandidate
    const currentRowIndex = prevDataCopy?.findIndex(
      (data) => data?.id === rowId
    )
    const candidateIndex = selectedCandidate?.findIndex(
      (data) => data?.id === rowId
    )

    prevDataCopy[currentRowIndex][`extracted_data`][`${changeKey}`] = !error
      ? inputValue
      : ''

    prevDataCopy[currentRowIndex][`error`] = {
      ...(prevDataCopy[currentRowIndex][`error`] || {}),
      [`${changeKey}`]: error,
    }
    if (candidateIndex > -1) {
      selectedCandidate[candidateIndex][`extracted_data`][`${changeKey}`] =
        !error ? inputValue : ''
      prevDataCopy[currentRowIndex][`error`] = {
        ...(prevDataCopy[currentRowIndex][`error`] || {}),
        [`${changeKey}`]: error,
      }
    }

    setFieldValue('selectedCandidate', selectedCandidate)
    setFieldValue('resume_data', prevDataCopy)
    setFilteredResumeData(prevDataCopy)
    setAllResumes &&
      setAllResumes((prev) => {
        const prevDataCopy = _.cloneDeep(prev)
        const currentRowIndex = prevDataCopy?.findIndex(
          (data) => data?.id === rowId
        )
        prevDataCopy[currentRowIndex][`extracted_data`][`${changeKey}`] =
          inputValue
        return prevDataCopy
      })
  }

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      e?.preventDefault()
      e?.stopPropagation()
      handleInputBlur()
    }
  }

  const getFormattedNumber = (value) => {
    if (value?.includes(COUNTRY_CODE) || value === '--') {
      return value
    }

    return `${COUNTRY_CODE} ${value?.split('')?.slice(0, 5)?.join('')}-${value
      ?.split('')
      ?.slice(5, 10)
      ?.join('')}`
  }

  return (
    <div onClick={() => !isEditing && handleLabelClick()}>
      {isEditing ? (
        <TextField
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyUp={handleInputKeyPress}
          inputRef={inputRef}
          type={type}
        />
      ) : (
        <span>
          {changeKey === 'phone_number' ? getFormattedNumber(value) : value}
        </span>
      )}
      {error && <span className="formik-error ms-0 d-block">{error}</span>}
    </div>
  )
}

export default EditableLabel
