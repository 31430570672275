import { useState } from 'react'
import {
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Nav,
  Row,
  Tab,
} from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import { IoMdArrowBack } from 'react-icons/io'
import { Link } from 'react-router-dom'

import AccountDetails from '../../../assets/images/Help_Contact.svg'
import ChangePassword from '../../../assets/images/Help_faq.svg'
import Bankdetails from '../../../assets/images/Help_res.svg'
import SearchIcon from '../../../assets/images/search_icon.svg'
import HelpCenterResource from './HelpCenterResource'
import HelpCenterResourceContact from './HelpCenterResourceContact'

const HelpCenterFaqDetail = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const [show2, setShow2] = useState(false)
  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  const [show3, setShow3] = useState(false)
  const handleClose3 = () => setShow3(false)
  const handleShow3 = () => setShow3(true)

  const [show4, setShow4] = useState(false)
  const handleClose4 = () => setShow4(false)
  const handleShow4 = () => setShow4(true)

  const [show5, setShow5] = useState(false)
  const handleClose5 = () => setShow5(false)
  const handleShow5 = () => setShow5(true)

  return (
    <Container className="px-4 py-5 shadow-card-box">
      <div className="d-flex align-items-center mb-4">
        <Link to="" className="fw-semibold">
          Help center
        </Link>
        <i className="fa fa-angle-right fa-fw ms-1 me-1"></i>
        <Link to="" className="fw-semibold">
          FAQ
        </Link>
        <i className="fa fa-angle-right fa-fw ms-1 me-1"></i>
        <p className="mb-0 light-gray-text fw-medium">Get Started</p>
      </div>

      <div className="verical-tab mb-5">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col lg={4}>
              <div className="card">
                <div className="card-body">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <Image className="me-2" src={AccountDetails} />
                        FAQ
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="me-2" eventKey="second">
                        <Image className="me-2" src={Bankdetails} />
                        Resource
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className="mb-0" eventKey="third">
                        <Image className="me-2" src={ChangePassword} />
                        Contact Us
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className="card">
                <div className="card-body p-4">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="mb-4 d-flex align-items-start">
                        <div>
                          <h1 className="font-size-22 text-body fw-semibold mb-1">
                            Get Started
                          </h1>
                          <p className="sub-title-clr font-size-16 mb-0">
                            Checkout these top questions commonly asked by
                            customers like yourself.
                          </p>
                        </div>
                        <InputGroup
                          className="search-box ms-auto"
                          style={{
                            maxWidth: 200,
                          }}
                        ></InputGroup>
                      </div>
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="text-black-300">
                            What is Prep.study?
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="custom-text-clr">
                              For an organization seeking talent from campuses
                              or the open market, we have the tools and
                              expertise to streamline your recruitment process
                              and fulfil your hiring needs. With our
                              cutting-edge platform, HR professionals can easily
                              conduct comprehensive assessments tailored to
                              their requirements.
                            </p>
                            <Link to="" className="fw-semibold">
                              Read More
                            </Link>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header className="text-black-300">
                            How to create question paper?
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="custom-text-clr">
                              For an organization seeking talent from campuses
                              or the open market, we have the tools and
                              expertise to streamline your recruitment process
                              and fulfil your hiring needs. With our
                              cutting-edge platform, HR professionals can easily
                              conduct comprehensive assessments tailored to
                              their requirements.
                            </p>
                            <Link to="" className="fw-semibold">
                              Read More
                            </Link>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header className="text-black-300">
                            Is candidates proctoring available?
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="custom-text-clr">
                              For an organization seeking talent from campuses
                              or the open market, we have the tools and
                              expertise to streamline your recruitment process
                              and fulfil your hiring needs. With our
                              cutting-edge platform, HR professionals can easily
                              conduct comprehensive assessments tailored to
                              their requirements.
                            </p>
                            <Link to="" className="fw-semibold">
                              Read More
                            </Link>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header className="text-black-300">
                            How long candidates database recorded?
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="custom-text-clr">
                              For an organization seeking talent from campuses
                              or the open market, we have the tools and
                              expertise to streamline your recruitment process
                              and fulfil your hiring needs. With our
                              cutting-edge platform, HR professionals can easily
                              conduct comprehensive assessments tailored to
                              their requirements.
                            </p>
                            <Link to="" className="fw-semibold">
                              Read More
                            </Link>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header className="text-black-300">
                            Can i bulk upload resumes?
                          </Accordion.Header>
                          <Accordion.Body>
                            <p className="custom-text-clr">
                              For an organization seeking talent from campuses
                              or the open market, we have the tools and
                              expertise to streamline your recruitment process
                              and fulfil your hiring needs. With our
                              cutting-edge platform, HR professionals can easily
                              conduct comprehensive assessments tailored to
                              their requirements.
                            </p>
                            <Link to="" className="fw-semibold">
                              Read More
                            </Link>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <HelpCenterResource />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <HelpCenterResourceContact />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </Container>
  )
}

export default HelpCenterFaqDetail
